import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme: Theme) => ({
  taxDisclaimer: {
    textTransform: 'uppercase',
  },
  priceDisclaimer: {
    fontStyle: 'italic',
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'lato',
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}))

interface PriceDisclaimerProps {
  showTaxDisclaimer?: boolean
}

const PriceDisclaimer = ({
  showTaxDisclaimer = false,
}: PriceDisclaimerProps) => {
  const classes = useStyles()

  return (
    <>
      {showTaxDisclaimer && (
        <div className={clsx(classes.priceDisclaimer, classes.taxDisclaimer)}>
          <FormattedMessage
            id="PRICE_WITHOUT_NATIONAL_TAXES"
            defaultMessage="PRICE WITHOUT NATIONAL TAXES"
          />
        </div>
      )}
    </>
  )
}

export default PriceDisclaimer
