import { FC, useEffect, useState } from 'react'
import { Link } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useGlobalModal } from '@src/utils/useGlobalModal'
import PromoBundleModal from '../PromoBundleModal'
import { useRouter } from 'next/router'
import { SitePreference, useCurrentUser } from '@src/utils/useCurrentUser'
import { determineCatalogType } from '@src/utils/searchUtils'
import { usePromotionalBundlesLazyQuery } from '@src/queries/PromotionalBundlesQuery.generated'
import CircleLoader from '../CircleLoader'
import { sendBuyInKitClickEvent } from '@src/utils/analytics'
import { BuyInKItEventActionEnum } from '@utils/analytics/enums'
import { ModalCloseReason } from '../ResponsiveModal/ResponsiveModal'

const useStyles = makeStyles((theme: Theme) => ({
  promoButton: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: theme.spacing(1 / 2),
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    },
  },
}))

interface PromoBundleButtonProps {
  materialNumber: string
  brand?: string
  productName: string
  erp_type?: string[] | null
}
const PromoBundleButton: FC<PromoBundleButtonProps> = ({
  materialNumber,
  brand,
  productName,
  erp_type,
}) => {
  const classes = useStyles()
  const { setGlobalModalComponent, clearGlobalModalComponent } =
    useGlobalModal()
  const router = useRouter()
  const { getSitePreference, isBlueErpIntegrationEnabled } = useCurrentUser()
  const { focus = '', catalog = '' } = router.query || {}
  const catalogType = determineCatalogType(focus || catalog)
  const orgId = getSitePreference(SitePreference.CatalogFilter) || null
  const passERPType =
    isBlueErpIntegrationEnabled && erp_type && erp_type?.length > 0
  const [showModal, setShowModal] = useState(false)

  const queryVariables = {
    variables: {
      brand: brand ?? router.query.brand,
      productNumber: materialNumber,
      materialIds: [materialNumber],
      catalogType,
      orgId,
      ...(passERPType && { erp_type }),
    },
  }
  const [
    getPromotionalBundlesData,
    { data: promoBundlesData, loading: loadingPromotionalBundles },
  ] = usePromotionalBundlesLazyQuery()

  const handleClose = (reason?: string) => {
    setShowModal(false)
    let eventAction: BuyInKItEventActionEnum
    if (
      reason === ModalCloseReason.BackdropClick ||
      reason === ModalCloseReason.EscapeKeyDown
    ) {
      eventAction = BuyInKItEventActionEnum.IgnorePromoBundleModal
    } else {
      eventAction = BuyInKItEventActionEnum.ClosePromoBundleModal
    }

    if (promoBundlesData?.getPromotionalBundlesForProduct?.length) {
      sendBuyInKitClickEvent(
        materialNumber,
        promoBundlesData?.getPromotionalBundlesForProduct,
        eventAction
      )
    }
    clearGlobalModalComponent()
  }

  useEffect(() => {
    if (
      promoBundlesData?.getPromotionalBundlesForProduct?.length &&
      showModal
    ) {
      setGlobalModalComponent(
        <PromoBundleModal
          onClose={(reason) => handleClose(reason)}
          loading={loadingPromotionalBundles}
          bundleData={promoBundlesData.getPromotionalBundlesForProduct}
          productName={productName}
          materialNumber={materialNumber}
          setShowModal={setShowModal}
        />
      )
      sendBuyInKitClickEvent(
        materialNumber,
        promoBundlesData?.getPromotionalBundlesForProduct,
        BuyInKItEventActionEnum.OpenPromoBundleModal
      )
    }
  }, [promoBundlesData, showModal])

  const handlePromoBundle = () => {
    setShowModal(true)
    getPromotionalBundlesData(queryVariables)
  }

  return (
    <>
      <Link
        component="div"
        className={classes.promoButton}
        onClick={handlePromoBundle}
        data-testid="promo-bundle-button"
      >
        <FormattedMessage
          id="AVAILABLE_IN_KIT_AT_DISCOUNTED_PRICE"
          defaultMessage="Buy in a Bundle"
        />
      </Link>
      {loadingPromotionalBundles && <CircleLoader />}
    </>
  )
}

export default PromoBundleButton
