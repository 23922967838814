import React, { useCallback } from 'react'
import { flatMap } from 'lodash'
import { useRouter } from '@src/routes'
import { useIntl, FormattedMessage } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Facet } from '@src/types/graphql-types'
import messages from '@utils/messages'
import { isTermlessSearch } from '@utils/termlessSearch'
import { sendSearchFacetEvent } from '@utils/analytics'
import { buildFacetEventPayload } from '@utils/analytics/facets'
import { sendEvent } from '@utils/analytics/coreAnalytics'
import clsx from 'clsx'
import {
  useSearchQuery,
  FacetChangeAction,
} from '../../../pages/[country]/[language]/search/[searchTerm]'
import HandleMarkup from '@src/components/HandleMarkup'
import SelectionChip from '@src/components/SelectionChip'
import { formatFacetName } from '../Facets/FormattedFacetMessage'
import { getValidSearchQuery, SearchFocusType } from '@src/utils/searchUtils'
import { alignSupSub } from '@src/styles/utils/alignSupSub'

const useStyles = makeStyles((theme: Theme) => ({
  facetMarkup: {
    marginRight: theme.spacing(1),
    '& sub, & sup': {
      verticalAlign: 'baseline',
      position: 'relative',
      top: '0.4em',
    },
    '& sup': {
      top: '-0.4em',
    },
  },
  searchTerm: {
    marginRight: theme.spacing(1),
  },
  hideAtMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(-1),
  },
  chipWrapper: {
    padding: theme.spacing(1),
    ...alignSupSub,
  },
  keywordChipWrapper: {
    padding: theme.spacing(1),
  },
  facetsMenuWrapper: {
    display: 'none',
    padding: theme.spacing(3),
    background: theme.palette.background.grey,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 5,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    marginTop: theme.spacing(6),
  },
  facetsMenuLabel: {
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
}))

interface ChipLabelProps {
  facetLabel: string
  facetKey: string
  facets: Facet[] | undefined
}

const ChipLabel: React.FC<ChipLabelProps> = ({
  facetLabel,
  facetKey,
  facets,
}) => {
  const router = useRouter()
  const classes = useStyles()
  const intl = useIntl()
  const validSearchQuery = getValidSearchQuery({
    focus: router.query.focus,
  })
  const matchKeyToDisplayName = useCallback(
    (selectedFacetKey: string, facets: Facet[] | undefined) => {
      if (
        !facets?.length &&
        validSearchQuery.focus === SearchFocusType.Chromatograms &&
        selectedFacetKey.toUpperCase() === 'FACET_WEB_TITERTEST_APP'
      ) {
        return intl.formatMessage(messages[selectedFacetKey.toUpperCase()])
      }

      if (!facets) return ''
      const facetMatch = facets.find((facet) => facet.key === selectedFacetKey)
      return facetMatch?.key && messages[facetMatch.key.toUpperCase()]
        ? intl.formatMessage(messages[facetMatch.key.toUpperCase()])
        : formatFacetName(facetMatch?.key)
    },
    [intl]
  )

  const facetDisplayName = matchKeyToDisplayName(facetKey, facets)

  return (
    <>
      <HandleMarkup
        className={classes.facetMarkup}
        value={`${facetDisplayName}:`}
      />
      <HandleMarkup value={facetLabel} />
    </>
  )
}

const removeFacetActions = (
  allFacets,
  facet,
  appliedFilters,
  queryActions,
  query
): void => {
  queryActions.handleSelectedFacetChange({
    key: facet.key,
    options: [facet.label],
    action: FacetChangeAction.Remove,
  })

  // Legacy UA filter event which should be deleted once Analytics team greenlights it.
  sendSearchFacetEvent(
    query.term || '',
    facet.key,
    facet.label,
    false,
    appliedFilters,
    allFacets
  )

  const facetElementText = `${facet.key}:${facet.label}`

  const facetRibbonPayload = buildFacetEventPayload({
    action: 'remove filter',
    component: 'filter ribbon',
    element_type: 'filter ribbon box',
    element_text: facetElementText?.toLowerCase(),
    filter_name: facet.label,
    filter_name_count: facet.count,
    filter_category: facet.key,
  })

  sendEvent({ payload: facetRibbonPayload })
}

interface FacetSelectionsProps {
  facets?: Facet[]
  isStructureSearch: boolean
}

const FacetSelections: React.FC<FacetSelectionsProps> = ({
  facets,
  isStructureSearch,
}) => {
  const [query, selectedFacets, queryActions] = useSearchQuery()
  const allOptions = flatMap(facets, 'options')

  const mappedSelectedFacets = flatMap(selectedFacets, (facet) =>
    facet.options.map((option) => {
      const optionData = allOptions.find((aOpt) => aOpt.value === option)

      return {
        key: facet.key,
        label: option,
        count: optionData?.count,
      }
    })
  )

  const { formatMessage } = useIntl()
  const router = useRouter()
  const classes = useStyles()
  const isKeywordChipShown =
    query.term && !isStructureSearch && !isTermlessSearch(router)

  if (mappedSelectedFacets.length === 0 && !isKeywordChipShown) return null

  return (
    <div className={classes.facetsMenuWrapper}>
      <Typography variant="caption" className={classes.facetsMenuLabel}>
        <FormattedMessage
          id="APPLIED_FILTERS_HEADING"
          defaultMessage="Applied Filters:"
        />
      </Typography>
      <div className={clsx(classes.hideAtMobile, classes.chipsContainer)}>
        {isKeywordChipShown && (
          <div className={classes.keywordChipWrapper}>
            <SelectionChip
              ariaLabel={`${formatMessage(
                messages.SELECTION_CHIP_REMOVE_FILTER_1
              )} ${query.term} ${formatMessage(
                messages.SELECTION_CHIP_REMOVE_TO_PERFORM_FACET_SEARCH
              )}`}
              label={
                <>
                  <span className={classes.searchTerm}>
                    {formatMessage(messages.KEYWORD)}:
                  </span>
                  <span>{`'${query.term}'`}</span>
                </>
              }
              dataTestId={'query-term-chip'}
              onDelete={() =>
                queryActions.handleTermRemoval(selectedFacets, facets)
              }
            />
          </div>
        )}
        {facets &&
          mappedSelectedFacets.map((facet) => {
            return (
              <div
                className={classes.chipWrapper}
                key={`${facet.key}:${facet.label}`}
              >
                <SelectionChip
                  ariaLabel={`${formatMessage(
                    messages.SELECTION_CHIP_REMOVE_FILTER_1
                  )} ${facet.label} ${formatMessage(
                    messages.SELECTION_CHIP_REMOVE_FILTER_2
                  )}`}
                  onDelete={() =>
                    removeFacetActions(
                      facets,
                      facet,
                      selectedFacets,
                      queryActions,
                      query
                    )
                  }
                  label={
                    <ChipLabel
                      facetLabel={facet.label}
                      facetKey={facet.key}
                      facets={facets}
                    />
                  }
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default FacetSelections
