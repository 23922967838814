import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import ResponsiveDataAccordionItem, {
  ResponsiveDataAccordionItemProps,
} from './ResponsiveDataAccordionItem'
import { DataColumn } from '../DataTable/DataTable'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  resultsWrapper: {
    listStyle: 'none',
    backgroundColor: theme.palette.common.white,
    '&:first-child > div': {
      borderTop: `solid 1px ${theme.palette.grey[400]}`,
    },
  },
  data: {
    borderBottom: `solid 1px ${theme.palette.grey[400]}`,
  },
  noResults: {
    padding: theme.spacing(6, 4),
  },
}))

interface ResponsiveDataAccordionProps<DataType> {
  data: DataType[]
  columns: DataColumn[]
  accordionTitleColumn: DataColumn
  accordionSubtitleColumn?: DataColumn
  noResultsComponent?: React.ReactNode
  itemProps?: Partial<ResponsiveDataAccordionItemProps<DataType>>
}

/**
 * Responsive Data Accordion is meant to be used alongside the DataTable Component.
 *
 * It takes columns, data, and a noResultsComponent similar to the DataTable.
 *
 * It Also requires A column to be associated as the accordionTitleColumn
 * Optionally you can also associate another column as the accordionSubtitleColumn.
 *
 * If the accordionTitle is also a Link
 * this component will pass back a noLink prop along with
 * the data; only when calling for the title.
 *
 * This prop can be used when columns are being defined to
 * disable the Link element in the case of a noLink prop.
 *
 * You can also pass a type for the data element when using this component.
 */
const ResponsiveDataAccordion = <DataType extends {}>({
  data,
  noResultsComponent,
  columns,
  accordionTitleColumn,
  accordionSubtitleColumn,
  itemProps,
}: ResponsiveDataAccordionProps<DataType>) => {
  const classes = useStyles()

  return (
    <ul className={classes.root}>
      {data?.length > 0 ? (
        data.map((order, index) => (
          <li className={classes.resultsWrapper} key={index}>
            <div key={index} className={classes.data}>
              <ResponsiveDataAccordionItem<DataType>
                data={order}
                columns={columns}
                accordionTitleColumn={accordionTitleColumn}
                accordionSubtitleColumn={accordionSubtitleColumn}
                {...itemProps}
              />
            </div>
          </li>
        ))
      ) : (
        <div className={classes.noResults}>{noResultsComponent}</div>
      )}
    </ul>
  )
}

export default ResponsiveDataAccordion
