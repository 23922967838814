import React from 'react'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core/styles'

const FacetsSkeleon = () => {
  const theme = useTheme()

  return (
    <div>
      {Array(3)
        .fill({})
        .map((_, index) => (
          <Box key={index} mb={6}>
            <Box mb={4}>
              <Divider light />
            </Box>
            <Box
              height={22}
              width={0.75}
              borderRadius="borderRadius"
              bgcolor={theme.palette.grey['50']}
              mb={4}
            />
            {Array(5)
              .fill({})
              .map((_, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  pr={4}
                  mb={4}
                >
                  <Box
                    height={24}
                    width={24}
                    borderRadius="borderRadius"
                    bgcolor={theme.palette.grey['50']}
                    mr={4}
                  />
                  <Box
                    flex={1}
                    height={20}
                    width={20}
                    borderRadius="borderRadius"
                    bgcolor={theme.palette.grey['50']}
                  />
                </Box>
              ))}
          </Box>
        ))}
    </div>
  )
}

export default FacetsSkeleon
