import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ClickAwayListener, Tooltip } from '@material-ui/core'
import CheckMarkIcon from '@src/icons/CheckMarkIcon'
import { FormattedMessage } from 'react-intl'
import { sendClipboardCopyEvent } from '@src/utils/analytics'

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(11),
  },
  materialNumberText: {
    cursor: 'pointer',
    transition: 'background 100ms',
    '&:hover': {
      // Replicate browser text selected color
      backgroundColor: '#afd2fc',
    },
  },
}))

interface MaterialTextWithCopyProps {
  materialNumber: string
}

const TooltipContent = () => {
  return (
    <span>
      <CheckMarkIcon style={{ marginRight: 6 }} fontSize="inherit" />
      <FormattedMessage
        id="COPIED_TO_CLIPBOARD"
        defaultMessage="Copied to Clipboard"
      />
    </span>
  )
}

const MaterialTextWithCopy: React.FC<MaterialTextWithCopyProps> = ({
  materialNumber,
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const handleClick = (materialNumber: string) => {
    sendClipboardCopyEvent({
      action: 'click to copy',
      label: [materialNumber],
    })
    navigator.clipboard.writeText(materialNumber)
    handleTooltipOpen()
    setTimeout(() => {
      handleTooltipClose()
    }, 750)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<TooltipContent />}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
      >
        <span
          aria-hidden="true"
          onClick={() => handleClick(materialNumber)}
          onKeyDown={() => handleClick(materialNumber)}
          className={classes.materialNumberText}
        >
          {materialNumber}
        </span>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default MaterialTextWithCopy
