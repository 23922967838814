import {
  Box,
  Button,
  Link as MUILink,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { Link } from '@src/components/Link'
import SpacingBox from '@src/components/SpacingBox'
import {
  useRouter,
  sellerRoutes,
  ContactSellerReasonType,
  homeRoute,
  loginRoute,
} from '@src/routes'
import {
  CatalogImage,
  CatalogType,
  ProductAttribute,
} from '@src/types/graphql-types'
import { useMultiCountryPricingAndAvailabilityLazyQuery } from '@src/queries/MultiCountryPricingAndAvailabilityQuery.generated'
import { usePricingAndAvailabilityLazyQuery } from '@src/queries/PricingAndAvailabilityQuery.generated'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import {
  sendDealerPricingEvent,
  sendProductDetailEvent,
} from '@src/utils/analytics'
import { GAMapProducts } from '@utils/analytics/types'
import { AddToCartPagesEnum } from '@utils/analytics/enums'
import { sendPricingAndAvailabilityErrorEvent } from '@utils/analytics/pricingAndAvailability'
import { deObfuscateCatalogID, ObfuscatedCatalogID } from '@utils/catalogKeyIds'
import messages from './messages'
import { determineCatalogType, getSellerName } from '@utils/searchUtils'
import { Abilities, useAbility } from '@utils/useAbility'
import { SitePreference, useCurrentUser } from '@utils/useCurrentUser'
import { ApolloError } from 'apollo-boost'
import React, { useEffect, useCallback, useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { MaterialsInput } from '../QuickOrder'
import MobileProductPriceAvailability from './MobileProductPriceAvailability'
import MobileProductPriceAvailabilityForm from './MobileProductPriceAvailabilityForm'
import ProductPartnerInfo from './ProductPartnerInfo'
import ProductPriceAvailabilityError from './ProductPriceAvailabilityError'
import ProductPriceAvailabilityForm, {
  ProductPriceAvailabilityFormProps,
} from './ProductPriceAvailabilityForm'
import { AddToCartFormValues } from './ProductPriceAvailabilityFormBody'
import ProductPriceAvailabilitySkeleton from './ProductPriceAvailabilitySkeleton'
import ProductInfoMessage from './ProductInfoMessage'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { EventValues } from '@sial/common-utils'
import { sendPricingAndAvailabilityEvent } from '@src/utils/analytics/pricingAndAvailability'
import SignInBanner from '../SignInBanner'
import { useUserSession } from '@src/utils/useUserSession'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import isbot from '@src/utils/isBot'
import {
  TrackedAvailabilityMessageContext,
  TrackedAvailabilityMessageProvider,
} from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import { useChinaUser } from '@src/utils/useChinaUser'
import { useMultiCountryPricingTabs } from '@src/components/ProductPriceAvailability/useMultiCountryPricingTabs'

interface ProductPriceAvailabilityProps {
  productNumber: string
  productDescription?: string
  images?: CatalogImage[]
  queryFocus?: string // intended to override the default focus param
  brandKey?: string // brand is not required to query pricing
  materialIds?: string[]
  LoadingComponent?: React.ComponentType<{ displaySDS?: boolean }>
  ErrorComponent?: React.ComponentType<{ error?: ApolloError }>
  PricingComponent?: ProductPriceAvailabilityFormProps['PricingComponent']
  displayPromotionalBundlesAnchor?: boolean
  displayAllCountries?: boolean
  checkForPb?: boolean
  ctas?: PdpFieldsFragment['forms']
  productName?: string
  displaySellerName?: string
  productDetailEvent?: boolean
  gaType?: AddToCartPagesEnum
  gaMapProducts?: GAMapProducts
  addToList?: (replacementMaterials: MaterialsInput[]) => void
  pricingExpanded?: boolean
  condensed?: boolean
  sendPandAEvent?: boolean
  isMarketplace?: boolean
  marketplaceSellerId?: string
  marketplaceOfferId?: string
  productAttributes?: ProductAttribute[]
  usePricingComponentOnMobile?: boolean
  onAddToCartResult?: () => void
  setProductIsAvailable?: (boolean) => void
  displayCompareMarketplaceSellerContact?: boolean
  productKey?: string
  erp_type?: string[] | null
  quantity?: number
  productId?: string
  isBBE?: boolean | null
}

const useStyles = makeStyles((theme: Theme) => ({
  bulkOrderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  dchainMessage: {
    alignItems: 'center',
    borderRadius: 0,
    justifyContent: 'center',
    padding: theme.spacing(0.2, 0),
  },
  tabs: {
    color: theme.palette.primary.main,
    '& .Mui-selected': {
      color: theme.palette.common.black,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.common.black,
      height: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(5),
      '& .MuiTabs-indicator': {
        height: theme.spacing(0.5),
      },
    },
  },
  tab: {
    fontSize: theme.typography.pxToRem(18),
    paddingBottom: 0,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(6),
    textTransform: 'capitalize',
    minWidth: 'auto',
    opacity: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      marginRight: 0,
    },
  },
  page: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(10),
    margin: theme.spacing(2, 0),
    border: `2px solid #222`,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  pricingTableContainer: {
    margin: 0,
  },
}))

const ProductPriceAvailability: React.FC<ProductPriceAvailabilityProps> = ({
  queryFocus,
  productNumber,
  productDescription,
  images,
  materialIds,
  brandKey,
  LoadingComponent = ProductPriceAvailabilitySkeleton,
  ErrorComponent = ProductPriceAvailabilityError,
  PricingComponent,
  displayPromotionalBundlesAnchor,
  displayAllCountries = true,
  checkForPb,
  ctas,
  productName,
  productId,
  displaySellerName,
  productDetailEvent,
  gaType = AddToCartPagesEnum.Others,
  gaMapProducts,
  addToList,
  pricingExpanded = false,
  condensed = false,
  sendPandAEvent = false,
  isMarketplace = false,
  marketplaceSellerId,
  marketplaceOfferId,
  productAttributes,
  usePricingComponentOnMobile,
  onAddToCartResult,
  setProductIsAvailable,
  displayCompareMarketplaceSellerContact = false,
  productKey,
  erp_type,
  quantity = 1,
  isBBE,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { focus = '', catalogType = '', context = '' } = router.query || {}
  const catalogId = deObfuscateCatalogID(context as ObfuscatedCatalogID)
  const {
    getSitePreference,
    isDTAQZuCustomer,
    isMultiCountryUser,
    currentUser,
    isBlueErpIntegrationEnabled,
    isB2BUser,
    userErpType,
  } = useCurrentUser()
  const {
    userSession: { country },
  } = useUserSession()
  const isChinaUser = useChinaUser()
  const { authorized, anonymous } = useAbility(Abilities.canAddToCart)
  const displayMultiCountryResults = isMultiCountryUser && displayAllCountries
  const displaySDS = productNumber.startsWith('CB_')
  const [
    getPricingForProduct,
    {
      data: productData,
      loading: productLoading,
      error: productError,
      refetch: productRefetch,
    },
  ] = usePricingAndAvailabilityLazyQuery({
    fetchPolicy: isDTAQZuCustomer ? 'no-cache' : 'cache-first',
  })

  const ctx = useContext(TrackedAvailabilityMessageContext)

  const [
    getPricingForMultiCountryProducts,
    {
      data: multiCountryData,
      loading: multiCountryLoading,
      error: multiCountryError,
      refetch: multiCountryRefetch,
      called,
    },
  ] = useMultiCountryPricingAndAvailabilityLazyQuery()

  const preferredDealerId = isDTAQZuCustomer
    ? getSitePreference(SitePreference.PreferredDealerId)
    : null
  const [currentDtaqDealer, setCurrentDtaqDealer] = useState(preferredDealerId)
  const [tabValue, setTabValue] = useState(country)

  const handleTabChange = (_, value) => {
    setTabValue(value)
  }

  const orgId = getSitePreference(SitePreference.CatalogFilter)
  const marketplace = isMarketplace ? CatalogType.Marketplace : null
  const bbe = isBBE ? CatalogType.Buildingblocks : undefined
  const passERPType =
    isBlueErpIntegrationEnabled && erp_type && erp_type?.length > 0
  const queryVariables = {
    variables: {
      productNumber,
      materialIds,
      brand: brandKey,
      quantity,
      catalogType: determineCatalogType(
        bbe || marketplace || catalogType || queryFocus || focus || catalogId
      ),
      orgId,
      checkForPb: !isChinaUser && checkForPb,
      displaySDS,
      dealerId: '',
      checkBuyNow: true,
      productKey,
      ...(passERPType && { erp_type }),
    },
  }

  const multiCountryPricingTabs = useMultiCountryPricingTabs(
    multiCountryData,
    handleTabChange
  )

  useEffect(() => {
    const ua = window.navigator.userAgent
    const isBot = isbot(ua)
    // NOTE: Updating query variables with isBot check for P&A call-
    // to return the data from DB or ML only without hitting ERP.
    const updatedQueryVariables = {
      variables: {
        ...queryVariables.variables,
        cachedPriceOnly: isBot,
      },
    }
    if (productNumber) {
      displayMultiCountryResults
        ? getPricingForMultiCountryProducts(queryVariables)
        : getPricingForProduct(updatedQueryVariables)
    }
    // NOTE: adding queryVariables to the deps array causes a hang in this component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayMultiCountryResults,
    getPricingForMultiCountryProducts,
    getPricingForProduct,
    productNumber,
    quantity,
  ])

  useEffect(() => {
    if (
      displayMultiCountryResults &&
      multiCountryData?.getPricingForMultiCountryProducts?.length
    ) {
      const [firstMultiCountry] =
        multiCountryData?.getPricingForMultiCountryProducts
      setTabValue(firstMultiCountry?.country ?? country)
    }
  }, [multiCountryData?.getPricingForMultiCountryProducts])

  useEffect(() => {
    const materialPricing = productData?.getPricingForProduct?.materialPricing

    if (productDetailEvent && (materialPricing || productError)) {
      const userType = { isBlueErpIntegrationEnabled, isB2BUser, userErpType }
      sendProductDetailEvent(
        materialPricing || [],
        productName,
        productNumber,
        brandKey,
        isMarketplace,
        erp_type || [],
        userType
      )
    }
    if (
      productError &&
      gaType !== AddToCartPagesEnum.PaperDetails &&
      gaType !== AddToCartPagesEnum.ProductCompare
    ) {
      sendPricingAndAvailabilityErrorEvent(productNumber, productError)
      if (typeof setProductIsAvailable === 'function') {
        setProductIsAvailable(false)
      }
    }
  }, [productData, productError])

  const [dchainMessage, setDchainMessage] = React.useState<
    string | null | undefined
  >()

  useEffect(() => {
    if (multiCountryData?.getPricingForMultiCountryProducts?.length) {
      const selectedCountryData =
        multiCountryData?.getPricingForMultiCountryProducts.find(
          (countryData) => countryData?.country === tabValue
        )

      return setDchainMessage(selectedCountryData?.dchainMessage)
    }

    setDchainMessage(productData?.getPricingForProduct?.dchainMessage)
  }, [productData, multiCountryData])

  useEffect(() => {
    if (
      dchainMessage &&
      gaType !== AddToCartPagesEnum.PaperDetails &&
      gaType !== AddToCartPagesEnum.ProductCompare
    ) {
      sendPricingAndAvailabilityErrorEvent(
        productNumber,
        undefined,
        dchainMessage
      )
    }
  }, [dchainMessage])

  useEffect(() => {
    if (
      dchainMessage ||
      productError ||
      (multiCountryError &&
        pricingExpanded &&
        gaType === AddToCartPagesEnum.PaperDetails)
    ) {
      sendPricingAndAvailabilityErrorEvent(
        productNumber,
        productError || multiCountryError,
        dchainMessage || ''
      )
    }
  }, [pricingExpanded])

  useEffect(() => {
    const materialPricing = !!multiCountryData
      ?.getPricingForMultiCountryProducts?.length
      ? multiCountryData?.getPricingForMultiCountryProducts[0]?.materialPricing
      : undefined
    if (productDetailEvent && (materialPricing || multiCountryError)) {
      sendProductDetailEvent(
        materialPricing || [],
        productName,
        productNumber,
        brandKey
      )
    }
    if (
      multiCountryError &&
      gaType !== AddToCartPagesEnum.PaperDetails &&
      gaType !== AddToCartPagesEnum.ProductCompare
    ) {
      sendPricingAndAvailabilityErrorEvent(productNumber, multiCountryError)
    }
  }, [multiCountryLoading])

  const signInGAEvent = () => {
    sendPricingAndAvailabilityEvent('Sign In to View Pricing', productNumber, {
      clickType: gaType,
      clickAction: 'Sign In to View Pricing',
      productId: productNumber,
      productName,
      productBrand: brandKey,
      component: 'modal',
      elementType: 'link',
      linkText: 'sign in',
      linkUrl: `${loginRoute.index()}?redirect=${encodeURIComponent(
        router.asPath || homeRoute.index()
      )}`,
    })
  }

  const handlePriceUpdateForDealer = async (
    dealerId: string | null,
    _values: AddToCartFormValues,
    _materialPricing: ValidMaterialPricingDetailFragment[],
    _setFieldValue: (field: string, value: any) => void,
    setIsModalOpen: (value: React.SetStateAction<boolean>) => void,
    dealerName: Maybe<string> | undefined
  ) => {
    if (dealerId) {
      setCurrentDtaqDealer(dealerId)
      queryVariables.variables.dealerId = dealerId
    }
    getPricingForProduct(queryVariables)
    sendDealerPricingEvent(productNumber, dealerName)

    return setIsModalOpen(false)
  }

  const updateQuantity = useCallback(
    (quantity: number) => {
      if (displayMultiCountryResults) {
        return multiCountryRefetch({ ...queryVariables.variables, quantity })
      }
      return productRefetch({ ...queryVariables.variables, quantity })
    },
    [productData || multiCountryData]
  )

  useEffect(() => {
    const paDchainMessage = productData?.getPricingForProduct?.dchainMessage
    if (
      !productLoading &&
      sendPandAEvent &&
      paDchainMessage &&
      gaType === AddToCartPagesEnum.ProductCompare
    ) {
      sendPricingAndAvailabilityErrorEvent(
        productNumber,
        undefined,
        paDchainMessage
      )
    }
  }, [productLoading])

  const hasData = !!productData || !!multiCountryData

  if ((productLoading || multiCountryLoading) && !hasData) {
    return <LoadingComponent displaySDS={displaySDS} />
  }

  if (
    productError ||
    multiCountryError ||
    (called && !(multiCountryData || productData))
  ) {
    return (
      <TrackedAvailabilityMessageProvider
        {...ctx}
        brand={brandKey}
        item={productNumber}
      >
        <ErrorComponent
          handlePriceUpdateForDealer={handlePriceUpdateForDealer}
          materialPricing={[
            {
              materialNumber: queryVariables.variables.productNumber,
              brand: brandKey,
              listPrice: 0,
              productGroupSBU: '',
            },
          ]}
          error={productError || multiCountryError}
          productNumber={productNumber}
          isMarketplace={isMarketplace}
          sellerName={displaySellerName || getSellerName(productAttributes)}
          marketplaceSellerId={marketplaceSellerId}
          marketplaceOfferId={marketplaceOfferId}
        />
      </TrackedAvailabilityMessageProvider>
    )
  }

  if (
    !!productData?.getPricingForProduct.productInfo &&
    productData?.getPricingForProduct.productInfo.length
  ) {
    const productInfoMessage =
      productData?.getPricingForProduct.productInfo[0]?.message

    if (productInfoMessage) {
      return (
        <ProductInfoMessage
          country={country}
          brandKey={brandKey ?? ''}
          productNumber={
            productData?.getPricingForProduct.productInfo[0]?.productNumber ??
            ''
          }
          productMessage={productInfoMessage}
        />
      )
    }
  }

  return displayMultiCountryResults ? (
    <>
      {dchainMessage &&
        dchainMessage !== 'PRODUCT_DISCONTINUED_BUT_AVAILABLE_FOR_PURCHASE' && (
          <Box my={2}>
            <Alert
              severity="error"
              icon={<HighlightOffRoundedIcon />}
              className={classes.dchainMessage}
            >
              <FormattedMessage
                {...messages[dchainMessage]}
                values={{
                  productNumber: <b>{productNumber}</b>,
                }}
              />
            </Alert>
          </Box>
        )}
      {!usePricingComponentOnMobile && isMobile && (
        <Box>
          <Typography variant="h3">
            <FormattedMessage
              id="PRICING_AND_AVAILABILITY_HEADING"
              defaultMessage="Select an Option"
            />
          </Typography>
        </Box>
      )}

      {!!multiCountryData?.getPricingForMultiCountryProducts?.length
        ? multiCountryPricingTabs
        : null}

      {!!multiCountryData?.getPricingForMultiCountryProducts?.length
        ? multiCountryData?.getPricingForMultiCountryProducts.map(
            (countryPricingData) => {
              if (tabValue === countryPricingData?.country) {
                return (
                  countryPricingData?.materialPricing && (
                    <>
                      {!usePricingComponentOnMobile && isMobile && (
                        <Box>
                          <MobileProductPriceAvailabilityForm
                            country={countryPricingData?.country}
                            materialPricing={
                              countryPricingData?.materialPricing
                            }
                            productNumber={productNumber}
                            canAddToCart={false}
                            anonymousUser={anonymous}
                            marketplaceOfferId={marketplaceOfferId}
                          />
                        </Box>
                      )}
                      <Box
                        display={
                          usePricingComponentOnMobile
                            ? undefined
                            : { xs: 'none', sm: 'block' }
                        }
                        className={classes.pricingTableContainer}
                        data-testid={`pricing-availability-${brandKey}${productNumber}`}
                        id={`pricing-availability-${brandKey}${productNumber}`}
                        mt={3}
                      >
                        <ProductPriceAvailabilityForm
                          country={countryPricingData?.country}
                          currentDtaqDealer={currentDtaqDealer}
                          materialPricing={countryPricingData?.materialPricing}
                          discontinuedPricingInfo={
                            countryPricingData?.discontinuedPricingInfo
                          }
                          productName={productName ?? ''}
                          displaySellerName={displaySellerName ?? ''}
                          productKey={productKey}
                          images={images}
                          canAddToCart={false}
                          PricingComponent={PricingComponent}
                          displayPromotionalBundlesAnchor={
                            displayPromotionalBundlesAnchor
                          }
                          displaySDS={displaySDS}
                          ctas={ctas}
                          brandKey={brandKey}
                          gaType={gaType}
                          gaMapProducts={gaMapProducts}
                          condensed={condensed}
                          isMarketplace={isMarketplace}
                          isMarketplaceCartEnabled={
                            !!currentUser?.metadata?.isMarketplaceCartEnabled
                          }
                          marketplaceSellerId={marketplaceSellerId}
                          marketplaceOfferId={marketplaceOfferId}
                          productAttributes={productAttributes}
                          handlePriceUpdateForDealer={
                            handlePriceUpdateForDealer
                          }
                          updateQuantity={updateQuantity}
                          erpType={erp_type || []}
                        />
                      </Box>
                    </>
                  )
                )
              }
              return null
            }
          )
        : null}
    </>
  ) : !!productData?.getPricingForProduct.materialPricing.length ||
    !!productData?.getPricingForProduct.discontinuedPricingInfo ? (
    <>
      {dchainMessage &&
        dchainMessage !== 'PRODUCT_DISCONTINUED_BUT_AVAILABLE_FOR_PURCHASE' && (
          <Box my={2}>
            <Alert
              severity="error"
              icon={<HighlightOffRoundedIcon />}
              className={classes.dchainMessage}
            >
              <FormattedMessage
                {...messages[dchainMessage]}
                values={{
                  productNumber: <b>{productNumber}</b>,
                }}
              />{' '}
              {isMarketplace && (
                <Link
                  {...sellerRoutes.contact(
                    displaySellerName || getSellerName(productAttributes),
                    marketplaceSellerId as string,
                    router.asPath,
                    ContactSellerReasonType.OFFER_MESSAGING,
                    marketplaceOfferId as string
                  )}
                >
                  <a>
                    <FormattedMessage
                      id="CONTACT_SELLER_WITH_NAME"
                      defaultMessage="Contact {sellerName}."
                      values={{
                        sellerName: getSellerName(productAttributes),
                      }}
                    />
                  </a>
                </Link>
              )}
            </Alert>
          </Box>
        )}
      {!usePricingComponentOnMobile && isMobile && (
        <Box>
          {!productData?.getPricingForProduct?.discontinuedPricingInfo ? (
            <SpacingBox mb={4}>
              <Typography variant="h3" component="h3">
                <FormattedMessage
                  id="PRICING_AND_AVAILABILITY_HEADING"
                  defaultMessage="Select an Option"
                />
              </Typography>
            </SpacingBox>
          ) : null}
          <MobileProductPriceAvailability
            productKey={productKey}
            brandKey={brandKey}
            productName={productName ?? ''}
            images={images}
            discontinuedPricingInfo={
              productData?.getPricingForProduct?.discontinuedPricingInfo
            }
            materialPricing={productData?.getPricingForProduct?.materialPricing}
            canAddToCart={
              authorized &&
              !isMultiCountryUser &&
              (!isMarketplace ||
                (isMarketplace &&
                  !!currentUser?.metadata?.isMarketplaceCartEnabled))
            }
            renderSecondaryAction={() => {
              const bulkOrderCta = ctas?.find((f) => f.type === 'bulk order')
              const secondaryCta = ctas?.find((f) => f.type !== 'bulk order')

              return (
                <>
                  {secondaryCta && (
                    <SpacingBox mt={4}>
                      <Button
                        component="a"
                        target="_blank"
                        href={secondaryCta.url}
                        variant="outlined"
                        size="large"
                        color="primary"
                        fullWidth
                        onClick={() =>
                          sendPricingAndAvailabilityEvent(
                            `request ${secondaryCta.type}`,
                            productNumber
                          )
                        }
                      >
                        {secondaryCta.label}
                      </Button>
                    </SpacingBox>
                  )}
                  {!!bulkOrderCta && !isMarketplace && (
                    <div className={classes.bulkOrderWrapper}>
                      <Link href={bulkOrderCta.url} passHref>
                        <MUILink
                          data-testid={'p&a-request-bulk-link'}
                          color="primary"
                          target="_blank"
                          onClick={() =>
                            sendPricingAndAvailabilityEvent(
                              EventValues.RequestBulkOrder,
                              productNumber
                            )
                          }
                        >
                          {bulkOrderCta.label}
                        </MUILink>
                      </Link>
                    </div>
                  )}
                </>
              )
            }}
            marketplaceOfferId={marketplaceOfferId}
          />
          <ProductPartnerInfo
            isMarketplace={isMarketplace}
            marketplaceSellerId={marketplaceSellerId}
            marketplaceOfferId={marketplaceOfferId}
            productAttributes={productAttributes}
            displaySellerName={displaySellerName}
          />
        </Box>
      )}
      {!isMobile && !router.asPath.includes('/compare') ? (
        <SignInBanner mb={4} signInGAEvent={signInGAEvent} />
      ) : null}
      <Box
        width="100%"
        display={
          usePricingComponentOnMobile ? undefined : { xs: 'none', sm: 'block' }
        }
        data-testid={`pricing-availability-${brandKey}${productNumber}`}
        id={`pricing-availability-${brandKey}${productNumber}`}
        mt={condensed ? 0 : 3}
      >
        <ProductPriceAvailabilityForm
          currentDtaqDealer={currentDtaqDealer}
          materialPricing={productData?.getPricingForProduct?.materialPricing}
          discontinuedPricingInfo={
            productData?.getPricingForProduct?.discontinuedPricingInfo
          }
          productKey={productKey}
          productDescription={productDescription}
          productName={productName ?? ''}
          productId={productId}
          displaySellerName={displaySellerName ?? ''}
          images={images}
          canAddToCart={
            authorized &&
            !isMultiCountryUser &&
            (!isMarketplace ||
              (isMarketplace &&
                !!currentUser?.metadata?.isMarketplaceCartEnabled))
          }
          PricingComponent={PricingComponent}
          displayPromotionalBundlesAnchor={displayPromotionalBundlesAnchor}
          displaySDS={displaySDS}
          ctas={ctas}
          brandKey={brandKey}
          gaType={gaType}
          gaMapProducts={gaMapProducts}
          addToList={addToList}
          condensed={condensed}
          isMarketplace={isMarketplace}
          isMarketplaceCartEnabled={
            !!currentUser?.metadata?.isMarketplaceCartEnabled
          }
          marketplaceSellerId={marketplaceSellerId}
          marketplaceOfferId={marketplaceOfferId}
          productAttributes={productAttributes}
          onAddToCartResult={onAddToCartResult}
          handlePriceUpdateForDealer={handlePriceUpdateForDealer}
          updateQuantity={updateQuantity}
          displayCompareMarketplaceSellerContact={
            displayCompareMarketplaceSellerContact
          }
          erpType={erp_type || []}
        />
      </Box>
    </>
  ) : called ? (
    <ErrorComponent
      handlePriceUpdateForDealer={handlePriceUpdateForDealer}
      materialPricing={[
        {
          materialNumber: queryVariables.variables.productNumber,
          brand: brandKey,
          listPrice: 0,
          productGroupSBU: '',
        },
      ]}
    />
  ) : null
}

export default ProductPriceAvailability
