import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography, Button, Modal, Box, Link } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import RequestQuoteItemDetails from './RequestQuoteItemDetails'
import { useGenerateQuoteMutation } from '@src/mutations/GenerateQuoteMutation.generated'
import { GET_QUOTES_COUNT } from '@src/queries/QuotesCountQuery'
import ResponsiveModal, {
  ModalSizes,
  ResponsiveModalActions,
  ResponsiveModalBody,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import CircleLoader from '@src/components/CircleLoader/CircleLoader'
import { useRouter, orderCenterRoutes } from '@src/routes'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import ErrorCircleIcon from '@src/icons/ErrorCircleIcon'
import {
  sendRequestQuoteEvent,
  sendBeginOrCompleteQuoteRequestEvent,
  sendErrorEvent,
} from '@src/utils/analytics'

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  errorTitle: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(14),
  },
  errorMessage: {
    fontSize: theme.typography.pxToRem(14),
  },
  errorIcon: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  link: {
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
  },
}))

interface RequestQuoteModalProps {
  quoteData?: {
    material?: ValidMaterialPricingDetailFragment | null
    product?: Partial<PdpFieldsFragment>
    quantity?: number
  }
  onClose: (reason?: string) => void
}

const RequestQuoteModal: React.FC<RequestQuoteModalProps> = ({
  onClose,
  quoteData,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const { material, product, quantity } = quoteData ?? {}

  const [generateQuote] = useGenerateQuoteMutation({
    refetchQueries: [{ query: GET_QUOTES_COUNT }],
  })

  const handleSubmit = async () => {
    if (material?.materialNumber && quantity) {
      setIsLoading(true)
      sendBeginOrCompleteQuoteRequestEvent({
        event: 'complete_quote_request',
        section: 'pricing card',
        component: 'modal',
        elementType: 'button',
        elementText: 'submit request',
      })
      sendRequestQuoteEvent({
        category: 'pricing and availability',
        action: 'request quote',
        label: product?.productNumber,
      })

      try {
        const { data } = await generateQuote({
          variables: {
            input: {
              materialNumber: material?.materialNumber,
              quantity,
            },
          },
        })
        if (data) {
          router.replace(
            orderCenterRoutes.quoteDetail(data?.generateQuote?.orderNumber)
          )
        }
      } catch (error) {
        setError(true)
        setIsLoading(false)
        sendErrorEvent({
          description: 'we were unable to initiate your request',
          errorCategory: 'other errors',
          productId: material?.product,
          productVariant: material?.materialNumber,
          productName: material?.materialDescription,
          productBrand: material?.brand,
        })
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <Modal open hideBackdrop>
          <CircleLoader />
        </Modal>
      ) : (
        <ResponsiveModal
          open
          onClose={(_, reason) => onClose(reason)}
          size={ModalSizes.Small}
          renderTitle={() => (
            <Typography variant="h2">
              <FormattedMessage
                id="REQUEST_QUOTE"
                defaultMessage="Request Quote"
              />
            </Typography>
          )}
        >
          <ResponsiveModalBody>
            {!error ? (
              <>
                {material && product && quantity ? (
                  <RequestQuoteItemDetails
                    material={material}
                    product={product}
                    quantity={quantity}
                  />
                ) : null}
                <ResponsiveModalActions className={classes.actionContainer}>
                  <Button
                    color="primary"
                    disabled={!quantity}
                    size="large"
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    <FormattedMessage
                      id="SUBMIT_REQUEST"
                      defaultMessage="Submit Request"
                    />
                  </Button>
                </ResponsiveModalActions>
              </>
            ) : (
              <Box>
                <Box display="flex">
                  <ErrorCircleIcon
                    className={classes.errorIcon}
                    color="error"
                  />
                  <Box>
                    <Typography className={classes.errorTitle}>
                      <FormattedMessage
                        id="REQUEST_QUOTE_ERROR_TITLE"
                        defaultMessage="We were unable to initiate your request."
                      />
                    </Typography>
                    <Typography className={classes.errorMessage}>
                      <FormattedMessage id="PLEASE" defaultMessage="Please" />
                      <Link
                        className={classes.link}
                        href={'/support/customer-support'}
                        onClick={() => setIsLoading(true)}
                      >
                        <FormattedMessage
                          id="CONTACT_CUSTOMER_SERVICE"
                          defaultMessage="contact customer service"
                        />
                      </Link>
                      <FormattedMessage
                        id="FOR_ASSISTANCE"
                        defaultMessage="for assistance."
                      />
                    </Typography>
                  </Box>
                </Box>

                <ResponsiveModalActions className={classes.actionContainer}>
                  <Button
                    color="primary"
                    size="large"
                    onClick={() => onClose()}
                    variant="contained"
                  >
                    <FormattedMessage id="CLOSE" defaultMessage="Close" />
                  </Button>
                </ResponsiveModalActions>
              </Box>
            )}
          </ResponsiveModalBody>
        </ResponsiveModal>
      )}
    </>
  )
}

export default RequestQuoteModal
