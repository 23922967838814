import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import SearchResultsSortBar from '../SearchResultsSortBar'
import SearchResultsCount from '../SearchResultsCount'
import { SearchResultsMetadata } from '@src/types/graphql-types'
import { useRouter } from 'next/router'
import { getValidSearchQuery } from '@src/utils/searchUtils'

const useStyles = makeStyles((theme: Theme) => ({
  searchLink: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: theme.palette.secondary.dark,
  },
  borderLeft: {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
  },
  compareButton: {
    marginLeft: theme.spacing(3),
    minWidth: 116,
  },
  desktopResultsMenu: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      minHeight: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexShrink: 0,
    },
  },
}))

interface DesktopSearchResultsMenuProps {
  metadata?: SearchResultsMetadata
  showSortBar?: boolean
  orgId?: string
}

const DesktopSearchResultsMenu: FC<DesktopSearchResultsMenuProps> = ({
  metadata,
  showSortBar,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const validSearchQuery = getValidSearchQuery({
    focus: router.query.focus,
    term: router.query.term,
  })

  return (
    <div className={classes.desktopResultsMenu}>
      {metadata && (
        <SearchResultsCount
          currPage={metadata.page}
          perPage={metadata.perPage}
          resultCount={metadata.setsCount || metadata.itemCount || 0}
          searchTerm={validSearchQuery.term}
          searchType={validSearchQuery.focus}
        />
      )}
      {showSortBar && (
        <Box ml={2}>
          <SearchResultsSortBar />
        </Box>
      )}
    </div>
  )
}

export default DesktopSearchResultsMenu
