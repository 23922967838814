import React, { FC, Fragment } from 'react'
import { Button, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ResponsiveModal from '@src/components/ResponsiveModal'
import messages from '@utils/messages'
import { FormattedMessage } from 'react-intl'
import MobileFacetGroup from './MobileFacetGroup'
import MobileSortFacets from './MobileSortFacets'
import MobileFilterFacets from './MobileFilterFacets'
import { Facet } from '@src/types/graphql-types'
import AppliedFilters from './AppliedFilters'
import FormattedFacetMessage from '../Facets/FormattedFacetMessage'

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    overflow: 'scroll',
    padding: theme.spacing(6, 4),
  },
  footer: {
    padding: theme.spacing(6, 4),
  },
}))

interface Props {
  facets?: Facet[]
  isStructureSearch: boolean
  isModalOpen: boolean
  onModalClose(): void
  showSortBar?: boolean
}

const MobileFilterAndSortModal: FC<Props> = ({
  facets,
  isStructureSearch,
  isModalOpen,
  onModalClose,
  showSortBar,
}) => {
  const classes = useStyles()

  return (
    <ResponsiveModal
      open={isModalOpen}
      onClose={onModalClose}
      renderTitle={() => (
        <Typography variant="h2" component="h2">
          <FormattedMessage {...messages.FILTER_AND_SORT_HEADING} />
        </Typography>
      )}
    >
      <>
        <div className={classes.body}>
          <AppliedFilters
            facets={facets}
            isStructureSearch={isStructureSearch}
          />

          {showSortBar && (
            <MobileFacetGroup
              renderHeading={() => (
                <Typography variant="body1" component="h2">
                  <b>
                    <FormattedMessage {...messages.SORT_HEADING} />
                  </b>
                </Typography>
              )}
            >
              <MobileSortFacets />
            </MobileFacetGroup>
          )}

          {!!facets &&
            facets.map((facet) => (
              <Fragment key={facet.key}>
                <MobileFacetGroup
                  renderHeading={() => (
                    <Typography variant="body1" component="h2">
                      <b>
                        <FormattedFacetMessage id={facet.key.toUpperCase()} />
                      </b>
                    </Typography>
                  )}
                >
                  <MobileFilterFacets facet={facet} allFacets={facets} />
                </MobileFacetGroup>
              </Fragment>
            ))}
        </div>
        <div className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={onModalClose}
          >
            <FormattedMessage id="DONE" defaultMessage="Done" />
          </Button>
        </div>
      </>
    </ResponsiveModal>
  )
}

export default MobileFilterAndSortModal
