import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import RequestQuoteModalB2B from './RequestQuoteModalB2B'
import { useGlobalModal } from '@src/utils/useGlobalModal'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'

type ButtonVariants = 'outlined' | 'contained' | 'text'
interface RequestQuoteButtonProps {
  quoteData?: {
    material?: ValidMaterialPricingDetailFragment | null
    product?: Partial<PdpFieldsFragment>
    quantity?: number
  }
  variant?: ButtonVariants
  size?: 'small' | 'medium' | 'large'
  fullWidth?: boolean
  className?: string
  type?: 'button' | 'submit'
  disabled?: boolean
  id?: string
}

const RequestQuoteButtonB2B: React.FC<RequestQuoteButtonProps> = ({
  quoteData,
  variant = 'outlined',
  size = 'large',
  fullWidth = true,
  className,
  type = 'button',
  disabled,
  id,
}) => {
  const { setGlobalModalComponent, clearGlobalModalComponent } =
    useGlobalModal()

  const handleOpenRequestQuoteModal = () => {
    setGlobalModalComponent(
      <RequestQuoteModalB2B
        quoteData={quoteData}
        onClose={clearGlobalModalComponent}
      />
    )
  }

  return (
    <Button
      id={id}
      className={className}
      color="primary"
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
      type={type}
      variant={variant}
      onClick={handleOpenRequestQuoteModal}
    >
      <FormattedMessage id="REQUEST_QUOTE" defaultMessage="Request Quote" />
    </Button>
  )
}

export default RequestQuoteButtonB2B
