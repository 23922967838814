// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SearchSuggestionsQueryVariables = Types.Exact<{
  input: Types.GetSearchSuggestionsInput;
}>;


export type SearchSuggestionsQuery = { __typename?: 'Query', getSearchSuggestions?: { __typename?: 'GetSearchSuggestionResponse', count: number, suggestions: { __typename?: 'GroupedSuggestions', productName: Array<{ __typename?: 'Suggestion', label: string, brandKey?: string | null, productKey?: string | null, substanceKey?: string | null }>, casNumber: Array<{ __typename?: 'Suggestion', label: string, brandKey?: string | null, productKey?: string | null, substanceKey?: string | null }>, materialNumber: Array<{ __typename?: 'Suggestion', label: string, brandKey?: string | null, productKey?: string | null, substanceKey?: string | null }>, productNumber: Array<{ __typename?: 'Suggestion', label: string, brandKey?: string | null, productKey?: string | null, substanceKey?: string | null }>, relatedCategories: Array<{ __typename?: 'RelatedCategory', value: string, facetId: string }>, pinyin: Array<{ __typename?: 'Suggestion', label: string, brandKey?: string | null, productKey?: string | null, substanceKey?: string | null }> } } | null };

export type SuggestionFragment = { __typename?: 'Suggestion', label: string, brandKey?: string | null, productKey?: string | null, substanceKey?: string | null };

export const SuggestionFragmentDoc = gql`
    fragment Suggestion on Suggestion {
  label
  brandKey
  productKey
  substanceKey
}
    `;
export const SearchSuggestionsDocument = gql`
    query SearchSuggestions($input: GetSearchSuggestionsInput!) {
  getSearchSuggestions(input: $input) {
    count
    suggestions {
      productName {
        ...Suggestion
      }
      casNumber {
        ...Suggestion
      }
      materialNumber {
        ...Suggestion
      }
      productNumber {
        ...Suggestion
      }
      relatedCategories {
        value
        facetId
      }
      pinyin {
        ...Suggestion
      }
    }
  }
}
    ${SuggestionFragmentDoc}`;
export type SearchSuggestionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>, 'query'> & ({ variables: SearchSuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchSuggestionsComponent = (props: SearchSuggestionsComponentProps) => (
      <ApolloReactComponents.Query<SearchSuggestionsQuery, SearchSuggestionsQueryVariables> query={SearchSuggestionsDocument} {...props} />
    );
    

/**
 * __useSearchSuggestionsQuery__
 *
 * To run a query within a React component, call `useSearchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSuggestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchSuggestionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>(SearchSuggestionsDocument, options);
      }
export function useSearchSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>(SearchSuggestionsDocument, options);
        }
export type SearchSuggestionsQueryHookResult = ReturnType<typeof useSearchSuggestionsQuery>;
export type SearchSuggestionsLazyQueryHookResult = ReturnType<typeof useSearchSuggestionsLazyQuery>;
export type SearchSuggestionsQueryResult = ApolloReactCommon.QueryResult<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>;