import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import HandleMarkup from '../HandleMarkup'
import { SubstanceInfoSegment } from '../SubstanceDetailSegment'
import {
  ProductSearchType,
  determineQueryFocus,
  formatCasNumberSearchTerm,
} from '@src/utils/searchUtils'
import {
  sendHeaderSearchEvent,
  sendProductInfoInteractionEvent,
} from '@src/utils/analytics'
import { buildProductAliasPayload } from '@src/utils/analytics/analyticsUtils'

const useStyles = makeStyles((theme) => ({
  infoList: {
    paddingTop: theme.spacing(4),
    maxWidth: 900,
    '& dd, & dt': { margin: 0 }, // reset browser styles
    '&:empty': { display: 'none' },
  },
  formula: {
    paddingTop: theme.spacing(1),
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  },
}))

interface ProductQuickLookInfoListProps {
  focus: string
  linearFormula: string | null | undefined
  empiricalFormula: string | null | undefined
  casNumber: string | null | undefined
  molecularWeight: string | null | undefined
  productNumber: string | null | undefined
}

const ProductQuickLookInfoList: React.FC<ProductQuickLookInfoListProps> = ({
  focus,
  linearFormula,
  empiricalFormula,
  casNumber,
  molecularWeight,
  productNumber,
}) => {
  const classes = useStyles()

  const formulaValue = linearFormula || empiricalFormula

  return (
    <Grid
      container
      component="dl"
      spacing={1}
      className={classes.infoList}
      alignItems="baseline"
    >
      {formulaValue && (
        <>
          <Grid item xs={5} lg={2}>
            <Typography variant="caption" component="dt">
              {linearFormula ? (
                <FormattedMessage
                  id="LINEAR_FORMULA"
                  defaultMessage="Linear Formula"
                />
              ) : (
                <FormattedMessage
                  id="EMPIRICAL_FORMULA"
                  defaultMessage="Empirical Formula"
                />
              )}
              :
            </Typography>
          </Grid>
          <Grid item xs={7} lg={10}>
            <dd className={classes.formula}>
              <HandleMarkup value={formulaValue} />
            </dd>
          </Grid>
        </>
      )}
      {casNumber && (
        <>
          <Grid item xs={5} lg={2} component="dt">
            <Typography variant="caption" component="div">
              <FormattedMessage id="CAS_NUMBER" defaultMessage="CAS Number" />:
            </Typography>
          </Grid>
          <Grid item xs={7} lg={10} component="dd">
            <SubstanceInfoSegment
              color="primary"
              label={casNumber}
              searchValue={formatCasNumberSearchTerm(casNumber)}
              searchFocusType={determineQueryFocus(focus)}
              productSearchType={ProductSearchType.CasNumber}
              onClick={() => {
                sendHeaderSearchEvent(
                  {
                    action: 'cas no',
                    label: casNumber,
                  },
                  {
                    searchTerm: productNumber || '',
                    searchAutoSuggest: 'quick view - cas number',
                    searchAutoSuggestTerm: `${casNumber}`,
                    searchType: determineQueryFocus(focus),
                    searchComponent: 'global search',
                    searchErrorMessage: '',
                  }
                )
                sendProductInfoInteractionEvent(
                  buildProductAliasPayload({
                    action: 'cas number',
                    detail: casNumber,
                    focus,
                  })
                )
              }}
            />
          </Grid>
        </>
      )}
      {molecularWeight && (
        <>
          <Grid item xs={5} lg={2} component="dt">
            <Typography variant="caption" component="div">
              <FormattedMessage
                id="MOLECULAR_WEIGHT"
                defaultMessage="Molecular Weight"
              />
              :
            </Typography>
          </Grid>
          <Grid item xs={7} lg={10} component="dd">
            <HandleMarkup value={molecularWeight} />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ProductQuickLookInfoList
