import React, { FC, useEffect, useState } from 'react'
import ResponsiveModal, {
  ModalSizes,
  ResponsiveModalActions,
  ResponsiveModalBody,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import HandleMarkup from '@src/components/HandleMarkup'
import {
  CartItemType,
  PromotionalBundle,
  PromotionalBundleItemPricing,
} from '@src/types/graphql-types'
import SelectableChips from '../SelectableChips/SelectableChips'
import { usePromotionalBundlesPricingLazyQuery } from '@src/queries/PromotionalBundlesPricingQuery.generated'
import LiquidQuantityInput from '@src/components/LiquidQuantityInput'
import AddToCartButton from '../AddToCartButton'
import PromoBundleTable, { getPromoBundlePricing } from './PromoBundleTable'
import { PricingLoading } from '../ProductQuickLook/ProductQuickLook'
import { useAddToCartWithGlobalSnackbar } from '@utils/useCart'
import {
  SnackbarType,
  useGlobalSnackbar,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { SitePreference, useCurrentUser } from '@src/utils/useCurrentUser'
import { Abilities, useAbility } from '@src/utils/useAbility'
import clsx from 'clsx'
import ResponsiveDataAccordion from '../ResponsiveDataAccordion/ResponsiveDataAccordion'
import { DataColumn } from '@src/components/DataTable/DataTable'
import AvailabilityMessage from '@src/components/AvailabilityMessage'
import CreateCustomBundleModal from '@src/components/PromoBundleModal/CreateCustomBundleModal'
import { useGlobalModal } from '@src/utils/useGlobalModal'
import { sendBuyInKitClickEvent } from '@src/utils/analytics'
import {
  setAddToCartData,
  sendBundleKitAddToCartEvent,
} from '@utils/analytics/cartAndCheckoutEvents'
import {
  AddToCartPagesEnum,
  BuyInKItEventActionEnum,
} from '@utils/analytics/enums'
import { TrackedAvailabilityMessageProvider } from '../TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(1),
  },
  materialNumber: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  liquidQtyInputContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 112,
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: theme.spacing(4, 0, 5, 0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: theme.spacing(4, 0, 0, 0),
    },
  },
  listPrice: {
    fontSize: theme.typography.pxToRem(14),
  },
  yourPrice: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
  },
  strikeThrough: {
    textDecoration: 'line-through',
    fontWeight: theme.typography.fontWeightRegular,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 200,
  },
  pricingContainer: {
    margin: theme.spacing(4, 0, 4, 0),
    flexDirection: 'column',
  },
  priceTag: {
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
  },
  tableTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(0, 4, 4, 4),
  },
  errorMessageWrapper: {},
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(4),
  },
}))
interface Props {
  onClose: (reason?: string) => void
  loading: boolean
  bundleData: PromotionalBundle[]
  productName: string
  materialNumber: string
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const PromoBundleModal: FC<Props> = ({
  onClose,
  bundleData,
  productName,
  materialNumber,
  setShowModal,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { getSitePreference, userIsLoggedIn } = useCurrentUser()
  const { authorized } = useAbility(Abilities.canAddToCart)
  const { setGlobalSnackbarState } = useGlobalSnackbar()
  const addToCart = useAddToCartWithGlobalSnackbar()
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [quantity, setQuantity] = useState<number>(1)
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { setGlobalModalComponent, clearGlobalModalComponent } =
    useGlobalModal()
  const hideAddToCartForPrepackItems = getSitePreference(
    SitePreference.HideAddToCartForPrepackItems
  )
  const [
    getPromotionalBundlesPricing,
    { data: promoBundlePricing, loading: promoBundlePricingLoading },
  ] = usePromotionalBundlesPricingLazyQuery()

  const promoBundlePricingData =
    promoBundlePricing?.getPricingForPromotionalBundle

  const showYourPrice =
    (promoBundlePricingData?.listPrice &&
      promoBundlePricingData?.yourPrice &&
      promoBundlePricingData?.yourPrice < promoBundlePricingData?.listPrice) ||
    (!promoBundlePricingData?.listPrice && promoBundlePricingData?.yourPrice)

  const queryVariables = {
    variables: { promotionalBundleId: selectedItem.promotionalBundleId },
    ssr: false,
  }
  const noResultsComponent = (
    <FormattedMessage id="NO_RESULTS_FOUND" defaultMessage="No results found" />
  )

  const productMarkUp = <HandleMarkup value={productName} />

  const tableColumns: DataColumn<PromotionalBundleItemPricing>[] = [
    {
      headElement: (
        <FormattedMessage id="PROMO_PRODUCT" defaultMessage="Product" />
      ),
      bodyElement: (bundleData) => bundleData.materialNumber,
    },
    {
      headElement: (
        <FormattedMessage id="PROMO_PRODUCT_NAME" defaultMessage="Name" />
      ),
      bodyElement: (bundleData) => (
        <HandleMarkup value={bundleData.materialDescription} />
      ),
    },
    {
      headElement: (
        <FormattedMessage id="PROMO_QUANTITY" defaultMessage="Qty per Bundle" />
      ),
      bodyElement: (bundleData) => bundleData.quantity,
    },
    {
      headElement: (
        <FormattedMessage
          id="PROMO_AVAILABILITY"
          defaultMessage="Availability"
        />
      ),
      bodyElement: (bundleData) => {
        return bundleData.availabilityMessages?.length ? (
          <TrackedAvailabilityMessageProvider
            source="promo bundle modal"
            brand={bundleData?.brandKey || undefined}
            item={bundleData?.materialNumber}
            pricing={getPromoBundlePricing(bundleData)}
            waitUntilVisible
          >
            <AvailabilityMessage
              availabilities={bundleData?.availabilityMessages?.map((item) => ({
                key: item && item.key,
                date: item && item.date,
                plantLoc: item && item.plantLoc,
                quantity: item && item.quantity,
              }))}
            />
          </TrackedAvailabilityMessageProvider>
        ) : null
      },
    },
  ]
  const [firstColumn, secondColumn] = tableColumns

  useEffect(() => {
    if (bundleData.length > 0) {
      setSelectedItem(bundleData[0])
    }
  }, [bundleData])

  useEffect(() => {
    if (selectedItem.promotionalBundleId) {
      getPromotionalBundlesPricing(queryVariables)
    }
  }, [selectedItem.promotionalBundleId])

  const handleSelectSKU = (selectedPromotionalBundleId: string) => {
    const item = bundleData.find(
      ({ promotionalBundleId }) =>
        promotionalBundleId === selectedPromotionalBundleId
    )
    if (item) {
      setSelectedItem(item)
    }
    sendBuyInKitClickEvent(
      selectedItem.bundleId,
      bundleData,
      BuyInKItEventActionEnum.ChangePromoBundle
    )
  }

  const handleAddToCart = async () => {
    onClose('addedToCart')
    setAddToCartData(
      selectedItem?.bundleItems,
      promoBundlePricing?.getPricingForPromotionalBundle?.bundleItems ?? [],
      AddToCartPagesEnum.PromoBundle,
      undefined,
      undefined,
      undefined,
      { bundleId: selectedItem.bundleId, quantity: quantity }
    )
    sendBundleKitAddToCartEvent(
      promoBundlePricing?.getPricingForPromotionalBundle,
      selectedItem.bundleId,
      quantity
    )
    try {
      await addToCart([
        {
          materialNumber: selectedItem.bundleId,
          quantity: quantity,
          materialDescription: selectedItem.bundleDescription,
          materialName: selectedItem.bundleName,
          type: CartItemType.PromotionalBundle,
        },
      ])
    } catch (err) {
      setGlobalSnackbarState({
        open: true,
        message: (
          <FormattedMessage
            id="GENERIC_ERROR"
            defaultMessage="Sorry, an unexpected error has occurred"
          />
        ),
        variant: SnackbarType.Error,
      })
    }
  }
  const handleCreateCustomModal = () => {
    sendBuyInKitClickEvent(
      materialNumber,
      bundleData,
      BuyInKItEventActionEnum.NeedMoreBundleOptions
    )
    setGlobalModalComponent(
      <CreateCustomBundleModal
        setCreateCustomBundleModal={clearGlobalModalComponent}
        materialNumber={materialNumber}
        bundleData={bundleData}
        setShowModal={setShowModal}
      />
    )
  }

  return (
    <ResponsiveModal
      open
      onClose={(_, reason) => onClose(reason)}
      size={ModalSizes.Large}
      renderTitle={() => (
        <Typography variant="h2">
          <FormattedMessage
            id="PROMO_BUNDLE_TITLE"
            defaultMessage="Bundles Featuring {material}"
            values={{ material: productMarkUp }}
          />
        </Typography>
      )}
    >
      <div>
        <ResponsiveModalBody>
          <SelectableChips
            items={bundleData.map(({ bundleId, promotionalBundleId }) => {
              return { label: bundleId, value: promotionalBundleId }
            })}
            selectedChip={selectedItem.promotionalBundleId}
            onSelect={handleSelectSKU}
          />
          <Typography variant="body1" className={classes.materialNumber}>
            <HandleMarkup
              value={`${selectedItem.bundleId} - ${selectedItem.bundleName}`}
            />
          </Typography>
          <Typography className={classes.description}>
            <HandleMarkup value={selectedItem.bundleDescription} />
          </Typography>
          <Grid container className={classes.pricingContainer}>
            {promoBundlePricingLoading ? (
              <div className={classes.loadingContainer}>
                <PricingLoading />
              </div>
            ) : (
              <>
                {promoBundlePricingData?.currency &&
                (promoBundlePricingData.listPrice ||
                  promoBundlePricingData.yourPrice) ? (
                  <>
                    <div className={classes.yourPrice}>
                      {promoBundlePricingData.yourPrice && showYourPrice && (
                        <>
                          <FormattedNumber
                            style="currency"
                            currencyDisplay="symbol"
                            currency={promoBundlePricingData?.currency}
                            value={promoBundlePricingData?.yourPrice}
                          />
                          <span className={classes.priceTag}>
                            <FormattedMessage
                              id="KIT_PRICE"
                              defaultMessage="Bundle Price"
                            />
                          </span>
                        </>
                      )}
                      {promoBundlePricingData.listPrice ? (
                        <div
                          className={clsx(classes.listPrice, {
                            [classes.strikeThrough]: showYourPrice,
                          })}
                        >
                          <>
                            <FormattedNumber
                              style="currency"
                              currencyDisplay="symbol"
                              currency={promoBundlePricingData?.currency}
                              value={promoBundlePricingData?.listPrice}
                            />
                            <span className={classes.priceTag}>
                              {showYourPrice ? (
                                <FormattedMessage
                                  id="LIST_PRICE"
                                  defaultMessage="List Price"
                                />
                              ) : (
                                <FormattedMessage
                                  id="KIT_PRICE"
                                  defaultMessage="Bundle Price"
                                />
                              )}
                            </span>
                          </>
                        </div>
                      ) : null}
                    </div>
                    <ResponsiveModalActions className={classes.actionContainer}>
                      <>
                        <div className={classes.liquidQtyInputContainer}>
                          <LiquidQuantityInput
                            value={quantity}
                            min={1}
                            onChange={(value) => {
                              setQuantity(value)
                            }}
                          />
                        </div>
                        <div>
                          <AddToCartButton
                            dirty
                            isSubmitting={false}
                            canAddToCart={authorized}
                            hideAddToCartForPrepackItems={
                              hideAddToCartForPrepackItems
                            }
                            handleAddToCart={handleAddToCart}
                            fullWidth
                          />
                        </div>
                      </>
                    </ResponsiveModalActions>
                  </>
                ) : (
                  <Typography className={classes.errorMessage}>
                    <FormattedMessage
                      id="BUNDLE_UNAVAILABLE"
                      defaultMessage="Sorry! This Promotional Bundle might not be available in your country."
                    />
                  </Typography>
                )}

                {promoBundlePricingData && isDesktop ? (
                  <PromoBundleTable
                    promoBundlePricingData={promoBundlePricingData}
                  />
                ) : null}
              </>
            )}
          </Grid>
          {userIsLoggedIn && (
            <Grid item xs={12}>
              <Button
                style={{ paddingLeft: 0 }}
                onClick={handleCreateCustomModal}
              >
                <FormattedMessage
                  id="NEED_MORE_BUNDLE_BUTTON_TEXT"
                  defaultMessage="Need more bundle options?"
                />
              </Button>
            </Grid>
          )}
        </ResponsiveModalBody>
        {!isDesktop ? (
          <>
            <Typography className={classes.tableTitle}>
              <FormattedMessage
                id="PROMO_BUNDLE_TABLE_TITLE"
                defaultMessage="THIS {count}-ITEM BUNDLE INCLUDES:"
                values={{ count: promoBundlePricingData?.bundleItems.length }}
              />
            </Typography>
            <ResponsiveDataAccordion<PromotionalBundleItemPricing>
              data={promoBundlePricingData?.bundleItems || []}
              columns={tableColumns}
              noResultsComponent={noResultsComponent}
              accordionTitleColumn={firstColumn}
              accordionSubtitleColumn={secondColumn}
              itemProps={{ headerProps: { variant: 'h3' } }}
            />
          </>
        ) : null}
      </div>
    </ResponsiveModal>
  )
}

export default PromoBundleModal
