// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ProductPaMessageQueryVariables = Types.Exact<{
  brandKey: Types.Scalars['String'];
  productKey: Types.Scalars['String'];
  catalogType?: Types.InputMaybe<Types.CatalogType>;
  orgId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ProductPaMessageQuery = { __typename?: 'Query', getProductDetail?: { __typename?: 'Product', paMessage?: string | null } | null };


export const ProductPaMessageDocument = gql`
    query ProductPAMessage($brandKey: String!, $productKey: String!, $catalogType: CatalogType, $orgId: String) {
  getProductDetail(input: {brandKey: $brandKey, productKey: $productKey, catalogType: $catalogType, orgId: $orgId}) {
    paMessage
  }
}
    `;
export type ProductPaMessageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductPaMessageQuery, ProductPaMessageQueryVariables>, 'query'> & ({ variables: ProductPaMessageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProductPaMessageComponent = (props: ProductPaMessageComponentProps) => (
      <ApolloReactComponents.Query<ProductPaMessageQuery, ProductPaMessageQueryVariables> query={ProductPaMessageDocument} {...props} />
    );
    

/**
 * __useProductPaMessageQuery__
 *
 * To run a query within a React component, call `useProductPaMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductPaMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductPaMessageQuery({
 *   variables: {
 *      brandKey: // value for 'brandKey'
 *      productKey: // value for 'productKey'
 *      catalogType: // value for 'catalogType'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useProductPaMessageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProductPaMessageQuery, ProductPaMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProductPaMessageQuery, ProductPaMessageQueryVariables>(ProductPaMessageDocument, options);
      }
export function useProductPaMessageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductPaMessageQuery, ProductPaMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProductPaMessageQuery, ProductPaMessageQueryVariables>(ProductPaMessageDocument, options);
        }
export type ProductPaMessageQueryHookResult = ReturnType<typeof useProductPaMessageQuery>;
export type ProductPaMessageLazyQueryHookResult = ReturnType<typeof useProductPaMessageLazyQuery>;
export type ProductPaMessageQueryResult = ApolloReactCommon.QueryResult<ProductPaMessageQuery, ProductPaMessageQueryVariables>;