import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, Collapse } from '@material-ui/core'
import { SubstanceProductFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import ResponsiveSubstanceProduct from '@src/routes/SearchResults/ProductSearchResults/ResponsiveSubstanceProduct'
import { ProductTableProps } from './ProductTable'
import ShowMoreLessButton from '@src/components/ShowMoreLessButton'

const PRODUCTS_TO_SHOW = 2

export const useStyles = makeStyles((theme) => {
  return {
    showMore: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      borderTop: `solid 1px ${theme.palette.grey[400]}`,
      padding: theme.spacing(2, 0, 6, 0),
      textAlign: 'center',
      width: '100%',
    },
    initialProducts: {
      '& li': {
        borderBottom: `solid 1px ${theme.palette.grey[400]}`,
      },
      '& li:last-child': {
        borderBottom: 'none',
      },
    },
    moreProducts: {
      '& li': {
        borderBottom: `solid 1px ${theme.palette.grey[400]}`,
      },
      '& li:first-child': {
        borderTop: `solid 1px ${theme.palette.grey[400]}`,
      },
      '& li:last-child': {
        borderBottom: 'none',
      },
    },
  }
})

const MobileProductTable: React.FC<ProductTableProps> = (props) => {
  const { products, substanceName, substanceId, substancePosition } = props
  const totalProducts = products.length
  const initialProducts = products.slice(0, PRODUCTS_TO_SHOW)
  const restProducts = products.slice(PRODUCTS_TO_SHOW)
  const [isExpanded, setIsExpanded] = useState(false)
  const classes = useStyles()

  const onShowMoreToggle = (): void => {
    setIsExpanded(!isExpanded)
  }
  const mapProductToComponent = (
    product: SubstanceProductFieldsFragment
  ): JSX.Element => (
    <ResponsiveSubstanceProduct
      key={product.productNumber}
      product={product}
      substanceName={substanceName}
      substancePosition={substancePosition}
      substanceId={substanceId}
    />
  )

  return (
    <div>
      <List disablePadding className={classes.initialProducts}>
        {initialProducts.map(mapProductToComponent)}
      </List>
      {totalProducts > PRODUCTS_TO_SHOW && (
        <>
          <Collapse in={isExpanded}>
            <List disablePadding className={classes.moreProducts}>
              {restProducts.map(mapProductToComponent)}
            </List>
          </Collapse>
          <ShowMoreLessButton
            expanded={isExpanded}
            setExpanded={onShowMoreToggle}
            className={classes.showMore}
            data-testid={`show-${!isExpanded ? 'more' : 'less'}`}
          />
        </>
      )}
    </div>
  )
}

export default MobileProductTable
