import React, { useRef, useState, useEffect } from 'react'
import { Button, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CheckMarkCircleIcon from '@icons/CheckMarkCircleIcon'
import { useReadyToShipNotificationMutation } from '@src/mutations/NotifyReadyToShipMutations.generated'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import {
  ReadyToShipNotificationModal,
  ReadyToShipNotificationModalType,
  ReadyToShipNotificationRequestStatus,
  ReadyToShipNotificationWalkMeStep,
} from '@src/components/ProductInfo/ReadyToShipNotification'

interface ReadyToShipNotificationProps {
  material: ValidMaterialPricingDetailFragment | null
  userEmail: string
  productImageUrl: string
  productNumber: string
}

const useStyles = makeStyles((theme: Theme) => ({
  notifyReadyToShipContainer: {
    paddingTop: theme.spacing(6),
    marginTop: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.grey[400]}`,
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(2),
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(4),
  },
  callOut: {
    wordWrap: 'break-word',
    fontSize: 'inherit',
    fontWeight: 700,
  },
  button: {
    marginBottom: theme.spacing(4),
  },
}))

export const ReadyToShipNotification: React.FC<
  ReadyToShipNotificationProps
> = ({ material, userEmail, productImageUrl, productNumber }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [requestStatus, setRequestStatus] =
    useState<ReadyToShipNotificationRequestStatus>('ready')
  const [modalType, setModalType] =
    useState<ReadyToShipNotificationModalType>('closed')
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const [addReadyToShipNotification] = useReadyToShipNotificationMutation()
  const successElement = useRef<HTMLDivElement>(null)

  const anchorEl1 = useRef<HTMLElement>(null)

  useEffect(() => {
    setRequestStatus('ready')
  }, [material])

  const handleClick = async () => {
    setRequestStatus('communicating')
    try {
      await addReadyToShipNotification({
        variables: {
          params: {
            brand: material?.brand || '',
            materialNumber: material?.materialNumber || '',
            imageUrl: productImageUrl,
            productNumber: productNumber,
          },
        },
      })
      setRequestStatus('success')
      setModalType('closed')
      successElement.current?.focus() // have screen readers read the success message
    } catch {
      setRequestStatus('error')
      setModalType('error')
    }
  }

  const handleError = () => {
    setRequestStatus('communicating')
    // Adding a slight delay so that users see we are trying again. Otherwise, it fails so fast, you can't tell it did anything.
    setTimeout(() => {
      handleClick()
    }, 1000)
  }

  const handleClose = () => {
    setModalType('closed')
    setRequestStatus('ready')
  }

  return (
    <div className={classes.notifyReadyToShipContainer} aria-live="polite">
      <ReadyToShipNotificationWalkMeStep
        stepNumber={1}
        anchorEl={anchorEl1}
        placement={isDesktop ? 'left' : 'top'}
      />
      {requestStatus !== 'success' ? (
        <>
          <Typography
            component="h6"
            className={classes.title}
            ref={anchorEl1 as React.RefObject<HTMLDivElement>}
          >
            <FormattedMessage id="NOTIFY_ME" defaultMessage="Notify Me" />
          </Typography>
          <Typography component="p" className={classes.text}>
            <FormattedMessage
              id="NOTIFY_ME_DESCRIPTION"
              defaultMessage="Get notified when this item is ready to ship via email."
            />
          </Typography>
          <Button
            id="notify-me"
            className={classes.button}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            onClick={() => setModalType('request')}
          >
            <FormattedMessage id="NOTIFY_ME" defaultMessage="Notify Me" />
          </Button>
        </>
      ) : (
        <div ref={successElement} tabIndex={-1}>
          <Typography component="h6" className={classes.title}>
            <CheckMarkCircleIcon color="success" fontSize="inherit" />
            <FormattedMessage id="CONFIRMED" defaultMessage="Confirmed" />
          </Typography>
          <Typography component="p" className={classes.text}>
            <FormattedMessage
              id="NOTIFY_ME_SUCCESS"
              defaultMessage="We will send an email to {email} when this item is ready to ship."
              values={{
                email: (
                  <Typography component="span" className={classes.callOut}>
                    {userEmail}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </div>
      )}

      <ReadyToShipNotificationModal
        requestStatus={requestStatus}
        modalType={modalType}
        handleClick={handleClick}
        handleError={handleError}
        handleClose={handleClose}
        userEmail={userEmail}
        productSKU={material?.materialNumber ? material?.materialNumber : ''}
      />
    </div>
  )
}
