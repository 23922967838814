import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Theme } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import DefaultLayout from '@src/components/DefaultLayout'
import { FormattedMessage, useIntl } from 'react-intl'
import { AnalyticsDispType } from '@src/types/graphql-types'

import RecentlyViewedProductsCarousel from '@src/components/RecentlyViewedProductsCarousel/RecentlyViewedProductsCarousel'
import messages from '@utils/messages'

import DocumentLibraryBanner from './DocumentLibraryBanner'
import vrStyles from '@src/styles/utils/vrStyles'
import { documentLibraryRoute, useRouter } from '@src/routes'
import DocumentSearchTabs from './DocumentSearchTabs'
import { sendCommonDetailProductIdEvent } from '@src/utils/analytics'
import { Linq } from '@src/components/Linq'

export const useStyles = makeStyles((theme: Theme) => {
  const { vr2, vr4 } = vrStyles
  return {
    vr2,
    vr4,
    body: {
      padding: theme.spacing(8, 0, 10),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(10, 0, 12),
      },
    },
    title: {
      ...vr2,
      [theme.breakpoints.up('sm')]: {
        ...vr4,
      },
    },
    overview: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    section: {
      marginBottom: theme.spacing(10),
    },
    recentlyViewedProducts: {
      marginTop: theme.spacing(12),
    },
    medium: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }
})

export enum DocumentTypeAbbreviations {
  sds = 'safety data sheet',
  coa = 'certificate of analysis',
  coo = 'certificate of origin',
  coq = 'certificate of quality',
}

const DocumentSearch = () => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const router = useRouter()

  return (
    <DefaultLayout className={classes.body}>
      <Container maxWidth="lg">
        <Typography
          id="documents-search-title"
          variant="h1"
          className={classes.title}
        >
          {formatMessage({ id: 'DOCUMENTS', defaultMessage: 'Documents' })}
        </Typography>
        <div className={classes.section}>
          <div className={classes.overview}>
            <Typography
              id="documents-search-overview"
              variant="body1"
              className={classes.vr4}
            >
              {formatMessage(messages.DOCUMENT_SEARCH_SUBTITLE)}
            </Typography>
            <DocumentLibraryBanner>
              <>
                <Typography variant="body1">
                  <FormattedMessage
                    id="IS_IT_RELATED_TO_A_PREVIOUS_ORDER"
                    defaultMessage="Is it related to a <b>previous order</b>"
                    values={{
                      b: (...chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                  {'?'}
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage
                    id="TRY_OUR_WITH_LINK"
                    defaultMessage="Try our {link}"
                    values={{
                      link: (
                        <Linq
                          href={documentLibraryRoute.index()}
                          onClick={() =>
                            sendCommonDetailProductIdEvent(
                              {
                                event: 'documentation_interaction',
                                action: 'visit document library',
                                detail: `${router.query.tab} tab`,
                                section: 'document search',
                                component: 'body',
                                elementType: 'link',
                                elementText: 'document library',
                                linkUrl: documentLibraryRoute.index(),
                                coreEvent: 'no',
                              },
                              {
                                eventCategory: 'document search page',
                                eventAction: `${router.query.tab} tab`,
                                eventLabel: 'document library link',
                                eventInteractionType: 0,
                              }
                            )
                          }
                        >
                          <FormattedMessage
                            id="DOCUMENT_LIBRARY"
                            defaultMessage="Document Library"
                          />
                        </Linq>
                      ),
                    }}
                  />
                  {'.'}
                </Typography>
              </>
            </DocumentLibraryBanner>
          </div>
        </div>
        <DocumentSearchTabs />
      </Container>
      <RecentlyViewedProductsCarousel
        dispType={AnalyticsDispType.RecentlyViewedDocumentsSearch}
        className={classes.recentlyViewedProducts}
        parentPageName="document search"
        parentPageNameDetail="document search page"
      />
    </DefaultLayout>
  )
}

export default DocumentSearch
