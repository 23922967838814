import LinkStyleButton from '@src/components/LinkStyleButton'
import WalkMeStep from '@src/components/WalkMeStep'
import { Step } from '@src/components/WalkMeStep/StepBody'
import { WalkMeStepProps } from '@src/components/WalkMeStep/WalkMeStep'
import { FormattedMessage } from 'react-intl'
import {
  ReadyToShipNotificationConstants,
  useReadyToShipNotificationWalkMe,
} from '@src/components/ProductInfo/ReadyToShipNotification'
import { sendWalkMeTracking } from '@utils/analytics'

const steps: Step[] = [
  {
    title: {
      id: 'READY_TO_SHIP_NOTIFICATION_STEP_1_TITLE',
      defaultMessage: 'Notify Me When Ready to Ship',
    },
    description: {
      id: 'READY_TO_SHIP_NOTIFICATION_STEP_1_DESCRIPTION',
      defaultMessage:
        'Sign up to be notified via email when this product is ready to ship.',
    },
  },
]

type ReadyToShipNotificationWalkMeStepProps = Pick<
  WalkMeStepProps,
  'anchorEl' | 'placement'
> & {
  stepNumber: number
}

export const ReadyToShipNotificationWalkMeStep = ({
  anchorEl,
  placement,
  stepNumber,
}: ReadyToShipNotificationWalkMeStepProps) => {
  const { dismissAlert, state, dismissStep } =
    useReadyToShipNotificationWalkMe()

  const step = steps[stepNumber - 1]
  if (
    !step ||
    !state.enabled ||
    state.stepDismissed ||
    state.activeStepNumber !== stepNumber
  )
    return null

  const optOutOfWalkMe = (eventLabel?: string) => {
    dismissAlert(true)
    sendWalkMeTracking(
      ReadyToShipNotificationConstants.ANALYTICS_TOUR,
      'notify me tip',
      eventLabel ? eventLabel : "don't show me tips"
    )
  }

  return (
    <WalkMeStep
      id={`ready-to-ship-notification-step${stepNumber}`}
      anchorEl={anchorEl}
      placement={placement}
      step={step}
      onClose={() => optOutOfWalkMe('close')}
      onClickAway={dismissStep}
      footer={
        <LinkStyleButton
          data-testid="dismiss-tips"
          onClick={() => optOutOfWalkMe()}
        >
          <FormattedMessage
            id="DONT_SHOW_ME_TIPS"
            defaultMessage="Don't Show Me Tips"
          />
        </LinkStyleButton>
      }
    />
  )
}
