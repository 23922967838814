import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Theme, Typography, Link, Box } from '@material-ui/core'
import { useCurrentUser } from '@utils/useCurrentUser'
import messages from '@src/utils/messages'
import { homeRoute, loginRoute, useRouter, registerRoute } from '@src/routes'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2, 0),
    userSelect: 'none',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      padding: theme.spacing(2),
    },
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
  },
  link: {
    cursor: 'pointer',
  },
}))

interface SignInBannerProps {
  mb?: number
  mr?: number
  signInGAEvent?: () => void
}

const SignInBanner: React.FC<SignInBannerProps> = ({
  mb,
  mr,
  signInGAEvent,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const { userIsLoggedIn, currentUser } = useCurrentUser()

  const affiliateAccount =
    currentUser?.__typename === 'LoggedInUser' &&
    currentUser.soldToPartners.length < 1

  return !userIsLoggedIn ? (
    <Box mb={mb} mr={mr}>
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <Link
            href={`${loginRoute.index()}?redirect=${encodeURIComponent(
              router.asPath || homeRoute.index()
            )}`}
            className={classes.link}
            onClick={() => signInGAEvent?.()}
          >
            <FormattedMessage id="SIGN_IN" defaultMessage="Sign In" />
          </Link>
          <Typography
            variant="body1"
            component="span"
            className={classes.title}
          >
            <FormattedMessage {...messages.VIEW_ORG_CONTRACT_PRICING} />
          </Typography>
        </div>
      </div>
    </Box>
  ) : affiliateAccount ? (
    <Box mb={mb} mr={mr}>
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <Link
            href={`${registerRoute.linkProfile()}?returnUrl=${encodeURIComponent(
              router.asPath || homeRoute.index()
            )}`}
            className={classes.link}
          >
            <FormattedMessage {...messages.LINK_YOUR_ACCOUNT} />
          </Link>
          <Typography
            variant="body1"
            component="span"
            className={classes.title}
          >
            <FormattedMessage {...messages.VIEW_ORG_CONTRACT_PRICING} />
          </Typography>
        </div>
      </div>
    </Box>
  ) : null
}

export default SignInBanner
