import { useGlobalModal } from '@src/utils/useGlobalModal'
import React from 'react'
import LinkStyleButton from '@src/components/LinkStyleButton'
import { FormattedMessage } from 'react-intl'
import ProductPricingModal from '@src/components/ProductPricingModal'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { Typography } from '@material-ui/core'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'
import { GA4ViewPricingEventPayload } from '@src/utils/analytics/types'

interface ViewPricingLinkProps {
  product: Partial<PdpFieldsFragment>
  substancePosition?: number
  focus: string
  typeButtonText?: React.ReactNode
  ga4Payload?: GA4ViewPricingEventPayload
}

const ViewPricingLink: React.FC<ViewPricingLinkProps> = ({
  product,
  substancePosition,
  focus,
  typeButtonText,
  ga4Payload,
}) => {
  const { setGlobalModalComponent, clearGlobalModalComponent } =
    useGlobalModal()

  const handleViewPricingLink = (focus) => {
    setGlobalModalComponent(
      <ProductPricingModal
        onClose={clearGlobalModalComponent}
        product={product}
        focus={focus}
      />
    )
    sendPricingAvailabilityInteractionEvent({
      action: 'view p&a',
      section: substancePosition ? undefined : 'products',
      component: ga4Payload?.component ?? 'list',
      elementType: ga4Payload?.elementType ?? 'link',
      elementText: ga4Payload?.elementText ?? 'view',
      material: {
        materialDescription: product.name,
        brand: product.brand?.key,
        product: product.productNumber,
      },
    })
  }

  return (
    <>
      <LinkStyleButton
        typeButtonText={typeButtonText}
        onClick={() => handleViewPricingLink(focus)}
      >
        <Typography variant="inherit">
          {typeButtonText || (
            <FormattedMessage id="VIEW" defaultMessage="View" />
          )}
        </Typography>
      </LinkStyleButton>
    </>
  )
}

export default ViewPricingLink
