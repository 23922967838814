import React from 'react'
import clsx from 'clsx'
import { Chip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import HandleMarkup from '@src/components/HandleMarkup'

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    margin: theme.spacing(2, 4, 5, 0),
  },
  chipContainer: {
    display: 'inline-block',
    margin: theme.spacing(2, 4, 5, 0),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: theme.spacing(2, 2, 4, 0),
      overflowX: 'scroll',
    },
  },
  chip: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 2, 2, 0),
    minWidth: 88,
    padding: theme.spacing(6, 0),
    textAlign: 'center',
    '& .MuiChip-label': {
      padding: theme.spacing(0, 2),
    },
  },
  packSize: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(-1),
  },
  price: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.black,
  },
  chipSelected: {
    backgroundColor: '#e7f3fb',
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
    '&&:focus': {
      // custom focus state
      backgroundColor: '#e7f3fb',
    },
  },
  chipUnavailable: {
    backgroundColor: theme.palette.grey[200],
    borderColor: theme.palette.grey[300],
    color: theme.palette.common.black,
  },
  chipSelectedUnvailable: {
    borderColor: theme.palette.primary.main,
  },
  // 7 frames * 0.1 seconds = 0.7 seconds total
  loader: {
    animation: '$fadeAnimation 0.7s ease-in-out infinite',
    backgroundColor: '#efefef',
    borderRadius: 5,
    height: 50,
    marginRight: theme.spacing(2),
    opacity: 0,
    width: 88,
  },
  // 0.1 seconds for each frame
  '@keyframes fadeAnimation': {
    '0%, 100%': { opacity: 1 },
    '14.285714%': { opacity: 0.8 },
    '28.571429%': { opacity: 0.6 },
    '42.857143%': { opacity: 0.4 },
    '57.142857%': { opacity: 0.2 },
    '71.428571%': { opacity: 0.2 },
    '85.714286%': { opacity: 0.2 },
  },
}))

interface IChip {
  currency: string
  packSize: string | null | undefined
  price: number
  value: string
  hidePriceMessageKey?: string | null | undefined
  availabilities?: {
    displayInquireLink?: boolean | null | undefined
  }[]
}

interface SelectableChipsProps {
  items: IChip[]
  loading?: boolean
  onSelect: (item: string) => void
  selectedChip: string
  unavailableChips?: string[]
  loadCount?: number
}

const SelectableChips = ({
  items,
  loading,
  onSelect,
  selectedChip,
  unavailableChips,
  loadCount = 6,
}: SelectableChipsProps) => {
  const classes = useStyles()

  return loading ? (
    <div className={classes.loaderContainer}>
      {Array(loadCount)
        .fill({})
        .map((_, index) => (
          <div
            className={classes.loader}
            key={index}
            style={{ animationDelay: `${index * 0.1}s` }}
          />
        ))}
    </div>
  ) : (
    <div className={classes.chipContainer}>
      {items.map((item, i) => {
        const isSelected = item?.value === selectedChip
        const isUnavailable = unavailableChips?.includes(item?.value)
        const hasInquireLink = item?.availabilities?.some(
          (availability) => availability?.displayInquireLink
        )

        const formattedLabel = (
          <>
            <HandleMarkup
              className={classes.packSize}
              value={item?.packSize?.toUpperCase()}
            />

            {!item?.hidePriceMessageKey ? (
              <div className={classes.price}>
                {hasInquireLink ? (
                  <Typography variant="inherit">
                    <FormattedMessage id="INQUIRE" defaultMessage="inquire" />
                  </Typography>
                ) : (
                  <FormattedNumber
                    currency={item?.currency}
                    currencyDisplay="symbol"
                    style="currency"
                    value={item?.price}
                  />
                )}
              </div>
            ) : null}
          </>
        )

        return (
          <Chip
            key={`${i}-${item?.packSize}-${item?.value}`}
            label={formattedLabel}
            variant="outlined"
            clickable
            onClick={() => onSelect(item?.value)}
            className={clsx(classes.chip, {
              [classes.chipSelected]: isSelected,
              [classes.chipUnavailable]: isUnavailable,
              [classes.chipSelectedUnvailable]: isSelected && isUnavailable,
            })}
          />
        )
      })}
    </div>
  )
}

export default SelectableChips
