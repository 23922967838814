import React from 'react'
import clsx from 'clsx'
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, MenuItem, Theme } from '@material-ui/core'
import { SearchSuggestionType } from '@src/types/graphql-types'
import {
  useSearchSuggestionsQuery,
  SuggestionFragment,
} from '@src/queries/SearchSuggestionsQuery.generated'
import LiquidInput, {
  LiquidInputProps,
} from '@src/components/LiquidInput/LiquidInput'
import { useMaterialCatalogFilter } from '@utils/useMaterialCatalogFilter'
import { useCurrentUser } from '@utils/useCurrentUser'

const useStyles = makeStyles((theme: Theme) => {
  return {
    autoSuggestContainer: { position: 'relative' },
    autoSuggest: {
      left: 0,
      right: 0,
      zIndex: 1,
      position: 'absolute',
      marginTop: theme.spacing(2),
      maxHeight: 268,
      overflowY: 'auto',
    },
    autoSuggestItem: {
      fontSize: theme.typography.pxToRem(14),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }
})

interface MaterialAutoSuggestProps
  extends Omit<LiquidInputProps, 'onChange' | 'onSelect' | 'value'> {
  value: string
  onChange: (item: string) => void
  onSelect?: (suggestion: SuggestionFragment | null) => void
  rootClass?: string
  rootStyle?: React.CSSProperties
  error?: boolean
  overrideMarketplaceByTag?: string
}

const stateReducer = (
  state: DownshiftState<SuggestionFragment>,
  changes: StateChangeOptions<SuggestionFragment>
) => {
  // this prevents input value from being reset on blur/clickaway
  switch (changes.type) {
    case Downshift.stateChangeTypes.touchEnd:
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.blurInput:
    case Downshift.stateChangeTypes.keyDownEscape:
      return {
        ...changes,
        inputValue: state.inputValue,
      }
    default:
      return changes
  }
}

const MaterialAutoSuggest: React.FC<MaterialAutoSuggestProps> = ({
  value,
  onBlur: _onBlur,
  onChange,
  onSelect,
  rootClass,
  rootStyle,
  error,
  id,
  overrideMarketplaceByTag,
  ...rest
}) => {
  const { catalogType, filter } = useMaterialCatalogFilter()
  const { isDarmstadtUser } = useCurrentUser()
  const classes = useStyles()
  const { data } = useSearchSuggestionsQuery({
    ssr: false,
    skip: value.length < 3,
    variables: {
      input: {
        term: value,
        types: [SearchSuggestionType.MaterialNumber],
        overrideMarketplaceByTag: overrideMarketplaceByTag,
        filter,
        catalogType,
        orgId: isDarmstadtUser ? 'DARMSTADT' : undefined,
      },
    },
  })

  return (
    <Downshift<SuggestionFragment>
      id={id}
      inputValue={value}
      onInputValueChange={(item) => onChange(item)}
      itemToString={(item) => (item ? item.label : '')}
      stateReducer={stateReducer}
      onSelect={onSelect}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        closeMenu,
      }) => {
        // We need to override the onBlur handler because material-ui
        // sometimes calls the handler without an event, which causes
        // issues with Downshift.
        const { onBlur, onChange, onKeyDown, ...inputProps } = getInputProps()
        return (
          <div
            className={clsx({ [String(rootClass)]: rootClass })}
            style={rootStyle}
          >
            <LiquidInput
              autoComplete="off"
              inputProps={{ ...inputProps }}
              onChange={
                onChange as
                  | React.ChangeEventHandler<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  | undefined
              }
              onKeyDown={(e) => {
                const isDisplayingSuggestions =
                  isOpen &&
                  !!data?.getSearchSuggestions?.suggestions.materialNumber
                    .length
                if (
                  e.key === 'Enter' &&
                  isDisplayingSuggestions &&
                  highlightedIndex == null
                ) {
                  e.preventDefault()
                  if (onSelect) onSelect(null)
                  closeMenu()
                  return
                }
                onKeyDown &&
                  onKeyDown(e as React.KeyboardEvent<HTMLInputElement>)
              }}
              onBlur={(event) => {
                if (!event) return
                onBlur && onBlur(event as React.FocusEvent<HTMLInputElement>)
                if (_onBlur) _onBlur(event)
              }}
              value={inputValue}
              error={error}
              {...rest}
            />
            {isOpen &&
              inputValue &&
              inputValue.length >= 3 &&
              data &&
              data.getSearchSuggestions &&
              !error && (
                <div
                  {...getMenuProps()}
                  className={classes.autoSuggestContainer}
                >
                  <Paper square className={classes.autoSuggest}>
                    {data.getSearchSuggestions.suggestions.materialNumber.map(
                      (item, index) => (
                        <MenuItem
                          key={item.label}
                          {...getItemProps({
                            key: item.label,
                            index,
                            item,
                          })}
                          component="div"
                          selected={index === highlightedIndex}
                          className={classes.autoSuggestItem}
                        >
                          {item.label}
                        </MenuItem>
                      )
                    )}
                  </Paper>
                </div>
              )}
          </div>
        )
      }}
    </Downshift>
  )
}

export default MaterialAutoSuggest
