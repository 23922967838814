import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import RequestQuoteModal from './RequestQuoteModal'
import { useGlobalModal } from '@src/utils/useGlobalModal'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { sendBeginOrCompleteQuoteRequestEvent } from '@src/utils/analytics'

type ButtonVariants = 'outlined' | 'contained' | 'text'
interface RequestQuoteButtonProps {
  quoteData?: {
    material?: ValidMaterialPricingDetailFragment | null
    product?: Partial<PdpFieldsFragment>
    quantity?: number
  }
  variant?: ButtonVariants
  size?: 'small' | 'medium' | 'large'
  fullWidth?: boolean
  className?: string
  type?: 'button' | 'submit'
  disabled?: boolean
  id?: string
  onClick?: () => void
}

const RequestQuoteButton: React.FC<RequestQuoteButtonProps> = ({
  quoteData,
  variant = 'outlined',
  size = 'large',
  fullWidth = true,
  className,
  type = 'button',
  disabled,
  id,
}) => {
  const { setGlobalModalComponent, clearGlobalModalComponent } =
    useGlobalModal()

  const handleOpenRequestQuoteModal = () => {
    sendBeginOrCompleteQuoteRequestEvent({
      event: 'begin_quote_request',
      section: 'pricing card',
      component: 'right rail',
      elementType: 'button',
      elementText: 'request quote',
    })
    setGlobalModalComponent(
      <RequestQuoteModal
        quoteData={quoteData}
        onClose={clearGlobalModalComponent}
      />
    )
  }

  return (
    <Button
      id={id}
      className={className}
      color="primary"
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={handleOpenRequestQuoteModal}
      size={size}
      type={type}
      variant={variant}
    >
      <FormattedMessage id="REQUEST_QUOTE" defaultMessage="Request Quote" />
    </Button>
  )
}

export default RequestQuoteButton
