import React, { FC, useMemo } from 'react'
import { useSearchQuery } from '@src/pages/[country]/[language]/search/[searchTerm]'
import { useIntl } from 'react-intl'
import { Sort } from '@src/types/graphql-types'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { searchSortOptions } from '../config'

const useStyles = makeStyles((theme: Theme) => ({
  radioGroup: {
    flexDirection: 'column',
  },
  icon: {
    display: 'flex',
    width: '24px',
    height: '24px',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '50%',
    '& span': {
      margin: 'auto',
      width: '14px',
      height: '14px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const MobileSortFacets: FC<{}> = () => {
  const [query, , queryActions] = useSearchQuery()
  const { formatMessage } = useIntl()
  const classes = useStyles()

  const sortOptions = useMemo(
    () =>
      searchSortOptions[query.focus].map((option) => ({
        ...option,
        label: formatMessage(option.label),
      })),
    [formatMessage, query.focus]
  )

  return (
    <RadioGroup
      row
      className={classes.radioGroup}
      aria-label="sortOptions"
      name="sortOptions"
      value={query.sort}
      onChange={(e) => queryActions.handleSortChange(e.target.value as Sort)}
    >
      {sortOptions.map(({ value, label }, index) => (
        <FormControlLabel
          name="sortOptionId"
          value={value}
          key={index}
          label={label.split(' ').slice(2).join(' ')}
          control={
            <Radio
              disableRipple
              disableTouchRipple
              disableFocusRipple
              icon={<span className={classes.icon} />}
              checkedIcon={
                <span className={classes.icon}>
                  <span></span>
                </span>
              }
            />
          }
        />
      ))}
    </RadioGroup>
  )
}

export default MobileSortFacets
