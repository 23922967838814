import React, { FC } from 'react'
import { FormattedNumber } from 'react-intl'
import { Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import HidePriceMessage from '@src/components/HidePriceMessage'

const useStyles = makeStyles((theme: Theme) => ({
  pricingInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  listPrice: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'line-through',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 2),
    },
  },
}))

interface Props {
  material: ValidMaterialPricingDetailFragment
}

const DisplayPrice: FC<Props> = ({ material }) => {
  const classes = useStyles()

  if (material.hidePriceMessageKey) {
    return <HidePriceMessage msgKey={material.hidePriceMessageKey} />
  }

  return (
    // Price
    <div className={classes.pricingInfo}>
      {!!material.price && (
        <Typography variant="h2" component="div">
          <FormattedNumber
            style="currency"
            currencyDisplay="symbol"
            currency={material.currency}
            value={material.price}
          />
        </Typography>
      )}

      {/* List Price */}
      {!!material.price &&
      !!material.listPrice &&
      material.listPrice > material.price ? (
        <Typography variant="h2" component="div" className={classes.listPrice}>
          <FormattedNumber
            style="currency"
            currencyDisplay="symbol"
            currency={material.currency}
            value={material.listPrice}
          />
        </Typography>
      ) : null}
    </div>
  )
}

export default DisplayPrice
