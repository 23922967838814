import React, { useState, FC } from 'react'
import { useIntl } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'
import { useSearchQuery } from '@src/pages/[country]/[language]/search/[searchTerm]'
import { Facet, SearchResultsMetadata } from '@src/types/graphql-types'
import { SearchFocusType } from '@utils/searchUtils'
import messages from '../messages'
import vrStyles from '@src/styles/utils/vrStyles'
import { MobileSearchResultsNavigation } from '../SearchResultsNavigation'
import SearchResultsCount from '../SearchResultsCount'
import MobileFilterAndSortModal from '../MobileFilterAndSortModal'
import FilterAndSortIcon from '@src/icons/FilterAndSortIcon'

const { vr6 } = vrStyles

const useStyles = makeStyles((theme: Theme) => ({
  filterAndSortBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(3)}px 0`,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.common.white,
  },
  filterTrigger: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  filterIcon: {
    fontSize: theme.typography.pxToRem(24),
    marginRight: theme.spacing(2),
  },
  facetCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(2),
  },
  vr6,
  mobileFiltersAndNav: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

interface ResponsiveSearchResultsMenuProps {
  metadata?: SearchResultsMetadata
  orgId?: string
  facets?: Facet[]
  showSortBar?: boolean
}

const ResponsiveSearchResultsMenu: FC<ResponsiveSearchResultsMenuProps> = ({
  metadata,
  orgId,
  facets,
  showSortBar,
}) => {
  const [query, selectedFacets] = useSearchQuery()
  const { formatMessage } = useIntl()
  const [isFilterAndSortModalOpen, setIsFilterAndSortModalOpen] =
    useState(false)
  const classes = useStyles()
  const handleOnModalClose = (): void => {
    setIsFilterAndSortModalOpen(false)
  }
  const handleOnClickFilterAndSort = (
    e:
      | React.MouseEvent<EventTarget & HTMLSpanElement>
      | React.KeyboardEvent<EventTarget & HTMLSpanElement>
  ): void => {
    e.preventDefault()
    setIsFilterAndSortModalOpen(true)
  }
  const isStructureSearch = query.focus === SearchFocusType.StructureSearch

  return (
    <div className={classes.mobileFiltersAndNav}>
      <div className={classes.vr6}>
        {metadata && (
          <SearchResultsCount
            currPage={metadata.page}
            perPage={metadata.perPage}
            resultCount={metadata.setsCount || metadata.itemCount || 0}
            searchTerm={query.term}
            searchType={query.focus}
          />
        )}
      </div>
      <MobileSearchResultsNavigation orgId={orgId} />

      {(Boolean(facets?.length) || showSortBar) && (
        <div className={classes.filterAndSortBar}>
          <ButtonBase
            className={classes.filterTrigger}
            onClick={handleOnClickFilterAndSort}
          >
            {!!selectedFacets.length ? (
              <span className={classes.facetCount}>
                {selectedFacets.length}
              </span>
            ) : (
              <FilterAndSortIcon
                className={classes.filterIcon}
                fontSize="inherit"
                color="primary"
              />
            )}

            {formatMessage(messages.FILTER_AND_SORT_HEADING)}
          </ButtonBase>
        </div>
      )}

      <MobileFilterAndSortModal
        facets={facets}
        isStructureSearch={isStructureSearch}
        isModalOpen={isFilterAndSortModalOpen}
        onModalClose={() => handleOnModalClose()}
        showSortBar={showSortBar}
      />
    </div>
  )
}

export default ResponsiveSearchResultsMenu
