import ErrorIcon from '@material-ui/icons/Error'
import { ApolloQueryResult } from 'apollo-client'
import messages from '@utils/messages'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { FC, useEffect, useState } from 'react'
import SelectDtaqDealerModal from '@src/components/SelectDtaqDealerModal'

import SpacingBox from '../SpacingBox'
import useStyles from './styles'
import { Button } from '@material-ui/core'
import { SitePreference, useCurrentUser } from '@src/utils/useCurrentUser'
import { Dealer } from '@src/types/graphql-types'
import { useDealersQuery } from '@src/queries/DealersQuery.generated'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { useUserSession } from '@src/utils/useUserSession'

interface PreferredDealerSectionProps {
  materialPricing: ValidMaterialPricingDetailFragment[]
  customerPricingResponse?: ApolloQueryResult<any>
  values: Record<string, unknown>
  setFieldValue: (field: string, value: string) => void
  selectCallback: (
    dealerId: string | null,
    values: Record<string, unknown>,
    materialPricing: ValidMaterialPricingDetailFragment[],
    setFieldValue: (field: string, value: any) => void,
    setIsModalOpen: (value: React.SetStateAction<boolean>) => void
  ) => Promise<void>
}

const SeeBuyingOptionsDealerText: FC<{
  dealerCount: number | undefined
}> = ({ dealerCount }) => {
  const { formatMessage } = useIntl()
  const linkText = formatMessage(messages.SEE_ALL_BUYING_OPTIONS)
  if (Boolean(dealerCount)) {
    return <>{`${linkText} (${dealerCount})`}</>
  }
  return <>{`${linkText}`}</>
}

const PreferredDealerShippingText: FC<{
  preferredDealerName: string
  noPriceForDealer: boolean
}> = ({ preferredDealerName, noPriceForDealer }) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  if (noPriceForDealer) {
    return (
      <span className={classes.seeDealerOptionsText}>
        <ErrorIcon color="error" className={classes.errorIcon} />
        <FormattedMessage
          {...messages.PRICING_AND_AVAILABILITY_TEMPORARILY_UNAVAILABLE_FROM}
          values={{ preferredDealerName }}
        />
      </span>
    )
  }

  const preferredDealerText = preferredDealerName
    ? `${formatMessage(messages.SHIPPED_SOLD_BY)} ${preferredDealerName}`
    : ''

  if (!preferredDealerText) return <></>
  // Space after text needed for formatting
  return (
    <span
      className={classes.seeDealerOptionsText}
    >{`${preferredDealerText} `}</span>
  )
}

const PreferredDealerSection: FC<PreferredDealerSectionProps> = ({
  customerPricingResponse,
  materialPricing,
  selectCallback,
  setFieldValue,
  values,
}) => {
  const classes = useStyles()
  const {
    getSitePreference,
    isDTAQZuCustomer,
    currentUserState,
    userIsLoggedIn,
  } = useCurrentUser()
  const { userSession } = useUserSession()
  const [dealerCount, setDealerCount] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const preferredDealerInfo = getSitePreference(
    SitePreference.PreferredDealerInfo
  )
  const preferredDealerName = preferredDealerInfo?.orgName
  const [dealerSoldBy, setDealerSoldBy] = useState<string | null>(
    preferredDealerName
  )

  const {
    data: dealersData,
    refetch,
    error: dealersError,
  } = useDealersQuery({
    context: { userSession },
    errorPolicy: 'all',
    variables: {
      countryCode: userSession?.country,
      stateCode: String(currentUserState),
      searchTerm: '',
    },
    skip: !userIsLoggedIn || !isDTAQZuCustomer,
  })

  const onSearch = async (term: string) => {
    await refetch({
      countryCode: userSession.country,
      stateCode: String(currentUserState),
      searchTerm: term.toLowerCase(),
    })
  }

  useEffect(() => {
    if (dealersData && dealerCount === 0) {
      setDealerCount(dealersData?.getDealers?.length)
    }
  }, [dealersData])

  useEffect(() => {
    // this should only run after the default dealer is updated for pricing
    if (customerPricingResponse?.data) {
      const cspProducts =
        customerPricingResponse?.data?.getCustomerPricingForProduct?.products
      const updatedDealerId = cspProducts?.[0]?.dealerId
      const { organizationName = null } = dealersData?.getDealers.find(
        (dealer) => dealer.id === updatedDealerId
      ) as Dealer

      setIsModalOpen(false)
      setDealerSoldBy(organizationName)
    }
  }, [customerPricingResponse?.data])

  return (
    <>
      <SpacingBox mb={6}>
        {preferredDealerName && (
          <PreferredDealerShippingText
            preferredDealerName={dealerSoldBy ?? preferredDealerName}
            noPriceForDealer={false}
          />
        )}
        <Button
          variant="text"
          className={classes.seeDealerOptionsLink}
          onClick={() => setIsModalOpen(true)}
        >
          <SeeBuyingOptionsDealerText dealerCount={dealerCount} />
        </Button>
      </SpacingBox>
      {isModalOpen && (
        <SelectDtaqDealerModal
          dealers={dealersData?.getDealers || []}
          disabled={false}
          error={dealersError}
          isSavingDealerPreference={false}
          materialPricing={materialPricing}
          onDealerSelect={selectCallback}
          onSearch={onSearch}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          setFieldValue={setFieldValue}
          setIsModalOpen={setIsModalOpen}
          values={values}
        />
      )}
    </>
  )
}

export default PreferredDealerSection
