import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { Container } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { SearchFocusType } from '@utils/searchUtils'
import DefaultLayout from '@src/components/DefaultLayout'
import { useSearchQuery } from '../../pages/[country]/[language]/search/[searchTerm]'
import CompareDrawer from './CompareDrawer'
const ProductSearchResults = dynamic(() => import('./ProductSearchResults'))
const GeneSearchResults = dynamic(() => import('./GeneSearchResults'))
const PaperSearchResults = dynamic(() => import('./PaperSearchResults'))
const DocumentSearchResults = dynamic(() => import('./DocumentSearchResults'))
const ContentSearchResults = dynamic(() => import('./ContentSearchResults'))
const BuildingBlocksSearchResults = dynamic(
  () => import('./BuildingBlocksSearchResults')
)
const StructureSearchResults = dynamic(() => import('./StructureSearchResults'))
const ChromatogramSearchResults = dynamic(
  () => import('./ChromatogramSearchResults')
)

const useStyles = makeStyles((theme: Theme) => {
  return {
    searchContainer: {
      [theme.breakpoints.down('xs')]: {
        overflowX: 'hidden',
      },
      [theme.breakpoints.up('xs')]: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderTop: 0,
      },
    },
    resultsContainer: {
      marginBottom: theme.spacing(10),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(8),
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(7, 0, 0, 0),
      },
    },
  }
})

export enum ViewPreference {
  grid,
  list,
}

export interface ViewPreferenceProps {
  view: ViewPreference
  setView: (view: ViewPreference) => void
}

const SearchResults = () => {
  // lazy load each search type to reduce bundle size of search page
  const classes = useStyles()
  const [query] = useSearchQuery()

  const [viewPreference, setViewPreference] = useState<ViewPreference>(
    ViewPreference.grid
  )

  let searchComponent: React.ReactNode = null
  switch (query.focus) {
    case SearchFocusType.Products:
      searchComponent = <ProductSearchResults />
      break
    case SearchFocusType.Genes:
      searchComponent = <GeneSearchResults />
      break
    case SearchFocusType.Papers:
      searchComponent = <PaperSearchResults />
      break
    // ContentDocumentSearchResults handles both Technical Document and Site Content search results, the focus determines the query results within the component.
    case SearchFocusType.TechnicalDocuments:
      searchComponent = <DocumentSearchResults />
      break
    case SearchFocusType.SiteContent:
      searchComponent = <ContentSearchResults />
      break
    case SearchFocusType.BuildingBlocks:
      searchComponent = (
        <BuildingBlocksSearchResults
          view={viewPreference}
          setView={setViewPreference}
        />
      )
      break
    case SearchFocusType.StructureSearch:
      searchComponent = (
        <StructureSearchResults
          view={viewPreference}
          setView={setViewPreference}
        />
      )
      break
    case SearchFocusType.Chromatograms:
      searchComponent = <ChromatogramSearchResults />
      break
    default:
      searchComponent = null
      break
  }

  return (
    <DefaultLayout className={classes.searchContainer}>
      <Container maxWidth="lg" classes={{ root: classes.resultsContainer }}>
        <div> {searchComponent}</div>
      </Container>
      <CompareDrawer />
    </DefaultLayout>
  )
}

export default SearchResults
