import React, { FC, Fragment } from 'react'
import { ApolloError } from 'apollo-boost'
import { Facet, FacetInput } from '@src/types/graphql-types'
import FacetGroup from './FacetGroup'
import FacetsSkeleton from './FacetsSkeleton'

interface FacetsProps {
  loading: boolean
  error?: ApolloError
  facets?: Facet[]
  showMore?: boolean
  substanceFacetChange?: (
    newSelectedFacets: FacetInput,
    optionPreviouslyChecked: boolean
  ) => void
  selectedSubstanceFacetOptions?: string[]
}
const Facets: FC<FacetsProps> = ({
  loading,
  error,
  facets,
  showMore,
  substanceFacetChange,
  selectedSubstanceFacetOptions,
}) => {
  if (loading) return <FacetsSkeleton />
  if (error || !facets) return null
  return (
    <div data-testid="srp-facets">
      {facets &&
        facets.map((facet) => (
          <Fragment key={facet.key}>
            {!facet?.isHidden && (
              <FacetGroup
                key={facet.key}
                facet={facet}
                allFacets={facets}
                showMore={showMore}
                substanceFacetChange={substanceFacetChange}
                selectedSubstanceFacetOptions={selectedSubstanceFacetOptions}
              />
            )}
          </Fragment>
        ))}
    </div>
  )
}

export default Facets
