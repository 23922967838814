import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    table: {
      '& .MuiTableCell-root.MuiTableCell-head': {
        padding: theme.spacing(0, 2),
        '&:first-child': {
          padding: theme.spacing(0, 2, 0, 1 / 2),
        },
        '&:last-child': {
          padding: theme.spacing(0, 1 / 2, 0, 2),
        },
      },
      '& .MuiTableCell-root': {
        padding: theme.spacing(2),
        '&:first-child': {
          padding: theme.spacing(2, 2, 2, 1 / 2),
        },
        '&:last-child': {
          padding: theme.spacing(1, 1 / 2, 1, 2),
        },
      },
    },
    multiCountryTable: {
      paddingBottom: theme.spacing(7),
    },
    tRow: {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        lineHeight: 1.3,
        '&:nth-child(1)': {
          width: '.5%',
        },
        '&:nth-child(2)': {
          width: '15%',
        },
        '&:nth-child(3)': {
          width: '12%',
        },
        '&:nth-child(4)': {
          flexGrow: 1,
          flexShrink: 0,
        },
        '&:nth-child(5)': {
          width: '14%',
          minWidth: 'fit-content',
        },
        '&:nth-child(6)': {
          width: '17%',
          maxWidth: theme.typography.pxToRem(180),
        },
        '&:not(:last-child)': {
          paddingRight: theme.spacing(2),
        },
      },
    },
    tHeadRow: {
      whiteSpace: 'nowrap',
      '& > *': {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    tBodyRow: {
      padding: theme.spacing(3, 0),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    tfoot: {
      backgroundColor: 'white',
    },
    addToCart: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4),
    },
    countryName: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(19),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
    },
    listPrice: {
      fontSize: theme.typography.pxToRem(11),
      textDecoration: 'line-through',
      color: theme.palette.common.black,
    },
    shipEstimateColumn: {
      display: 'flex',
      alignItems: 'center',
    },
    availabilityUnknownIcon: {
      fontSize: theme.typography.pxToRem(20),
      color: '#f7a703',
      marginRight: theme.spacing(2),
      width: '16px',
    },
    availableIcon: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.success.main,
      marginRight: theme.spacing(2),
      width: '16px',
      height: 'auto',
    },
    seeDealerOptionsText: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'capitalize',
      marginLeft: theme.spacing(1),
      verticalAlign: 'inherit',
      display: 'inline-flex',
    },
    seeDealerOptionsLink: {
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(16),
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        verticalAlign: 'baseline',
      },
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    tBodyButtonRow: {
      padding: theme.spacing(3, 0),
      justifyContent: 'flex-end',
    },
    addToCartButton: {
      minWidth: theme.typography.pxToRem(136),
    },
    bulkOrderLink: {
      fontSize: theme.typography.pxToRem(16),
      marginRight: theme.spacing(6),
    },
    buttonFilled: {
      marginRight: theme.spacing(6),
      '&, &:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
    errorIcon: {
      marginRight: theme.spacing(1),
    },
  }
})

export default useStyles
