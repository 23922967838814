import gql from 'graphql-tag'

export const PRICING_FOR_PRODUCT_CUSTOMER_SPECIFIC = gql`
  query PricingAndAvailabilityForCustomer(
    $input: GetCustomerPricingForProductInput!
  ) {
    getCustomerPricingForProduct(input: $input) {
      products {
        dealerId
        items {
          sku
          brand
          listPrice
          price
          qty
          cartItemId
        }
      }
    }
  }
`
