import React, { useRef, useState } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Carousel, { ControlProps } from 'nuka-carousel'
import { Theme, ButtonBase, Container, Typography } from '@material-ui/core'
import CarouselArrowLeftIcon from '@icons/CarouselArrowLeftIcon'
import CarouselArrowRightIcon from '@icons/CarouselArrowRightIcon'
import {
  PromoObject,
  GA4EcommercePayload,
  GA4EcommercePayloadItem,
} from '@sial/common-utils'
import { ProductCardType, PRODUCT_CARD_TYPE_TEST_ID } from '@utils/searchUtils'
import ReplacementProductCard, { MainProduct } from './ReplacementProductCard'
import useResponsiveSizes from '@utils/useResponsiveSizes'
import { Brand, CatalogImage } from '@src/types/graphql-types'
import { sendInternalPromotionsEvent } from '@src/utils/analytics'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    withoutControls: {
      padding: 0,
    },
    content: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),

      // Adds visible keyboard focus since the div element is tabbable (though not interactive)
      '& .slider-frame': {
        border: `2px solid transparent`,
        '&:focus-visible': {
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: theme.shape.borderRadius,
        },
      },
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(8),
      },
    },
    contentCondensed: {
      padding: 0,
    },
    title: {
      marginBottom: theme.spacing(8),
      '$contentCondensed &': {
        marginBottom: theme.spacing(4),
      },
    },
    navBtn: {},
    navBtnSmall: {
      height: 24,
      width: 24,
    },
    navBtnLarge: {
      height: 32,
      width: 32,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    navBtnLeft: {
      transform: 'translateX(-40px)',
    },
    mobileBottomControls: {
      width: 105,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      bottom: -44,
      [theme.breakpoints.up('xs')]: {
        bottom: -25,
      },
    },
    icon: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.primary.main,
      width: '1em',
    },
    iconDisabled: {
      color: theme.palette.grey[500],
    },
    cartRecommended: {
      backgroundColor: theme.palette.background.grey,
      marginBottom: theme.spacing(20),
    },
    card: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(10),
      },
    },
  }
})

const handlePrevious = (previousSlide: Function) => {
  previousSlide()
}

const handleNext = (nextSlide: Function) => {
  nextSlide()
}

export type ProductCarouselControlProps = Pick<
  ControlProps,
  | 'currentSlide'
  | 'slidesToShow'
  | 'slideCount'
  | 'previousSlide'
  | 'nextSlide'
  | 'slidesToScroll'
>

export const useProductCarouselControls = () => {
  const classes = useStyles()

  return {
    renderCenterLeftControls: (props: ProductCarouselControlProps) => {
      const disabled = props.currentSlide === 0
      return (
        <ButtonBase
          className={clsx(
            classes.navBtn,
            classes.navBtnLarge,
            classes.navBtnLeft
          )}
          disabled={disabled}
          onClick={() => handlePrevious(props.previousSlide)}
          aria-label="previous"
        >
          <CarouselArrowLeftIcon
            className={clsx(classes.icon, {
              [classes.iconDisabled]: disabled,
            })}
          />
        </ButtonBase>
      )
    },
    renderCenterRightControls: (props: ProductCarouselControlProps) => {
      const disabled =
        props.currentSlide + props.slidesToShow === props.slideCount ||
        props.slideCount < props.slidesToShow
      return (
        <ButtonBase
          disabled={disabled}
          className={clsx(classes.navBtn, classes.navBtnLarge)}
          onClick={() => handleNext(props.nextSlide)}
          aria-label="next"
        >
          <CarouselArrowRightIcon
            className={clsx(classes.icon, {
              [classes.iconDisabled]: props.slidesToScroll && disabled,
            })}
          />
        </ButtonBase>
      )
    },
    renderBottomCenterControls: (props: ProductCarouselControlProps) => {
      const previousDisabled = props.currentSlide === 0
      const nextDisabled =
        props.currentSlide + props.slidesToShow === props.slideCount ||
        props.slideCount < props.slidesToShow
      return (
        <div className={classes.mobileBottomControls}>
          <ButtonBase
            disabled={previousDisabled}
            onClick={() => handlePrevious(props.previousSlide)}
            aria-label="previous"
            className={clsx(classes.navBtn, classes.navBtnSmall)}
          >
            <CarouselArrowLeftIcon
              className={clsx(classes.icon, {
                [classes.iconDisabled]: previousDisabled,
              })}
            />
          </ButtonBase>
          <Typography variant="body2">
            {props.currentSlide + 1} of {props.slideCount}
          </Typography>
          <ButtonBase
            disabled={nextDisabled}
            onClick={() => handleNext(props.nextSlide)}
            aria-label="next"
            className={clsx(classes.navBtn, classes.navBtnSmall)}
          >
            <CarouselArrowRightIcon
              className={clsx(classes.icon, {
                [classes.iconDisabled]: props.slidesToScroll && nextDisabled,
              })}
            />
          </ButtonBase>
        </div>
      )
    },
  }
}

export interface CarouselProduct {
  id?: string | null
  productKey?: string
  productNumber: string
  name: string
  description?: string | null
  gaProductCode?: string | null
  brand: Pick<Brand, 'key' | 'erpKey' | 'name' | 'color'>
  images: Pick<CatalogImage, 'altText' | 'mediumUrl'>[]
  isMarketplace?: boolean
}
interface CarouselProps {
  type: ProductCardType
  withoutControls?: boolean
  slidesToShow?: number
  condensed?: boolean
  preloadCardImages?: boolean
  products: CarouselProduct[]
  mainProduct?: MainProduct
}

const ReplacementProductsCarousel: React.FC<CarouselProps> = ({
  type,
  products,
  withoutControls,
  slidesToShow,
  condensed,
  preloadCardImages,
  mainProduct,
}) => {
  const classes = useStyles()
  const getSize = useResponsiveSizes()
  const testId = PRODUCT_CARD_TYPE_TEST_ID[type]
  const carouselControls = useProductCarouselControls()
  const isCartRecommended = type === ProductCardType.CartRecommendedProducts
  const numProducts = getSize({ xs: 1, sm: 2, lg: 3 })

  const carouselRef = useRef(null)
  const [sawCarousel, setSawCarousel] = useState(false)
  const carouselVisbility = useIntersectionObserver(carouselRef, {
    threshold: 0.75,
  })

  // Kick out and don't render anything if no product data.
  if (!products || !products?.length) return null

  if (carouselVisbility?.isIntersecting && !sawCarousel) {
    const slicedViewedProducts = products.slice(0, slidesToShow || numProducts)
    const promoObjects: PromoObject[] = []
    const ga4Promotions: GA4EcommercePayloadItem[] = []

    for (const [index, product] of slicedViewedProducts.entries()) {
      const creativeName = `${product?.brand?.key?.toLowerCase()}_${product?.productNumber?.toLowerCase()}`

      const promoObj: PromoObject = {
        id: 'PDP Discontinued Product Replacement',
        name: 'Manually Set P&A Replacement',
        creative: creativeName,
      }

      promoObjects.push(promoObj)

      const ga4PromoObj: GA4EcommercePayloadItem = {
        creative_name: creativeName,
        promotion_id: 'pdp discontinued product replacement',
        promotion_name: 'manually set p&a replacement',
        index: index + 1,
        item_id: product?.productNumber?.toLowerCase(),
        item_brand: product?.brand?.key?.toLowerCase(),
        item_list_id: 'pdp',
        item_list_name: 'pdp',
      }

      ga4Promotions.push(ga4PromoObj)
    }

    const ga4PromoPayload: GA4EcommercePayload = {
      creative_slot: `discontinued replacement products - pdp`,
      items: ga4Promotions,
    }

    sendInternalPromotionsEvent(promoObjects, ga4PromoPayload)
    setSawCarousel(true)
  }

  return (
    <div
      data-testid={testId}
      id={testId}
      className={clsx({
        [classes.cartRecommended]: isCartRecommended,
      })}
      ref={carouselRef}
    >
      <Container
        maxWidth="lg"
        className={clsx({
          [classes.withoutControls]: withoutControls,
        })}
      >
        <div
          className={clsx(classes.content, {
            [classes.contentCondensed]: condensed,
          })}
        >
          <Carousel
            slidesToScroll={slidesToShow || numProducts}
            slidesToShow={slidesToShow || numProducts}
            disableEdgeSwiping
            withoutControls={withoutControls}
            {...carouselControls}
          >
            {products.map((product, i) => (
              <ReplacementProductCard
                key={i}
                mainProduct={mainProduct}
                product={product}
                testId={testId}
                preloadCardImages={preloadCardImages}
                className={classes.card}
                index={i % numProducts}
                type={type}
              />
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  )
}

export default ReplacementProductsCarousel
