import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { FormattedMessage, FormattedMessageProps } from 'react-intl'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { homeRoute, loginRoute, useRouter } from '@src/routes'

type buttonSize = 'small' | 'medium' | 'large'
interface SignInButtonProps {
  buttonMessage?: FormattedMessageProps
  fullWidth?: boolean
  size?: buttonSize
}

const SignInButton: FC<SignInButtonProps> = ({
  buttonMessage,
  fullWidth = false,
  size = 'large',
}) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const displayFullWidth = !isDesktop || fullWidth
  const router = useRouter()

  const message = buttonMessage ?? {
    id: 'SIGN_IN_FOR_BEST_PRICING',
    defaultMessage: 'Sign In for Best Price',
  }

  return (
    <>
      <Button
        color="primary"
        fullWidth={displayFullWidth}
        size={size}
        variant="contained"
        href={`${loginRoute.index()}?redirect=${encodeURIComponent(
          router.asPath || homeRoute.index()
        )}`}
      >
        <FormattedMessage {...message} />
      </Button>
    </>
  )
}

export default SignInButton
