import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from '@utils/messages'
import ResponsiveModal, {
  ModalSizes,
  ResponsiveModalBody,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import { Typography } from '@material-ui/core'

interface Props {
  open: boolean
  onCancel: () => void
}

const RestrictedItemModal: React.FC<Props> = ({ open, onCancel }) => {
  return (
    <ResponsiveModal
      open={open}
      onClose={onCancel}
      size={ModalSizes.Small}
      renderTitle={() => (
        <Typography variant="h2">
          <FormattedMessage {...messages.RESTRICTED_ITEM} />
        </Typography>
      )}
    >
      <ResponsiveModalBody>
        <Typography component="p">
          <FormattedMessage {...messages.RESTRICTED_ITEM_MESSAGE} />
        </Typography>
      </ResponsiveModalBody>
    </ResponsiveModal>
  )
}

export default RestrictedItemModal
