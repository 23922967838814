import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import HandleMarkup from '../HandleMarkup'
import ResponsiveCatalogImage from '@src/components/ResponsiveCatalogImage'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'

const useStyles = makeStyles((theme: Theme) => ({
  itemContainer: {
    display: 'flex',
    marginBottom: theme.spacing(5),
  },
  details: { overflow: 'hidden' },
  imageContainer: {
    width: 60,
    height: 60,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: `solid 1px ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    wordWrap: 'break-word',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  materialName: {
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  quantity: {
    fontSize: theme.typography.pxToRem(12),
  },
}))

interface RequestQuoteItemDetailProps {
  material: ValidMaterialPricingDetailFragment | null
  product: Partial<PdpFieldsFragment>
  quantity: number
}

const RequestQuoteItemDetails: React.FC<RequestQuoteItemDetailProps> = ({
  material,
  product,
  quantity,
}) => {
  const classes = useStyles()
  const [productImages] = product?.images ?? []

  return (
    <div className={classes.itemContainer}>
      <div className={classes.imageContainer}>
        {productImages ? (
          <ResponsiveCatalogImage
            alt={product?.productNumber}
            className={classes.image}
            sizes={'60px'}
            src={productImages.smallUrl}
            urls={[
              productImages.smallUrl,
              productImages.mediumUrl,
              productImages.largeUrl,
            ]}
          />
        ) : null}
      </div>

      <div className={classes.details}>
        {material?.materialNumber ? (
          <Typography variant="subtitle2">
            {material?.materialNumber}
          </Typography>
        ) : null}
        {product?.name ? (
          <div className={classes.materialName}>
            <HandleMarkup value={product?.name} />
          </div>
        ) : null}
        <div className={classes.quantity}>
          <FormattedMessage id="QUANTITY" defaultMessage="Quantity" />
          <span>: {quantity}</span>
        </div>
      </div>
    </div>
  )
}

export default RequestQuoteItemDetails
