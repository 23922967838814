import { defineMessages } from 'react-intl'

export interface Message {
  id: string
  defaultMessage: string
  title?: string
}

export const messages = defineMessages({
  REPLACEMENT_PRODUCT_MESSAGE: {
    id: 'ReplacementProductsCarouselModal.REPLACEMENT_PRODUCT_MESSAGE',
    defaultMessage: 'We recommend the replacement products listed below:',
  },
  CONTACT_SELLER_WITH_NAME: {
    id: 'ReplacementProductsCarouselModal.CONTACT_SELLER_WITH_NAME',
    defaultMessage: 'Contact {sellerName}.',
  },
  CONTACT_TECHNICAL_SERVICE: {
    id: 'ReplacementProductsCarouselModal.CONTACT_TECHNICAL_SERVICE',
    defaultMessage: 'Contact Technical Service',
  },
  FOR_ASSISTANCE: {
    id: 'ReplacementProductsCarouselModal.FOR_ASSISTANCE',
    defaultMessage: 'for assistance.',
  },
  THIS_PRODUCT_HAS_BEEN_DISCONTINUED: {
    id: 'ReplacementProductsCarouselModal.THIS_PRODUCT_HAS_BEEN_DISCONTINUED',
    defaultMessage: 'Product {productNumber} has been discontinued.',
    title: 'DISCONTINUED',
  },
  TECHNICAL_DIFFICULTIES: {
    id: 'ReplacementProductsCarouselModal.TECHNICAL_DIFFICULTIES',
    defaultMessage:
      'We are currently experiencing some technical difficulties. Please try again later.',
    title: 'TECHNICAL DIFFICULTIES',
  },
})

export default messages
