import React, { FC } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AvailabilityMessage from '@src/components/AvailabilityMessage'
import { FormattedMessage } from 'react-intl'
import messages from '@src/utils/messages'
import HandleMarkup from '../HandleMarkup'
import {
  PromotionalBundleItemPricing,
  PromotionalBundlePricing,
} from '@src/types/graphql-types'
import {
  Pricing,
  TrackedAvailabilityMessageProvider,
} from '../TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'

export const getPromoBundlePricing = (
  promoBundle: PromotionalBundleItemPricing
): Pricing => ({
  currency: promoBundle.currency,
  listPriceCurrency: promoBundle.currency,
  listPrice: promoBundle.listPrice,
  price: promoBundle.yourPrice,
})

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& td': {
      '&:nth-child(3)': {
        textAlign: 'center',
      },
      fontSize: 14,
      paddingLeft: 0,
    },
    '& th': {
      '&:nth-child(3)': {
        textAlign: 'center',
      },
      paddingLeft: 0,
    },
  },
  tableContainer: {
    boxShadow: 'none',
    maxHeight: 150,
  },
  headerLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  labelName: {
    marginRight: theme.spacing(2),
  },
  materialNumber: {},
  materialDescription: {
    width: '30%',
  },
  tableTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
  },
  header: {
    fontSize: 12,
    fontWeight: 900,
  },
}))

interface Props {
  promoBundlePricingData: PromotionalBundlePricing
}

const PromoBundleTable: FC<Props> = ({ promoBundlePricingData }) => {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.tableTitle}>
        <FormattedMessage
          {...messages.PROMO_BUNDLE_TABLE_TITLE}
          values={{ count: promoBundlePricingData?.bundleItems.length }}
        />
      </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table size="small" stickyHeader className={classes.table}>
          <col style={{ width: '20%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '50%' }} />
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>
                <FormattedMessage {...messages.PROMO_PRODUCT} />
              </TableCell>
              <TableCell className={classes.header}>
                <FormattedMessage {...messages.PROMO_PRODUCT_NAME} />
              </TableCell>
              <TableCell className={classes.header}>
                <FormattedMessage {...messages.PROMO_QUANTITY} />
              </TableCell>
              <TableCell className={classes.header}>
                <FormattedMessage {...messages.PROMO_AVAILABILITY} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promoBundlePricingData?.bundleItems?.map((bundleItem, index) => {
              return (
                <TrackedAvailabilityMessageProvider
                  source="promo bundle modal"
                  item={bundleItem?.materialNumber}
                  brand={bundleItem?.brandKey || undefined}
                  pricing={getPromoBundlePricing(bundleItem)}
                  waitUntilVisible
                  key={index}
                >
                  <TableRow key={index}>
                    <TableCell className={classes.materialNumber}>
                      {bundleItem.materialNumber}
                    </TableCell>
                    <TableCell className={classes.materialDescription}>
                      <HandleMarkup value={bundleItem.materialName} />
                    </TableCell>
                    <TableCell>{bundleItem.quantity}</TableCell>
                    <TableCell>
                      {bundleItem?.availabilityMessages?.length ? (
                        <AvailabilityMessage
                          availabilities={bundleItem?.availabilityMessages?.map(
                            (item) => ({
                              key: item && item.key,
                              date: item && item.date,
                              plantLoc: item && item.plantLoc,
                              quantity: item && item.quantity,
                            })
                          )}
                        />
                      ) : null}
                    </TableCell>
                  </TableRow>
                </TrackedAvailabilityMessageProvider>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PromoBundleTable
