import React from 'react'
import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LockIcon from '@src/icons/LockIcon'
import { FormattedMessage } from 'react-intl'
import messages from '@utils/messages'

const useStyles = makeStyles((theme: Theme) => ({
  isBlockedProduct: {
    display: 'flex',
    marginTop: theme.spacing(8),
    alignItems: 'center',
  },
  lockIcon: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    marginRight: theme.spacing(3),
    display: 'flex',
  },
}))

const BlockedProductMessage = () => {
  const classes = useStyles()

  return (
    <div className={classes.isBlockedProduct}>
      <div className={classes.lockIcon}>
        <LockIcon fontSize="inherit" />
      </div>
      <Typography>
        <FormattedMessage {...messages.RESTRICTED_ITEM_MESSAGE} />
      </Typography>
    </div>
  )
}

export default BlockedProductMessage
