import { createWalkMeContext } from '@src/utils/createWalkMeContext'

const [
  ReadyToShipNotificationWalkMeContext,
  useReadyToShipNotificationWalkMeContext,
] = createWalkMeContext({})

const ReadyToShipNotificationProvider = ({ children }) => {
  const ReadyToShipNotificationWalkMe =
    useReadyToShipNotificationWalkMeContext()

  return (
    <ReadyToShipNotificationWalkMeContext.Provider
      value={ReadyToShipNotificationWalkMe}
    >
      {children}
    </ReadyToShipNotificationWalkMeContext.Provider>
  )
}

export { ReadyToShipNotificationProvider, ReadyToShipNotificationWalkMeContext }
