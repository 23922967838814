import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import SDSLink from '../SDSLink'
import LabeledDownloadIcon from '../ProductInfo/LabeledDownloadIcon'
import { sendHeaderSearchEvent } from '@src/utils/analytics'
import { Brand } from '@src/types/graphql-types'
import { useSpecificationSheetUrlLazyQuery } from '@src/queries/SpecificationSheetUrlQuery.generated'
import { useSdsCertificateSearchLazyQuery } from '@src/queries/SdsCertificateSearchQuery.generated'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
  documentsContainer: {
    paddingTop: theme.spacing(10),
  },
  sectionTitle: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    fontSize: theme.typography.pxToRem(12),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  documents: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(-1, -3),
    '& > *': {
      margin: theme.spacing(1, 3),
    },
  },
}))

interface ProductQuickLookDocumentsProps {
  brand: Pick<Brand, 'color' | 'key' | 'name'>
  productNumber: string
}

const ProductQuickLookDocuments: React.FC<ProductQuickLookDocumentsProps> = ({
  brand,
  productNumber,
}) => {
  const classes = useStyles()

  const [loadSds, { data: sdsData }] = useSdsCertificateSearchLazyQuery()
  const [loadSpecSheet, { data: specSheetData }] =
    useSpecificationSheetUrlLazyQuery()

  const sdsLanguages =
    sdsData?.getSdsCertificateSearch?.sds[0]?.languages.altLanguages || []

  useEffect(() => {
    loadSds({
      variables: {
        productNumber: productNumber,
        brand: brand.key,
      },
    })
    loadSpecSheet({
      variables: {
        input: {
          productNumber: productNumber,
          brand: brand.key,
        },
      },
    })
  }, [productNumber, brand.key])

  const { url: specSheetUrl } = specSheetData?.getSpecificationSheet || {}

  if (!sdsLanguages.length && !specSheetUrl) return null

  return (
    <div className={classes.documentsContainer}>
      <Typography
        variant="caption"
        component="h2"
        className={classes.sectionTitle}
      >
        <FormattedMessage id="DOCUMENTS" defaultMessage="Documents" />
      </Typography>
      <div className={classes.documents}>
        {sdsLanguages.length > 0 && (
          <SDSLink
            // TODO: The SDS modal doesn't work well with the search dropdown
            // (both technically and design-wise). Revisit the UX to support
            // multiple SDS languages.
            sdsLanguages={[sdsLanguages[0]]}
            productNumber={productNumber || ''}
            brandKey={brand.key || ''}
            trigger={<LabeledDownloadIcon />}
            onClick={() =>
              sendHeaderSearchEvent({
                action: 'sds',
                label: productNumber || '',
              })
            }
          />
        )}
        {specSheetUrl && (
          <a
            href={specSheetUrl}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() =>
              sendHeaderSearchEvent({
                action: 'specification sheet',
                label: specSheetUrl,
              })
            }
          >
            <LabeledDownloadIcon label="SPECIFICATION_SHEET" />
          </a>
        )}
      </div>
    </div>
  )
}

export default ProductQuickLookDocuments
