import { localStorage } from 'react-storage'
import { createPersistentStorage } from '@src/utils/createPersistentStorage'
import { TrackingDataTypes } from '@src/utils/useTypedUserActivity'

export type ReadyToShipNotificationRequestStatus =
  | 'ready'
  | 'communicating'
  | 'success'
  | 'error'

export type ReadyToShipNotificationModalType = 'request' | 'error' | 'closed'

export enum ReadyToShipNotificationConstants {
  BANNER_ID = 'ready-to-ship-banner',
  MAX_DISMISSALS = 4,
  TRACKING_DATA_KEY = 'READY_TO_SHIP_NOTIFICATIONS',
  ANALYTICS_TOUR = 'notify me',
}

const readyToShipNotificationStorage = createPersistentStorage<
  TrackingDataTypes[typeof ReadyToShipNotificationConstants.TRACKING_DATA_KEY]
>(localStorage, ReadyToShipNotificationConstants.TRACKING_DATA_KEY, 'v1')

export const getReadyToShipNotificationStorageOverride = (
  userIsLoggedIn: boolean
) => {
  return userIsLoggedIn ? undefined : readyToShipNotificationStorage
}
