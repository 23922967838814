import React, { useContext, useState } from 'react'
import { localStorage } from 'react-storage'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { productDetailRoute, useRouter } from '@src/routes'
import CircleLoader from '@src/components/CircleLoader'
import {
  ButtonBase,
  Link as MUILink,
  ListItem,
  Typography,
} from '@material-ui/core'
import {
  SubstanceProductFieldsFragment,
  SubstanceFieldsFragment,
} from '@src/queries/ProductSearchQuery.generated'
import HandleMarkup from '@src/components/HandleMarkup'
import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import vrStyles from '@src/styles/utils/vrStyles'
import { Link } from '@src/components/Link'
import { determineCatalogType, ProductCardType } from '@utils/searchUtils'
import ResponsiveProductPAModal from '@src/components/ResponsiveProductPAModal'
import ReplacementProductsCarousel from '@src/components/ReplacementProductsCarousel'
import useResponsiveSizes from '@src/utils/useResponsiveSizes'
import DiscontinuedProductAlert from '@src/components/DiscontinuedProductAlert/DiscontinuedProductAlert'
import { usePricingAndAvailabilityLazyQuery } from '@src/queries/PricingAndAvailabilityQuery.generated'
import { CatalogType } from '@src/types/graphql-types'
import { SitePreference, useCurrentUser } from '@src/utils/useCurrentUser'
import ProductPriceAvailabilityError from '@src/components/ProductPriceAvailability/ProductPriceAvailabilityError'
import {
  TrackedAvailabilityMessageContext,
  TrackedAvailabilityMessageProvider,
} from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import { sendProductClickEvent } from '@src/utils/analytics'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'
import { useChinaUser } from '@src/utils/useChinaUser'

const { vr1 } = vrStyles

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'block',
      padding: theme.spacing(4),
    },
    name: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      ...vr1,
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.spacing(4),
    },
    viewPricingWrapper: {
      textAlign: 'right',
    },
    viewPricing: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 300,
      width: '100%',
    },
  }
})

interface ProductProps {
  product: SubstanceProductFieldsFragment
  substanceName?: SubstanceFieldsFragment['name']
  className?: string
  substanceId?: string
  substancePosition?: number
}

const ResponsiveSubstanceProduct: React.FC<ProductProps> = ({
  product,
  substanceName,
  className,
  substanceId,
  substancePosition,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const getSize = useResponsiveSizes()
  const { formatMessage } = useIntl()
  const { focus = '', catalogType = '', orgId = '' } = router.query || {}
  const [isModalOpen, setIsModalOpen] = useState(false)
  const marketplace = product?.isMarketplace ? CatalogType.Marketplace : null
  const { getSitePreference, isDTAQZuCustomer, isBlueErpIntegrationEnabled } =
    useCurrentUser()
  const isChinaUser = useChinaUser()
  const preferredDealerId = isDTAQZuCustomer
    ? getSitePreference(SitePreference.PreferredDealerId)
    : null
  const passERPType =
    isBlueErpIntegrationEnabled &&
    product?.erp_type &&
    product?.erp_type?.length > 0

  const querySearchTerm: string = localStorage?.getItem('searchedfor') ?? ''

  const [fetchPricing, { data, loading, error }] =
    usePricingAndAvailabilityLazyQuery({
      variables: {
        ...(passERPType && { erp_type: product?.erp_type }),
        brand: product?.brand?.key,
        catalogType: determineCatalogType(marketplace || catalogType || focus),
        checkBuyNow: true,
        checkForPb: !isChinaUser,
        dealerId: preferredDealerId,
        displaySDS: false,
        materialIds: product?.materialIds,
        orgId,
        productKey: product?.productKey,
        productNumber: product?.productNumber,
        quantity: 1,
      },
    })
  const { discontinuedPricingInfo } = data?.getPricingForProduct ?? {}
  const showModal = !loading && isModalOpen && !discontinuedPricingInfo

  const onToggleModal = (): void => {
    setIsModalOpen(!isModalOpen)
  }

  const handleClick = (): void => {
    !product.paMessage && fetchPricing()
    setIsModalOpen(true)
    sendPricingAvailabilityInteractionEvent({
      action: 'view p&a',
      section: substancePosition ? undefined : 'products',
      component: 'list',
      elementType: 'link',
      elementText: 'view',
      material: {
        materialDescription: product.name,
        brand: product.brand?.key,
        product: product.productNumber,
      },
    })
  }

  const productNumberLabel = product.productNumber.startsWith('CB_')
    ? formatMessage({ id: 'DETAILS', defaultMessage: 'Details' })
    : product.productNumber

  const ctx = useContext(TrackedAvailabilityMessageContext)

  const handleProductClick = (): void =>
    sendProductClickEvent({
      product,
      searchTerm: querySearchTerm,
      substanceId,
      substancePosition,
    })

  if (error) {
    return (
      <TrackedAvailabilityMessageProvider
        {...ctx}
        brand={product.brand.key}
        item={product.name}
      >
        <ProductPriceAvailabilityError
          productNumber={product?.productNumber}
          error={error}
        />
      </TrackedAvailabilityMessageProvider>
    )
  }

  return (
    <ListItem component="li" className={clsx(className, classes.root)}>
      <Typography component="h3" className={classes.name}>
        {product.brand.key && product.productNumber ? (
          <Link
            {...productDetailRoute.index(
              product.brand.key,
              product.productKey,
              { catalog: determineCatalogType(catalogType || focus) }
            )}
            passHref
          >
            <MUILink onClick={handleProductClick}>{productNumberLabel}</MUILink>
          </Link>
        ) : (
          <span>{product.productNumber}</span>
        )}
      </Typography>
      <Typography component="p" className={classes.description}>
        <HandleMarkup value={product.legalNameSuffix || product.description} />
      </Typography>

      {!!discontinuedPricingInfo ? (
        <>
          <DiscontinuedProductAlert
            discontinuedPricingInfo={discontinuedPricingInfo}
          />
          {discontinuedPricingInfo?.replacementProducts?.length ? (
            <ReplacementProductsCarousel
              type={ProductCardType.Recommended}
              preloadCardImages
              products={discontinuedPricingInfo?.replacementProducts}
              slidesToShow={getSize({ xs: 1, sm: 2, lg: 3 })}
              withoutControls={
                discontinuedPricingInfo?.replacementProducts?.length === 1 ||
                discontinuedPricingInfo?.replacementProducts?.length <= 4
              }
            />
          ) : null}
        </>
      ) : (
        <div className={classes.viewPricingWrapper}>
          <ButtonBase
            className={classes.viewPricing}
            onClick={handleClick}
            aria-label="view pricing"
          >
            <FormattedMessage
              id="SUBSTANCE_VIEW_PRICING"
              defaultMessage="View Pricing"
            />
          </ButtonBase>
        </div>
      )}

      {loading ? (
        <CircleLoader />
      ) : (
        showModal && (
          <ResponsiveProductPAModal
            onClose={onToggleModal}
            substanceName={substanceName}
            productNumber={product.productNumber}
            productKey={product.productKey}
            brandKey={product.brand.key}
            description={product.description}
            materialIds={product.materialIds}
            isMarketplace={product.isMarketplace}
            productAttributes={product.attributes}
            marketplaceSellerId={product.marketplaceSellerId || ''}
            marketplaceOfferId={product.marketplaceOfferId || ''}
            erp_type={product.erp_type}
            productName={product.name ?? ''}
            displaySellerName={product.displaySellerName ?? ''}
            pricingData={data?.getPricingForProduct}
            paMessage={product.paMessage}
          />
        )
      )}
    </ListItem>
  )
}

export default ResponsiveSubstanceProduct
