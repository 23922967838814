import { useContext, useState } from 'react'

import { useUserActivity } from '@utils/useUserActivity'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import {
  ReadyToShipNotificationWalkMeContext,
  ReadyToShipNotificationConstants,
  getReadyToShipNotificationStorageOverride,
} from '@src/components/ProductInfo/ReadyToShipNotification'

export const useReadyToShipNotificationWalkMe = () => {
  const {
    state,
    setEnabled,
    dismissStep,
    startTour,
    advanceActiveStepNumber,
    setExtraState,
  } = useContext(ReadyToShipNotificationWalkMeContext)
  const { userIsLoggedIn } = useCurrentUser()
  const { updateUserActivity, trackingData } = useUserActivity(
    ReadyToShipNotificationConstants.TRACKING_DATA_KEY,
    getReadyToShipNotificationStorageOverride(userIsLoggedIn)
  )

  const [dismissedAlert, setDismissedAlert] = useState(false)

  const dismissedCount = trackingData?.numWalkMeDismissals || 0

  const shouldHideAlert =
    dismissedAlert ||
    dismissedCount >= ReadyToShipNotificationConstants.MAX_DISMISSALS ||
    trackingData?.hasOptedOutOfWalkMe

  const dismissAlert = (optOutOfWalkMe?: boolean) => {
    if (optOutOfWalkMe) setEnabled(false)
    setDismissedAlert(true)
    updateUserActivity({
      numWalkMeDismissals: dismissedCount + 1,
      ...(optOutOfWalkMe != null && {
        hasOptedOutOfWalkMe: optOutOfWalkMe,
      }),
    })
  }

  return {
    state,
    setEnabled,
    dismissStep,
    startTour,
    dismissAlert,
    shouldHideAlert,
    advanceActiveStepNumber,
    setExtraState,
  }
}
