import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import messages from '@src/utils/messages'
import { makeStyles } from '@material-ui/core/styles'
import FlagIcon from '@icons/FlagIcon'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { usePreviouslyPurchasedProductQuery } from '@src/queries/PreviouslyPurchasedProductQuery.generated'

const useStyles = makeStyles((theme) => ({
  chip: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
    padding: theme.spacing(2, 3, 2, 2),
    borderRadius: 4,
  },
  icon: {
    width: 11,
    height: 13,
    marginRight: theme.spacing(2),
  },
}))

interface PreviouslyPurchasedChipProps {
  productNumber: string
}

export const PreviouslyPurchasedChip: React.FC<
  PreviouslyPurchasedChipProps
> = ({ productNumber }) => {
  const classes = useStyles()
  const { userIsLoggedIn } = useCurrentUser()
  const { loading, error, data } = usePreviouslyPurchasedProductQuery({
    variables: { productNumber },
    skip: !userIsLoggedIn,
    ssr: false,
  })

  const previouslyPurchasedDate = data?.recentOrderItems.orderItems.find(
    (orderItem) => orderItem.product === productNumber
  )?.orderDate

  if (loading || error || !data?.recentOrderItems.orderItems.length) return null

  return (
    <div className={classes.chip}>
      <FlagIcon className={classes.icon} />
      <FormattedMessage {...messages.LAST_PURCHASED} />{' '}
      {previouslyPurchasedDate && (
        <FormattedDate
          value={new Date(previouslyPurchasedDate)}
          year="numeric"
          month="short"
          day="numeric"
        />
      )}
    </div>
  )
}
