// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import { MaterialAvailabilitiesDetailFragmentDoc } from '../fragments/ProductPricing.generated';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type MaterialAvailabilitiesQueryVariables = Types.Exact<{
  materialNumber: Types.Scalars['String'];
  quantity: Types.Scalars['Int'];
  catalogType?: Types.InputMaybe<Types.CatalogType>;
  orgId?: Types.InputMaybe<Types.Scalars['String']>;
  countryCode?: Types.InputMaybe<Types.Scalars['String']>;
  useSimulate?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type MaterialAvailabilitiesQuery = { __typename?: 'Query', getAvailabilityForMaterial: { __typename?: 'MaterialAvailabilities', materialAvailabilities?: { __typename?: 'ValidMaterialAvailability', availabilities: Array<{ __typename?: 'MaterialAvailability', date?: number | null, key: Types.MaterialAvailabilityKey, plantLoc?: string | null, quantity?: number | null, displayFromLink?: boolean | null, displayInquireLink?: boolean | null, messageType?: string | null, contactInfo?: { __typename?: 'MaterialContactInfo', contactPhone?: string | null, contactEmail?: string | null } | null, availabilityOverwriteMessage?: { __typename?: 'AvailabilityOverwriteMessage', messageKey?: string | null, messageValue?: string | null, messageVariable1?: string | null, messageVariable2?: string | null, messageVariable3?: string | null } | null, supplementaryMessage?: { __typename?: 'MaterialSupplementaryMessage', messageKey?: string | null, messageValue?: string | null, messageVariable1?: string | null, messageVariable2?: string | null, messageVariable3?: string | null } | null }> } | null } };


export const MaterialAvailabilitiesDocument = gql`
    query MaterialAvailabilities($materialNumber: String!, $quantity: Int!, $catalogType: CatalogType, $orgId: String, $countryCode: String, $useSimulate: Boolean = false) {
  getAvailabilityForMaterial(input: {materialNumber: $materialNumber, quantity: $quantity, catalogType: $catalogType, orgId: $orgId, countryCode: $countryCode, useSimulate: $useSimulate}) {
    ...MaterialAvailabilitiesDetail
  }
}
    ${MaterialAvailabilitiesDetailFragmentDoc}`;
export type MaterialAvailabilitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MaterialAvailabilitiesQuery, MaterialAvailabilitiesQueryVariables>, 'query'> & ({ variables: MaterialAvailabilitiesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MaterialAvailabilitiesComponent = (props: MaterialAvailabilitiesComponentProps) => (
      <ApolloReactComponents.Query<MaterialAvailabilitiesQuery, MaterialAvailabilitiesQueryVariables> query={MaterialAvailabilitiesDocument} {...props} />
    );
    

/**
 * __useMaterialAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useMaterialAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialAvailabilitiesQuery({
 *   variables: {
 *      materialNumber: // value for 'materialNumber'
 *      quantity: // value for 'quantity'
 *      catalogType: // value for 'catalogType'
 *      orgId: // value for 'orgId'
 *      countryCode: // value for 'countryCode'
 *      useSimulate: // value for 'useSimulate'
 *   },
 * });
 */
export function useMaterialAvailabilitiesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MaterialAvailabilitiesQuery, MaterialAvailabilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MaterialAvailabilitiesQuery, MaterialAvailabilitiesQueryVariables>(MaterialAvailabilitiesDocument, options);
      }
export function useMaterialAvailabilitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MaterialAvailabilitiesQuery, MaterialAvailabilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MaterialAvailabilitiesQuery, MaterialAvailabilitiesQueryVariables>(MaterialAvailabilitiesDocument, options);
        }
export type MaterialAvailabilitiesQueryHookResult = ReturnType<typeof useMaterialAvailabilitiesQuery>;
export type MaterialAvailabilitiesLazyQueryHookResult = ReturnType<typeof useMaterialAvailabilitiesLazyQuery>;
export type MaterialAvailabilitiesQueryResult = ApolloReactCommon.QueryResult<MaterialAvailabilitiesQuery, MaterialAvailabilitiesQueryVariables>;