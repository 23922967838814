import React from 'react'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core/styles'

interface BorderRadiusProps {
  xs?: number
  sm?: string
}

interface MarginBottomProps {
  xs?: number
  sm?: number
}

interface SearchResultsSkeletonSegmentProps {
  height: number
  width: number
  borderRadius?: string | BorderRadiusProps
  mb?: number | MarginBottomProps
}

const SearchResultsSkeletonSegment: React.FC<
  SearchResultsSkeletonSegmentProps
> = ({ height, width, borderRadius = 'borderRadius', mb = 0 }) => {
  const theme = useTheme()

  return (
    <Box
      height={height}
      width={width}
      borderRadius={borderRadius}
      bgcolor={theme.palette.grey['50']}
      mb={mb}
    />
  )
}

export default SearchResultsSkeletonSegment
