import { ReactElement } from 'react'
import { Radio, RadioProps, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles<
  Theme,
  { disabled: boolean; size: 'small' | 'medium' }
>((theme: Theme) => {
  return {
    root: {
      padding: '0',
      marginRight: theme.spacing(2),
      '&:hover': {
        outline: `3px solid ${theme.palette.primary.light}`,
      },
      '&.Mui-checked:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
      },
    },
    icon: {
      display: 'flex',
      border: ({ disabled }) =>
        `1px solid ${disabled ? 'transparent' : theme.palette.grey[500]}`,
      borderRadius: '50%',
      backgroundColor: ({ disabled }) =>
        disabled ? '#DFDFDF' : theme.palette.common.white,
    },
    checkedIcon: {
      backgroundColor: theme.palette.common.white,
      '&:before': {
        backgroundColor: ({ disabled }) =>
          disabled ? theme.palette.grey[800] : theme.palette.primary.main,
        borderRadius: '50%',
      },
    },
    customRadio: {
      marginLeft: 0,
      ' &:not(:last-child)': {
        marginBottom: theme.spacing(4),
      },
      '& .MuiIconButton-label': {
        '& span': {
          height: ({ size }) => (size === 'medium' ? '24px' : '20px'),
          width: ({ size }) => (size === 'medium' ? '24px' : '20px'),
          '&:before': {
            width: ({ size }) => (size === 'medium' ? '14px' : '12px'),
            height: ({ size }) => (size === 'medium' ? '14px' : '12px'),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            content: '""',
          },
        },
      },
    },
  }
})

interface CommonRadioProps {
  name: string
  value: string | number
  label: string | ReactElement
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  size?: RadioProps['size']
  disabled?: boolean
  'data-testid'?: string
}

const CommonRadio = ({
  name,
  value,
  label,
  checked,
  onChange,
  size = 'medium',
  disabled = false,
  'data-testid': dataTestId,
}: CommonRadioProps) => {
  const classes = useStyles({ disabled, size })

  const generatedLabel =
    typeof label === 'string' ? (
      <Typography variant="body1">{label}</Typography>
    ) : (
      label
    )

  return (
    <FormControlLabel
      className={classes.customRadio}
      value={value}
      control={
        <Radio
          data-testid={dataTestId}
          classes={{ root: classes.root }}
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          size={size}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
        />
      }
      label={generatedLabel}
    />
  )
}

export default CommonRadio
