import React from 'react'
import { useIntl } from 'react-intl'
import messages from '@utils/messages'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  infoIcon: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

export interface AdditionalInfoIconProps {
  setDialogOpen: (boolean) => void
}

const AdditionalInfoIcon: React.FC<AdditionalInfoIconProps> = ({
  setDialogOpen,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <Tooltip
      title={formatMessage(messages.ADDITIONAL_INFO_POPOVER)}
      placement="top"
      enterDelay={1000}
    >
      <InfoIcon
        className={classes.infoIcon}
        onClick={() => setDialogOpen(true)}
      />
    </Tooltip>
  )
}

export default AdditionalInfoIcon
