import { CatalogImage, ProductAttribute } from '@src/types/graphql-types'
import {
  DiscontinuedMaterialPricingDetailFragment,
  ValidMaterialPricingDetailFragment,
} from '@src/fragments/ProductPricing.generated'
import { sendPricingAndAvailabilityErrorEvent } from '@utils/analytics/pricingAndAvailability'
import { setAddToCartData } from '@utils/analytics/cartAndCheckoutEvents'
import { GAMapProducts } from '@utils/analytics/types'
import { AddToCartPagesEnum } from '@utils/analytics/enums'
import {
  ProductListPagesEnum,
  setStoreAddToCartDataForAnalytics,
} from '@utils/tealiumAnalytics'
import { useAddToCartWithGlobalSnackbar } from '@utils/useCart'
import { SitePreference, useCurrentUser } from '@utils/useCurrentUser'
import { Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { MaterialsInput } from '../QuickOrder'
import ProductPriceAvailabilityFormBody, {
  AddToCartFormValues,
  ProductPriceAvailabilityFormBodyProps,
} from './ProductPriceAvailabilityFormBody'
import { ApolloQueryResult } from 'apollo-client/core/types'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import ReplacementProductsCarousel from '@src/components/ReplacementProductsCarousel'
import useResponsiveSizes from '@src/utils/useResponsiveSizes'
import { ProductCardType } from '@src/utils/searchUtils'
import DiscontinuedProductAlert from '../DiscontinuedProductAlert'
import {
  TrackedAvailabilityMessageContext,
  TrackedAvailabilityMessageProvider,
} from '../TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'

export interface ProductPriceAvailabilityFormProps {
  country?: string
  currentDtaqDealer?: string | null
  materialPricing?: ValidMaterialPricingDetailFragment[]
  canAddToCart: boolean
  PricingComponent?: React.ComponentType<ProductPriceAvailabilityFormBodyProps>
  displayPromotionalBundlesAnchor?: boolean
  displaySDS?: boolean
  ctas?: PdpFieldsFragment['forms']
  discontinuedPricingInfo?: DiscontinuedMaterialPricingDetailFragment | null
  productName: string
  displaySellerName?: string
  productKey?: string
  productDescription?: string
  productId?: string
  images?: CatalogImage[]
  brandKey?: string
  gaType: AddToCartPagesEnum
  gaMapProducts?: GAMapProducts
  addToList?: (replacementMaterials: MaterialsInput[]) => void
  condensed?: boolean
  isMarketplace: boolean
  isMarketplaceCartEnabled: boolean
  marketplaceSellerId?: string
  marketplaceOfferId?: string
  productAttributes?: ProductAttribute[]
  onAddToCartResult?: () => void
  handlePriceUpdateForDealer?: (
    dealerId: string | null,
    values: AddToCartFormValues,
    materialPricing: ValidMaterialPricingDetailFragment[],
    setFieldValue: (field: string, value: any) => void,
    setIsModalOpen: (value: React.SetStateAction<boolean>) => void,
    dealerName: Maybe<string> | undefined
  ) => Promise<void>
  updateQuantity?: (quantity: number) => Promise<ApolloQueryResult<unknown>>
  displayCompareMarketplaceSellerContact?: boolean
  erpType?: string[]
}

const ProductPriceAvailabilityForm: React.FC<
  ProductPriceAvailabilityFormProps
> = ({
  country,
  currentDtaqDealer,
  materialPricing,
  canAddToCart,
  PricingComponent = ProductPriceAvailabilityFormBody,
  displayPromotionalBundlesAnchor,
  displaySDS,
  ctas,
  discontinuedPricingInfo,
  productName,
  displaySellerName,
  productKey,
  productDescription,
  productId,
  images,
  brandKey,
  gaType,
  gaMapProducts,
  addToList,
  condensed = false,
  isMarketplace,
  isMarketplaceCartEnabled,
  marketplaceSellerId,
  marketplaceOfferId,
  productAttributes,
  onAddToCartResult,
  handlePriceUpdateForDealer,
  updateQuantity,
  erpType,
}) => {
  const router = useRouter()
  const getSize = useResponsiveSizes()
  const handleAddToCart = useAddToCartWithGlobalSnackbar()
  const {
    getSitePreference,
    isDTAQZuCustomer,
    isBlueErpIntegrationEnabled,
    isB2BUser,
    userErpType,
  } = useCurrentUser()

  const comparePage = router.asPath.includes('/compare')
  const hideAddToCartForPrepackItems = getSitePreference(
    SitePreference.HideAddToCartForPrepackItems
  )

  useEffect(() => {
    if (!condensed && discontinuedPricingInfo) {
      sendPricingAndAvailabilityErrorEvent(
        productKey ?? '',
        undefined,
        'PRODUCT_DISCONTINUED'
      )
    }
  }, [discontinuedPricingInfo])

  const ctx = useContext(TrackedAvailabilityMessageContext)
  if (discontinuedPricingInfo) {
    // the main product that is being compared
    const mainProduct = {
      productKey,
      brandKey,
      images,
    }
    const replacementProducts = !comparePage
      ? discontinuedPricingInfo?.replacementProducts
      : null

    return (
      <TrackedAvailabilityMessageProvider
        {...ctx}
        replacementProducts={replacementProducts}
      >
        <DiscontinuedProductAlert
          discontinuedPricingInfo={discontinuedPricingInfo}
        />

        {replacementProducts ? (
          <ReplacementProductsCarousel
            type={ProductCardType.Recommended}
            preloadCardImages
            mainProduct={mainProduct}
            products={replacementProducts}
            slidesToShow={getSize({ xs: 1, sm: 2, lg: 3 })}
            withoutControls={
              replacementProducts.length === 1 ||
              replacementProducts.length <= 4
            }
          />
        ) : null}
      </TrackedAvailabilityMessageProvider>
    )
  }

  if (!materialPricing?.length) {
    return null
  }

  const preferredDealerId = isDTAQZuCustomer
    ? getSitePreference(SitePreference.PreferredDealerId)
    : null

  const loadInitialFormValues = (
    productPricing: ValidMaterialPricingDetailFragment[],
    preferredDealerId: string | null
  ) => {
    if (productPricing) {
      return productPricing.reduce(
        (acc, { materialNumber, marketplaceOfferId }, index) => {
          return {
            ...acc,
            [index]: {
              materialNumber,
              marketplaceOfferId,
              quantity: '',
              dealerId: preferredDealerId,
            },
          }
        },
        {}
      )
    } else {
      return []
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={loadInitialFormValues(materialPricing, preferredDealerId)}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const items = Object.keys(values)
          .map((key) => ({
            materialNumber: values[key].materialNumber,
            quantity: Number(values[key].quantity),
            dealerId: currentDtaqDealer,
            marketplaceOfferId: values[key].marketplaceOfferId,
          }))
          .filter((value) => value.quantity)

        // material pricing info of selected add to cart item(s)
        const selectedAddToCartMaterialItems = materialPricing.filter(
          (material) =>
            items.filter(
              (item) => item.materialNumber === material?.materialNumber
            )[0]
        )
        const userType = { isBlueErpIntegrationEnabled, isB2BUser, userErpType }
        setAddToCartData(
          items,
          materialPricing,
          gaType,
          gaMapProducts,
          erpType,
          userType
        )
        if (addToList) {
          addToList(items)
        } else {
          setStoreAddToCartDataForAnalytics({
            items: selectedAddToCartMaterialItems,
            productListPageName: router.asPath.includes('/search')
              ? ProductListPagesEnum.SearchResultPage
              : ProductListPagesEnum.ProductDetailPage,
          })
          handleAddToCart(items, setSubmitting, resetForm, isMarketplace).then(
            onAddToCartResult
          )
        }
      }}
    >
      {({
        isSubmitting,
        dirty,
        submitForm,
        resetForm,
        setFieldValue,
        values,
      }) => (
        <Form>
          <PricingComponent
            country={country}
            productName={productName}
            displaySellerName={displaySellerName}
            productDescription={productDescription}
            productId={productId}
            materialPricing={materialPricing}
            canAddToCart={canAddToCart}
            hideAddToCartForPrepackItems={hideAddToCartForPrepackItems}
            isSubmitting={isSubmitting}
            dirty={dirty}
            submitForm={submitForm}
            resetForm={resetForm}
            displayPromotionalBundlesAnchor={displayPromotionalBundlesAnchor}
            displaySDS={displaySDS}
            ctas={ctas}
            isAddToList={!!addToList}
            condensed={condensed}
            setFieldValue={setFieldValue}
            values={values}
            isMarketplace={isMarketplace}
            isMarketplaceCartEnabled={isMarketplaceCartEnabled}
            marketplaceSellerId={marketplaceSellerId}
            marketplaceOfferId={marketplaceOfferId}
            productAttributes={productAttributes}
            handlePriceUpdateForDealer={handlePriceUpdateForDealer}
            updateQuantity={updateQuantity}
            erpType={erpType}
          />
        </Form>
      )}
    </Formik>
  )
}

export default ProductPriceAvailabilityForm
