import { Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import GlobalAlert from '@src/components/GlobalAlert'
import LinkStyleButton from '@src/components/LinkStyleButton'
import { useWalkMeAlertStyles } from '@src/styles/module/walkMeAlert'
import windowScrollTo from '@utils/windowScrollTo'
import {
  ReadyToShipNotificationConstants,
  useReadyToShipNotificationWalkMe,
} from '@src/components/ProductInfo/ReadyToShipNotification'
import { sendWalkMeTracking } from '@utils/analytics'

interface ReadyToShipNotificationWalkMeAlertProps {
  dismissAlert: (optOutOfWalkMe?: boolean) => void
}

export const ReadyToShipNotificationWalkMeAlert = ({
  dismissAlert,
}: ReadyToShipNotificationWalkMeAlertProps) => {
  const classes = useWalkMeAlertStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { startTour } = useReadyToShipNotificationWalkMe()

  const optOutOfWalkMe = () => {
    dismissAlert(true)
    sendWalkMeTracking(
      ReadyToShipNotificationConstants.ANALYTICS_TOUR,
      'notify me banner',
      "don't show me tips"
    )
  }

  const onClickClose = () => {
    dismissAlert()
    sendWalkMeTracking(
      ReadyToShipNotificationConstants.ANALYTICS_TOUR,
      'notify me banner',
      'close'
    )
  }

  return (
    <GlobalAlert
      id={ReadyToShipNotificationConstants.BANNER_ID as string}
      severity="info"
      onClose={onClickClose}
      icon={<InfoIcon style={{ fontSize: 24 }} />}
      className={classes.alertRoot}
    >
      <Typography className={classes.alertHeading} component="h3">
        <FormattedMessage
          id="WALK_ME_READY_TO_SHIP_NOTIFICATION_HEADING"
          defaultMessage="Now Available: Ready to Ship notifications"
        />
      </Typography>

      <div className={classes.alertActions}>
        <div>
          <LinkStyleButton
            data-testid="dismiss-tips"
            onClick={optOutOfWalkMe}
            className={clsx(classes.link, {
              [classes.largeLink]: !isDesktop,
            })}
          >
            <FormattedMessage
              id="DONT_SHOW_ME_TIPS"
              defaultMessage="Don't Show Me Tips"
            />
          </LinkStyleButton>
        </div>
        <div>
          <LinkStyleButton
            data-testid="start-tour"
            className={clsx(classes.link, classes.largeLink)}
            onClick={() => {
              sendWalkMeTracking(
                ReadyToShipNotificationConstants.ANALYTICS_TOUR,
                'notify me banner',
                "show me what's new"
              )
              // setTimeout ensures 2 things, both if step 1 is already visible:
              // 1. the step is not immediately dismissed via the click-away action
              // 2. the step is read again by screen readers
              //setTimeout(startTour, 0)
              setTimeout(() => {
                startTour()
              }, 0)
              // This setTimeout ensures the step is on the page before we scroll it into view
              setTimeout(() => {
                const offset = 200 // This is to ensure we don't scroll to far.
                const bannerEl = document.getElementById(
                  'ready-to-ship-notification-step1'
                )
                const bannerTop = bannerEl?.getBoundingClientRect().top || 0
                windowScrollTo({ top: bannerTop - offset + window.scrollY })
              }, 10)
            }}
          >
            <FormattedMessage
              id="SHOW_ME_WHATS_NEW"
              defaultMessage="Show Me What's New"
            />
          </LinkStyleButton>
        </div>
      </div>
    </GlobalAlert>
  )
}
