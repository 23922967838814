import React, { FC } from 'react'
import Head from 'next/head'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import { SearchResultsMetadata } from '@src/types/graphql-types'

import { SearchFocusType, ProductSearchType } from '@utils/searchUtils'
import { useCompanyName } from '@src/utils/useCompanyName'
import { SeoTags } from '@utils/seo'
import getConfig from 'next/config'
import { searchResultsRoute } from '@src/routes'

const {
  publicRuntimeConfig: { canonicalUrlPrefix },
} = getConfig()

const SearchResultsHead: FC<{ metadata?: SearchResultsMetadata }> = ({
  metadata,
}) => {
  const intl = useIntl()
  const router = useRouter()
  const {
    asPath,
    query: { page, term, focus, type: productType, country, language },
  } = router

  const companyName = useCompanyName()
  const isStructureSearch = focus === SearchFocusType.StructureSearch
  const dynamicTitle =
    term && !isStructureSearch
      ? `${term.charAt(0).toUpperCase()}${term.slice(1)}`
      : intl.formatMessage({
          id: 'STRUCTURE_SEARCH',
          defaultMessage: 'Structure Search',
        })
  let title = `${dynamicTitle} | Sigma-Aldrich`
  //SEOO-56 : SRP Title tags for common identifiers
  const prefixes = {
    [ProductSearchType.CasNumber]: 'CAS',
    [ProductSearchType.EgecNumber]: 'EC/EG',
    [ProductSearchType.MdlNumber]: 'MDL',
  }
  if (prefixes[productType]) title = `${prefixes[productType]} ${title}`
  const pageParam = `page=${page}`
  const pageParamIndex = asPath.indexOf(pageParam)
  const prePageHalf = asPath.slice(0, pageParamIndex)
  const postPageHalf = asPath.slice(
    pageParamIndex + pageParam.length,
    asPath.length
  )
  const next = `${prePageHalf}page=${Number(page) + 1}${postPageHalf}`
  const prev = `${prePageHalf}page=${Number(page) - 1}${postPageHalf}`

  const descriptionContent = !isStructureSearch
    ? `Find ${term} and related products for scientific research at ${companyName}`
    : `Structure search results`
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Search',
        item: `${canonicalUrlPrefix}/${country}/${language}${searchResultsRoute.index()}`,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: `${term}`,
        item: `${canonicalUrlPrefix}${asPath}`,
      },
    ],
  }
  // info on localized version of pages https://support.google.com/webmasters/answer/189077
  return (
    <>
      <Head>
        <title data-testid="head-title">{title}</title>
        {Number(page) !== metadata?.numPages ? (
          <link rel="next" href={next} />
        ) : null}
        {Number(page) > 1 ? <link rel="prev" href={prev} /> : null}
      </Head>
      <SeoTags
        description={descriptionContent}
        keywords={[
          term,
          'search',
          'sigma',
          'aldrich',
          'millipore',
          companyName,
        ]}
        // We are scrubbing all params by default though here, we're allowing
        // only default keys as shown below. Ref : SEOO-79.
        canonicalParamAllowed={(key) =>
          ['term', 'focus', 'page', 'perpage', 'sort', 'type'].includes(key)
        }
        schema={schema}
      />
    </>
  )
}

export default SearchResultsHead
