import { productDetailRoute } from '@src/routes'
import { Link } from '@src/components/Link'
import { determineCatalogType } from '@utils/searchUtils'
import HandleMarkup from '@src/components/HandleMarkup'
import { Link as MUILink } from '@material-ui/core'
import { SubstanceProductFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import { RelatedProductFragment } from '@src/queries/RelatedProductsQuery.generated'
import { CatalogType } from '@src/types/graphql-types'

export enum ProductLinkType {
  NAME = 'NAME',
  DESCRIPTION = 'DESCRIPTION',
}

interface ProductLinkProps {
  product: SubstanceProductFieldsFragment | RelatedProductFragment
  focus: string | (string[] & string)
  label?: string | null
  productLinkType: ProductLinkType
  onProductLinkClick?: () => void
  productName?: string
  productDescription?: string | null
  productNameClass?: string
  productDescriptionClass: string
}

const ProductLink: React.FC<ProductLinkProps> = ({
  product,
  focus,
  label,
  onProductLinkClick,
  productNameClass,
  productDescriptionClass,
  productLinkType,
  productName,
  productDescription,
}) => {
  const bbe =
    'isBBE' in product && product.isBBE ? CatalogType.Buildingblocks : undefined
  return (
    <>
      {product.brand.key && product.productNumber ? (
        <Link
          {...productDetailRoute.index(product.brand.key, product.productKey, {
            catalog: determineCatalogType(bbe ?? focus),
          })}
          passHref
        >
          <MUILink
            data-testid={`${productLinkType}-pdp-link-${product.productNumber}-${product.brand.key}`}
            id={`${productLinkType}-pdp-link-${product.productNumber}-${product.brand.key}`}
            className={productNameClass}
            aria-label={`${productLinkType}-pdp-link-${product.productNumber}-${product.brand.key}`}
            onClick={() => onProductLinkClick?.()}
          >
            {productLinkType === ProductLinkType.DESCRIPTION ? (
              <>
                {productName && productNameClass && (
                  <HandleMarkup
                    value={productName + ', '}
                    className={productNameClass}
                  />
                )}
                <HandleMarkup
                  value={productDescription}
                  className={productDescriptionClass}
                />
              </>
            ) : (
              label
            )}
          </MUILink>
        </Link>
      ) : productLinkType === ProductLinkType.DESCRIPTION ? (
        <HandleMarkup value={product.description} />
      ) : (
        label
      )}
    </>
  )
}
export default ProductLink
