import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Theme } from '@material-ui/core'
import clsx from 'clsx'

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2em 0',
  },
  baseButton: {
    border: 0,
    background: 'none',
    cursor: 'pointer',
    height: theme.spacing(8),
    minWidth: theme.spacing(8),
    fontSize: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    transition: 'all .2s ease-in-out',
    '&:disabled': {
      cursor: 'auto',
      color: theme.palette.text.disabled,
    },
    '&:hover:enabled': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  prevNext: {
    color: theme.palette.secondary.main,
  },
  pageNumber: {
    color: theme.palette.common.black,
  },
  curPage: {
    cursor: 'auto',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  ellipsis: {
    cursor: 'auto',
  },
  hideOnMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

interface PaginationButtonProps {
  onClick: React.MouseEventHandler
  label: string
  children: ReactNode
  classes?: string[]
  disabled?: boolean
  id?: string
}

const PaginationButton: React.FC<PaginationButtonProps> = ({
  onClick,
  label,
  children,
  classes = [],
  disabled = false,
  id,
}) => (
  <button
    className={clsx(...classes)}
    type="button"
    disabled={disabled}
    aria-label={label}
    onClick={onClick}
    id={id}
  >
    {children}
  </button>
)

interface Props {
  curPage: number
  numPages: number
  clickHandler: (page: number) => void
}

const Pagination: React.FC<Props> = ({ curPage, numPages, clickHandler }) => {
  const classes = useStyles()
  const paginationMeta = calcPaginationMeta(curPage, numPages)
  const lastDisplayedPage =
    paginationMeta.pages[paginationMeta.pages.length - 1]
  const firstDisplayedPage = paginationMeta.pages[0]

  const ellipsis = (
    <div
      className={clsx(
        classes.baseButton,
        classes.ellipsis,
        classes.hideOnMobile
      )}
    >
      ...
    </div>
  )

  const lastPageComponent = (
    <PaginationButton
      key={numPages}
      classes={[classes.baseButton, classes.pageNumber, classes.hideOnMobile]}
      label={`Go to page ${numPages}`}
      onClick={(e) => {
        e.preventDefault()
        clickHandler(numPages)
      }}
    >
      {numPages}
    </PaginationButton>
  )

  const firstPageComponent = (
    <PaginationButton
      key={1}
      classes={[classes.baseButton, classes.pageNumber]}
      label={`Go to page 1`}
      onClick={(e) => {
        e.preventDefault()
        clickHandler(1)
      }}
      id="pagination-first-page"
      data-testid="pagination-first-page"
    >
      1
    </PaginationButton>
  )

  return numPages > 1 ? (
    <nav className={classes.wrapper} aria-label="Pagination Navigation">
      {/* first page and previous page buttons */}
      <PaginationButton
        key={'first'}
        label="Go to first page"
        disabled={!paginationMeta.showPrevious}
        classes={[classes.baseButton, classes.prevNext, classes.hideOnMobile]}
        onClick={(e) => {
          e.preventDefault()
          clickHandler(1)
        }}
        id="go-to-first-page"
        data-testid="go-to-first-page"
      >
        <FirstPageIcon />
      </PaginationButton>
      <PaginationButton
        key={'previous'}
        classes={[classes.baseButton, classes.prevNext]}
        disabled={!paginationMeta.showPrevious}
        label="Go to previous page"
        id="pagination-go-to-previous-page"
        data-testid="pagination-go-to-previous-page"
        onClick={(e) => {
          e.preventDefault()
          clickHandler(curPage - 1)
        }}
      >
        <ChevronLeftIcon />
      </PaginationButton>

      {/* ellipsis with starting page */}
      {firstDisplayedPage === 2 && firstPageComponent}
      {firstDisplayedPage > 2 && (
        <>
          {firstPageComponent}
          {ellipsis}
        </>
      )}

      {/* visible page numbers */}
      {paginationMeta.pages.map((page, idx) =>
        page === curPage ? (
          <div
            id="pagination-current-page"
            key={page}
            aria-label="Current page"
            className={clsx(classes.baseButton, classes.curPage)}
          >
            {page}
          </div>
        ) : (
          <PaginationButton
            key={page}
            classes={[
              classes.baseButton,
              classes.pageNumber,
              classes.hideOnMobile,
            ]}
            disabled={page === curPage}
            label={`Go to page ${page}`}
            onClick={(e) => {
              e.preventDefault()
              clickHandler(page)
            }}
            id={`pagination-go-to-page-${idx}`}
            data-testid={`pagination-go-to-page-${idx}`}
          >
            {page}
          </PaginationButton>
        )
      )}

      {/* ellipsis with ending page */}
      {numPages - lastDisplayedPage === 1 && lastPageComponent}
      {numPages - lastDisplayedPage > 1 && (
        <>
          {ellipsis}
          {lastPageComponent}
        </>
      )}

      {/* next page and last page buttons */}
      <PaginationButton
        key={'next'}
        classes={[classes.baseButton, classes.prevNext]}
        disabled={!paginationMeta.showNext}
        label="Go to next page"
        id="pagination-go-to-next-page"
        data-testid="pagination-go-to-next-page"
        onClick={(e) => {
          e.preventDefault()
          clickHandler(curPage + 1)
        }}
      >
        <ChevronRightIcon />
      </PaginationButton>
      <PaginationButton
        key={'last'}
        classes={[classes.baseButton, classes.prevNext, classes.hideOnMobile]}
        disabled={!paginationMeta.showNext}
        label="Go to last page"
        onClick={(e) => {
          e.preventDefault()
          clickHandler(numPages)
        }}
      >
        <LastPageIcon />
      </PaginationButton>
    </nav>
  ) : null
}

export default Pagination

function calcPaginationMeta(
  curPage: number,
  numPages: number,
  maxPagesDisplayed = 5
) {
  // ensure current page isn't out of range
  if (curPage < 1) {
    curPage = 1
  } else if (curPage > numPages) {
    curPage = numPages
  }

  let startPage: number
  let endPage: number
  if (numPages <= maxPagesDisplayed) {
    // total pages less than max so show all pages
    startPage = 1
    endPage = numPages
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPagesDisplayed / 2)
    const maxPagesAfterCurrentPage = Math.ceil(maxPagesDisplayed / 2) - 1
    if (curPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1
      endPage = maxPagesDisplayed
    } else if (curPage + maxPagesAfterCurrentPage >= numPages) {
      // current page near the end
      startPage = numPages - maxPagesDisplayed + 1
      endPage = numPages
    } else {
      // current page somewhere in the middle
      startPage = curPage - maxPagesBeforeCurrentPage
      endPage = curPage + maxPagesAfterCurrentPage
    }
  }

  // create an array of pages to display in pagination component
  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i
  )

  return {
    pages,
    showNext: curPage < numPages,
    showPrevious: curPage > 1,
  }
}
