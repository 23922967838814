import React, { FC } from 'react'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import SpacingBox from '@src/components/SpacingBox'
import ShipsTodayIcon from '@src/icons/ShipsTodayIcon'
import { FormattedMessage } from 'react-intl'
import messages from '@src/utils/messages'
import { makeStyles, Theme } from '@material-ui/core/styles'
import FreeFreightIcon from '@src/icons/FreeFreightIcon'

const useStyles = makeStyles((theme: Theme) => ({
  shippingMessage: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}))

interface Props {
  material: ValidMaterialPricingDetailFragment
}

const ShippingMessages: FC<Props> = ({ material }) => {
  const classes = useStyles()

  return (
    <>
      {material.shipsToday && (
        <SpacingBox mb={6} className={classes.shippingMessage}>
          <ShipsTodayIcon />
          <FormattedMessage {...messages.SHIPS_TODAY} />
        </SpacingBox>
      )}

      {material.freeFreight && (
        <SpacingBox mb={6} className={classes.shippingMessage}>
          <FreeFreightIcon color="secondary" />
          <FormattedMessage {...messages.FREE_FREIGHT} />
        </SpacingBox>
      )}
    </>
  )
}

export default ShippingMessages
