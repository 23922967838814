import React, { useState } from 'react'
import clsx from 'clsx'
import { useRouter, ContactSellerReasonType, sellerRoutes } from '@src/routes'
import { Link } from '@src/components/Link'
import { Box } from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Info as InfoIcon } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'
import messages from '@utils/messages'
import { ProductAttribute } from '@src/types/graphql-types'
import { getSellerName } from '@src/utils/searchUtils'
import MarketplaceSellerModal from '@src/components/MarketplaceSellerModal'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
    },
  },
  shipped: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(6),
    },
  },
  infoIcon: {
    transform: 'scale(1.2)',
    color: theme.palette.primary.main,
    height: theme.spacing(4),
    verticalAlign: 'bottom',
    cursor: 'pointer',
  },
  contactIcon: {
    color: theme.palette.grey[500],
    marginTop: 3,
    marginRight: theme.spacing(4),
    width: 20,
    height: 16,
  },
  trustedInfo: {
    fontSize: theme.typography.pxToRem(12),
  },
  verticalLine: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      marginRight: theme.spacing(10),
      paddingRight: theme.spacing(10),
      lineHeight: theme.typography.pxToRem(16),
    },
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  flexStart: {
    alignSelf: 'flex-start',
  },
}))

interface ProductPartnerInfoProps {
  className?: string
  isMarketplace?: boolean
  marketplaceSellerId?: string
  marketplaceOfferId?: string
  productAttributes?: ProductAttribute[]
  displaySellerName?: string
  hideMarketPlaceContactInfo?: boolean
}
const ProductPartnerInfo: React.FC<ProductPartnerInfoProps> = ({
  className,
  isMarketplace,
  marketplaceSellerId = '',
  marketplaceOfferId = '',
  productAttributes,
  displaySellerName,
  hideMarketPlaceContactInfo,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const router = useRouter()
  const sellerName = displaySellerName
    ? displaySellerName
    : getSellerName(productAttributes)
  const mPartnerData =
    isMarketplace &&
    !!marketplaceSellerId &&
    !!marketplaceOfferId &&
    !!sellerName

  const [marketplaceModalIsOpen, setMarketplaceModalIsOpen] = useState(false)
  const toggleMarketplaceSellerModalOpen = () => {
    setMarketplaceModalIsOpen((open) => !open)
  }

  if (!mPartnerData) {
    return null
  }

  return (
    <Box display="flex" className={clsx(classes.root, className)}>
      <Box display="flex" className={classes.shipped}>
        <div>
          <img
            src="/static/package.svg"
            alt="PDF Icon"
            className={classes.contactIcon}
          />
        </div>
        <div>
          <div>
            <FormattedMessage {...messages.CONTACT_SELLER_SHIPPED_SOLD} />{' '}
            <Link
              {...sellerRoutes.index(sellerName, marketplaceSellerId as string)}
            >
              <a>{sellerName}</a>
            </Link>
          </div>
          <div className={classes.trustedInfo}>
            <FormattedMessage {...messages.CONTACT_SELLER_TRUSTED} />
            <InfoIcon
              className={classes.infoIcon}
              onClick={toggleMarketplaceSellerModalOpen}
            />
            {marketplaceModalIsOpen && (
              <MarketplaceSellerModal
                open={marketplaceModalIsOpen}
                onCancel={toggleMarketplaceSellerModalOpen}
              />
            )}
          </div>
        </div>
      </Box>
      {!hideMarketPlaceContactInfo ? (
        <>
          <Box display="flex">
            <span className={classes.verticalLine} />
          </Box>
          <Box display="flex" className={classes.flexStart}>
            <Box display="flex" alignItems="center">
              <img
                src="/static/contact.svg"
                alt="PDF Icon"
                className={classes.contactIcon}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <FormattedMessage {...messages.CONTACT_SELLER_HAVE_QUESTION} />{' '}
              <Link
                {...sellerRoutes.contact(
                  sellerName,
                  marketplaceSellerId,
                  router.asPath,
                  ContactSellerReasonType.OFFER_MESSAGING,
                  marketplaceOfferId as string
                )}
              >
                <Box display="inline-block" marginLeft={theme.spacing(0.25)}>
                  <a className={classes.pointerCursor}>
                    <FormattedMessage {...messages.SELLER_CONTACT_SELLER} />
                  </a>
                </Box>
              </Link>
            </Box>
          </Box>
        </>
      ) : null}
    </Box>
  )
}

export default ProductPartnerInfo
