import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Divider, Theme, Link as MUILink } from '@material-ui/core'
import { Link } from '@src/components/Link'
import { sendConfiguratorUserInteractionEvent } from '@utils/analytics/configuratorAnalytics'
import { useAvailabilityMessageTracking } from '../TrackedAvailabilityMessage/TrackedAvailabilityMessage'
import clsx from 'clsx'
import { ConfiguratorType } from '@src/routes/Configurators/components/ConfiguratorConfiguration/enum'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  paMessageContainer: {
    display: 'block',
    flexDirection: 'column',
    textAlign: 'center',
  },
  leftAlign: {
    textAlign: 'left',
  },
  contentWrapper: {
    display: 'inline-block',
    margin: theme.spacing(5, 0),
    width: '90%',
  },
  contentWrapperWithButtons: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(5, 0),
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
  },
  buttonContainer: {
    margin: theme.spacing(5, 0),
  },
  button: {
    textAlign: 'center',
    color: `#fff !important`,
    '&:hover': {
      textDecoration: 'none !important',
    },
    display: 'inline-block',
    margin: theme.spacing(1, 0),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 1),
      width: 'auto',
    },
  },
  textLink: {
    textTransform: 'lowercase',
  },
}))

interface ElementCollection {
  htmlLinks: (HTMLAnchorElement | HTMLAreaElement)[]
  htmlText: HTMLCollectionOf<HTMLElement | HTMLSpanElement> | []
}

export const parseHTML = (message: string): Document => {
  const parser = new DOMParser()
  return parser.parseFromString(message, 'text/html')
}

export const getElements = (htmlCollection: Document): ElementCollection => {
  const htmlLinks = [...htmlCollection.links]
  const b = htmlCollection.getElementsByTagName('b')
  const span = htmlCollection.getElementsByTagName('span')
  const htmlText = span.length > 0 ? span : b

  return { htmlLinks, htmlText }
}

export const parseLinks = (elements) => {
  return elements.htmlLinks
    .map((link) => {
      const href = link.getAttribute('href')
      return {
        href: `${!href.startsWith('/') ? '/' : ''}${href}`,
        text: link.textContent.trim(),
      }
    })
    .filter(Boolean)
}

export enum ProductPaMessageDisplayType {
  PDP = 'pdp',
  SRP = 'srp',
}

interface ProductPaMessageProps {
  brandKey: string
  productNumber: string
  paMessage: string
  displayType?: string
  leftAlign?: boolean
}

const ProductPaMessage: React.FC<ProductPaMessageProps> = (props) => {
  const { brandKey, productNumber, paMessage, displayType, leftAlign } = props
  useAvailabilityMessageTracking('PA_MESSAGE')
  const classes = useStyles()
  const [isClient, setIsClient] = useState(false)

  const renderMessage = (elements: ElementCollection): JSX.Element | null => {
    const links = parseLinks(elements)
    const textTag = elements.htmlText[0]

    if (textTag?.localName === 'b') {
      return (
        <div className={classes.contentWrapper}>
          <b>{textTag.textContent} </b>
          <span>{textTag.nextSibling?.textContent}</span>
          <span>
            <Link href={links[0].href} passHref>
              <MUILink
                className={classes.textLink}
                color="primary"
                data-testid={`pa-message-link-${brandKey}-${productNumber}`}
              >
                {links[0].text}
              </MUILink>
            </Link>
          </span>
        </div>
      )
    }
    if (textTag?.localName === 'span') {
      return (
        <div className={classes.contentWrapperWithButtons}>
          <span>{textTag.textContent}</span>
          <div className={classes.buttonContainer}>
            {links.map((link) => {
              return (
                <Button
                  key={link.text}
                  data-testid={`pa-message-button-${brandKey}-${productNumber}`}
                  variant="contained"
                  color="primary"
                  href={link.href}
                  className={classes.button}
                  onClick={() => {
                    if (link.href === '/lab-water') {
                      sendConfiguratorUserInteractionEvent(
                        `${link.text} - ${displayType}`,
                        ConfiguratorType.LabWater,
                        productNumber
                      )
                    }
                  }}
                >
                  {link.text}
                </Button>
              )
            })}
          </div>
        </div>
      )
    }
    return null
  }

  let elements: ElementCollection = { htmlLinks: [], htmlText: [] }
  if (isClient) {
    const htmlCollection = parseHTML(paMessage)
    elements = getElements(htmlCollection)
  }

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <>
      {isClient ? (
        <div
          className={classes.container}
          id={`pricing-availability-message-${brandKey}-${productNumber}`}
          data-testid={`pricing-availability-message-${brandKey}-${productNumber}`}
        >
          <div
            className={clsx(classes.paMessageContainer, {
              [classes.leftAlign]: leftAlign,
            })}
          >
            {!leftAlign && <Divider light />}
            {renderMessage(elements)}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ProductPaMessage
