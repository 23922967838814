import React from 'react'
import messages from '@utils/messages'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Link, Typography, Theme } from '@material-ui/core'
import { support } from '@src/routes'
import { CertificateType } from '@src/types/graphql-types'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    heading: {
      marginTop: theme.spacing(4),
    },
    banner: {
      alignItems: 'start',
      background: theme.palette.background.grey,
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(4),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        alignItems: 'start',
        paddingLeft: theme.spacing(6),
      },
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.up('sm')]: {
          fontSize: theme.typography.pxToRem(15),
        },
      },
    },
    bodyText: {
      marginBottom: theme.spacing(2),
    },
  }
})

interface RequestCertificateProps {
  type: CertificateType
  requestCertLinkClicked?: Function
}

const RequestCertificate: React.FC<RequestCertificateProps> = ({
  type,
  requestCertLinkClicked,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const eventLabelForGA4 = 'request ' + type.toLowerCase()
  const eventLabelForRequestCertLink = eventLabelForGA4 + ' link'

  return (
    <>
      <Typography variant="h3" className={classes.heading}>
        {formatMessage(messages.NOT_FINDING_WHAT_YOU_ARE_LOOKING_FOR)}
      </Typography>
      <div className={classes.banner}>
        <Typography variant="body1" className={classes.bodyText}>
          {formatMessage(messages.REQUEST_CERTIFICATE_INSTRUCTIONS, {
            type,
          })}
        </Typography>
        <Typography variant="body1">
          <Link
            href={support.customerSupport()}
            onClick={() =>
              requestCertLinkClicked
                ? requestCertLinkClicked(eventLabelForRequestCertLink, {
                    action: eventLabelForGA4,
                    component: 'right rail',
                    elementType: 'link',
                    elementText: eventLabelForGA4,
                    linkUrl: support.customerSupport(),
                  })
                : null
            }
          >
            {formatMessage(messages.REQUEST_CERTIFICATE, { type })}
          </Link>
        </Typography>
      </div>
    </>
  )
}

export default RequestCertificate
