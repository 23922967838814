import React from 'react'
import { CatalogImage, Brand, CartItemType } from '@src/types/graphql-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HandleMarkup from '@src/components/HandleMarkup'
import { Link } from '@src/components/Link'
import { productDetailRoute, sellerRoutes } from '@src/routes'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import messages from '@src/utils/messages'
import { Info as InfoIcon } from '@material-ui/icons'
import { PreviouslyPurchasedChip } from '../PreviouslyPurchasedChip/PreviouslyPurchasedChip'

const useStyles = makeStyles((theme) => ({
  productLockupSimple: {
    '& > div': {
      flexGrow: 1,
    },
  },
  brand: {
    display: 'block',
    fontWeight: theme.typography.fontWeightRegular,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
    '& sup, sub': {
      verticalAlign: 'baseline',
      position: 'relative',
      top: '-0.4em',
    },
    '& sub': {
      top: '0.1em',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: theme.spacing(4),
    },
  },
  clampLines: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
  },
  sellerInfo: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.spacing(2),
  },
  infoIcon: {
    transform: 'scale(1.2)',
    color: theme.palette.primary.main,
    height: theme.spacing(4),
    verticalAlign: 'bottom',
    cursor: 'pointer',
  },
  verticalLine: {
    [theme.breakpoints.up('lg')]: {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      marginRight: theme.spacing(4),
      paddingRight: theme.spacing(4),
      lineHeight: theme.typography.pxToRem(16),
    },
  },
  trustedInfo: {
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  previouslyPurchasedChip: {
    display: 'inline-block',
    marginBottom: theme.spacing(4),
    '&:empty': { display: 'none' },
  },
  productImageAndInfo: {
    display: 'flex',
  },
  productImageContainer: {
    boxSizing: 'initial',
    width: 64,
    height: 64,
    border: `solid 1px ${theme.palette.grey[300]}`,
    marginRight: theme.spacing(4),
    borderRadius: 3,
    [theme.breakpoints.up('md')]: {
      width: 88,
      minWidth: 88, // forces container to be 88px when images are smaller
      height: 88,
      marginRight: theme.spacing(6),
    },
  },
  productImage: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
}))

export interface ProductLockupSimpleProps {
  image: Pick<CatalogImage, 'altText' | 'mediumUrl'>
  brand: Pick<Brand, 'key' | 'name' | 'color'>
  type?: CartItemType
  productKey: string
  productNumber: string
  name: string
  displaySellerName?: string
  description?: string | null
  additionalInfo?: React.ReactNode
  classes?: {
    root?: string
    brand?: string
    heading?: string
    description?: string
    imageContainer?: string
  }
  onProductLinkClick?: () => void
  isMarketplace?: boolean
  marketplaceSellerId?: string
  toggleMarketplaceSellerModalOpen?: () => void
  showPreviouslyPurchasedChip?: boolean
}

export const ProductLockupSimple = ({
  image,
  brand,
  type,
  productKey,
  productNumber,
  name,
  displaySellerName,
  description,
  additionalInfo,
  classes: propsClasses,
  isMarketplace,
  marketplaceSellerId,
  onProductLinkClick,
  toggleMarketplaceSellerModalOpen,
  showPreviouslyPurchasedChip = false,
}: ProductLockupSimpleProps) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.productLockupSimple, propsClasses?.root)}>
      {showPreviouslyPurchasedChip && (
        <div className={classes.previouslyPurchasedChip}>
          <PreviouslyPurchasedChip productNumber={productNumber} />
        </div>
      )}
      <div className={classes.productImageAndInfo}>
        <div
          className={clsx(
            classes.productImageContainer,
            propsClasses?.imageContainer
          )}
        >
          <img
            src={image.mediumUrl}
            alt={image.altText || name}
            className={classes.productImage}
          />
        </div>
        <div>
          <Typography
            variant="caption"
            className={clsx(classes.brand, propsClasses?.brand)}
            style={brand.color ? { color: brand.color } : {}}
          >
            {brand.name}
          </Typography>
          {isMarketplace && marketplaceSellerId && (
            <div className={classes.sellerInfo}>
              <FormattedMessage {...messages.CONTACT_SELLER_SHIPPED_SOLD} />{' '}
              <Link
                {...sellerRoutes.index(
                  displaySellerName || brand.key,
                  marketplaceSellerId
                )}
              >
                <a>{displaySellerName || brand.name}</a>
              </Link>
              <span className={classes.verticalLine} />
              <span className={classes.trustedInfo}>
                <FormattedMessage {...messages.CONTACT_SELLER_TRUSTED} />
                <InfoIcon
                  className={classes.infoIcon}
                  onClick={() =>
                    !!toggleMarketplaceSellerModalOpen &&
                    toggleMarketplaceSellerModalOpen()
                  }
                />
              </span>
            </div>
          )}
          <Typography
            variant="h3"
            component="div"
            className={clsx(classes.heading, propsClasses?.heading)}
          >
            {name && brand.key && productKey ? (
              <Link
                {...productDetailRoute.index(
                  brand.key,
                  productKey,
                  type === CartItemType.ThirdPartyProvider
                    ? { context: 'bbe' }
                    : {}
                )}
                passHref
              >
                {/* jsx-a11y rules disabled because href is implicitly passed to anchor */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
                <a onClick={onProductLinkClick}>{productNumber}</a>
              </Link>
            ) : (
              productNumber
            )}
          </Typography>
          <Typography
            variant="h3"
            className={clsx(classes.heading, propsClasses?.heading)}
          >
            <HandleMarkup value={name} />
          </Typography>
          {description && (
            <Typography
              className={clsx(
                classes.description,
                classes.clampLines,
                propsClasses?.description
              )}
            >
              <HandleMarkup value={description} />
            </Typography>
          )}
          {additionalInfo}
        </div>
      </div>
    </div>
  )
}
