import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import SearchResultsSkeletonSegment from '@src/components/SearchResultsSkeletonSegment'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    tRow: {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        lineHeight: 1.3,
        '&:nth-child(1)': {
          width: '4%',
        },
        '&:nth-child(2)': {
          width: '16%',
        },
        '&:nth-child(3)': {
          width: '38%',
        },
        '&:nth-child(4)': {
          width: '16%',
        },
        '&:nth-child(5)': {
          width: '10%',
        },
        '&:nth-child(6)': {
          width: '16%',
        },
        '&:not(:last-child)': {
          paddingRight: theme.spacing(5),
        },
      },
    },
    tBodyRow: {
      padding: theme.spacing(2, 0),
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.grey[50]}`,
      },
    },
    imageContainer: {
      paddingBottom: '100%',
      position: 'relative',
      width: '100%',
      border: `1px solid ${theme.palette.grey[50]}`,
    },
    imageWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },
  }
})

const ProductSearchResultsSkeleton = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div data-testid="products-srp-skeleton">
      <Box mb={20} />
      <div>
        {Array(5)
          .fill({})
          .map((_, index) => (
            <Box
              key={index}
              border={1}
              borderColor={theme.palette.grey['50']}
              mb={{ xs: 6, sm: 10 }}
              p={{ xs: 3, sm: 6 }}
            >
              <Grid container spacing={5}>
                <Grid item xs={2}>
                  <div className={classes.imageContainer}>
                    <div className={classes.imageWrapper}>
                      <SearchResultsSkeletonSegment height={1} width={1} />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={10}>
                  <Box mt={2}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <SearchResultsSkeletonSegment
                          height={28}
                          width={0.7}
                          mb={3}
                          borderRadius={{ xs: 2, sm: 'borderRadius' }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <SearchResultsSkeletonSegment
                          height={16}
                          width={0.9}
                          borderRadius={{ xs: 2, sm: 'borderRadius' }}
                        />
                      </Grid>
                      <Grid item xs={8} sm={10}>
                        <SearchResultsSkeletonSegment
                          height={16}
                          width={0.7}
                          borderRadius={{ xs: 2, sm: 'borderRadius' }}
                        />
                      </Grid>
                      {Array(6)
                        .fill({})
                        .map((_, index) => (
                          <Grid key={index} item xs={4} sm={2}>
                            <SearchResultsSkeletonSegment
                              height={16}
                              width={0.6}
                              borderRadius={{ xs: 2, sm: 'borderRadius' }}
                              mb={3}
                            />
                            <SearchResultsSkeletonSegment
                              height={16}
                              width={0.9}
                              borderRadius={{ xs: 2, sm: 'borderRadius' }}
                              mb={{ xs: 1, sm: 3 }}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {Array(3)
                    .fill({})
                    .map((_, index) => (
                      <div
                        key={index}
                        className={clsx(classes.tRow, classes.tBodyRow)}
                      >
                        {Array(6)
                          .fill({})
                          .map((_, index) => (
                            <div key={index}>
                              <SearchResultsSkeletonSegment
                                height={16}
                                width={1}
                                borderRadius={{ xs: 2, sm: 'borderRadius' }}
                              />
                            </div>
                          ))}
                      </div>
                    ))}
                </Grid>
              </Grid>
            </Box>
          ))}
      </div>
    </div>
  )
}

export default ProductSearchResultsSkeleton
