import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import messages from '@utils/messages'

const useStyles = makeStyles((theme: Theme) => ({
  supplementary: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5,
  },
}))

interface Promotional {
  messageKey?: Maybe<string>
  messageValue?: Maybe<string>
  messageVariable1?: Maybe<string>
  messageVariable2?: Maybe<string>
  messageVariable3?: Maybe<string>
}

interface Props {
  promotional: Promotional
}

const PromotionalMessage: React.FC<Props> = ({ promotional }) => {
  const classes = useStyles()
  const {
    messageKey,
    messageValue,
    messageVariable1,
    messageVariable2,
    messageVariable3,
  } = promotional ?? {}

  const noMessage = messageKey && !messageValue && !messages[messageKey]
  if (noMessage) return null

  const formattedMessage = messageValue
    ?.replace('%0', `${messageVariable1}`)
    ?.replace('%1', `${messageVariable2}`)
    ?.replace('%2', `${messageVariable3}`)

  const renderMessage = (promotional) => (
    <FormattedMessage
      {...messages[promotional.messageKey]}
      values={{
        messageVariable1: promotional.messageVariable1,
        messageVariable2: <strong>{promotional.messageVariable2}</strong>,
      }}
    >
      {(text) => <div className={classes.supplementary}>{text}</div>}
    </FormattedMessage>
  )

  const renderDefaultMessage = (messageValue) => (
    <FormattedMessage
      id="DEFAULT_PROMOTIONAL_MESSAGE"
      defaultMessage="{messageValue}"
      values={{ messageValue }}
    >
      {(text) => <div className={classes.supplementary}>{text}</div>}
    </FormattedMessage>
  )

  return (
    <div>
      {messageKey && messages[messageKey]
        ? renderMessage(promotional)
        : renderDefaultMessage(formattedMessage)}
    </div>
  )
}

export default PromotionalMessage
