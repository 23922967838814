import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Link as MuiLink } from '@material-ui/core'
import { Link } from '@src/components/Link'
import { productDetailRoute } from '@src/routes'
import HandleMarkup from '../HandleMarkup'
import ResponsiveCatalogImage from '@src/components/ResponsiveCatalogImage'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { CartItemType } from '@src/types/graphql-types'
import ProductFeaturesBadges from '@src/components/ProductFeaturesBadges'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(5),
  },
  details: { overflow: 'hidden' },
  imageContainer: {
    width: 88,
    height: 88,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: `solid 1px ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(4),
    wordWrap: 'break-word',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  brandName: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
  },
  materialNumber: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'block',
    fontSize: theme.typography.pxToRem(20),
    overflow: 'hidden',
  },
  materialName: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(20),
    overflow: 'hidden',
  },
  materialDescription: {
    fontSize: theme.typography.pxToRem(16),
    overflow: 'hidden',
  },
  materialNameWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(4),
  },
  badge: {
    margin: theme.spacing(1),
    width: 20,
    height: 20,
  },
}))

interface ProductPricingDetailProps {
  item: Partial<PdpFieldsFragment>
}

const ProductPricingDetails: React.FC<ProductPricingDetailProps> = ({
  item,
}) => {
  const classes = useStyles()
  const shouldShowFeatureBadges = item?.features && item?.features.length > 0
  const [firstImage] = item?.images ?? []

  // Products can contain special characters which need to be removed for their PDP URL
  const productKey = item?.productKey?.replace(/[\W_]+/g, '')

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        {firstImage ? (
          <ResponsiveCatalogImage
            alt={item?.productNumber}
            className={classes.image}
            sizes={'60px'}
            src={
              firstImage?.smallUrl ||
              firstImage?.mediumUrl ||
              firstImage?.largeUrl
            }
            urls={[
              firstImage?.smallUrl,
              firstImage?.mediumUrl,
              firstImage?.largeUrl,
            ]}
          />
        ) : null}
      </div>

      <div className={classes.details}>
        {item?.brand ? (
          <>
            <div className={classes.brandName}>
              <HandleMarkup value={item?.brand.name} />
            </div>

            <Link
              {...productDetailRoute.index(
                (item?.brand.key || item?.brand.name || '')
                  .replace(' ', '')
                  .toLowerCase(),
                productKey ?? '',
                item?.type === CartItemType.ThirdPartyProvider
                  ? { context: 'bbe' }
                  : {}
              )}
              passHref
            >
              <MuiLink className={classes.materialNumber}>
                {item?.productNumber}
              </MuiLink>
            </Link>
          </>
        ) : null}
        <div className={classes.materialNameWrapper}>
          {item?.name ? (
            <div className={classes.materialName}>
              <HandleMarkup value={item?.name} />
            </div>
          ) : null}
          {shouldShowFeatureBadges ? (
            <ProductFeaturesBadges
              features={item?.features ?? []}
              customClass={classes.badge}
            />
          ) : null}
        </div>

        {item?.description ? (
          <div className={classes.materialDescription}>
            <HandleMarkup value={item?.description} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ProductPricingDetails
