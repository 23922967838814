import { makeStyles, Theme } from '@material-ui/core/styles'
import CommonAccordion, {
  CommonAccordionsWrapper,
} from '@src/components/Common/CommonAccordion'
import LotNumberInstructions from '@src/components/LotNumberInstructions/LotNumberInstructions'
import FindProductNumber from '@src/routes/DocumentSearch/FindProductNumber'
import RequestCertificate from '@src/routes/DocumentSearch/RequestCertificate'
import { CertificateType } from '@src/types/graphql-types'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme: Theme) => {
  return {
    accordionContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(6),
      marginTop: theme.spacing(4),
    },
    accordion: {
      background: theme.palette.background.grey,

      '& h2': {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
        letterSpacing: 'normal',
        paddingLeft: theme.spacing(2),
        textTransform: 'none',
        [theme.breakpoints.up('md')]: {
          fontSize: theme.typography.pxToRem(16),
          paddingLeft: theme.spacing(4),
        },
      },
    },
    accordionDetails: {
      background: theme.palette.background.grey,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 4),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 6),
      },
    },
  }
})

interface DocumentHelpAccordionsProps {
  analyticsCallback: Function
  certificateType?: CertificateType
}

const DocumentHelpAccordions = ({
  analyticsCallback,
  certificateType,
}: DocumentHelpAccordionsProps) => {
  const classes = useStyles()

  const howToFindClicked = (summaryTitle: string, isExpanded: boolean) => {
    const expandOrHide = isExpanded ? 'expand' : 'hide'
    const eventLabel = `${summaryTitle} - ${expandOrHide}`

    analyticsCallback(eventLabel, {
      action: `${expandOrHide} - ${summaryTitle}`,
      component: 'right rail',
      elementType: 'accordion',
      elementText: summaryTitle.toLowerCase(),
    })
  }

  return (
    <div className={classes.accordionContainer}>
      <CommonAccordionsWrapper>
        <CommonAccordion
          summary={
            <FormattedMessage
              id="HOW_TO_FIND_PRODUCT_NUMBER"
              defaultMessage="How to Find the Product Number"
            />
          }
          details={
            <FindProductNumber techServiceLinkClicked={analyticsCallback} />
          }
          summaryId="findProductNumber-summary"
          summaryAriaControls="findProductNumber-content"
          overrideClasses={{
            accordion: classes.accordion,
            details: classes.accordionDetails,
          }}
          expandedCallback={(isExpanded: boolean) =>
            howToFindClicked('How to Find the Product Number', isExpanded)
          }
        />

        {certificateType && (
          <CommonAccordion
            summary={
              <FormattedMessage
                id="HOW_TO_FIND_LOT_NUMBER"
                defaultMessage="How to Find a Lot/Batch Number for {formType}"
                values={{ formType: certificateType }}
              />
            }
            details={
              <LotNumberInstructions
                type={certificateType}
                isAccordion
                isDialog
                customerSupportLinkClicked={analyticsCallback}
              />
            }
            summaryId="findLotNumber-summary"
            summaryAriaControls="findLotNumber-content"
            overrideClasses={{
              accordion: classes.accordion,
              details: classes.accordionDetails,
            }}
            expandedCallback={(isExpanded: boolean) =>
              howToFindClicked(
                `How to Find a Lot/Batch Number for ${certificateType}`,
                isExpanded
              )
            }
          />
        )}
      </CommonAccordionsWrapper>

      {certificateType && (
        <RequestCertificate
          requestCertLinkClicked={analyticsCallback}
          type={certificateType}
        />
      )}
    </div>
  )
}

export default DocumentHelpAccordions
