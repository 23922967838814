import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Formik } from 'formik'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import {
  Theme,
  Button,
  Grid,
  CircularProgress,
  Typography,
} from '@material-ui/core/'
import LiquidInputAdapter from '@src/components/LiquidInputAdapter'
import messages from '../../routes/Cart/messages'
import { OrderingPartyAddress } from '../../routes/Cart/ShoppingCart/ShoppingCartFormValues'
import { useValidators } from '@utils/validators'
import {
  useGlobalSnackbar,
  SnackbarMessages,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { OriginalError, extractData } from '@utils/errorHandler'
import windowScrollTo from '@utils/windowScrollTo'
import ResponsiveModal, { ModalSizes } from '@src/components/ResponsiveModal'
import {
  ResponsiveModalActions,
  ResponsiveModalBody,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import RequestQuoteItemDetails from '@src/components/RequestQuoteModal/RequestQuoteItemDetails'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { useGenerateQuoteMutation } from '@src/mutations/GenerateQuoteMutation.generated'
import { orderCenterRoutes, useRouter } from '@src/routes'
import CircleLoader from '@src/components/CircleLoader'
import { useCurrentUser } from '@src/utils/useCurrentUser'

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.secondary.main,
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.grey,
  },
  submitButton: {
    margin: theme.spacing(0, 2, 2),
  },
  confirmTitle: {
    margin: theme.spacing(2, 0, 5),
  },
}))

interface MaterialData {
  material?: ValidMaterialPricingDetailFragment | null
  materialNumber?: string
  product?: Partial<PdpFieldsFragment>
  quantity?: number
}

interface RequestQuoteModalB2BProps {
  quoteData?: MaterialData | MaterialData[]
  onClose: (reason?: any) => void
}

const RequestQuoteModalB2B: React.FC<RequestQuoteModalB2BProps> = ({
  onClose,
  quoteData,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const [generateQuote] = useGenerateQuoteMutation()
  const { setSnackbar } = useGlobalSnackbar()
  const { currentUser } = useCurrentUser()
  const [initialValues, setInitialValues] = useState<OrderingPartyAddress>({})
  const [isLoading, setIsLoading] = useState(true)

  let material, product, quantity
  if (!Array.isArray(quoteData)) {
    material = quoteData?.material
    product = quoteData?.product
    quantity = quoteData?.quantity
  }

  const {
    rawYupSchema: {
      ccEmail,
      extension,
      fax,
      mobile,
      phone,
      required,
      requiredEmail,
    },
  } = useValidators()

  const validationSchema = yup.object().shape({
    ccForOrderConfEmailIds: ccEmail,
    email: requiredEmail,
    extension: extension,
    fax: fax,
    firstName: required,
    mobile: mobile,
    telephone: phone,
  })

  const handleSubmitModal = async (values, actions) => {
    windowScrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    try {
      if (quoteData) {
        const variables = Array.isArray(quoteData)
          ? {
              addressInfo: values,
              input: quoteData.map((item) => ({
                materialNumber:
                  item?.materialNumber ?? item?.material?.materialNumber ?? '',
                quantity: item.quantity ?? 0,
              })),
            }
          : {
              addressInfo: values,
              input: {
                materialNumber:
                  quoteData?.materialNumber ??
                  quoteData?.material?.materialNumber ??
                  '',
                quantity: quoteData.quantity ?? 0,
              },
            }

        const { data } = await generateQuote({ variables })
        if (data) {
          router.replace(
            orderCenterRoutes.quoteDetail(data?.generateQuote?.orderNumber)
          )
        }
      }
    } catch (error) {
      setIsLoading(false)
      const { errors } = extractData(error as OriginalError)
      if (errors.length > 0) {
        const { code } = errors[0]
        messages[code]
          ? setSnackbar(SnackbarMessages.CartErrorRequestingQuote, code)
          : setSnackbar(SnackbarMessages.GenericError)
      }
      actions.setSubmitting(false)
    }
  }

  useEffect(() => {
    if (currentUser?.contactAddress) {
      const { contactAddress } = currentUser
      setInitialValues({
        firstName: `${contactAddress?.firstName} ${contactAddress?.lastName}`,
        email: contactAddress?.email,
        telephone: contactAddress?.telephone,
        extension: contactAddress?.extension,
        mobile: contactAddress?.mobilePhone,
        fax: contactAddress?.fax,
      })
      setIsLoading(false)
    }
  }, [])

  return isLoading ? (
    <CircleLoader />
  ) : (
    <Formik<OrderingPartyAddress>
      initialValues={initialValues}
      onSubmit={handleSubmitModal}
      enableReinitialize={false}
      validateOnMount={false}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <ResponsiveModal
            open
            onClose={(_, reason) => onClose(reason)}
            size={ModalSizes.Large}
            renderTitle={() => (
              <Typography variant="h2">
                <FormattedMessage
                  id="REQUEST_QUOTE"
                  defaultMessage="Request Quote"
                />
              </Typography>
            )}
          >
            <ResponsiveModalBody>
              {material && product && quantity ? (
                <Grid container>
                  <RequestQuoteItemDetails
                    material={material}
                    product={product}
                    quantity={quantity}
                  />
                </Grid>
              ) : null}

              <Grid className={classes.confirmTitle}>
                <Typography variant="h3">
                  <FormattedMessage
                    id="CART_CONFIRM_BUYER_INFORMATION_TITLE"
                    defaultMessage="Confirm Buyer Information"
                  />
                </Typography>
                <Typography>
                  <FormattedMessage
                    id="REQUIRED_FIELD"
                    defaultMessage="* Required"
                  />
                </Typography>
              </Grid>

              <Grid container spacing={3}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <label htmlFor="Contact Name" className="inline-label">
                      * <FormattedMessage {...messages.CONTACT_NAME} />
                    </label>
                    <Field
                      name="firstName"
                      component={LiquidInputAdapter}
                      filled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label htmlFor="email" className="inline-label">
                      * <FormattedMessage {...messages.EMAIL} />
                    </label>
                    <Field name="email" component={LiquidInputAdapter} filled />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <label htmlFor="Phone" className="inline-label">
                      <FormattedMessage {...messages.CART_PHONE} />
                    </label>
                    <Field
                      name="telephone"
                      component={LiquidInputAdapter}
                      filled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <label htmlFor="Extension" className="inline-label">
                      <FormattedMessage {...messages.CART_EXT} />
                    </label>
                    <Field
                      name="extension"
                      component={LiquidInputAdapter}
                      filled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label htmlFor="Mobile" className="inline-label">
                      <FormattedMessage {...messages.CART_MOBILE} />
                    </label>
                    <Field
                      name="mobile"
                      component={LiquidInputAdapter}
                      filled
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={4}>
                    <label htmlFor="Fax" className="inline-label">
                      <FormattedMessage {...messages.CART_FAX} />
                    </label>
                    <Field name="fax" component={LiquidInputAdapter} filled />
                  </Grid>
                </Grid>
              </Grid>
              <ResponsiveModalActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submitButton}
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  {isSubmitting && <CircularProgress size={30} />}
                  <FormattedMessage {...messages.SUBMIT_REQUEST} />
                </Button>
              </ResponsiveModalActions>
            </ResponsiveModalBody>
          </ResponsiveModal>
        )
      }}
    </Formik>
  )
}

export default RequestQuoteModalB2B
