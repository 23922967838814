import React, { useEffect, useMemo, useState } from 'react'
import {
  Divider,
  Theme,
  Typography,
  Link as MuiLink,
  Button,
  Box,
  ButtonBase,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import Modal from '@src/components/Modal'
import AddToCartButton from '../AddToCartButton'
import BuyNowButton from '../BuyNowButton'
import SpacingBox from '../SpacingBox/SpacingBox'
import { Link } from '@src/components/Link'
import MaterialAvailabilityPreview from '../MaterialAvailabilityPreview/MaterialAvailabilityPreview'
import LiquidQuantityInput from '@src/components/LiquidQuantityInput'
import { Formik } from 'formik'
import AddToListButton from '@src/components/AddToListButton'
import { useAddToCartWithGlobalSnackbar } from '@src/utils/useCart'
import ErrorCircleIcon from '@src/icons/ErrorCircleIcon'
import getConfig from 'next/config'
import {
  sendBeginRequestEvent,
  sendPricingAvailabilityInteractionEvent,
} from '@src/utils/analytics/pricingAndAvailability'
import { EventValues, GTMEventCategory } from '@sial/common-utils'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { ApolloError } from 'apollo-boost'
import ProductPriceAvailabilityError from '@src/components/ProductPriceAvailability/ProductPriceAvailabilityError'
import { getSellerName } from '@src/utils/searchUtils'
import PromoBundleButton from '@src/components/PromoBundleButton/PromoBundleButton'
import PromotionalMessage from '@src/components/PromotionalMessage'
import SupplementaryMessage from '@src/components/SupplementaryMessage'
import RestrictedItemModal from '@src/components/RestrictedItemModal'
import LockIcon from '@src/icons/LockIcon'
import {
  SitePreference,
  canUserRequestQuote,
  useCurrentUser,
} from '@src/utils/useCurrentUser'
import RequestQuoteButton from '@src/components/RequestQuoteModal/RequestQuoteButton'
import {
  setAddToCartData,
  sendBuyItNowAddToCartEvent,
} from '@utils/analytics/cartAndCheckoutEvents'
import { AddToCartPagesEnum } from '@utils/analytics/enums'
import { useUserSession } from '@src/utils/useUserSession'
import { staticContent } from '@src/routes'
import { useAbility, Abilities } from '@src/utils/useAbility'
import ExpiryDate from '@src/components/ExpiryDate'
import clsx from 'clsx'
import MaterialTextWithCopy from '../MaterialTextWithCopy/MaterialTextWithCopy'
import HidePriceMessage from '@src/components/HidePriceMessage'
import AdditionalInfoTooltip from './AdditionalInfoTooltip'
import { TrackedAvailabilityMessageProvider } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import { useChinaUser, useIndonesiaUser } from '@src/utils/useChinaUser'
import { hasMultipleMiniMumOrderQuantityError } from '@src/utils/cartUtils'
import decodeTitle from '@utils/decodeTitle'
import RequestQuoteButtonB2B from '../RequestQuoteModalB2B/RequestQuoteButtonB2B'
import { ReadyToShipNotification } from '@src/components/ProductInfo/ReadyToShipNotification'
import { useProductErpType } from '@src/utils/useProductErpType'
import SignInButton from '@src/components/SignInButton'
import CommonAccordion from '@src/components/Common/CommonAccordion'
import CommonRadio from '@src/components/Common/CommonRadio'
import { useRouter } from 'next/router'
import { Linq } from '../Linq'
import PriceDisclaimer from './PriceDisclaimer'

const useStyles = makeStyles((theme: Theme) => ({
  buyBoxContainer: {
    backgroundColor: theme.palette.background.grey,
    boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.25);',
    padding: theme.spacing(4),
  },
  productName: {
    marginBottom: theme.spacing(-1),
  },
  materialNumber: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(-1),
    maxWidth: 200,
  },
  price: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(1),
    color: theme.palette.orange,
  },
  listPrice: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.grey[600],
  },
  listPriceLabel: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
  saveLabel: {
    color: theme.palette.orange,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight,
    marginLeft: theme.spacing(2),
  },
  ctaLink: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(4, 0, 1),
  },
  loaderContainer: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  // 6 frames * 0.15 seconds = 0.9 seconds total
  loader: {
    animation: '$fadeAnimation 0.9s linear infinite',
    backgroundColor: '#efefef',
    minHeight: 424,
  },
  // 0.15 seconds for each frame
  '@keyframes fadeAnimation': {
    '0%, 100%': { opacity: 0.4 },
    '16.67%': { opacity: 0.6 },
    '33.33%': { opacity: 0.8 },
    '50%': { opacity: 1 },
    '66.67%': { opacity: 0.8 },
    '83.33%': { opacity: 0.6 },
  },
  vendorSku: {
    fontSize: theme.typography.pxToRem(14),
    margin: theme.spacing(1, 0),
  },
  errorIcon: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.error.main,
    margin: theme.spacing(1, 1, 0, 0),
  },
  phone: {
    display: 'inline-block',
  },
  or: {
    margin: theme.spacing(0, 1),
  },
  email: {
    display: 'inline-block',
    wordBreak: 'break-word',
  },
  linkStyle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  productBlockedLink: {
    margin: theme.spacing(4, 0),
    textAlign: 'center',
  },
  lockIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
  },
  productBlockedText: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(2),
  },
  labelFontSize: {
    fontSize: theme.typography.pxToRem(14),
  },
  minimumQuantityError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  pricingModal: {
    display: 'flex',
    justifyContent: 'center',
  },
  hideLink: {
    display: 'none',
  },
  minHeight: {
    minHeight: 475,
  },
  minHeightError: {
    minHeight: 320,
    padding: theme.spacing(15, 12, 0, 3),
  },
  inquireLink: {
    display: 'flex',
    minWidth: 0,
    padding: 0,
  },
  subscriptionContainer: {
    backgroundColor: theme.palette.background.grey,
    margin: theme.spacing(-3, -4, -4),
  },
  accordion: {
    backgroundColor: theme.palette.background.grey,
  },
  summary: {
    marginLeft: theme.spacing(1),
  },
  details: {
    margin: theme.spacing(0, 2),
    display: 'inherit',
  },
  summaryContainer: {
    width: '100%',
  },
  summaryLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 4, 2, 0),
  },
  accordionBackground: {
    backgroundColor: '#ECECF4',
  },
  infoLink: {
    marginLeft: theme.spacing(8),
    fontWeight: 700,
  },
}))

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

interface BuyBoxProps {
  isMultiCountryUser: boolean
  countryCode: string
  loading: boolean
  material: ValidMaterialPricingDetailFragment | null
  product: Partial<PdpFieldsFragment>
  error?: ApolloError | undefined
  isPricingModal?: boolean
  showPriceDisclaimer?: boolean
  focus?: string
  shouldShowReadyToShipNotification?: boolean
}

const BuyBox: React.FC<BuyBoxProps> = ({
  isMultiCountryUser,
  countryCode,
  loading,
  material,
  product,
  error,
  isPricingModal = false,
  showPriceDisclaimer = false,
  focus,
  shouldShowReadyToShipNotification = false,
}) => {
  const router = useRouter()
  const classes = useStyles()
  const { userSession } = useUserSession()
  const isIndonesiaUser = useIndonesiaUser()
  const user = useCurrentUser()
  const {
    userIsLoggedIn,
    currentUser,
    isPublicOrderingCustomer,
    isQuoteRequester,
    isB2BUser,
    getSitePreference,
    isDarmstadtUser,
  } = user
  const hideAddToCartForPrepackItems = getSitePreference(
    SitePreference.HideAddToCartForPrepackItems
  )
  const shouldRenderRequestQuoteButton = canUserRequestQuote(user)
  const { authorized } = useAbility(Abilities.canAddToCart)
  const addToCart = useAddToCartWithGlobalSnackbar()
  const { formatMessage } = useIntl()
  const [quantity, setQuantity] = useState(1)
  const [openInquireModal, setOpenInquireModal] = useState(false)
  const [restrictedItemModalOpen, setRestrictedItemModalOpen] = useState(false)
  const [subscription, setSubscription] = useState(false)
  const multipleMinimumOrderError = hasMultipleMiniMumOrderQuantityError(
    quantity,
    material?.minOrderQuantity,
    isIndonesiaUser
  )
  const validQty = quantity > 0 && !multipleMinimumOrderError
  const listPrice = material?.listPrice ?? 0
  const price = material?.price ?? 0
  //show list price only if it's greater than customer price
  const discount =
    listPrice > price ? ((listPrice - price) / listPrice) * 100 : null
  const bulkOrderCta = product.forms?.find((form) => form.type === 'bulk order')
  const allSecondaryCtas =
    product.forms?.filter((form) => form.type !== 'bulk order') ?? []
  const secondaryCtas = useMemo(() => {
    if (bulkOrderCta) {
      return allSecondaryCtas.slice(0, 1)
    }
    return allSecondaryCtas.slice(0, 2)
  }, [allSecondaryCtas, bulkOrderCta])

  const isUsUser = userSession.country === 'US'
  const isArgentinaUser = countryCode.toLowerCase() === 'ar'
  const isSubscriptionOrderingEnabled =
    (!userIsLoggedIn || isPublicOrderingCustomer) &&
    router.query?.subscription === 'true' &&
    material?.shipsToday &&
    isUsUser

  const isBBE = material?.catalogType === 'buildingblocks'
  const isMarketplace = product?.isMarketplace
  const [firstAvailability] = material?.availabilities ?? []
  const { contactPhone, contactEmail } = firstAvailability?.contactInfo ?? {}
  const hasInquireLink = material?.availabilities?.some(
    (item) => item?.displayInquireLink
  )
  const canAddToCart =
    authorized &&
    !hideAddToCartForPrepackItems &&
    !isMultiCountryUser &&
    (!isMarketplace ||
      (isMarketplace && !!currentUser?.metadata?.isMarketplaceCartEnabled))
  const showInquireMessage =
    firstAvailability?.key === 'INQUIRE_FOR_PRICE_AND_AVAILABILITY'
  const guestShowLocalDealer =
    currentUser?.__typename === 'GuestUser' && !canAddToCart
  const loggedInShowLocalDealer =
    currentUser?.__typename === 'LoggedInUser' &&
    !currentUser?.metadata?.guestShoppingType &&
    !isPublicOrderingCustomer &&
    !isQuoteRequester

  const userEmail = user.currentUser?.email || ''
  const productImageUrl = product?.images?.[0]?.smallUrl || ''
  const productNumber = product.productNumber || ''

  const { isPurpleProduct } = useProductErpType(product.erp_type)
  const isChinaUser = useChinaUser()
  const requiresSignInForBestPrice =
    isPurpleProduct && isChinaUser && !userIsLoggedIn

  // Renders info icon if there is additional info
  const hasAdditionalInfoFields = Object.values(
    material?.additionalInfo ?? {}
  ).filter((field) => !!field && field !== 'CartAdditionalInfo').length

  const inquirePriceContactInfo = [contactPhone, contactEmail]
    .filter((contactValue) => !!contactValue)
    .join(` ${formatMessage({ id: 'OR', defaultMessage: 'or' })} `)

  const toggleRestrictedItemModalOpen = () => {
    setRestrictedItemModalOpen((open) => !open)
  }

  const handleAddToCart = async (material) => {
    setAddToCartData(
      [
        {
          materialNumber: material.materialNumber,
          quantity,
          marketplaceOfferId: material.marketplaceOfferId,
        },
      ],
      [material],
      isPricingModal
        ? AddToCartPagesEnum.SearchResultPage
        : AddToCartPagesEnum.ProductDetailPage
    )
    await addToCart(
      [{ materialNumber: material.materialNumber, quantity }],
      undefined,
      undefined,
      isMarketplace
    )
  }

  const buyItNowEcommerceEvent = () => {
    if (!isB2BUser) {
      sendBuyItNowAddToCartEvent({
        currency: material?.currency,
        price,
        quantity,
        itemId: product.productNumber,
        itemName: product.name,
        discount,
        itemBrand: product.brand?.key,
        itemListId: isPricingModal
          ? 'search results page'
          : 'product detail page',
        itemListName: isPricingModal
          ? 'search results page'
          : 'product detail page',
        itemVariant: material?.materialNumber,
        isMarketplace,
      })
    }
  }

  useEffect(() => {
    if (material?.availableQtyInStock === 0) {
      setQuantity(0)
    }
  }, [material?.availableQtyInStock])

  const OneTimeOrder = () => (
    <>
      {material?.promotionalMessage ? (
        <SpacingBox mb={3}>
          <Typography variant="body2">
            <PromotionalMessage promotional={material?.promotionalMessage} />
          </Typography>
        </SpacingBox>
      ) : null}

      {material?.isBlockedProduct ? (
        <Box className={classes.productBlockedLink}>
          <ButtonBase onClick={() => setRestrictedItemModalOpen(true)}>
            <LockIcon className={classes.lockIcon} />
            <Typography className={classes.productBlockedText}>
              <FormattedMessage
                id="PRODUCT_BLOCKED"
                defaultMessage="Product Blocked"
              />
            </Typography>
          </ButtonBase>
          {restrictedItemModalOpen && (
            <RestrictedItemModal
              open={restrictedItemModalOpen}
              onCancel={toggleRestrictedItemModalOpen}
            />
          )}
        </Box>
      ) : (
        <LiquidQuantityInput
          disabled={hideAddToCartForPrepackItems}
          error={!validQty}
          filled
          max={material?.availableQtyInStock ?? 9999}
          min={1}
          onChange={setQuantity}
          value={quantity}
        />
      )}
      {requiresSignInForBestPrice ? (
        <SpacingBox mb={2} mt={2}>
          <SignInButton fullWidth size="large" />
        </SpacingBox>
      ) : (
        <SpacingBox mb={2} mt={2}>
          <AddToCartButton
            canAddToCart={canAddToCart}
            dirty
            fullWidth
            handleAddToCart={() => handleAddToCart(material)}
            hideAddToCartForPrepackItems={hideAddToCartForPrepackItems}
            isSubmitting={false}
            size="large"
            disabled={!validQty || material?.isBlockedProduct}
          />
        </SpacingBox>
      )}

      {material?.isBuyNow && canAddToCart ? (
        <BuyNowButton
          fullWidth
          materialNumber={material?.materialNumber}
          quantity={quantity}
          marketplace={isMarketplace}
          gaEcommerceEvent={buyItNowEcommerceEvent}
        />
      ) : null}

      {shouldRenderRequestQuoteButton &&
      featureFlags?.requestQuoteModal &&
      !hideAddToCartForPrepackItems &&
      !isMarketplace &&
      !isBBE ? (
        <Box mt={2}>
          {isB2BUser ? (
            <RequestQuoteButtonB2B
              quoteData={{
                material,
                product,
                quantity,
              }}
              disabled={!quantity}
            />
          ) : (
            <RequestQuoteButton
              quoteData={{
                material,
                product,
                quantity,
              }}
              disabled={!quantity}
            />
          )}
        </Box>
      ) : null}

      {bulkOrderCta &&
        !isMarketplace &&
        !hideAddToCartForPrepackItems &&
        !isDarmstadtUser && (
          <Link
            href={`${bulkOrderCta.url}?tfa_1=${
              product.productNumber
            }&tfa_2=${decodeTitle(product?.name ?? '')}`}
            passHref
          >
            <MuiLink
              className={classes.ctaLink}
              color="primary"
              target="_blank"
              onClick={() =>
                sendBeginRequestEvent(
                  {
                    product,
                    materialNumber: material?.materialNumber,
                    cta: bulkOrderCta,
                  },
                  {
                    eventCategory:
                      GTMEventCategory.PricingAndAvailability.toLowerCase(),
                    eventAction: EventValues.RequestBulkOrder.toLowerCase(),
                    eventLabel: material?.product ?? '',
                    eventInteractionType: 0,
                  }
                )
              }
            >
              <FormattedMessage
                id={bulkOrderCta.label.toUpperCase().split(' ').join('_')}
                defaultMessage={bulkOrderCta.label}
              />
            </MuiLink>
          </Link>
        )}
      {Boolean(secondaryCtas.length) &&
        !isMarketplace &&
        secondaryCtas.map((secondaryCta) => (
          <Link href={secondaryCta.url} key={secondaryCta.label} passHref>
            <MuiLink
              className={classes.ctaLink}
              color="primary"
              target="_blank"
              onClick={() =>
                sendBeginRequestEvent({
                  product,
                  materialNumber: material?.materialNumber,
                  cta: secondaryCta,
                })
              }
            >
              <FormattedMessage
                id={secondaryCta.label.toUpperCase().split(' ').join('_')}
                defaultMessage={secondaryCta.label}
              />
            </MuiLink>
          </Link>
        ))}
      {shouldShowReadyToShipNotification && (
        <ReadyToShipNotification
          material={material}
          userEmail={userEmail}
          productImageUrl={productImageUrl}
          productNumber={productNumber}
        />
      )}
    </>
  )

  const SubscriptionOrder = () => <div>Subscription details...</div>

  return (
    <Formik
      initialValues={{
        materialNumber: material?.materialNumber,
        quantity: 1,
      }}
      onSubmit={(_, actions) => {
        setTimeout(() => {
          actions.setSubmitting(false)
        }, 1000)
      }}
    >
      {() => (
        <>
          {loading ? (
            <div className={classes.loaderContainer}>
              <div
                className={clsx(classes.loader, {
                  [classes.minHeight]: isPricingModal,
                })}
              />
            </div>
          ) : (
            <div
              className={clsx(classes.buyBoxContainer, {
                [classes.minHeight]: isPricingModal,
                [classes.minHeightError]: isPricingModal && error,
              })}
            >
              {error ? (
                <TrackedAvailabilityMessageProvider
                  source="pdp"
                  brand={material?.brand || product?.brand?.name || ''}
                  item={material?.materialNumber || product?.productNumber}
                  waitUntilVisible
                >
                  <ProductPriceAvailabilityError
                    error={error}
                    productNumber={product?.productNumber}
                    isCenterJustified={false}
                    isMarketplace={isMarketplace}
                    sellerName={
                      product?.displaySellerName ||
                      getSellerName(product?.attributes)
                    }
                    marketplaceSellerId={product?.marketplaceSellerId ?? ''}
                    marketplaceOfferId={product?.marketplaceOfferId ?? ''}
                  />
                </TrackedAvailabilityMessageProvider>
              ) : (
                <>
                  <Typography className={classes.materialNumber}>
                    {material ? (
                      <MaterialTextWithCopy
                        materialNumber={material.materialNumber}
                      />
                    ) : null}
                    {hasAdditionalInfoFields ? (
                      <AdditionalInfoTooltip material={material} />
                    ) : null}
                  </Typography>

                  {isBBE && material?.vendorSKU ? (
                    <Typography className={classes.vendorSku}>
                      <FormattedMessage
                        id="VENDOR_SKU"
                        defaultMessage="Vendor SKU"
                      />
                      : {material?.vendorSKU}
                    </Typography>
                  ) : null}

                  {showInquireMessage ? (
                    <SpacingBox mt={4}>
                      <Typography variant="body2">
                        <ErrorCircleIcon className={classes.errorIcon} />
                        <FormattedMessage
                          id="INQUIRE_FOR_PRICE"
                          defaultMessage="This item is not able to be purchased as a guest user. For further information, please contact us at {contactInfo}."
                          values={{
                            contactInfo: (
                              <>
                                {contactPhone && (
                                  <span className={classes.phone}>
                                    {contactPhone}
                                  </span>
                                )}
                                {contactPhone && contactEmail && (
                                  <Typography
                                    component="span"
                                    className={classes.or}
                                  >
                                    <FormattedMessage
                                      id="OR"
                                      defaultMessage="or"
                                    />
                                  </Typography>
                                )}
                                {contactEmail && (
                                  <div className={classes.email}>
                                    <a href={`mailto:${contactEmail}`}>
                                      {contactEmail}
                                    </a>
                                  </div>
                                )}
                              </>
                            ),
                          }}
                        />
                      </Typography>
                    </SpacingBox>
                  ) : (
                    <>
                      {hasInquireLink ? (
                        <>
                          <Button
                            className={classes.inquireLink}
                            onClick={() => setOpenInquireModal(true)}
                          >
                            <FormattedMessage
                              id="INQUIRE"
                              defaultMessage="Inquire"
                            />
                          </Button>
                          {openInquireModal && (
                            <Modal
                              open={openInquireModal}
                              setModalOpen={setOpenInquireModal}
                              headerContent={material?.materialNumber}
                              bodyContent={
                                <Box mr={12} mb={8}>
                                  <FormattedMessage
                                    id="INQUIRE_PRICING_MODAL"
                                    defaultMessage="It is difficult to publish a price for the item selected due to market conditions and/or volume. Please contact us at the following phone number or email address for further discussion. {inquirePriceContactInfo}"
                                    values={{
                                      inquirePriceContactInfo,
                                    }}
                                  />
                                </Box>
                              }
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {material?.hidePriceMessageKey ? (
                            <Box mt={1}>
                              <HidePriceMessage
                                msgKey={material?.hidePriceMessageKey}
                              />
                            </Box>
                          ) : (
                            <Typography className={classes.price}>
                              <FormattedNumber
                                style="currency"
                                currencyDisplay="symbol"
                                currency={material?.currency}
                                value={price ?? 0}
                              />
                            </Typography>
                          )}

                          {discount !== null &&
                          discount > 0 &&
                          !material?.hidePriceMessageKey ? (
                            <>
                              <Typography
                                component="span"
                                className={classes.listPriceLabel}
                              >
                                <FormattedMessage
                                  id="LIST_PRICE"
                                  defaultMessage="List price"
                                />
                              </Typography>

                              <Typography
                                component="span"
                                className={classes.listPrice}
                              >
                                <FormattedNumber
                                  style="currency"
                                  currencyDisplay="symbol"
                                  currency={material?.currency}
                                  value={listPrice ?? 0}
                                />
                              </Typography>
                              {/* should display Save % only if discount is >= 5% */}
                              {discount >= 5 && (
                                <Typography
                                  component="span"
                                  className={classes.saveLabel}
                                >
                                  <FormattedMessage
                                    id="SAVE_DISCOUNT_PRICE"
                                    defaultMessage="Save {discount}%"
                                    values={{ discount: Math.round(discount) }}
                                  />
                                </Typography>
                              )}
                            </>
                          ) : null}
                        </>
                      )}

                      {showPriceDisclaimer && (
                        <PriceDisclaimer showTaxDisclaimer={isArgentinaUser} />
                      )}

                      <SpacingBox mt={4}>
                        <Divider />
                      </SpacingBox>

                      <SpacingBox mt={4} mb={2} data-optimizely="availability">
                        {material ? (
                          <TrackedAvailabilityMessageProvider
                            source={isPricingModal ? `srp - ${focus}` : 'pdp'}
                            brand={material.brand}
                            item={material.materialNumber}
                            pricing={material}
                            waitUntilVisible
                          >
                            <MaterialAvailabilityPreview
                              material={material}
                              canAddToCart={canAddToCart}
                              countryCode={countryCode}
                              gaDetailsClick={() =>
                                sendPricingAvailabilityInteractionEvent({
                                  action: 'check availability details',
                                  section: isPricingModal
                                    ? 'modal p&a'
                                    : 'summary',
                                  component: isPricingModal
                                    ? 'modal'
                                    : 'right rail',
                                  elementType: 'link',
                                  elementText: 'details',
                                  material,
                                })
                              }
                              gaSection={'pricing card'}
                              gaComponent={'right rail'}
                            />
                          </TrackedAvailabilityMessageProvider>
                        ) : null}

                        {material?.expirationDate ? (
                          <Box mt={1}>
                            <ExpiryDate
                              expirationDate={material.expirationDate}
                              outerClassName={classes.labelFontSize}
                              displayInline
                              displayColon
                            />
                          </Box>
                        ) : null}

                        {material?.minOrderQuantity &&
                        material?.minOrderQuantity > 1 ? (
                          <Typography
                            className={clsx(classes.labelFontSize, {
                              [classes.minimumQuantityError]: !validQty,
                            })}
                          >
                            <FormattedMessage
                              id="MINIMUM_ORDER_QUANTITY"
                              defaultMessage="Minimum Order Quantity: "
                            />
                            {material.minOrderQuantity}
                          </Typography>
                        ) : null}

                        {firstAvailability?.supplementaryMessage?.messageKey ? (
                          <Box mt={1}>
                            <SupplementaryMessage
                              supplementary={
                                firstAvailability?.supplementaryMessage
                              }
                              productName={product.name}
                            />
                          </Box>
                        ) : null}

                        {material?.isPBAvailable && (
                          <SpacingBox mt={2}>
                            <PromoBundleButton
                              materialNumber={material?.materialNumber ?? ''}
                              brand={material?.brand}
                              productName={material?.product ?? ''}
                              erp_type={product?.erp_type}
                            />
                          </SpacingBox>
                        )}
                      </SpacingBox>

                      <SpacingBox mb={3}>
                        <Divider />
                      </SpacingBox>

                      {isSubscriptionOrderingEnabled ? (
                        <Box className={classes.subscriptionContainer}>
                          <CommonAccordion
                            details={<OneTimeOrder />}
                            expanded={!subscription}
                            summary={
                              <CommonRadio
                                label="One Time Order"
                                name="defaultName"
                                size="medium"
                                value="defaultValue"
                                onChange={() => setSubscription(false)}
                                checked={!subscription}
                              />
                            }
                            summaryAriaControls="one-time-order-content"
                            summaryId="one-time-order-header"
                            overrideClasses={{
                              accordion: classes.accordion,
                              summary: classes.summary,
                              details: classes.details,
                            }}
                          />
                          <CommonAccordion
                            details={<SubscriptionOrder />}
                            expanded={subscription}
                            summary={
                              <Box className={classes.summaryContainer}>
                                <Box className={classes.summaryLabel}>
                                  <CommonRadio
                                    label="Subscription Order"
                                    name="defaultName"
                                    size="medium"
                                    value="defaultValue"
                                    onChange={() => setSubscription(true)}
                                    checked={subscription}
                                  />
                                  <FormattedMessage
                                    id="NEW"
                                    defaultMessage="NEW"
                                  />
                                </Box>
                                <Linq className={classes.infoLink} href={'/'}>
                                  <FormattedMessage
                                    id="LEARN_MORE"
                                    defaultMessage="Learn More"
                                  />
                                </Linq>
                              </Box>
                            }
                            summaryAriaControls="subscription-order-content"
                            summaryId="subscription-order-header"
                            overrideClasses={{
                              accordion: clsx(
                                classes.accordion,
                                classes.accordionBackground
                              ),
                              summary: classes.summary,
                              details: classes.details,
                            }}
                          />
                        </Box>
                      ) : null}

                      {!isSubscriptionOrderingEnabled && !isMultiCountryUser ? (
                        <>
                          {guestShowLocalDealer || loggedInShowLocalDealer ? (
                            <FormattedMessage
                              id="CONTACT_LOCAL_DEALER"
                              defaultMessage="To order products, please {contactLink} your local dealer."
                              values={{
                                contactLink: (
                                  <Link
                                    {...staticContent.index({
                                      path: '/collections/offices',
                                      language: userSession.language,
                                      country: userSession.country,
                                    })}
                                    passHref
                                  >
                                    <a rel="noopener" target="_blank">
                                      <FormattedMessage
                                        id="CONTACT"
                                        defaultMessage="contact"
                                      />
                                    </a>
                                  </Link>
                                ),
                              }}
                            />
                          ) : (
                            <OneTimeOrder />
                          )}
                        </>
                      ) : null}
                    </>
                  )}
                </>
              )}
              {isPricingModal ? (
                <Box
                  mt={5}
                  className={clsx({
                    [classes.pricingModal]: isPricingModal,
                  })}
                >
                  {material ? (
                    <AddToListButton
                      materialNumber={material?.materialNumber}
                      materialId={material?.materialId}
                      brand={material?.brand}
                      productName={material?.materialDescription}
                      productId={material?.product}
                      type={material?.type}
                      quantity={quantity}
                      isLinkButton
                      classes={{
                        linkStyle: classes.linkStyle,
                      }}
                    />
                  ) : null}
                </Box>
              ) : null}
            </div>
          )}
          <Box
            mt={5}
            className={clsx({
              [classes.hideLink]: isPricingModal,
            })}
          >
            {material ? (
              <AddToListButton
                materialNumber={material?.materialNumber}
                materialId={material?.materialId}
                brand={material?.brand}
                productName={material?.materialDescription}
                productId={material?.product}
                type={material?.type}
                quantity={quantity}
                isLinkButton
                classes={{
                  linkStyle: classes.linkStyle,
                }}
              />
            ) : null}
          </Box>
        </>
      )}
    </Formik>
  )
}

export default BuyBox
