import React, { useEffect, useState } from 'react'
import {
  Drawer,
  Theme,
  ButtonBase,
  Typography,
  Button,
  useMediaQuery,
} from '@material-ui/core'
import { useCompareProducts } from '@src/utils/useCompareProducts'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useRouter } from '@src/routes'
import { useSearchQuery } from '@src/pages/[country]/[language]/search/[searchTerm]'
import { CompareProduct } from './CompareProduct'
import { FormattedMessage } from 'react-intl'
import messages from '../messages'
import { times } from 'lodash'
import dynamic from 'next/dynamic'

const AemCompareDrawer = dynamic(
  () => import('../../../aem-content/components/AemCompareDrawer'),
  {
    ssr: false,
  }
)
interface CompareDrawerProps {
  isAemDrawer?: boolean
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: theme.spacing(6, 10),
    width: '100%',
    maxWidth: '1280px',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      boxShadow: '0 0px 10px 1px rgba(0, 0, 0, .2)',
    },
  },
  productsContainer: {
    flexGrow: 2,
    display: 'flex',
    padding: theme.spacing(0, 8),
    maxWidth: 640,
    '& > *': {
      width: `calc(25% - ${theme.spacing(8)}px)`,
      marginRight: theme.spacing(8),
    },
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  clearBtn: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(10),
    },
  },
  instructionsContainer: {
    maxWidth: '200px',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'inherit',
    },
  },
  instructions: {
    fontSize: theme.typography.pxToRem(18),
  },
  blank: {
    background: theme.palette.background.grey,
  },
}))

const CompareDrawer: React.FC<CompareDrawerProps> = ({
  isAemDrawer = false,
}) => {
  const {
    compareProducts,
    clearAllCompareProducts,
    sendToProductCompareDetail,
  } = useCompareProducts()
  const router = useRouter()
  const [query] = useSearchQuery()
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) return null
  if (isAemDrawer) return <AemCompareDrawer />

  return (
    <Drawer
      anchor="bottom"
      open={isDesktop && compareProducts.length > 0}
      variant="persistent"
      className={classes.drawer}
    >
      <div className={classes.root}>
        <div className={classes.instructionsContainer}>
          <Typography className={classes.instructions} component="h3">
            <FormattedMessage {...messages.CHOOSE_UP_TO_FOUR_PRODUCTS} />
          </Typography>
        </div>
        <div className={classes.productsContainer}>
          {times(4, (i) =>
            compareProducts[i] ? (
              <CompareProduct
                key={compareProducts[i].productKey}
                {...compareProducts[i]}
              />
            ) : (
              <div key={i} className={classes.blank} />
            )
          )}
        </div>
        <div className={classes.btnContainer}>
          <Button
            disabled={compareProducts.length < 2}
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => sendToProductCompareDetail(query, router)}
          >
            <FormattedMessage {...messages.COMPARE} />
          </Button>
          <ButtonBase
            className={classes.clearBtn}
            onClick={() => clearAllCompareProducts()}
          >
            <FormattedMessage {...messages.CLEAR_ALL} />
          </ButtonBase>
        </div>
      </div>
    </Drawer>
  )
}

export default CompareDrawer
