import { Divider, Grid, Tab, useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { documentsSearch, useRouter } from '@src/routes'
import DocumentHelpAccordions from '@src/routes/DocumentSearch/DocumentHelpAccordions'
import { DocumentTypeAbbreviations } from '@src/routes/DocumentSearch/DocumentSearch'
import vrStyles from '@src/styles/utils/vrStyles'
import { CertificateType } from '@src/types/graphql-types'
import { sendCommonDetailProductIdEvent } from '@src/utils/analytics'
import messages from '@utils/messages'
import { ChangeEvent, FC } from 'react'
import { useIntl } from 'react-intl'
import SDSSearchForm from '../AdvancedSearch/SDSSearchForm'
import CertificateForm from '../ProductDetail/ProductDocumentation/CertificateForm'

const useStyles = makeStyles((theme: Theme) => {
  const { vr4, vr8 } = vrStyles
  return {
    vr4,
    vr8,
    tabs: {
      minHeight: 35,
      '& .Mui-selected': {
        color: theme.palette.common.black,
        opacity: 1,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.common.black,
        height: '4px',
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: 48,
      },
    },
    tab: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      height: 'fit-content',
      lineHeight: 'unset',
      marginRight: theme.spacing(6),
      minHeight: 'unset',
      minWidth: 'auto',
      opacity: 1,
      textTransform: 'none',
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    tabpanel: {
      padding: 0,
    },
    divider: {
      ...vr4,
      [theme.breakpoints.up('md')]: {
        ...vr8,
      },
    },
  }
})

type DocumentSearchPartial = {
  action?: string
  component?: string
  elementType?: string
  elementText?: string
  linkUrl?: string
}

export enum CertificateLong {
  CERTIFICATE_OF_ANALYSIS = 'CERTIFICATE_OF_ANALYSIS',
  CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
  CERTIFICATE_OF_QUALITY = 'CERTIFICATE_OF_QUALITY',
}

const DocumentSearchTabs: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')) === true
  const router = useRouter()
  const selectedTab = router?.query?.tab || 'sds'

  const sendDocumentSearchPgEvent = (
    eventLabel: string,
    eventPayload?: DocumentSearchPartial,
    userSelectedTab?: string
  ) => {
    const selectedTabAbbreviation: DocumentTypeAbbreviations =
      DocumentTypeAbbreviations[userSelectedTab ?? '']
    const payload = eventPayload || {
      action: `switch to ${userSelectedTab} tab`,
      component: 'body',
      elementType: 'tab',
      elementText: selectedTabAbbreviation,
    }
    sendCommonDetailProductIdEvent(
      {
        ...payload,
        event: 'documentation_interaction',
        detail: `${selectedTab} tab`,
        section: 'document search',
        coreEvent: 'no',
      },
      {
        eventCategory: 'document search page',
        eventAction: selectedTab + ' tab',
        eventLabel: eventLabel,
        eventInteractionType: 0,
      }
    )
  }

  const tabPanels = [
    {
      value: 'sds',
      label: formatMessage(messages.SAFETY_DATA_SHEETS),
      content: (
        <SDSSearchForm
          title={formatMessage(messages.SAFETY_DATA_SHEETS_TITLE)}
          instructions={formatMessage(messages.SDS_INSTRUCTIONS)}
        />
      ),
      certificateType: null,
    },
    {
      value: 'coa',
      label: formatMessage(messages.CERTIFICATES_OF_ANALYSIS),
      content: (
        <CertificateForm
          type={CertificateType.Coa}
          title={formatMessage(messages.CERTIFICATE_OF_ANALYSIS_COA)}
          productInput
          dontShowSampleLink
          instructions={formatMessage({
            id: 'COA_INSTRUCTIONS',
            defaultMessage:
              'To search for a Certificate of Analysis (COA), please enter both the product number and the lot/batch number.',
          })}
          allFieldsRequired
        />
      ),
      certificateType: CertificateType.Coa,
    },
    {
      value: 'coo',
      label: formatMessage({
        id: 'CERTIFICATE_OF_ORIGIN_COO',
        defaultMessage: 'Certificates of Origin (COO)',
      }),
      content: (
        <CertificateForm
          type={CertificateType.Coo}
          title={formatMessage(messages.CERTIFICATE_OF_ORIGIN_COO)}
          productInput
          dontShowSampleLink
          instructions={formatMessage({
            id: 'COO_INSTRUCTIONS',
            defaultMessage:
              'To search for a Certificate of Origin (COO), please enter both the product number and the lot/batch number.',
          })}
          allFieldsRequired
        />
      ),
      certificateType: CertificateType.Coo,
    },
    {
      value: 'coq',
      label: formatMessage(messages.CERTIFICATES_OF_QUALITY),
      content: (
        <CertificateForm
          type={CertificateType.Coq}
          title={formatMessage(messages.CERTIFICATES_OF_QUALITY_COQ)}
          productInput
          dontShowSampleLink
          isBlueProduct
          instructions={formatMessage(messages.COQ_INSTRUCTIONS)}
          allFieldsRequired
        />
      ),
      certificateType: CertificateType.Coq,
    },
  ]

  const handleChange = (event: ChangeEvent<{}>, newValue: string) => {
    router.push(documentsSearch.index(newValue.toLowerCase()))
    const eventLabel = 'switch to ' + selectedTab + ' tab'
    sendDocumentSearchPgEvent(eventLabel, undefined, newValue)
  }

  return (
    <TabContext value={selectedTab}>
      <TabList
        onChange={handleChange}
        aria-label={`documentation-tabs-${selectedTab}`}
        data-testid={'documentation-tabs'}
        className={classes.tabs}
        variant="scrollable"
      >
        {tabPanels.map(({ value, label }, i) => (
          <Tab label={label} value={value} className={classes.tab} key={i} />
        ))}
      </TabList>
      <Divider className={classes.divider} />
      {tabPanels.map(({ value, content, certificateType }, i) => {
        return (
          <TabPanel value={value} key={i} className={classes.tabpanel}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              direction="row"
            >
              <Grid
                item
                xs={12}
                md={6}
                className={!isDesktop ? undefined : classes.vr8}
              >
                {content}
              </Grid>
              <Grid item xs={12} md={5}>
                <DocumentHelpAccordions
                  analyticsCallback={sendDocumentSearchPgEvent}
                  certificateType={certificateType || undefined}
                />
              </Grid>
            </Grid>
          </TabPanel>
        )
      })}
    </TabContext>
  )
}

export default DocumentSearchTabs
