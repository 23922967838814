import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Grid, Link as MuiLink, Box } from '@material-ui/core'
import { searchResultsRoute } from '@src/routes'
import { Link } from '@src/components/Link'
import { getValidSearch, ProductSearchType } from '@utils/searchUtils'
import { HandleMarkup } from '../HandleMarkup'

type SubstanceTitleSegmentProps = {
  message: object
}

type SubstanceInfoSegmentProps = {
  substanceId?: string
  searchFocusType?: string
  searchValue: string
  productSearchType?: ProductSearchType
  classes?: string
  color?: 'primary' | 'secondary' | undefined
  label?: string | null
  onClick?: () => void
}

type SubstanceDetailFullSegmentProps = SubstanceTitleSegmentProps &
  SubstanceInfoSegmentProps

export const SubstanceTitleSegment: React.FC<SubstanceTitleSegmentProps> = ({
  message,
}) => {
  return (
    <FormattedMessage {...message}>
      {(text) => (
        <Box
          mr={1}
          fontSize={12}
          fontWeight="fontWeightBold"
          fontFamily={'lato'}
        >
          {text}:{' '}
        </Box>
      )}
    </FormattedMessage>
  )
}

export const SubstanceInfoSegment: React.FC<SubstanceInfoSegmentProps> = ({
  substanceId,
  searchFocusType,
  searchValue,
  productSearchType,
  classes,
  color = 'primary',
  label,
  onClick,
}) => {
  return (
    <>
      {searchFocusType ? (
        <Link
          href={`${searchResultsRoute.searchResults(
            searchValue
          )}${getValidSearch({
            term: searchValue.toLowerCase(),
            focus: searchFocusType,
            type: productSearchType,
          })}`}
          passHref
        >
          <MuiLink
            className={classes}
            color={color}
            data-testid={`${searchValue}-alias-link`}
            id={`${substanceId}-${searchValue}-alias-link`}
            onClick={onClick}
          >
            <HandleMarkup value={label || searchValue} />
          </MuiLink>
        </Link>
      ) : (
        <HandleMarkup value={searchValue} className={classes} />
      )}
    </>
  )
}

export const SubstanceDetailSegment: React.FC<
  SubstanceDetailFullSegmentProps
> = ({
  message,
  searchFocusType,
  searchValue,
  productSearchType,
  classes,
  onClick,
}) => {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} sm={5}>
        <SubstanceTitleSegment message={message} />
      </Grid>
      <Grid item xs={12} sm={7}>
        <SubstanceInfoSegment
          searchFocusType={searchFocusType}
          searchValue={searchValue}
          productSearchType={productSearchType}
          classes={classes}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  )
}
