import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme, Typography, Link } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useRouter, searchResultsRoute } from '@src/routes'
import {
  ProductSearchType,
  SearchFocusType,
  getValidSearch,
} from '@src/utils/searchUtils'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    medium: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    banner: {
      alignItems: 'center',
      background: theme.palette.primary.light,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: theme.spacing(4, 2),
      padding: theme.spacing(2, 0),
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        padding: theme.spacing(2, 45, 2, 3),
      },
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.up('sm')]: {
          fontSize: theme.typography.pxToRem(15),
        },
      },
    },
  }
})

interface MolecularSearchBannerProps {
  searchQuery: string
}

const MolecularSearchBanner: React.FC<MolecularSearchBannerProps> = ({
  searchQuery,
}) => {
  const classes = useStyles()
  const router = useRouter()

  // Add subscripts to formula
  const formatFormula = (formula: string): string =>
    formula.replace(/(\d+)/g, (match: string) =>
      match
        .split('')
        .map((digit) => String.fromCharCode(0x2080 + parseInt(digit)))
        .join('')
    )

  function searchMolecularFormula() {
    router.push(
      `${searchResultsRoute.searchResults(searchQuery.trim())}${getValidSearch({
        term: searchQuery.trim(),
        focus: SearchFocusType.Products,
        type: ProductSearchType.MolecularFormula,
      })}`
    )
  }

  return (
    <div className={classes.banner}>
      <Typography variant="body1">
        <FormattedMessage
          id="LOOKING_FOR_MOL_FORMULA"
          defaultMessage="Are you looking for the <strong>Molecular Formula</strong>"
          values={{
            strong: (...chunks) => <strong>{chunks}</strong>,
          }}
        />
        <span className={classes.medium}>
          {' ‘'}
          <Link onClick={searchMolecularFormula} style={{ cursor: 'pointer' }}>
            {formatFormula(searchQuery)}
          </Link>
          {'’?'}
        </span>
      </Typography>
    </div>
  )
}

export default MolecularSearchBanner
