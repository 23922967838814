import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from '@utils/messages'
import ResponsiveModal, {
  ModalSizes,
  ResponsiveModalBody,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import MarketplaceLearnMoreLink from '../MarketplaceLearnMoreLink'

const useStyles = makeStyles((theme: Theme) => ({
  line: {
    margin: theme.spacing(3, 0),
  },
}))
interface Props {
  open: boolean
  onCancel: () => void
}

const MarketplaceSellerModal: React.FC<Props> = ({ open, onCancel }) => {
  const classes = useStyles()

  return (
    <ResponsiveModal
      open={open}
      onClose={onCancel}
      size={ModalSizes.Medium}
      renderTitle={() => (
        <Typography variant="h2">
          <FormattedMessage {...messages.WHAT_IS_MPARTNER_MARKETPLACE} />
        </Typography>
      )}
    >
      <ResponsiveModalBody>
        <Typography component="p">
          <FormattedMessage
            {...messages.WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE}
          />
          <MarketplaceLearnMoreLink />
        </Typography>
        <Typography className={classes.line} component="p">
          <FormattedMessage
            {...messages.WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE_LINE_1}
          />
        </Typography>
        <Typography className={classes.line} component="p">
          <FormattedMessage
            {...messages.WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE_LINE_2}
          />
        </Typography>
        <Typography className={classes.line} component="p">
          <FormattedMessage
            {...messages.WHAT_IS_MPARTNER_MARKETPLACE_MESSAGE_LINE_3}
          />
        </Typography>
      </ResponsiveModalBody>
    </ResponsiveModal>
  )
}

export default MarketplaceSellerModal
