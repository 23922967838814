import React, { useEffect, useState } from 'react'
import { sessionStorage } from 'react-storage'
import clsx from 'clsx'
import { ApolloError } from 'apollo-boost'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import { useRouter } from '@src/routes'
import {
  Facet,
  SearchResultsMetadata,
  AnalyticsDispType,
  CertificateType,
} from '@src/types/graphql-types'
import { getValidSearchQuery, SearchFocusType } from '@utils/searchUtils'
import Facets from '../Facets'
import FacetSelections from '../FacetSelections'
import SearchResultsHead from '../SearchResultsHead'
import SearchResultsNavigation from '../SearchResultsNavigation'
import StructureSearchModifyQuery from '../StructureSearchResults/StructureSearchModifyQuery'
import AvailableForSale from '../Facets/AvailableForSale'
import ResponsiveSearchResultsMenu from './ResponsiveSearchResultsMenu'
import AdvancedStructureSearchLinks from '../AdvancedStructureSearchLinks'
import RecentlyViewedProductsCarousel from '@src/components/RecentlyViewedProductsCarousel/RecentlyViewedProductsCarousel'
import DesktopSearchResultsMenu from './DesktopSearchResultsMenu'
import HandleMarkup from '@src/components/HandleMarkup'
import { useSearchQuery } from '@src/pages/[country]/[language]/search/[searchTerm]'
import DocumentSearchBanner, { SafetyDataSheet } from './DocumentSearchBanner'
import MolecularSearchBanner from './MolecularSearchBanner'
import getConfig from 'next/config'
import { FormattedMessage } from 'react-intl'
import AlertTitle from '@material-ui/lab/AlertTitle'
import InfoIcon from '@material-ui/icons/Info'
import GlobalAlert from '@src/components/GlobalAlert'
import { useBuildingBlocksCountry } from '@utils/regional'

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

const useStyles = makeStyles((theme: Theme) => ({
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchFacets: {
    margin: 0,
    '&$searchFacets': {
      padding: theme.spacing(0),
    },
  },
  searchFacetsInner: {
    borderRight: `1px solid ${theme.palette.grey[600]}`,
    height: `calc(100% - ${theme.spacing(5)}px)`,
    padding: theme.spacing(0, 7, 5, 5),
    marginTop: theme.spacing(5),
  },
  searchResults: {
    marginTop: theme.spacing(10),
    padding: 0,
    '&$searchResults': {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingTop: 0,
      },
    },
  },
  searchResultsLoose: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
    },
  },
  noResults: {
    marginTop: 0,
  },
  mobileContainer: {
    maxWidth: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  },
  searchLinks: {
    marginBottom: theme.spacing(4.5),
  },
  title: {
    marginLeft: theme.spacing(2),
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  alert: {
    display: 'flex',
    alignItems: 'flex-start',
    lineHeight: 1.5,
    borderRadius: '6px',
    marginBottom: theme.spacing(6),
    padding: theme.spacing(6, 4),
    '& .MuiAlert-icon': {
      paddingTop: 0,
    },
    '& .MuiAlert-action': {
      padding: 0,
      margin: 0,
    },
    '& svg': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  infoMessage: {
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
  },
}))

interface SearchResultsGridProps {
  children: React.ReactNode
  showSortBar?: boolean
  showFacets?: boolean
  showAvailableForSale?: boolean
  orgId?: string
  facets?: Facet[]
  loading: boolean
  error?: ApolloError
  metadata?: SearchResultsMetadata
  id?: string
  showMore?: boolean
}

const SearchResultsGrid: React.FC<SearchResultsGridProps> = ({
  children,
  showSortBar,
  showFacets,
  showAvailableForSale,
  orgId,
  facets,
  loading,
  error,
  metadata,
  showMore,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const [query] = useSearchQuery()
  const [displayInfo, setDisplayInfo] = useState(true)

  const validSearchQuery = getValidSearchQuery({
    focus: router.query.focus,
    term: router.query.term,
  })
  const isStructureSearch =
    validSearchQuery.focus === SearchFocusType.StructureSearch
  const isProductOrB2BSRP =
    validSearchQuery.focus === SearchFocusType.Products ||
    validSearchQuery.focus === SearchFocusType.B2B

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const noResults =
    metadata?.itemCount === 0 ||
    metadata === undefined ||
    metadata === null ||
    metadata?.setsCount === 0

  const searchResultsClasses = clsx({
    [noResults ? classes.noResults : classes.searchResultsLoose]:
      isProductOrB2BSRP,
  })

  const renderSearchResultsMenu = (): JSX.Element =>
    isDesktop && !noResults ? (
      <DesktopSearchResultsMenu
        showSortBar={showSortBar}
        metadata={metadata}
        orgId={orgId}
      />
    ) : (
      <ResponsiveSearchResultsMenu
        facets={facets}
        showSortBar={showSortBar}
        metadata={metadata}
        orgId={orgId}
      />
    )

  useEffect(() => {
    sessionStorage.setItem('previousSearchPath', router.asPath)
  }, [router.asPath])

  const getQueryTerm = (): string | undefined => {
    if (validSearchQuery.term === 'facet-search') {
      return query.facet[0]?.split(':')[1]
    } else {
      return router.query?.term || ''
    }
  }

  const docSearchValues = [
    Object.values(CertificateType),
    Object.values(SafetyDataSheet),
  ]
    .toString()
    .toLowerCase()

  const queryTerm = getQueryTerm()
  const isDocumentSearch = queryTerm
    ? docSearchValues.split(',').includes(queryTerm.toLowerCase())
    : false

  const isMolecularFormulaSearch =
    metadata?.suggestedType && metadata.suggestedType.includes('mol_form')

  const showBuildingBlocks =
    useBuildingBlocksCountry() && featureFlags.disableBBE

  return (
    <>
      <SearchResultsHead key="head" metadata={metadata} />
      <Container className={classes.mobileContainer}>
        <Grid container spacing={10}>
          <Grid
            item
            md={3}
            className={clsx(classes.hideOnMobile, classes.searchFacets)}
          >
            <div className={classes.searchFacetsInner}>
              <div className={classes.searchLinks}>
                <AdvancedStructureSearchLinks
                  orgId={orgId}
                  isStructureSearch={isStructureSearch}
                />
              </div>
              {isStructureSearch ? (
                <StructureSearchModifyQuery />
              ) : (
                <SearchResultsNavigation orgId={orgId} />
              )}
              {showFacets && (
                <Facets
                  loading={loading}
                  error={error}
                  facets={facets}
                  showMore={showMore}
                />
              )}
              {showAvailableForSale && (
                <AvailableForSale loading={loading} error={error} />
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            className={!!noResults ? classes.noResults : classes.searchResults}
          >
            {!loading && !noResults && (
              <>
                {showBuildingBlocks && displayInfo && (
                  <GlobalAlert
                    className={classes.alert}
                    icon={<InfoIcon style={{ fontSize: 24 }} />}
                    onClose={() => setDisplayInfo(false)}
                  >
                    <AlertTitle style={{ marginBottom: 0 }}>
                      <FormattedMessage
                        id="BUILDING_BLOCKS_EXPLORER"
                        defaultMessage="Building Blocks Explorer"
                      />
                    </AlertTitle>
                    <span className={classes.infoMessage}>
                      <FormattedMessage
                        id="BUILDING_BLOCKS_EXPLORER_INFO"
                        defaultMessage="We're making things easier for you to find. Building Blocks Explorer results are now included in Product search results."
                      />
                    </span>
                  </GlobalAlert>
                )}
                <Typography variant="h1" className={classes.title}>
                  <HandleMarkup value={queryTerm} />
                </Typography>
              </>
            )}

            {/* Documents search banner */}
            {isDocumentSearch && (
              <DocumentSearchBanner
                searchQuery={router.query.term.toLowerCase()}
              />
            )}

            {/* Molecular formula search banner */}
            {isMolecularFormulaSearch && !loading && (
              <MolecularSearchBanner searchQuery={router.query.term} />
            )}

            {/* Facet Selections */}
            {showFacets && !loading && (
              <FacetSelections
                facets={facets}
                isStructureSearch={isStructureSearch}
              />
            )}
            {renderSearchResultsMenu()}
            {/* Search results  */}
            <div className={searchResultsClasses}>{children}</div>
          </Grid>
        </Grid>
      </Container>
      {!loading && !noResults && (
        <RecentlyViewedProductsCarousel
          dispType={AnalyticsDispType.RecentlyViewedSrp}
          searchKey={validSearchQuery.term}
          parentPageName="srp"
          parentPageNameDetail="search results page"
        />
      )}
    </>
  )
}

export default SearchResultsGrid
