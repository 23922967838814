import React from 'react'
import getConfig from 'next/config'
import { useIntl } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import messages from '@src/routes/SearchResults/messages'
import { SearchFocusType, ReadableSearchTypes } from '@utils/searchUtils'
import { useBuildingBlocksCountry } from '@utils/regional'
import { useSearchQuery } from '@src/pages/[country]/[language]/search/[searchTerm]'
import FacetGroupCollapse from '../Facets/FacetGroupCollapse'
import { FormControlLabel, RadioGroup } from '@material-ui/core'
import RadioButton from '@src/components/RadioButton'
import { buildFacetEventPayload } from '@utils/analytics/facets'
import { sendEvent } from '@utils/analytics/coreAnalytics'

const useStyles = makeStyles((theme: Theme) => ({
  formBody: {
    marginTop: theme.spacing(0.5),
  },
  facet: {
    padding: theme.spacing(1, 0, 0, 2),
    width: 'fit-content',
    '& span': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  facetFocus: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  facetRadio: {
    padding: theme.spacing(1, 0.5),
    marginRight: theme.spacing(1.5),
    alignSelf: 'flex-start',
  },
}))

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

const SearchResultsNavigation: React.FC<{ orgId?: string }> = ({ orgId }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [query, , queryActions] = useSearchQuery()
  const showBuildingBlocks =
    useBuildingBlocksCountry() && !featureFlags.disableBBE

  const trimmedOrgId = orgId?.trim()
  const formattedOrgId =
    trimmedOrgId === 'UCB' ? trimmedOrgId : trimmedOrgId?.toLowerCase()

  const showSearchNavItem = (focus: string): boolean =>
    query.term && !orgId ? true : query.focus === focus

  return (
    <div className={classes.facetFocus}>
      <FacetGroupCollapse
        defaultCollapse
        facetKey={messages.FACET_SEARCH_WITHIN.id}
      >
        <form>
          <div className={classes.formBody}>
            <RadioGroup
              aria-label="focus-selection"
              onChange={(e) => {
                const builtFacetPayload = buildFacetEventPayload({
                  action: 'change search type',
                  component: 'left rail',
                  filter_category: 'search within',
                  filter_name: ReadableSearchTypes[e.target.value],
                  element_type: 'radio button',
                  element_text: ReadableSearchTypes[e.target.value],
                })

                sendEvent({ payload: builtFacetPayload })

                queryActions.handleFocusChange(e.target.value)
              }}
            >
              {showSearchNavItem(SearchFocusType.Products) && !orgId ? (
                <FormControlLabel
                  value={SearchFocusType.Products}
                  control={<RadioButton className={classes.facetRadio} />}
                  aria-current={
                    query.focus === SearchFocusType.Products
                      ? 'page'
                      : undefined
                  }
                  className={classes.facet}
                  checked={query.focus === SearchFocusType.Products}
                  label={formatMessage(messages.PRODUCTS)}
                  data-testid="srp-nav-products"
                />
              ) : (
                showSearchNavItem(SearchFocusType.B2B) && (
                  <FormControlLabel
                    disabled
                    value={SearchFocusType.Products}
                    control={<RadioButton className={classes.facetRadio} />}
                    className={classes.facet}
                    checked={query.focus === SearchFocusType.B2B}
                    label={`${formattedOrgId} ${formatMessage(
                      messages.PRODUCTS
                    )}`}
                  />
                )
              )}
              {showBuildingBlocks &&
                showSearchNavItem(SearchFocusType.BuildingBlocks) && (
                  <FormControlLabel
                    value={SearchFocusType.BuildingBlocks}
                    control={<RadioButton className={classes.facetRadio} />}
                    aria-current={
                      query.focus === SearchFocusType.BuildingBlocks
                        ? 'page'
                        : undefined
                    }
                    className={classes.facet}
                    checked={query.focus === SearchFocusType.BuildingBlocks}
                    label={formatMessage(messages.BUILDING_BLOCKS_EXPLORER)}
                    data-testid="srp-nav-building-blocks"
                  />
                )}
              {showSearchNavItem(SearchFocusType.TechnicalDocuments) && (
                <FormControlLabel
                  value={SearchFocusType.TechnicalDocuments}
                  control={<RadioButton className={classes.facetRadio} />}
                  aria-current={
                    query.focus === SearchFocusType.TechnicalDocuments
                      ? 'page'
                      : undefined
                  }
                  className={classes.facet}
                  checked={query.focus === SearchFocusType.TechnicalDocuments}
                  label={formatMessage(messages.TECHNICAL_DOCUMENTS)}
                  data-testid="srp-nav-techdocs"
                />
              )}
              {showSearchNavItem(SearchFocusType.SiteContent) && (
                <FormControlLabel
                  value={SearchFocusType.SiteContent}
                  control={<RadioButton className={classes.facetRadio} />}
                  className={classes.facet}
                  checked={query.focus === SearchFocusType.SiteContent}
                  label={formatMessage(messages.SITE_CONTENT)}
                />
              )}
              {showSearchNavItem(SearchFocusType.Papers) && (
                <FormControlLabel
                  value={SearchFocusType.Papers}
                  control={<RadioButton className={classes.facetRadio} />}
                  aria-current={
                    query.focus === SearchFocusType.Papers ? 'page' : undefined
                  }
                  className={classes.facet}
                  checked={query.focus === SearchFocusType.Papers}
                  label={formatMessage({
                    id: 'PAPERS',
                    defaultMessage: 'Papers',
                  })}
                  data-testid="srp-nav-papers"
                />
              )}
              {showSearchNavItem(SearchFocusType.Genes) && (
                <FormControlLabel
                  value={SearchFocusType.Genes}
                  control={<RadioButton className={classes.facetRadio} />}
                  aria-current={
                    query.focus === SearchFocusType.Genes ? 'page' : undefined
                  }
                  className={classes.facet}
                  checked={query.focus === SearchFocusType.Genes}
                  label={formatMessage({
                    id: 'GENES',
                    defaultMessage: 'Genes',
                  })}
                  data-testid="srp-nav-genes"
                />
              )}
              {featureFlags.chromatogramSearch &&
                showSearchNavItem(SearchFocusType.Chromatograms) && (
                  <FormControlLabel
                    value={SearchFocusType.Chromatograms}
                    control={<RadioButton className={classes.facetRadio} />}
                    aria-current={
                      query.focus === SearchFocusType.Chromatograms
                        ? 'page'
                        : undefined
                    }
                    className={classes.facet}
                    checked={query.focus === SearchFocusType.Chromatograms}
                    label={formatMessage(messages.CHROMATOGRAMS)}
                    data-testid="srp-nav-chromatograms"
                  />
                )}
            </RadioGroup>
          </div>
        </form>
      </FacetGroupCollapse>
    </div>
  )
}

export default SearchResultsNavigation
