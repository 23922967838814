import React, { Component, FC } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Link } from '@src/components/Link'
import { getValidSearch, SearchFocusType } from '@utils/searchUtils'
import { searchResultsRoute } from '@src/routes'
import { useSearchQuery } from '@src/pages/[country]/[language]/search/[searchTerm]'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    padding: theme.spacing(0, 3),
  },
  active: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

interface SearchResultsNavigationLinkProps {
  focus: SearchFocusType
  id: string
}

const getLinkProps = (term: string | undefined, focus: SearchFocusType) => {
  const searchConfig = { term, focus }
  let query = getValidSearch(searchConfig)
  if (query[0] === '?') query = query.slice(1)

  return {
    href: { pathname: `/search/[searchTerm]`, query },
    as: { pathname: `${searchResultsRoute.searchResults(term)}`, query },
  }
}

const SearchResultsNavigationLink: FC<SearchResultsNavigationLinkProps> = ({
  focus,
  id,
  children,
}) => {
  const classes = useStyles()
  const [query] = useSearchQuery()
  const linkClassName = clsx(
    classes.link,
    query.focus === focus && classes.active
  )
  const ariaCurrentValue = query.focus === focus ? 'page' : undefined

  return (
    <Link {...getLinkProps(query.term, focus)}>
      <a
        className={linkClassName}
        aria-current={ariaCurrentValue}
        data-testid={id}
        id={id}
        role="tab"
      >
        {children}
      </a>
    </Link>
  )
}

// NOTE: MUI Tab component expects to pass a ref; as such, requires a class component
export function getSearchResultsLink(focus: SearchFocusType, id: string) {
  return class LinkComponent extends Component {
    render() {
      return (
        <SearchResultsNavigationLink focus={focus} id={id}>
          {this.props.children}
        </SearchResultsNavigationLink>
      )
    }
  }
}

export default SearchResultsNavigationLink
