import React from 'react'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import SelectableChips from '@src/components/SelectableChips/SelectableChips'
import { MaterialAvailabilityKey } from '@src/types/graphql-types'

const unavailableSkuMessages: MaterialAvailabilityKey[] = [
  MaterialAvailabilityKey.ApoNoStock,
  MaterialAvailabilityKey.OutOfStockKey,
  MaterialAvailabilityKey.UnknownAvailability,
]

interface MaterialNumberChipsProps {
  materialPricing: ValidMaterialPricingDetailFragment[]
  selectedChip: string
  onSelect: (materialNumber: string) => void
}

export const MaterialNumberChips: React.FC<MaterialNumberChipsProps> = ({
  materialPricing,
  selectedChip,
  onSelect,
}) => {
  return (
    <SelectableChips
      items={materialPricing.map((p) => ({
        label: p.materialNumber,
        value: p.materialNumber,
      }))}
      selectedChip={selectedChip}
      unavailableChips={materialPricing
        .filter(({ availabilities }) => {
          return availabilities?.find((availability) =>
            unavailableSkuMessages.includes(availability.key)
          )
        })
        .map(({ materialNumber }) => materialNumber)}
      onSelect={onSelect}
      id="sku-selection"
    />
  )
}
