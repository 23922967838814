import React, { useCallback, useEffect, useMemo } from 'react'
import Head from 'next/head'
import {
  Typography,
  Button,
  Link as MuiLink,
  Grid,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { productDetailRoute, useRouter } from '@src/routes'
import { Link } from '@src/components/Link'
import HandleMarkup from '@src/components/HandleMarkup'
import { sendRecommendedProductsClickEvent } from '@src/utils/analytics'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import messages from '@src/utils/messages'
import { useCompareProducts } from '@src/utils/useCompareProducts'
import { useSearchQuery } from '@src/pages/[country]/[language]/search/[searchTerm]'
import LiquidCheckbox from '@src/components/LiquidCheckbox'
import { CarouselProduct } from './ReplacementProductsCarousel'
import { determineCatalogType } from '@src/utils/searchUtils'

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  mainContentImage: {
    height: 110,
    width: 110,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    textAlign: 'center',
    backgroundColor: theme.palette.common.white,
  },
  image: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    maxHeight: '100%',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  },
  mainContentText: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(4),
  },
  productNumber: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },
  productName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
  },
  productDescription: {
    fontSize: theme.typography.pxToRem(14),
  },
  productBrand: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.25,
    marginBottom: theme.spacing(2),
    color: theme.palette.rich.red,
  },
  linkContainer: {
    marginTop: theme.spacing(3),
  },
  link: {
    cursor: 'pointer',
  },
  compareButton: {
    marginTop: theme.spacing(3),
  },
  checkbox: {
    paddingLeft: theme.spacing(3),
  },
}))

export interface MainProduct {
  productKey: string | undefined
  brandKey: string | undefined
  images: CarouselProduct['images'] | undefined
  showViewDetailsLink?: boolean
}
export interface ProductCardProps {
  product: CarouselProduct
  testId: string
  preloadCardImages?: boolean
  className?: string
  mainProduct?: MainProduct
  index: number
  type: string
}

const ReplacementProductCard: React.FC<ProductCardProps> = ({
  product,
  testId,
  preloadCardImages = false,
  className,
  mainProduct,
  index,
  type,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const [query] = useSearchQuery()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const {
    addCompareProduct,
    removeCompareProduct,
    sendToProductCompareDetail,
    compareProducts,
  } = useCompareProducts()

  const catalog = router?.query?.catalog
  const catalogType = determineCatalogType(catalog)
  const displayProductNumber = !product?.productNumber?.startsWith('CB_')
  const linkId = `product-card-${product?.productNumber}${product?.brand?.key}-${testId}`
  const productDetailPage = router.asPath.includes('/product')

  const isProductSelectedForCompare = useCallback(
    (productKey: string, brandKey?: string) => {
      return !!compareProducts.find(
        (product) =>
          product.productKey === productKey &&
          product.brandKey === brandKey &&
          product.catalogType === catalogType
      )
    },
    [compareProducts]
  )

  const isMainProductSelectedForCompare = useMemo(() => {
    return isProductSelectedForCompare(
      mainProduct?.productKey ?? '',
      mainProduct?.brandKey
    )
  }, [isProductSelectedForCompare, mainProduct])

  const compareButtonEnabled =
    isMainProductSelectedForCompare &&
    compareProducts.length > 1 &&
    compareProducts.length <= 4

  const handleChange = (e) => {
    const { checked } = e.target
    const productToCompare = {
      productKey: product?.productNumber,
      brandKey: product.brand.key,
      images: product.images,
      catalogType,
    }
    checked
      ? addCompareProduct(productToCompare)
      : removeCompareProduct(productToCompare)
  }

  useEffect(() => {
    if (productDetailPage && !isMainProductSelectedForCompare) {
      addCompareProduct({
        productKey: mainProduct?.productKey ?? '',
        brandKey: mainProduct?.brandKey ?? '',
        images: mainProduct?.images,
        catalogType,
      })
    }
  }, [isMainProductSelectedForCompare])

  return (
    <div className={clsx(className, 'promotions-slider-div')}>
      {preloadCardImages && (
        <Head>
          <link rel="preload" href={product.images[0].mediumUrl} as="image" />
        </Head>
      )}
      <Link
        {...productDetailRoute.index(
          product?.brand.key,
          product?.productNumber,
          {
            catalog,
          }
        )}
      >
        <a
          data-testid={linkId}
          id={linkId}
          role="link"
          tabIndex={0}
          onKeyPress={() =>
            sendRecommendedProductsClickEvent(type, product, index)
          }
          onClick={() =>
            sendRecommendedProductsClickEvent(type, product, index)
          }
        >
          <div className={classes.mainContent}>
            <div className={classes.mainContentImage}>
              <img
                className={classes.image}
                src={product.images[0].mediumUrl}
                alt={
                  product.images[0].altText ||
                  product.description ||
                  product.name
                }
                width="112"
                height="50"
              />
            </div>
            <div className={classes.mainContentText}>
              <Typography className={classes.productBrand}>
                {product.brand.name}
              </Typography>
              {displayProductNumber && (
                <Typography className={classes.productNumber}>
                  {product?.productNumber}
                </Typography>
              )}
              <Typography className={classes.productName}>
                <HandleMarkup value={product.name} />
              </Typography>
              <Typography className={classes.productDescription}>
                <HandleMarkup value={product.description} />
              </Typography>
            </div>
          </div>
        </a>
      </Link>

      {mainProduct?.showViewDetailsLink || productDetailPage ? (
        <Link
          {...productDetailRoute.index(
            product?.brand?.key,
            product?.productNumber
          )}
        >
          <MuiLink className={classes.link}>
            <FormattedMessage {...messages.VIEW_PRODUCT_DETAILS} />
          </MuiLink>
        </Link>
      ) : null}

      {isDesktop ? (
        <Grid container>
          <Grid item xs={12}>
            <LiquidCheckbox
              className={classes.checkbox}
              checked={isProductSelectedForCompare(
                product?.productNumber,
                product?.brand?.key
              )}
              onChange={handleChange}
              label={
                <Typography variant="body2" component="span">
                  <FormattedMessage
                    {...messages.COMPARE_WITH_DISCONTINUED_PRODUCT}
                  />
                </Typography>
              }
            />
          </Grid>

          <Button
            variant="contained"
            color="primary"
            className={classes.compareButton}
            onClick={() => sendToProductCompareDetail(query, router)}
            disabled={!compareButtonEnabled}
          >
            <FormattedMessage
              id="COMPARE_SELECTED"
              defaultMessage="Compare Selected"
            />
            {compareProducts.length > 0 && ` (${compareProducts.length})`}
          </Button>
        </Grid>
      ) : null}
    </div>
  )
}

export default ReplacementProductCard
