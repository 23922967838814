import React, { useState } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import messages from '@utils/messages'
import { Theme, ButtonBase, useMediaQuery, Button } from '@material-ui/core'
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  PlayArrowRounded,
} from '@material-ui/icons'
import Carousel, { Alignment } from 'nuka-carousel'
import { FormattedMessage } from 'react-intl'
import ResponsiveModal, { ModalSizes } from '@src/components/ResponsiveModal'
import vrStyles from '@src/styles/utils/vrStyles'
import ResponsiveCatalogImage from '@src/components/ResponsiveCatalogImage'
import HandleMarkup from '@src/components/HandleMarkup'
import ZoomableImage from '@src/components/ZoomableImage'
import { productDetailRoute } from '@src/routes'
import { Link } from '@src/components/Link'
import { ProductDetailFragment } from '@src/fragments/ProductDetail.generated'
import VideoPlayer from './VideoPlayer'

const HEADER_HEIGHT = 36
const FOOTER_HEIGHT = 88 // NOTE: per mockup: 56px thumbnail + 16px top padding + 16px bottom padding
const MODAL_CHROME = `${HEADER_HEIGHT + FOOTER_HEIGHT}px`
const SLIDE_HEIGHT = 328
const { vr4 } = vrStyles

const useStyles = makeStyles((theme: Theme) => ({
  carouselWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      ...vr4,
    },
  },
  footer: {
    height: FOOTER_HEIGHT,
  },
  slide: {
    display: 'flex',
    justifyContent: 'center',
    height: `calc(100vh - ${MODAL_CHROME})`,
    [theme.breakpoints.up('md')]: {
      height: SLIDE_HEIGHT, // NOTE: maintains proper size @ desktop
    },
  },
  mainContentWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 12),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(0, 16),
    },
  },
  labelWrapper: {
    height: '8%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: SLIDE_HEIGHT,
      width: '50%',
      justifyContent: 'flex-start',
      padding: theme.spacing(4, 0, 4, 6),
    },
  },
  desktopLabelWrapper: {
    display: 'none',
    position: 'relative',
    maxHeight: SLIDE_HEIGHT,
    paddingLeft: theme.spacing(3),
    '&:before': {
      position: 'absolute',
      top: 5,
      left: 0,
      botton: 0,
      content: "''",
      height: 14,
      width: 4,
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  desktopLabel: {
    overflow: 'auto',
    maxHeight: SLIDE_HEIGHT,
    wordBreak: 'break-word',
  },
  mobileLabelWrapper: {
    overflow: 'scroll',
  },
  labelText: {
    '& sup, sub': {
      verticalAlign: 'baseline',
      position: 'relative',
      top: '-0.4em',
    },
    '& sub': {
      top: '0.1em',
    },
  },
  mobileLabelTrigger: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  nav: {
    backgroundColor: theme.palette.common.white,
    border: 0,
    height: theme.typography.pxToRem(64),
    width: theme.typography.pxToRem(32),
    boxShadow: theme.shadows[5],
  },
  prevNav: {
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
  },
  nextNav: {
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  buttonIcon: {
    fontSize: theme.typography.pxToRem(34),
  },
  thumbnailWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  thumbnailButton: {
    height: 54,
    width: 54,
    borderRadius: 2,
    margin: '0 auto',
    position: 'relative',
    display: 'block',
  },
  active: {
    border: `solid 2px ${theme.palette.primary.main}`,
  },
  thumbnail: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .3)',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  footerCarousel: {
    maxWidth: '350px',
    margin: '0 auto',
  },
  test: {},
  productLinkWrapper: {
    marginBottom: theme.spacing(2),
  },
  productNumberHeading: {
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(4),
  },
  productLink: {
    fontSize: theme.typography.pxToRem(16),
  },
  videoLink: {
    cursor: 'pointer',
    width: '100%',
    height: 'auto',
  },
  playButtonIcon: {
    background: theme.palette.common.white,
    borderRadius: '100%',
    position: 'absolute',
    opacity: 0.6,
    top: '30%',
    left: '30%',
  },
  videoContainer: {
    width: '70%',
    height: 'auto',
    flexShrink: 0,
    flexGrow: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

interface Props {
  isOpen: boolean
  images: ProductDetailFragment['images']
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  name?: string
  productId?: string
  brand?: string
}

const ProductImagesModal: React.FC<Props> = ({
  isOpen,
  images,
  onClose,
  name,
  productId,
  brand,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const totalImages = images.length
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const framePadding = isDesktop ? { padding: '0 16px' } : { padding: '4px' } // NOTE: smaller breakpoints can cut off thumbnails with larger frame padding sizes
  const maxNavLimit = totalImages - 1
  const [slideIndex, setSlideIndex] = useState(0)
  const [showMobileLabel, setShowMobileLabel] = useState(false)
  const thumbImgSizes = '54px'
  const thumbnailUrl =
    '/deepweb/assets/sigmaaldrich/marketing/global/images/video-posters/miscl/purple-video-poster-mslvn/purple-video-poster-mslvn.jpg'
  const posterUrl =
    '/deepweb/assets/sigmaaldrich/marketing/global/images/video-posters/miscl/blue-video-poster-mslvn/blue-video-poster-mslvn.jpg'
  const updateSlideIndex = (index: number) => {
    setShowMobileLabel(false)
    setSlideIndex(index)
  }

  const onCloseModal = () => {
    updateSlideIndex(0)
    onClose(!isOpen)
  }

  const onNext = () => {
    const nextIndex = slideIndex + 1

    if (nextIndex < totalImages) {
      updateSlideIndex(nextIndex)
    }
  }

  const onPrev = () => {
    const prevIndex = slideIndex - 1

    if (prevIndex >= 0) {
      updateSlideIndex(prevIndex)
    }
  }

  const onImageClick = (index: number) => {
    updateSlideIndex(index)
  }

  interface ProductLink {
    brandKey: string
    productKey: string
  }

  const renderProductLink = (productLink: ProductLink) => {
    const { brandKey, productKey } = productLink
    return (
      <div className={classes.productLinkWrapper}>
        <h4 className={classes.productNumberHeading}>
          <FormattedMessage {...messages.PRODUCT_NUMBER} />
        </h4>
        <Link {...productDetailRoute.index(brandKey, productKey)} passHref>
          <a className={classes.productLink}>
            <HandleMarkup value={productKey} />
          </a>
        </Link>
      </div>
    )
  }

  return (
    <ResponsiveModal
      open={isOpen}
      onClose={onCloseModal}
      size={ModalSizes.Large}
      renderTitle={() => (
        <FormattedMessage
          {...messages.PRODUCT_IMAGES_VIEWING}
          values={{ index: slideIndex + 1, count: totalImages }}
        />
      )}
    >
      <div data-testid="pdp-images-modal" id="pdp-images-modal">
        <div className={classes.carouselWrapper}>
          <Carousel
            slideIndex={slideIndex}
            renderBottomCenterControls={null}
            disableAnimation
            onDragStart={() => setShowMobileLabel(false)}
            renderCenterLeftControls={() => (
              <>
                {slideIndex > 0 && (
                  <ButtonBase
                    className={clsx(classes.nav, classes.prevNav)}
                    onClick={onPrev}
                    aria-label="previous"
                  >
                    <KeyboardArrowLeft
                      className={classes.buttonIcon}
                      color="primary"
                    />
                  </ButtonBase>
                )}
              </>
            )}
            renderCenterRightControls={() => (
              <>
                {slideIndex < maxNavLimit && (
                  <ButtonBase
                    className={clsx(classes.nav, classes.nextNav)}
                    onClick={onNext}
                    aria-label="next"
                  >
                    <KeyboardArrowRight
                      className={classes.buttonIcon}
                      color="primary"
                    />
                  </ButtonBase>
                )}
              </>
            )}
          >
            {images.map((image, index) => {
              const { label, brandKey, productKey } = image
              return (
                <div key={index} className={classes.slide}>
                  <div className={classes.mainContentWrapper}>
                    {/* Image / Label Toggle on Mobile  */}
                    {!showMobileLabel ? (
                      image.videoUrl ? (
                        <div className={classes.videoContainer}>
                          <VideoPlayer
                            videoUrl={image.videoUrl}
                            posterUrl={posterUrl}
                            css={classes.videoLink}
                            name={name}
                            brand={brand || brandKey}
                            productId={productId}
                          />
                        </div>
                      ) : (
                        <ZoomableImage image={image} />
                      )
                    ) : (
                      <div
                        className={clsx(
                          classes.mobileLabelWrapper,
                          classes.labelText
                        )}
                      >
                        {label && <HandleMarkup value={label} />}
                      </div>
                    )}
                    {label || (brandKey && productKey) ? (
                      <div className={classes.labelWrapper}>
                        {/* Show Label on Desktop */}
                        {/* {image.label = 'This is a test label for us.'} */}
                        <div className={classes.desktopLabelWrapper}>
                          <div className={classes.desktopLabel}>
                            {brandKey &&
                              productKey &&
                              renderProductLink({ brandKey, productKey })}
                            <div className={clsx(classes.labelText)}>
                              <HandleMarkup value={label} />
                            </div>
                          </div>
                        </div>
                        {/* Show Mobile Label Trigger on Mobile */}
                        <div className={classes.mobileLabelTrigger}>
                          <Button
                            onClick={() => setShowMobileLabel(!showMobileLabel)}
                          >
                            {!showMobileLabel ? (
                              <FormattedMessage {...messages.SHOW_CAPTION} />
                            ) : (
                              <FormattedMessage {...messages.HIDE_CAPTION} />
                            )}
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
        <div className={classes.footer}>
          <Carousel
            slideIndex={images.length > 4 ? slideIndex : undefined}
            className={classes.footerCarousel}
            withoutControls
            slidesToShow={5}
            style={framePadding}
            cellAlign={'left' as Alignment}
          >
            {images.map((image, index) => (
              <div key={index} className={classes.thumbnailWrapper}>
                <ButtonBase
                  className={clsx(
                    classes.thumbnailButton,
                    index === slideIndex && classes.active
                  )}
                  onClick={() => onImageClick(index)}
                >
                  <div
                    className={clsx(index !== slideIndex && classes.overlay)}
                  />
                  {image.videoUrl && (
                    <PlayArrowRounded className={classes.playButtonIcon} />
                  )}
                  <ResponsiveCatalogImage
                    key={index}
                    className={classes.thumbnail}
                    alt={image?.altText}
                    src={image.videoUrl ? thumbnailUrl : image?.largeUrl}
                    sizes={thumbImgSizes}
                    urls={[image?.smallUrl, image?.mediumUrl, image?.largeUrl]}
                  />
                </ButtonBase>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </ResponsiveModal>
  )
}

export default ProductImagesModal
