import {
  MaterialAvailability,
  MaterialAvailabilityKey,
} from '@src/types/graphql-types'
import { differenceInDays } from 'date-fns-next'

export const availableWithinFiveDays = (
  availability: MaterialAvailability
): boolean => {
  if (!availability?.date) {
    return false
  }

  const today = new Date()
  const availabilityDate = new Date(availability.date)
  return (
    availability.key === MaterialAvailabilityKey.AvailableToShipOn &&
    differenceInDays(availabilityDate, today) < 5
  )
}
