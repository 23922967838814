import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Grid, Button, Link as MuiLink } from '@material-ui/core'
import { substanceRoute, productDetailRoute, useRouter } from '@src/routes'
import { CatalogType } from '@src/types/graphql-types'
import {
  SubstanceFieldsFragment,
  SubstanceProductFieldsFragment,
} from '@src/queries/ProductSearchQuery.generated'
import messages from '@utils/messages'
import {
  SearchFocusType,
  ProductSearchType,
  determineQueryFocus,
  determineCatalogType,
  getValidSearchQuery,
} from '@utils/searchUtils'
import { Link } from '@src/components/Link'
import CatalogImage from '@src/components/CatalogImage'
import QuickViewSubstanceModal from '../QuickViewSubstanceModal'
import HandleMarkup from '../HandleMarkup'
import {
  SubstanceTitleSegment,
  SubstanceInfoSegment,
} from '../SubstanceDetailSegment'
import Box from '../Box'

const useStyles = makeStyles((theme: Theme) => {
  return {
    cardColor: {
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        background: theme.palette.background.grey,
        padding: 0,
      },
    },
    substanceNameWrapper: {
      paddingLeft: 30,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
        paddingLeft: 0,
        pointerEvents: 'none',
      },
    },
    substanceName: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: '1.43rem',
      wordBreak: 'break-all',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      [theme.breakpoints.down('xs')]: {
        color: theme.palette.common.black,
      },
    },
    link: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    button: {
      bottom: 5,
      fontSize: theme.typography.pxToRem(16),
      opacity: 0,
      position: 'absolute',
      transition: 'opacity .2s ease-in-out',
      width: '160px',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    imgContainer: {
      paddingTop: '0 !important',
    },
    imgButtonGroup: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imgWrapper: {
      height: theme.typography.pxToRem(185),
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        maxWidth: '85%',
        maxHeight: '85%',
        height: 'auto',
        width: 'auto',
      },
      [theme.breakpoints.down('md')]: {
        height: theme.typography.pxToRem(120),
        '& img': {
          maxWidth: '65%',
          maxHeight: '65%',
          border: `1px solid ${theme.palette.grey[600]}`,
        },
      },
    },
    substanceGridWrapper: {
      [theme.breakpoints.down('md')]: {
        paddingTop: 30,
      },
    },
    productDetails: {
      fontSize: theme.typography.pxToRem(12),
      minHeight: 60,
      paddingLeft: 30,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
      },
    },
    percentage: {
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.spacing(1),
    },
    truncate: {
      width: 140,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    similarity: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  }
})

export const substanceSimilarityPct = (
  products?: SubstanceProductFieldsFragment[]
) => {
  if (!products || !products.length) return null
  const firstProduct = products[0]
  if (products.length === 1) {
    return firstProduct.similarity
  } else {
    /**
     * if substance has more than 1 product, only display similarity pct on substance if all are the same
     * Otherwise we have conflicting values and cannot determine which to display to user (we can only display 1)
     */
    const restOfProducts = products.slice(1, products.length)
    const allTheSameSimilarity = restOfProducts.every(
      (product) => product.similarity === firstProduct.similarity
    )
    return allTheSameSimilarity ? firstProduct.similarity : null
  }
}
// optional override prop if focus is set by a no results product search first
interface SubstanceCardProps {
  colorIndex?: number
  catalogOverride?: CatalogType
  substance: SubstanceFieldsFragment
}

export const SubstanceCard: React.FC<SubstanceCardProps> = ({
  substance,
  catalogOverride = null,
  colorIndex = 0,
}) => {
  const classes = useStyles()
  const { casNumber, molecularWeight, linearFormula, name, id, images } =
    substance

  /*
    BE AWARE: Some substances only implement themselves and must be cast as a product. While other substances have multiple SKUs/variants and are passed by default with their own array of products.
    In an instance where a substance only has itself it must be cast as a single item array into a products array which is still iterated on in the return of renderSubstanceName.

    To elaborate, some substances will only have a single instance of themselves meaning they are only sold in one form and one quantity with no other "products" variations.
    An example of this would be something like distilled lab water sold in a 2L bottle and nothing else. In these instances the returned substance database query will have a empty/blank/undefined products property.
    So in order to make the products iterable and display on the page we have to cast that single item as itself on into the products array so it can then be interated like a substance with multiple variants.

    Fix as part of CORE-419
  */
  const products: any = substance.products
    ? [...substance.products]
    : [substance]
  substance.products = products

  const [modalOpen, setModalOpen] = useState(false)
  const router = useRouter()
  const routerCatalogType = router.query.catalogType
  const validSearchQuery = getValidSearchQuery({
    focus: router.query.focus,
    catalogType: determineCatalogType(routerCatalogType),
  })
  // check for catalogType in query first - WIBF-1498
  const catalogType =
    catalogOverride ??
    determineCatalogType(validSearchQuery.catalogType || validSearchQuery.focus)
  const renderSubstanceName = () => {
    const defaultName = (
      <title className={classes.substanceName}>
        <span title={name}>
          <HandleMarkup value={name} />
        </span>
      </title>
    )
    return products?.length === 1 ? (
      <Link
        {...productDetailRoute.index(
          products[0].brand.key,
          products[0].productKey,
          { catalog: catalogType }
        )}
        passHref
      >
        <MuiLink>{defaultName}</MuiLink>
      </Link>
    ) : (
      <Link {...substanceRoute.index(id, catalogType)} passHref>
        <MuiLink>{defaultName}</MuiLink>
      </Link>
    )
  }
  const substanceSimliarityValue =
    validSearchQuery.focus === SearchFocusType.StructureSearch
      ? substanceSimilarityPct(products)
      : null

  return (
    <Grid
      container
      alignItems="flex-start"
      data-testid="srp-substance-group-grid"
      id="srp-substance-group-grid"
      className={colorIndex % 2 === 0 ? classes.cardColor : ''}
    >
      <Grid item xs={4} sm={12} className={classes.imgContainer}>
        <div className={classes.imgButtonGroup}>
          <div className={classes.imgWrapper}>
            <CatalogImage
              image={images[0]}
              altTextFallback={name}
              width="270"
              height="185"
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setModalOpen(true)}
          >
            <FormattedMessage {...messages.QUICK_VIEW} />
          </Button>
        </div>
      </Grid>
      <Grid xs={8} sm={12} container className={classes.substanceGridWrapper}>
        <div className={classes.substanceNameWrapper}>
          {renderSubstanceName()}
        </div>

        <Grid container className={classes.productDetails}>
          <Grid container item direction="column" sm={12} spacing={1}>
            {casNumber ? (
              <Grid item>
                <Box display="flex" flex-direction="row">
                  <SubstanceTitleSegment message={messages.CAS_NUMBER_ABBREV} />
                  <SubstanceInfoSegment
                    searchFocusType={determineQueryFocus(
                      validSearchQuery.focus
                    )}
                    searchValue={casNumber}
                    productSearchType={ProductSearchType.CasNumber}
                    classes={classes.link}
                  />
                </Box>
              </Grid>
            ) : null}
            {molecularWeight ? (
              <Grid item>
                <Box display="flex" flex-direction="row">
                  <SubstanceTitleSegment
                    message={messages.MOLECULAR_WEIGHT_ABBREV}
                  />
                  <HandleMarkup
                    value={molecularWeight}
                    className={classes.truncate}
                  />
                </Box>
              </Grid>
            ) : null}
            {linearFormula ? (
              <Grid item>
                <Box display="flex" flex-direction="row">
                  <SubstanceTitleSegment
                    message={messages.MOLECULAR_FORMULA_ABBREV}
                  />
                  <HandleMarkup
                    value={linearFormula}
                    className={classes.truncate}
                  />
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {substanceSimliarityValue ? (
          <Grid container item sm={12} className={classes.similarity}>
            <>
              <Grid item>
                <span
                  className={classes.percentage}
                >{`${substanceSimliarityValue}% `}</span>
              </Grid>
              <Grid item>
                <FormattedMessage {...messages.SIMILARITY} />
              </Grid>
            </>
          </Grid>
        ) : null}
      </Grid>
      <QuickViewSubstanceModal
        catalogOverride={catalogType}
        catalogType={catalogType}
        open={modalOpen}
        setModalOpen={setModalOpen}
        substance={substance}
        substanceName={renderSubstanceName()}
      />
    </Grid>
  )
}

export default SubstanceCard
