import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import ProductPricingDetails from './ProductPricingDetails'
import ResponsiveModal, {
  ModalSizes,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import BuyBox from '../ProductInfo/BuyBox'
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import SelectableChips from '../ProductInfo/SelectableChips'
import { FormattedMessage } from 'react-intl'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { usePricingAndAvailabilityLazyQuery } from '@src/queries/PricingAndAvailabilityQuery.generated'
import { useMultiCountryPricingAndAvailabilityLazyQuery } from '@src/queries/MultiCountryPricingAndAvailabilityQuery.generated'
import { useUserSession } from '@src/utils/useUserSession'
import * as countriesMap from '@localization/countries.json'
import isbot from 'isbot'
import { SitePreference, useCurrentUser } from '@src/utils/useCurrentUser'
import ReplacementProductsCarouselModal from '@src/components/ReplacementProductsCarouselModal'
import { useGlobalModal } from '@src/utils/useGlobalModal'
import { PdpFieldsFragment, usePdpQuery } from '@src/queries/PDPQuery.generated'
import { extractData } from '@src/utils/errorHandler'
import SignInBanner from '@src/components/SignInBanner'
import ProductPaMessage from '@src/components/ProductPriceAvailability/ProductPaMessage'
import clsx from 'clsx'
import { CatalogType } from '@src/types/graphql-types'
import { determineCatalogType } from '@src/utils/searchUtils'
import ProductPartnerInfo from '@src/components/ProductPriceAvailability/ProductPartnerInfo'
import { useChinaUser } from '@src/utils/useChinaUser'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  chipContainer: {
    paddingLeft: theme.spacing(4),
  },
  paMessage: {
    paddingLeft: theme.spacing(10),
  },
  modalBody: {
    padding: theme.spacing(5, 10, 0, 5),
  },
  discontinuedBanner: {
    justifyContent: 'center',
  },
  tabs: {
    maxWidth: 'fit-content',
    backgroundColor: theme.palette.background.grey,
    marginBottom: theme.spacing(5),
    color: theme.palette.primary.main,
    '& .Mui-selected': {
      color: theme.palette.common.black,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.common.black,
      height: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(5),
      '& .MuiTabs-indicator': {
        height: theme.spacing(0.5),
      },
    },
  },
  tab: {
    fontSize: theme.typography.pxToRem(18),
    paddingBottom: 0,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(6),
    textTransform: 'capitalize',
    minWidth: 'auto',
    opacity: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      marginRight: 0,
    },
  },
  selectSize: {
    width: `fit-content`,
  },
}))

interface Props {
  onClose: () => void
  product: Partial<PdpFieldsFragment>
  focus: string
}

const ProductPricingModal: React.FC<Props> = ({
  onClose,
  product,
  focus,
}): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { setGlobalModalComponent, clearGlobalModalComponent } =
    useGlobalModal()
  const {
    userSession: { country },
  } = useUserSession()
  const isChinaUser = useChinaUser()
  const [tabValue, setTabValue] = useState(country)
  const [selectedMaterial, setSelectedMaterial] =
    useState<ValidMaterialPricingDetailFragment | null>(null)
  const ua = typeof window !== 'undefined' ? window.navigator.userAgent : ''
  const isBot = isbot(ua)
  const {
    isMultiCountryUser,
    isDTAQZuCustomer,
    isBlueErpIntegrationEnabled,
    getSitePreference,
    currentUser,
  } = useCurrentUser()
  const isMarketplace = product?.isMarketplace
  const erpType = product?.erp_type
  const passERPType =
    isBlueErpIntegrationEnabled &&
    product?.erp_type &&
    product?.erp_type?.length > 0
  const displaySDS = product?.productNumber?.startsWith('CB_')
  const orgId = getSitePreference(SitePreference.CatalogFilter)
  const [
    getPricingForProduct,
    {
      data: singleCountryPricing,
      loading: singleCountryLoading,
      error: singleCountryError,
    },
  ] = usePricingAndAvailabilityLazyQuery({
    fetchPolicy: isDTAQZuCustomer ? 'no-cache' : 'cache-first',
  })

  const [
    getPricingForMultiCountryProducts,
    {
      data: multiCountryPricing,
      loading: multiCountryLoading,
      error: multiCountryError,
    },
  ] = useMultiCountryPricingAndAvailabilityLazyQuery()

  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = usePdpQuery({
    variables: {
      brandKey: product?.brand?.key ?? '',
      productKey: product?.productKey ?? '',
      catalogType: product?.catalogId,
      orgId,
      isMarketplaceCatalogEnabled:
        currentUser?.metadata?.isMarketplaceCatalogEnabled,
    },
    fetchPolicy: isDTAQZuCustomer ? 'no-cache' : 'cache-first',
  })

  const pricingData = isMultiCountryUser
    ? multiCountryPricing?.getPricingForMultiCountryProducts?.find(
        (countryPricingData) => countryPricingData?.country === tabValue
      )
    : singleCountryPricing?.getPricingForProduct

  const { materialPricing, discontinuedPricingInfo } = pricingData ?? {}

  const productDetails = {
    ...product,
    forms: productData?.getProductDetail?.forms,
    type: selectedMaterial?.type,
  }

  const loading = singleCountryLoading || multiCountryLoading || productLoading
  const error = singleCountryError || multiCountryError || productError
  const errorObject = error ? extractData(error).errors[0] : undefined
  const errorKey = discontinuedPricingInfo?.errorMsg || errorObject?.code

  const getCountryDisplayName = useCallback(
    (country) => countriesMap?.[country]?.['displayName'],
    [countriesMap]
  )

  const handleSelect = (materialNumber: string) => {
    const selectedMaterial = materialPricing?.find(
      (product) => product.materialNumber === materialNumber
    )

    setSelectedMaterial(selectedMaterial ?? null)
  }

  const handleTabChange = (_, value) => {
    setTabValue(value)
  }

  const getProductPricing = () => {
    const queryVariables = {
      variables: {
        productNumber: product?.productNumber ?? '',
        materialIds: product?.materialIds,
        brand: product?.brand?.key,
        quantity: 1,
        catalogType: isMarketplace
          ? CatalogType.Marketplace
          : determineCatalogType(product?.catalogId),
        orgId,
        checkForPb: !isChinaUser,
        displaySDS,
        dealerId: '',
        checkBuyNow: true,
        productKey: product?.productKey,
        cachedPriceOnly: isBot,
        ...(passERPType && { erpType }),
      },
    }

    const updatedQueryVariables = {
      variables: {
        ...queryVariables.variables,
        cachedPriceOnly: isBot,
      },
    }

    isMultiCountryUser
      ? getPricingForMultiCountryProducts(queryVariables)
      : getPricingForProduct(updatedQueryVariables)
  }

  useEffect(() => {
    if (product?.productNumber && !product?.paMessage) {
      getProductPricing()
    }
  }, [isMultiCountryUser, product?.productNumber, product?.paMessage])

  useEffect(() => {
    if (multiCountryPricing?.getPricingForMultiCountryProducts?.length) {
      const [product] = multiCountryPricing?.getPricingForMultiCountryProducts
      product?.country && setTabValue(product?.country)
    }
  }, [multiCountryPricing])

  useEffect(() => {
    if (materialPricing?.length) {
      const [firstMaterial] = materialPricing
      setSelectedMaterial(firstMaterial)
    }

    if (errorKey && discontinuedPricingInfo) {
      setGlobalModalComponent(
        <ReplacementProductsCarouselModal
          errorKey={errorKey}
          product={product}
          replacementProducts={discontinuedPricingInfo?.replacementProducts}
          onClose={clearGlobalModalComponent}
        />
      )
    }
  }, [materialPricing, errorKey])

  return (
    <ResponsiveModal
      open
      onClose={() => {
        onClose()
        sendPricingAvailabilityInteractionEvent({
          action: 'close p&a',
          section: 'modal p&a',
          component: 'modal',
          elementType: 'icon',
          material: {
            materialDescription: product.name,
            brand: product.brand?.key,
            product: product.productNumber,
          },
        })
      }}
      size={ModalSizes.XLarge}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={product?.paMessage ? 12 : 8}
          className={classes.modalBody}
        >
          <ProductPricingDetails item={productDetails} />
          <Grid container>
            <Grid item xs={12} md={product?.paMessage ? 1 : 2} />
            <Grid
              item
              xs={12}
              md={10}
              className={clsx(classes.chipContainer, {
                [classes.paMessage]: product?.paMessage,
              })}
            >
              {!isMobile ? <SignInBanner mb={4} mr={4} /> : null}

              {isMultiCountryUser && multiCountryPricing ? (
                <Tabs
                  value={tabValue}
                  className={classes.tabs}
                  onChange={handleTabChange}
                >
                  {multiCountryPricing?.getPricingForMultiCountryProducts?.map(
                    (countryPricingData, i) => (
                      <Tab
                        key={i}
                        className={classes.tab}
                        label={getCountryDisplayName(
                          countryPricingData?.country
                        )}
                        value={countryPricingData?.country}
                      />
                    )
                  )}
                </Tabs>
              ) : null}

              {product?.paMessage ? (
                <ProductPaMessage
                  brandKey={product?.brand?.key ?? ''}
                  productNumber={product?.productNumber ?? ''}
                  paMessage={product?.paMessage ?? ''}
                  leftAlign
                />
              ) : (
                <>
                  {!error ? (
                    <>
                      <Typography className={classes.selectSize} variant="h3">
                        <FormattedMessage
                          id="SELECT_A_SIZE"
                          defaultMessage="Select a Size"
                        />
                      </Typography>
                    </>
                  ) : null}
                  <SelectableChips
                    items={
                      materialPricing?.map((product) => ({
                        packSize:
                          product?.packageSize?.trim() ||
                          product?.materialNumber,
                        value: product?.materialNumber,
                        price: product?.price ?? 0,
                        currency: product?.currency,
                        availabilities: product?.availabilities,
                        hidePriceMessageKey: product?.hidePriceMessageKey,
                      })) ?? []
                    }
                    selectedChip={selectedMaterial?.materialNumber ?? ''}
                    loading={loading}
                    loadCount={5}
                    onSelect={handleSelect}
                  />
                </>
              )}
              {isMarketplace ? (
                <Box display="flex" justifyContent="space-between">
                  <ProductPartnerInfo
                    isMarketplace={isMarketplace}
                    marketplaceSellerId={product?.marketplaceSellerId ?? ''}
                    marketplaceOfferId={product?.marketplaceOfferId ?? ''}
                    productAttributes={product?.attributes ?? []}
                    displaySellerName={product?.displaySellerName ?? ''}
                    hideMarketPlaceContactInfo
                  />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {!product?.paMessage ? (
          <Grid item xs={12} md={4}>
            <BuyBox
              countryCode={tabValue}
              error={error}
              isMultiCountryUser={isMultiCountryUser}
              isPricingModal
              loading={loading}
              material={selectedMaterial}
              product={productDetails}
              focus={focus}
            />
          </Grid>
        ) : null}
      </Grid>
    </ResponsiveModal>
  )
}

export default ProductPricingModal
