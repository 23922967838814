import { Facet } from '@src/types/graphql-types'
import React, { FC, useCallback } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import messages from '../messages'
import HandleMarkup from '@src/components/HandleMarkup'

const useStyles = makeStyles((theme: Theme) => ({
  facetMarkup: {
    marginRight: theme.spacing(1),
    '& sub, & sup': {
      verticalAlign: 'baseline',
      position: 'relative',
      top: '0.4em',
    },
    '& sup': {
      top: '-0.4em',
    },
  },
}))

interface Props {
  facetLabel: string
  facetKey: string
  facets: Facet[] | undefined
}

const ChipLabel: FC<Props> = ({ facetLabel, facetKey, facets }) => {
  const classes = useStyles()
  const intl = useIntl()

  const matchKeyToDisplayName = useCallback(
    (selectedFacetKey: string, facets: Facet[] | undefined) => {
      if (!facets) return ''
      const facetMatch = facets.find((facet) => facet.key === selectedFacetKey)
      return facetMatch?.key
        ? intl.formatMessage(messages[facetMatch.key.toUpperCase()])
        : ''
    },
    [intl]
  )

  const facetDisplayName = matchKeyToDisplayName(facetKey, facets)

  return (
    <>
      <HandleMarkup
        className={classes.facetMarkup}
        value={`${facetDisplayName}:`}
      />
      <HandleMarkup value={facetLabel} />
    </>
  )
}

export default ChipLabel
