import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link as MUILink } from '@material-ui/core'
import { homeRoute, loginRoute, useRouter } from '@src/routes'
import { messages as utilsMessages } from '@utils/messages'
import { Link } from '@src/components/Link'

interface HidePriceMessageProps {
  msgKey: string
}

const HidePriceMessage: React.FC<HidePriceMessageProps> = ({ msgKey }) => {
  const router = useRouter()
  if (msgKey === 'SIGN_IN_FOR_PRICING') {
    return (
      <Link
        href={`${loginRoute.index()}?redirect=${encodeURIComponent(
          router.asPath || homeRoute.index()
        )}`}
        passHref
      >
        <MUILink>
          <FormattedMessage {...utilsMessages[msgKey]} />
        </MUILink>
      </Link>
    )
  }
  return <FormattedMessage {...utilsMessages[msgKey]} />
}
export default HidePriceMessage
