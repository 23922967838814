import { Typography, Theme, Grid, Link as MuiLink } from '@material-ui/core'
import vrStyles from '@src/styles/utils/vrStyles'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import messages from '@utils/messages'
import { support } from '@src/routes'
import clsx from 'clsx'
import { Link } from '@src/components/Link'

export const useStyles = makeStyles((theme: Theme) => {
  const { vr4, vr6 } = vrStyles
  return {
    vr4,
    vr6,
    mediumBold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    boldWeight: {
      fontWeight: theme.typography.fontWeightBold,
    },
    diagramContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    purple: {
      color: theme.palette.secondary.main,
    },
    grey400: {
      color: theme.palette.grey[400],
    },
    value: {
      fontSize: theme.typography.pxToRem(40),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1,
      borderBottom: `4px solid`,
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(56),
      },
    },
    label: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
      textAlign: 'center',
    },
    quantitylabel: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
      color: theme.palette.common.black,
      textAlign: 'center',
    },
    examples: {
      marginTop: theme.spacing(6),
      marginRight: theme.spacing(4),
    },
    divider: {
      fontSize: theme.typography.pxToRem(40),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1,
      padding: theme.spacing(0, 2),
      color: theme.palette.grey[400],
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(72),
        lineHeight: 0.75,
      },
    },
    exampleContainer: {
      background: theme.palette.common.white,
      padding: theme.spacing(4, 6),
      ...vr4,
      width: '260px',
      [theme.breakpoints.up('md')]: {
        width: '320px',
      },
    },
    additionalExamplesContainer: {
      textAlign: 'center',
    },
    copyText: {
      fontSize: theme.typography.pxToRem(12),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  }
})

const eventLabelForTechServiceLink =
  'how to find the product number - technical service'

interface FindProductNumberProps {
  techServiceLinkClicked?: Function
}

const FindProductNumber: React.FC<FindProductNumberProps> = ({
  techServiceLinkClicked,
}) => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12} className={classes.vr6}>
        <Typography variant="body2" className={classes.copyText}>
          <FormattedMessage
            id="HOW_TO_ENTER_PRODUCT_NUMBER_EXAMPLE"
            defaultMessage="Product numbers are combined with Pack Sizes/Quantity when displayed on the website (example: T1503-25G). Please make sure you enter "
          />{' '}
          <span className={classes.mediumBold}>
            <FormattedMessage
              id="HOW_TO_ENTER_PRODUCT_NUMBER_INSTRUCTIONS_TEXT"
              defaultMessage="ONLY the product number in the Product Number field"
            />
          </span>
          {' ('}
          <span style={{ textTransform: 'lowercase' }}>
            <FormattedMessage
              id="EXAMPLE_PRODUCT_NUMBER"
              defaultMessage="example"
            />
          </span>
          {': '}
          <span>{' T1503).'}</span>
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.exampleContainer}>
        <Typography
          variant="body2"
          className={clsx(classes.vr4, classes.copyText)}
        >
          <FormattedMessage {...messages.EXAMPLE} />
        </Typography>
        <div className={clsx(classes.diagramContainer, classes.vr6)}>
          <div className={classes.purple}>
            <div className={classes.value}>T1503</div>
            <div className={classes.label}>
              <FormattedMessage {...messages.PRODUCT_NUMBER} />
            </div>
          </div>
          <div className={classes.divider}>-</div>
          <div className={classes.grey400}>
            <div className={classes.value}>25G</div>
            <div className={classes.quantitylabel}>
              <FormattedMessage {...messages.PACK_SIZE_QUANTITY} />
            </div>
          </div>
        </div>

        <Typography
          variant="body2"
          className={clsx(classes.vr4, classes.copyText)}
        >
          <FormattedMessage {...messages.ADDITIONAL_EXAMPLES} />
        </Typography>

        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          className={clsx(classes.additionalExamplesContainer)}
        >
          <Grid item xs={12} md={6} className={classes.vr4}>
            <Typography variant="body1" className={classes.boldWeight}>
              <span className={classes.purple}>705578</span>
              <span className={classes.grey400}>-5MG-PW</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.vr4}>
            <Typography variant="body1" className={classes.boldWeight}>
              <span className={classes.purple}>PL860-CGA/SHF</span>
              <span className={classes.grey400}>-1EA</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.vr4}>
            <Typography variant="body1" className={classes.boldWeight}>
              <span className={classes.purple}>MMYOMAG</span>
              <span className={classes.grey400}>-74K-13</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <span className={classes.boldWeight}>1000309185</span>
            </Typography>
            <Typography variant="body1">
              <FormattedMessage
                id="ENTER_AS_PRODUCT_NUMBER"
                defaultMessage="enter as"
              />{' '}
              <span className={clsx(classes.purple, classes.boldWeight)}>
                1.00030
              </span>
              <span className={clsx(classes.grey400, classes.boldWeight)}>
                9185
              </span>
              <span>)</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="body2" className={classes.copyText}>
        <FormattedMessage {...messages.HAVING_TROUBLE} />
        <Link href={support.customerSupport()} passHref>
          <MuiLink
            onClick={() =>
              techServiceLinkClicked
                ? techServiceLinkClicked(eventLabelForTechServiceLink, {
                    action:
                      'contact technical service - how to find the product number',
                    component: 'right rail',
                    elementType: 'link',
                    elementText: 'technical service',
                    linkUrl: support.customerSupport(),
                  })
                : null
            }
            href="passHref"
          >
            <FormattedMessage {...messages.TECHNICAL_SERVICE} />
          </MuiLink>
        </Link>{' '}
        <FormattedMessage {...messages.FOR_ASSISTANCE} />
      </Typography>
    </Grid>
  )
}

export default FindProductNumber
