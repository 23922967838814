import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import { ProductSearchGroup } from '@src/types/graphql-types'
import { SubstanceFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import {
  AliasType,
  determineQueryFocus,
  formatCasNumberSearchTerm,
  getValidSearchQuery,
  ProductSearchType,
  SearchFocusType,
} from '@utils/searchUtils'
import { buildProductAliasPayload } from '@src/utils/analytics/analyticsUtils'
import { GTMEventCategory } from '@sial/common-utils'
import messages from '../messages'
import HandleMarkup from '@src/components/HandleMarkup'
import { SubstanceInfoSegment } from '@src/components/SubstanceDetailSegment'
import { substanceSimilarityPct } from '@src/components/SubstanceCard/SubstanceCard'
import { useRouter } from '@src/routes'
import { sendProductInfoInteractionEvent } from '@src/utils/analytics'
import { SearchResultEventCategory } from '@utils/analytics/enums'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      alignItems: 'baseline',
      margin: 0,
      marginTop: theme.spacing(3),
      display: 'flex',
      flexWrap: 'wrap',
      gridRowGap: theme.spacing(1),
      '& > *': {
        margin: 0,
        flex: '0 0 16.66%',
      },
      '& > *:nth-child(4n)': {
        flexBasis: '50%',
      },
    },
    breakAll: { wordBreak: 'break-all' },
  }
})

interface ResponsiveSubstanceMetadataProps {
  substance: SubstanceFieldsFragment
}

const ResponsiveSubstanceMetadata: React.FC<
  ResponsiveSubstanceMetadataProps
> = ({ substance }) => {
  const { casNumber, molecularWeight, aliases, __typename } = substance
  const ecNumber = aliases.find((alias) => alias.key === AliasType.EcNumber)
  const beilsteinRegistryNumber = aliases.find((alias) =>
    alias.key.includes(AliasType.BeilsteinRegistryNumber)
  )

  const classes = useStyles()
  const router = useRouter()
  const validSearchQuery = getValidSearchQuery({
    focus: router.query.focus,
  })
  const substanceSimilarityValue =
    validSearchQuery.focus === SearchFocusType.StructureSearch
      ? substanceSimilarityPct(substance.products)
      : null

  const shouldDisplayField =
    __typename?.toLowerCase() === ProductSearchGroup.Substance

  return (
    <dl className={classes.root}>
      {casNumber && (
        <>
          <dt>
            <Typography component="div" variant="caption">
              <FormattedMessage {...messages.CAS_NO} />
              {': '}
            </Typography>
          </dt>
          <dd>
            <SubstanceInfoSegment
              substanceId={substance.id}
              label={casNumber}
              searchValue={formatCasNumberSearchTerm(casNumber)}
              searchFocusType={determineQueryFocus(validSearchQuery.focus)}
              productSearchType={ProductSearchType.CasNumber}
              classes={classes.breakAll}
              onClick={() =>
                sendProductInfoInteractionEvent(
                  buildProductAliasPayload({
                    action: 'cas number',
                    detail: casNumber,
                    focus: validSearchQuery.focus,
                  }),
                  {
                    eventCategory: GTMEventCategory.SearchResultPage,
                    eventAction: SearchResultEventCategory.CASNumber,
                    eventLabel: `${substance.id} > ${casNumber}`,
                    eventInteractionType: 0,
                  }
                )
              }
            />
          </dd>
        </>
      )}
      {shouldDisplayField && molecularWeight && (
        <>
          <dt>
            <Typography component="div" variant="caption">
              <FormattedMessage {...messages.MOLECULAR_WEIGHT} />
              {': '}
            </Typography>
          </dt>
          <dd>
            <HandleMarkup
              value={molecularWeight}
              className={classes.breakAll}
            />
          </dd>
        </>
      )}
      {ecNumber && (
        <>
          <dt>
            <Typography component="div" variant="caption">
              <FormattedMessage {...messages.EC_NO} />
              {': '}
            </Typography>
          </dt>
          <dd>
            <SubstanceInfoSegment
              substanceId={substance.id}
              searchValue={ecNumber.value}
              searchFocusType={determineQueryFocus(validSearchQuery.focus)}
              productSearchType={ProductSearchType.EgecNumber}
              classes={classes.breakAll}
              onClick={() =>
                sendProductInfoInteractionEvent(
                  buildProductAliasPayload({
                    action: 'ec number',
                    detail: ecNumber.value,
                    focus: validSearchQuery.focus,
                  }),
                  {
                    eventCategory: GTMEventCategory.SearchResultPage,
                    eventAction: SearchResultEventCategory.ECNumber,
                    eventLabel: `${substance.id} > ${ecNumber.value}`,
                    eventInteractionType: 0,
                  }
                )
              }
            />
          </dd>
        </>
      )}
      {beilsteinRegistryNumber && (
        <>
          <dt>
            <Typography component="div" variant="caption">
              <FormattedMessage {...messages.BEILSTEIN_NO} />
              {': '}
            </Typography>
          </dt>
          <dd>
            <div className={classes.breakAll}>
              {beilsteinRegistryNumber.value}
            </div>
          </dd>
        </>
      )}
      {substanceSimilarityValue && (
        <>
          <dt>
            <Typography component="div" variant="caption">
              <FormattedMessage {...messages.SIMILARITY} />
              {': '}
            </Typography>
          </dt>
          <dd>
            <span>{substanceSimilarityValue}%</span>
          </dd>
        </>
      )}
    </dl>
  )
}

export default ResponsiveSubstanceMetadata
