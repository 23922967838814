import React from 'react'
import { ApolloError } from 'apollo-boost'
import { useIntl } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FormControlLabel, RadioGroup } from '@material-ui/core'
import messages from '../messages'
import { useUserSession } from '@utils/useUserSession'
import { useSearchQuery } from '@src/pages/[country]/[language]/search/[searchTerm]'
import RadioButton from '@src/components/RadioButton'
import AvailableForSaleSkeleton from './AvailableForSaleSkeleton'
import FacetGroupCollapse from './FacetGroupCollapse'

interface AvailableForSaleProps {
  loading: boolean
  error?: ApolloError
}

const useStyles = makeStyles((theme: Theme) => ({
  availableForSale: {
    padding: theme.spacing(2, 0),
  },
  radio: {
    fontSize: theme.typography.pxToRem(14),
  },
  facetRadio: {
    padding: theme.spacing(1, 0.5),
    marginRight: theme.spacing(1.5),
    alignSelf: 'flex-start',
  },
  formBody: {
    marginTop: theme.spacing(2.5),
    marginLeft: theme.spacing(2),
  },
}))

const AvailableForSale: React.FC<AvailableForSaleProps> = ({
  loading,
  error,
}) => {
  const { formatMessage, formatDisplayName } = useIntl()
  const classes = useStyles()
  const { userSession } = useUserSession()
  const [query, , queryActions] = useSearchQuery()
  const GLOBAL_REGION = 'global'

  if (loading) return <AvailableForSaleSkeleton />
  if (error) return null
  return (
    <div className={classes.availableForSale}>
      <FacetGroupCollapse
        defaultCollapse
        facetKey={messages.AVAILABLE_FOR_SALE.id}
      >
        <form aria-label="region-input">
          <div className={classes.formBody}>
            <RadioGroup
              aria-label="region-selection"
              onChange={(e) => {
                queryActions.handleAvailableForSaleChange(e.target.value)
              }}
            >
              <FormControlLabel
                key={userSession.country}
                aria-label="region"
                value={userSession.country}
                control={<RadioButton className={classes.facetRadio} />}
                classes={{ label: classes.radio }}
                checked={!query.region || query.region === userSession.country}
                label={formatDisplayName(userSession.country, {
                  type: 'region',
                })}
              />
              <FormControlLabel
                key={GLOBAL_REGION}
                aria-label="region"
                value={GLOBAL_REGION}
                control={<RadioButton className={classes.facetRadio} />}
                classes={{ label: classes.radio }}
                checked={query.region === GLOBAL_REGION}
                label={formatMessage(messages.GLOBALLY)}
              />
            </RadioGroup>
          </div>
        </form>
      </FacetGroupCollapse>
    </div>
  )
}

export default AvailableForSale
