import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'
import LiquidCheckbox from '@src/components/LiquidCheckbox'
import messages from '../messages'
import { sendSearchFacetEvent } from '@utils/analytics'
import { Facet, FacetInput } from '@src/types/graphql-types'
import HandleMarkup from '@src/components/HandleMarkup'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import {
  buildFacetEventPayload,
  logExpandCollapseEvent,
} from '@utils/analytics/facets'
import { sendEvent } from '@utils/analytics/coreAnalytics'
import {
  useSearchQuery,
  FacetChangeAction,
} from '../../../pages/[country]/[language]/search/[searchTerm]'
import FacetGroupCollapse from './FacetGroupCollapse'
import FacetSearch from './FacetSearch'
import ShippingFacet from './ShippingFacet'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(6, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0),
      '&:first-child': {
        margin: theme.spacing(6, 0, 4),
      },
    },
  },
  showMore: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    margin: theme.spacing(3, 0, 1),
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  showMoreIcon: {
    marginLeft: theme.spacing(1.5),
  },
  checkboxIcon: {
    fontSize: theme.typography.pxToRem(24),
  },
  formControlLabel: {
    fontFamily: 'Lato',
    fontSize: theme.typography.pxToRem(14),
    marginTop: 2,
  },
  checkboxRoot: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  shippingIcon: {
    display: 'inline-flex',
    fontSize: theme.typography.pxToRem(28),
    marginLeft: theme.spacing(2),
  },
  facetLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  collapseInner: {
    paddingTop: theme.spacing(2),
  },
}))

// Search enabled facets are driven by business rules
// List is defined in PDP-529
enum SearchableFacets {
  facet_product_category,
  facet_web_special_grade,
  facet_physical_form,
}

interface FacetGroupProps {
  facet: Facet
  allFacets?: Facet[]
  showMore?: boolean
  substanceFacetChange?: (
    newSelectedFacets: FacetInput,
    optionPreviouslyChecked: boolean
  ) => void
  selectedSubstanceFacetOptions?: string[]
}

export type FacetChangeHandlerArguments = (
  groupKey: string,
  value: string,
  checked: boolean,
  count: number | undefined,
  filterElement: string
) => void

const FacetGroup: React.FC<FacetGroupProps> = ({
  facet,
  allFacets,
  showMore,
  substanceFacetChange,
  selectedSubstanceFacetOptions,
}) => {
  const classes = useStyles()
  const [query, selectedFacets, queryActions] = useSearchQuery()
  const selectedOptions: string[] = selectedSubstanceFacetOptions
    ? selectedSubstanceFacetOptions
    : (
        selectedFacets.find(({ key, options }) => {
          if (facet.prefix) options[0] = options[0].replace(facet.prefix, '')
          return key === facet.key
        }) || { options: [] }
      ).options
  const [showAll, setShowAll] = useState(false)
  const visibleFacets =
    showMore || showAll
      ? facet.options
      : facet.options.slice(0, facet.numToDisplay)

  if (showAll && visibleFacets.length === facet.numToDisplay) {
    setShowAll(false)
  }

  const showAllElementText = !showAll ? 'show more' : 'show less'
  const isShippingFacet = facet.key === 'facet_shipping'

  const handleFacetChange: FacetChangeHandlerArguments = (
    groupKey,
    value,
    checked,
    count,
    filterElement
  ) => {
    if (substanceFacetChange) {
      substanceFacetChange(
        {
          key: facet.key,
          options: facet.prefix ? [`${facet.prefix}${value}`] : [value],
        },
        checked
      )
    } else {
      queryActions.handleSelectedFacetChange({
        key: facet.key,
        options: [value],
        action: checked ? FacetChangeAction.Remove : FacetChangeAction.Add,
        multiSelect: facet.multiSelect,
        prefix: facet.prefix,
      })

      // Legacy UA event... Should be deleted once greenlit by Analytics team.
      sendSearchFacetEvent(
        query.term || '',
        facet.key,
        value,
        !checked,
        selectedFacets,
        allFacets
      )
    }

    const filterAction = `${checked ? 'remove' : 'add'} filter`

    const facetPayload = buildFacetEventPayload({
      action: filterAction,
      component: 'left rail',
      element_type: filterElement,
      element_text: `${value} (${count})`,
      filter_name: value,
      filter_name_count: count,
      filter_category: groupKey,
    })

    sendEvent({ payload: facetPayload })
  }

  const selectedOptionsKeys = selectedOptions.map(
    (selectedOption) => selectedOption.split('|')[0]
  )

  const collapseFacetGroup = substanceFacetChange
    ? selectedOptionsKeys.includes(`${facet.key}`)
    : selectedOptions.length > 0

  const resolvedColpaseStatus = !facet.isCollapsed || collapseFacetGroup

  return (
    <div key={facet.key} className={classes.root}>
      <FacetGroupCollapse
        defaultCollapse={resolvedColpaseStatus}
        facetKey={facet.key}
        isSubstanceFacets={substanceFacetChange ? true : false}
      >
        {facet.key in SearchableFacets && (
          <FacetSearch
            facet={facet}
            selectedOptions={selectedOptions}
            handleFacetChange={handleFacetChange}
          />
        )}
        <div className={classes.collapseInner}>
          {visibleFacets.map((option) => {
            const { value, count } = option
            const checked =
              selectedOptions.indexOf(value) >= 0 ||
              selectedOptions.indexOf(`${facet.key}|${value}`) >= 0 ||
              selectedOptions.indexOf(`${facet.key}|${facet.prefix}${value}`) >=
                0
            return (
              <div key={value}>
                <LiquidCheckbox
                  aria-label={value}
                  data-testid={`srp-facet-${value}`}
                  value={value}
                  checked={checked}
                  classes={{
                    root: classes.checkboxRoot,
                  }}
                  formControlClasses={{
                    label: classes.formControlLabel,
                  }}
                  onChange={() =>
                    handleFacetChange(
                      facet.key,
                      value,
                      checked,
                      count,
                      'checkbox'
                    )
                  }
                  label={
                    <span className={classes.facetLabel}>
                      {isShippingFacet ? (
                        <ShippingFacet
                          value={option.value}
                          count={option.count}
                        />
                      ) : (
                        <HandleMarkup
                          value={`${option.value} (${option.count})`}
                        />
                      )}
                    </span>
                  }
                />
              </div>
            )
          })}
          {(facet.options.length > visibleFacets.length || showAll) && (
            <ButtonBase
              onClick={() => {
                setShowAll(!showAll)
                logExpandCollapseEvent(
                  showAllElementText,
                  facet.key,
                  showAllElementText
                )
              }}
              aria-expanded={showAll}
              aria-label={showAllElementText}
              className={classes.showMore}
              id={
                showAll
                  ? `facet-group-${facet.key}-show-less`
                  : `facet-group-${facet.key}-show-more`
              }
            >
              {showAll ? (
                <div>
                  <FormattedMessage {...messages.SHOW_LESS} />
                  <ExpandLess className={classes.showMoreIcon} />
                </div>
              ) : (
                <div>
                  <FormattedMessage {...messages.SHOW_MORE} />
                  <ExpandMore className={classes.showMoreIcon} />
                </div>
              )}
            </ButtonBase>
          )}
        </div>
      </FacetGroupCollapse>
    </div>
  )
}

export default FacetGroup
