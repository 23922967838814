import React, { ReactNode, CSSProperties, useMemo } from 'react'
import clsx from 'clsx'
import { FieldProps, getIn } from 'formik'
import LiquidMaterialAutoSuggest from '../LiquidMaterialAutoSuggest'
import { LiquidInputProps } from '../LiquidInput/LiquidInput'
import LiquidFormLabel from '../LiquidFormLabel'
import LiquidFormHelpText from '../LiquidFormHelpText'
import { SuggestionFragment } from '@src/queries/SearchSuggestionsQuery.generated'

interface LiquidInputAdapterProps
  extends FieldProps,
    Omit<LiquidInputProps, 'onChange' | 'onSelect'> {
  rootClass?: string
  rootStyle?: CSSProperties
  label?: ReactNode
  required?: boolean
  help?: ReactNode
  onSelect?: (suggestion: SuggestionFragment | null) => void
}

const LiquidMaterialAutoSuggestAdapter: React.FC<LiquidInputAdapterProps> = ({
  rootClass,
  rootStyle,
  size = 'medium',
  field,
  form,
  label,
  required,
  help,
  onBlur,
  onSelect,
  ...otherProps
}) => {
  const error = useMemo(
    () =>
      getIn(form.errors, field.name) ||
      (form.status && form.status[field.name]),
    [form.errors, form.status, field.name]
  )

  const touched = useMemo(
    () => getIn(form.touched, field.name),
    [form.touched, field.name]
  )
  const hasError = error && touched

  return (
    <div
      className={clsx({
        [String(rootClass)]: rootClass,
      })}
      style={rootStyle}
    >
      {label && (
        <LiquidFormLabel htmlFor={field.name} size={size} required={required}>
          {label}
        </LiquidFormLabel>
      )}
      <LiquidMaterialAutoSuggest
        id={field.name}
        {...field}
        onChange={(value) => {
          form.setFieldValue(field.name, value)
        }}
        onBlur={(e) => {
          field.onBlur(e)
          if (onBlur) onBlur(e)
        }}
        onSelect={onSelect}
        size={size}
        error={Boolean(hasError)}
        aria-describedby={(hasError || help) && `${field.name}-help-text`}
        {...otherProps}
      />
      {(hasError || help) && (
        <LiquidFormHelpText
          id={`${field.name}-help-text`}
          error={Boolean(hasError)}
        >
          {hasError ? error : help ? help : null}
        </LiquidFormHelpText>
      )}
    </div>
  )
}

export default LiquidMaterialAutoSuggestAdapter
