// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ReadyToShipNotificationMutationVariables = Types.Exact<{
  params: Types.ReadyToShipNotificationInput;
}>;


export type ReadyToShipNotificationMutation = { __typename?: 'Mutation', readyToShipNotification: { __typename?: 'ReadyToShipNotificationResponse', brand?: string | null } };


export const ReadyToShipNotificationDocument = gql`
    mutation ReadyToShipNotification($params: ReadyToShipNotificationInput!) {
  readyToShipNotification(params: $params) {
    brand
  }
}
    `;
export type ReadyToShipNotificationMutationFn = ApolloReactCommon.MutationFunction<ReadyToShipNotificationMutation, ReadyToShipNotificationMutationVariables>;
export type ReadyToShipNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReadyToShipNotificationMutation, ReadyToShipNotificationMutationVariables>, 'mutation'>;

    export const ReadyToShipNotificationComponent = (props: ReadyToShipNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<ReadyToShipNotificationMutation, ReadyToShipNotificationMutationVariables> mutation={ReadyToShipNotificationDocument} {...props} />
    );
    

/**
 * __useReadyToShipNotificationMutation__
 *
 * To run a mutation, you first call `useReadyToShipNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadyToShipNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readyToShipNotificationMutation, { data, loading, error }] = useReadyToShipNotificationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useReadyToShipNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadyToShipNotificationMutation, ReadyToShipNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ReadyToShipNotificationMutation, ReadyToShipNotificationMutationVariables>(ReadyToShipNotificationDocument, options);
      }
export type ReadyToShipNotificationMutationHookResult = ReturnType<typeof useReadyToShipNotificationMutation>;
export type ReadyToShipNotificationMutationResult = ApolloReactCommon.MutationResult<ReadyToShipNotificationMutation>;
export type ReadyToShipNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadyToShipNotificationMutation, ReadyToShipNotificationMutationVariables>;