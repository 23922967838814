import React, { useState, useEffect, useCallback } from 'react'
import { MultiCountryPricingAndAvailabilityQuery } from '@src/queries/MultiCountryPricingAndAvailabilityQuery.generated'
import CommonTabs, { CommonTab } from '@src/components/Common/CommonTabs'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import * as countriesMap from '@localization/countries.json'
import { stringify } from 'qs'

const useStyles = makeStyles((theme: Theme) => ({
  multiCountryPricingTabs: {
    marginBottom: theme.spacing(5),
  },
}))

export function useMultiCountryPricingTabs(
  multiCountryPricing: MultiCountryPricingAndAvailabilityQuery | undefined,
  handleTabChange: (_: any, value: string) => void
) {
  const [tabs, setTabs] = useState<CommonTab[]>([])
  const classes = useStyles()

  const getCountryDisplayName = useCallback(
    (country) => countriesMap?.[country]?.['displayName'],
    [countriesMap]
  )

  useEffect(() => {
    const newTabs =
      multiCountryPricing?.getPricingForMultiCountryProducts?.map(
        (countryPricingData, index) => ({
          label: getCountryDisplayName(countryPricingData?.country),
          selectCallback: () =>
            handleTabChange(
              '',
              countryPricingData?.country || stringify(index)
            ),
          component: <div></div>,
        })
      ) || []

    setTabs(newTabs)
  }, [multiCountryPricing])

  return (
    <div className={classes.multiCountryPricingTabs}>
      <CommonTabs tabs={tabs} />
    </div>
  )
}
