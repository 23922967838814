import { makeStyles } from '@material-ui/core/styles'

export const useWalkMeAlertStyles = makeStyles((theme) => ({
  alertRoot: {
    margin: theme.spacing(0, 4, 6, 4),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 0, 6, 0),
    },
  },
  alertHeading: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  alertDescription: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.2,
    },
  },
  alertActions: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  link: {
    display: 'block',
    marginTop: theme.spacing(4),
  },
  largeLink: {
    fontSize: theme.typography.pxToRem(16),
  },
}))
