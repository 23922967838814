// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PreviouslyPurchasedProductQueryVariables = Types.Exact<{
  productNumber: Types.Scalars['String'];
}>;


export type PreviouslyPurchasedProductQuery = { __typename?: 'Query', recentOrderItems: { __typename?: 'RecentOrderItemsPayload', count: number, orderItems: Array<{ __typename?: 'RecentOrderItem', orderId: string, orderDate: number, product: string }> } };


export const PreviouslyPurchasedProductDocument = gql`
    query PreviouslyPurchasedProduct($productNumber: String!) {
  recentOrderItems(input: {page: 1, perpage: 1, product: $productNumber, sortcolumn: "orderDate", orderby: "desc"}) {
    count
    orderItems {
      orderId
      orderDate
      product
    }
  }
}
    `;
export type PreviouslyPurchasedProductComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PreviouslyPurchasedProductQuery, PreviouslyPurchasedProductQueryVariables>, 'query'> & ({ variables: PreviouslyPurchasedProductQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PreviouslyPurchasedProductComponent = (props: PreviouslyPurchasedProductComponentProps) => (
      <ApolloReactComponents.Query<PreviouslyPurchasedProductQuery, PreviouslyPurchasedProductQueryVariables> query={PreviouslyPurchasedProductDocument} {...props} />
    );
    

/**
 * __usePreviouslyPurchasedProductQuery__
 *
 * To run a query within a React component, call `usePreviouslyPurchasedProductQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviouslyPurchasedProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviouslyPurchasedProductQuery({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *   },
 * });
 */
export function usePreviouslyPurchasedProductQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PreviouslyPurchasedProductQuery, PreviouslyPurchasedProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PreviouslyPurchasedProductQuery, PreviouslyPurchasedProductQueryVariables>(PreviouslyPurchasedProductDocument, options);
      }
export function usePreviouslyPurchasedProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreviouslyPurchasedProductQuery, PreviouslyPurchasedProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PreviouslyPurchasedProductQuery, PreviouslyPurchasedProductQueryVariables>(PreviouslyPurchasedProductDocument, options);
        }
export type PreviouslyPurchasedProductQueryHookResult = ReturnType<typeof usePreviouslyPurchasedProductQuery>;
export type PreviouslyPurchasedProductLazyQueryHookResult = ReturnType<typeof usePreviouslyPurchasedProductLazyQuery>;
export type PreviouslyPurchasedProductQueryResult = ApolloReactCommon.QueryResult<PreviouslyPurchasedProductQuery, PreviouslyPurchasedProductQueryVariables>;