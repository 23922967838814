import React, { FC } from 'react'
import { Facet } from '@src/types/graphql-types'
import messages from '@utils/messages'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  useSearchQuery,
  FacetChangeAction,
} from '@src/pages/[country]/[language]/search/[searchTerm]'
import { flatMap } from 'lodash'
import { sendSearchFacetEvent } from '@src/utils/analytics'
import ChipLabel from './ChipLabel'
import vrStyles from '@src/styles/utils/vrStyles'
import { Typography } from '@material-ui/core'
import SelectionChip from '@src/components/SelectionChip'

const { vr4, vr8 } = vrStyles
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...vr8,
  },
  head: {
    ...vr4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  clearBtn: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  searchTerm: {
    //   textTransform: 'capitalize',
    marginRight: theme.spacing(1),
  },
}))

interface Props {
  facets?: Facet[]
  isStructureSearch: boolean
}

const AppliedFilters: FC<Props> = ({ facets, isStructureSearch }) => {
  const [query, selectedFacets, queryActions] = useSearchQuery()
  const mappedSelectedFacets = flatMap(selectedFacets, (facet) =>
    facet.options.map((option) => ({
      key: facet.key,
      label: option,
    }))
  )
  const { formatMessage } = useIntl()
  const classes = useStyles()

  // See Clear All CTA for update
  // const handleClearAll = () => {
  //   sendRemoveSearchTermEvent(query.term || '', selectedFacets, facets)
  //   router.push(searchResultsRoute.index())
  // }
  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <Typography variant="caption">
          <FormattedMessage
            id="APPLIED_FILTERS_HEADING"
            defaultMessage="Applied Filters:"
          />
        </Typography>
        {/* Clear All CTA */}
        {/*
        TODO: Currently this CTA clears the search tearm and
        redirects the user to the Advanced search page. Ideally this
        would clear only the applied filters and not the search tearm
        for both desktop and mobile experience.
        */}
        {/*
        <ButtonBase className={classes.clearBtn} onClick={handleClearAll}>
          <FormattedMessage {...messages.CLEAR_ALL} />
        </ButtonBase>
        */}
      </div>
      <div className={classes.body}>
        {query.term && !isStructureSearch && query.term !== 'facet-search' && (
          <SelectionChip
            ariaLabel={`${formatMessage(
              messages.SELECTION_CHIP_REMOVE_FILTER_1
            )} ${query.term} ${formatMessage(
              messages.SELECTION_CHIP_REMOVE_TO_PERFORM_FACET_SEARCH
            )}`}
            label={
              <>
                <span className={classes.searchTerm}>
                  {formatMessage(messages.KEYWORD)}:
                </span>
                <span>{`'${query.term}'`}</span>
              </>
            }
            dataTestId={'query-term-chip'}
            onDelete={() =>
              queryActions.handleTermRemoval(selectedFacets, facets)
            }
          />
        )}
        {facets &&
          mappedSelectedFacets.map((facet) => (
            <SelectionChip
              key={facet.key}
              ariaLabel={`${formatMessage(
                messages.SELECTION_CHIP_REMOVE_FILTER_1
              )} ${facet.label} ${formatMessage(
                messages.SELECTION_CHIP_REMOVE_FILTER_2
              )}`}
              onDelete={() => {
                queryActions.handleSelectedFacetChange({
                  key: facet.key,
                  options: [facet.label],
                  action: FacetChangeAction.Remove,
                })
                sendSearchFacetEvent(
                  query.term || '',
                  facet.key,
                  facet.label,
                  false,
                  selectedFacets,
                  facets
                )
              }}
              label={
                <ChipLabel
                  facetLabel={facet.label}
                  facetKey={facet.key}
                  facets={facets}
                />
              }
            />
          ))}
      </div>
    </div>
  )
}

export default AppliedFilters
