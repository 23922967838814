import React, { useMemo } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import LiquidSelect from '@src/components/LiquidSelect'
import { useSearchQuery } from '../../../pages/[country]/[language]/search/[searchTerm]'
import { Sort } from '@src/types/graphql-types'
import Box from '@material-ui/core/Box'
import { EventValues } from '@sial/common-utils'
import { sendSRPActionEvent } from '@src/utils/analytics'
import { useIntl } from 'react-intl'
import { searchSortOptions } from '../config'

const SearchResultsSortBar = () => {
  const [query, , queryActions] = useSearchQuery()
  const { formatMessage } = useIntl()

  const sortOptions = useMemo(
    () =>
      searchSortOptions[query.focus].map((option) => ({
        ...option,
        label: formatMessage(option.label),
      })),
    [formatMessage, query.focus]
  )

  return (
    <Box minWidth={204} clone>
      <LiquidSelect
        fullWidth={false}
        size="small"
        value={query.sort}
        onChange={(e) => {
          const selectedLabel =
            (
              (sortOptions.find((o) => o.value === e.target.value) || {})
                .label || ''
            )?.toLowerCase() || e.target.value
          sendSRPActionEvent(EventValues.Sort, selectedLabel)
          queryActions.handleSortChange(e.target.value as Sort)
        }}
        id="srp-sort-options"
      >
        {sortOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value} id={`sort-option-${value}`}>
            {label}
          </MenuItem>
        ))}
      </LiquidSelect>
    </Box>
  )
}

export default SearchResultsSortBar
