import { SearchFocusType } from '@src/utils/searchUtils'
import { Sort } from '@src/types/graphql-types'
import { messages, Message } from './messages'

interface SearchSortOption {
  value: Sort
  label: Message
}

const relevanceOnly: SearchSortOption[] = [
  {
    value: Sort.Relevance,
    label: messages.SORT_BY_RELEVANCE,
  },
]

const productSortOptions: SearchSortOption[] = [
  {
    value: Sort.Relevance,
    label: messages.SORT_BY_RELEVANCE,
  },
  {
    value: Sort.Nameasc,
    label: messages.SORT_BY_NAME_ASCENDING,
  },
  {
    value: Sort.Namedesc,
    label: messages.SORT_BY_NAME_DESCENDING,
  },
]

const buildingBlocksStructureSearchSortOptions = [
  {
    value: Sort.Relevance,
    label: messages.SORT_BY_RELEVANCE,
  },
  {
    value: Sort.Molwtasc,
    label: messages.SORT_BY_MOL_WT_ASC,
  },
  {
    value: Sort.Molwtdesc,
    label: messages.SORT_BY_MOL_WT_DESC,
  },
]

const geneSortOptions: SearchSortOption[] = [
  {
    value: Sort.Relevance,
    label: messages.SORT_BY_RELEVANCE,
  },
  {
    value: Sort.Genenameasc,
    label: messages.SORT_BY_NAME_ASCENDING,
  },
  {
    value: Sort.Genenamedesc,
    label: messages.SORT_BY_NAME_DESCENDING,
  },
]

export const searchSortOptions: Record<SearchFocusType, SearchSortOption[]> = {
  [SearchFocusType.Products]: productSortOptions,
  [SearchFocusType.Genes]: geneSortOptions,
  [SearchFocusType.Papers]: relevanceOnly,
  [SearchFocusType.TechnicalDocuments]: relevanceOnly,
  [SearchFocusType.SiteContent]: relevanceOnly,
  [SearchFocusType.BuildingBlocks]: buildingBlocksStructureSearchSortOptions,
  [SearchFocusType.StructureSearch]: buildingBlocksStructureSearchSortOptions,
  [SearchFocusType.B2B]: buildingBlocksStructureSearchSortOptions,
  [SearchFocusType.Chromatograms]: productSortOptions,
}
