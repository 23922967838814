import React from 'react'
import { useIntl } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import messages from '../messages'
import { isTermlessSearch } from '@utils/termlessSearch'
import { useRouter } from '@src/routes'
import { getSearchTypeMessage } from '@src/utils/searchUtils'

const useStyles = makeStyles((theme: Theme) => ({
  count: {
    display: 'block',
    marginRight: 'auto',
    marginLeft: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.up('md')]: {
      display: 'inline',
      marginLeft: 0,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  searchTerm: {
    display: 'inline',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 900,
  },
}))

interface SearchResultsCountProps {
  currPage: number
  perPage: number
  searchType: string
  searchTerm?: string
  resultCount?: number
  className?: string
}

export const resultDisplayRange = (currPage, perPage, resultCount) => {
  // if on first result page, start will be 1, else count will be prev page ending +1
  // ex page 3, start will be 61: (3 - 1 = 2 * 30[perPage]) = 60 + 1 = 61
  const resCountFirst = currPage > 1 ? (currPage - 1) * perPage + 1 : 1

  // if on last page, show resultCount, otherwise end is product of currPage * perPage
  // ex page 3, end will be 90: 3 * 30, BUT result count is only 87, so display 87 instead
  const calcResCountEnd = currPage * perPage
  const resCountEnd =
    resultCount && calcResCountEnd < resultCount ? calcResCountEnd : resultCount

  return {
    resCountFirst,
    resCountEnd,
  }
}

const SearchResultsCount: React.FC<SearchResultsCountProps> = ({
  currPage, // ex 3
  perPage, // ex 30
  resultCount = 0, // ex 87,
  searchTerm = '',
  searchType = '',
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { resCountFirst, resCountEnd } = resultDisplayRange(
    currPage,
    perPage,
    resultCount
  )
  const router = useRouter()
  const trimmedOrgId = router.query.orgId?.trim()
  const formattedOrgId =
    trimmedOrgId === 'UCB' ? trimmedOrgId : trimmedOrgId?.toLowerCase()
  const showingText = formatMessage(messages.SHOWING)
  // handle single result text
  const resultText =
    resultCount === 1
      ? formatMessage(messages.RESULT)
      : formatMessage(messages.RESULTS)
  const ofText = formatMessage(messages.SEARCH_RESULT_OF)
  const forText = formatMessage(messages.FOR)
  const withinText = formatMessage(messages.WITHIN)
  const searchTypeText = formatMessage(getSearchTypeMessage(searchType))

  return (
    <div className={classes.count} data-testid="srp-result-count">
      {!!resultCount ? (
        <>
          {`${showingText} ${resCountFirst}-${resCountEnd} ${ofText} ${resultCount} ${resultText} `}

          {isTermlessSearch(router) ? null : (
            <>
              {forText} {'"'}
              {searchTerm}
              {'"'}{' '}
              {formattedOrgId
                ? searchTypeText &&
                  `${withinText} ${formattedOrgId} ${searchTypeText}`
                : searchTypeText && `${withinText} ${searchTypeText}`}
            </>
          )}
        </>
      ) : null}
    </div>
  )
}

export default SearchResultsCount
