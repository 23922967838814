import React, { FC } from 'react'
import { Button, ButtonBase } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AddToListDialog from '../AddToListDialog'
import { useGetSharedListsQuery } from '@src/queries/SharedListQueries.generated'
import { useCurrentUser } from '@utils/useCurrentUser'
import messages from '@utils/messages'
import { FormattedMessage } from 'react-intl'
import { CartItemType } from '@src/types/graphql-types'
import clsx from 'clsx'

interface AddToListButtonProps {
  materialNumber: string
  materialId?: string | null
  productName?: string
  productId?: string
  brand?: string
  quantity: number
  trigger?: (onTriggerClick: () => void) => React.ReactNode
  disabled?: boolean
  type?: CartItemType | null
  isLinkButton?: boolean
  classes?: {
    linkStyle?: string
  }
}

const AddToListButton: FC<AddToListButtonProps> = ({
  materialNumber,
  materialId,
  productName,
  productId,
  brand,
  quantity,
  trigger,
  disabled,
  type,
  isLinkButton,
  classes,
}) => {
  const [listsModalOpen, setListsModalOpen] = React.useState(false)
  const { userIsLoggedIn, userId } = useCurrentUser()

  const { data, refetch: refetchLists } = useGetSharedListsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !userIsLoggedIn,
    variables: {
      input: {
        perpage: 100, //HACK: Avoiding paginated results as not all are returned.
        sortcolumn: 'lastUpdatedDate',
      },
    },
  })

  if (!userIsLoggedIn || !data?.getSharedLists) return null

  const openAddToListDialog = () => {
    setListsModalOpen(true)
  }

  const onAddToListDialogClose = async () => {
    setListsModalOpen(false)
  }

  return (
    <>
      {trigger ? (
        trigger(openAddToListDialog)
      ) : isLinkButton ? (
        <ButtonBase
          className={clsx(classes?.linkStyle)}
          disabled={disabled}
          onClick={openAddToListDialog}
        >
          <AddIcon />
          <FormattedMessage {...messages.ADD_TO_LIST} />
        </ButtonBase>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          disabled={disabled}
          onClick={openAddToListDialog}
        >
          <FormattedMessage {...messages.ADD_TO_LIST} />
        </Button>
      )}
      {listsModalOpen && (
        <AddToListDialog
          open={listsModalOpen}
          onClose={onAddToListDialogClose}
          materialNumber={materialNumber}
          materialId={materialId}
          productName={productName || ''}
          productId={productId || ''}
          brand={brand || ''}
          type={type}
          quantity={quantity}
          lists={data.getSharedLists.sharedLists}
          refetchLists={refetchLists}
          userId={userId}
        />
      )}
    </>
  )
}

export default AddToListButton
