import React from 'react'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import MobileProductPriceAvailability from './MobileProductPriceAvailability'

interface Props {
  materialPricing: ValidMaterialPricingDetailFragment[]
  country?: string
  productNumber: string
  canAddToCart: boolean
  anonymousUser: boolean
  marketplaceOfferId?: string
}

const MobileProductPriceAvailabilityForm: React.FC<Props> = ({
  materialPricing,
  country,
  productNumber,
  canAddToCart,
  anonymousUser,
  marketplaceOfferId,
}) => {
  if (!materialPricing?.length) {
    return null
  }
  return (
    <MobileProductPriceAvailability
      materialPricing={materialPricing}
      country={country}
      productNumber={productNumber}
      canAddToCart={canAddToCart}
      anonymousUser={anonymousUser}
      marketplaceOfferId={marketplaceOfferId}
    />
  )
}

export default MobileProductPriceAvailabilityForm
