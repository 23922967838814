import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import SearchResultsSkeletonSegment from '../SearchResultsSkeletonSegment'

const GridSkeleton = () => {
  const theme = useTheme()

  return (
    <>
      <Box mb={12} />
      <Grid container spacing={4}>
        {Array(12)
          .fill({})
          .map((_, index) => (
            <Grid key={index} item xs={6} sm={4}>
              <Box
                key={index}
                border={1}
                borderColor={theme.palette.grey['50']}
                mb={{ xs: 3, sm: 6 }}
                p={{ xs: 2, sm: 4 }}
              >
                <Grid container direction="column" spacing={2}>
                  <Grid item sm={12}>
                    <SearchResultsSkeletonSegment height={200} width={1} />
                  </Grid>
                  <Grid item sm={12}>
                    <SearchResultsSkeletonSegment
                      height={18}
                      width={1}
                      borderRadius={{ xs: 2, sm: 'borderRadius' }}
                      mb={{ sm: 2 }}
                    />
                  </Grid>
                  <Grid item sm={7}>
                    <SearchResultsSkeletonSegment
                      height={18}
                      width={1}
                      borderRadius={{ xs: 2, sm: 'borderRadius' }}
                    />
                  </Grid>
                  <Grid item sm={7}>
                    <SearchResultsSkeletonSegment
                      height={18}
                      width={1}
                      borderRadius={{ xs: 2, sm: 'borderRadius' }}
                    />
                  </Grid>
                  <Grid item sm={7}>
                    <SearchResultsSkeletonSegment
                      height={18}
                      width={1}
                      borderRadius={{ xs: 2, sm: 'borderRadius' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
      </Grid>
    </>
  )
}
export default GridSkeleton
