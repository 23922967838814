import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { ButtonBase, Input, useMediaQuery } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import SearchIcon from '@src/icons/SearchIcon'
import XIcon from '@src/icons/XIcon'
import clsx from 'clsx'
import { FacetChangeHandlerArguments } from './FacetGroup'
import messages from '../messages'
import HandleMarkup from '@src/components/HandleMarkup'
import { Facet } from '@src/types/graphql-types'

const useStyles = makeStyles((theme: Theme) => ({
  facetSearchBoxContainer: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(4),
  },
  facetSearchListBox: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '5px',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.21,
    margin: 0,
    padding: 0,
  },
  facetSearchListBoxOption: {
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
  facetSearchListBoxNoOptions: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '5px',
    fontSize: theme.typography.pxToRem(14),
  },
  facetSearchInputContainer: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '5px',
    height: theme.spacing(8),
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(4),
    outline: 'none',
    width: '100%',
  },
  facetSearchInput: {
    '& input': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  searchIcon: {
    color: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4),
  },
  clearIcon: {
    display: 'none',
    fontSize: theme.typography.pxToRem(10),
    marginRight: theme.spacing(3),
  },
  showClearIcon: {
    display: 'inline-flex',
  },
}))

interface FacetSearchProps {
  facet: Facet
  selectedOptions: string[]
  handleFacetChange: FacetChangeHandlerArguments
}

const FacetSearch: React.FC<FacetSearchProps> = ({
  facet,
  selectedOptions,
  handleFacetChange,
}) => {
  const [value, setValue] = useState<string | null>(null)
  const [inputValue, setInputValue] = useState('')
  const classes = useStyles()
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  const formatInputPlaceholder = (facetKey: string): string => {
    const placeholder = facetKey
      .split('_')
      .filter((str) => str !== 'facet' && str !== 'web')
      .map((str) => str[0].toUpperCase() + str.substring(1))
      .join(' ')

    return placeholder.length > 10 && isMd
      ? placeholder.slice(0, 10).concat('...')
      : placeholder
  }

  const handleClearInput = () => {
    setInputValue('')
    setValue(null)
  }

  useEffect(() => {
    handleClearInput()
  }, [handleFacetChange])

  const options = {}
  facet.options.map((o) => (options[o.value] = o.count))

  return (
    <div className={classes.facetSearchBoxContainer}>
      <Autocomplete
        disablePortal
        classes={{
          root: classes.facetSearchInput,
          listbox: classes.facetSearchListBox,
          option: classes.facetSearchListBoxOption,
          noOptions: classes.facetSearchListBoxNoOptions,
        }}
        options={Object.keys(options)}
        renderOption={(option) => (
          <HandleMarkup value={`${option} (${options[option]})`} />
        )}
        noOptionsText={formatMessage(messages.NO_FACETS_FOUND)}
        renderInput={(params) => (
          <div
            className={classes.facetSearchInputContainer}
            ref={params.InputProps.ref}
          >
            <Input
              ref={params.InputProps.ref}
              {...params.inputProps}
              placeholder={`Search ${formatInputPlaceholder(facet.key)}`}
              disableUnderline
              fullWidth
              endAdornment={
                <>
                  <ButtonBase
                    className={clsx(classes.clearIcon, {
                      [classes.showClearIcon]: inputValue !== '',
                    })}
                    onClick={handleClearInput}
                  >
                    <XIcon fontSize="inherit" />
                  </ButtonBase>
                  <SearchIcon className={classes.searchIcon} />
                </>
              }
            />
          </div>
        )}
        inputValue={inputValue}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue)
        }}
        value={value}
        onChange={(e, newValue: string | null) => {
          setValue(newValue)
          if (!newValue || selectedOptions.includes(newValue)) return

          const selectedResult = facet.options.find(
            (option) => option.value === newValue
          )
          const selectedCount = selectedResult?.count || undefined
          handleFacetChange(
            facet.key,
            newValue,
            false,
            selectedCount,
            'search box'
          )
        }}
      />
    </div>
  )
}

export default FacetSearch
