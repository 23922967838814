import { getIn, FieldProps } from 'formik'
import messages from '@utils/messages'
import LiquidQuantityInput from '@src/components/LiquidQuantityInput'
import React, { ReactNode, CSSProperties } from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { hasMultipleMiniMumOrderQuantityError } from '@utils/cartUtils'
import { LiquidInputProps } from '../LiquidInput/LiquidInput'

interface LiquidEmeraldQuantityAdapterProps
  extends FieldProps,
    LiquidInputProps {
  rootClass?: string
  rootStyle?: CSSProperties
  label?: ReactNode
  required?: boolean
  help?: ReactNode
  delay?: boolean
  min?: number
  type?: string
  handleBlur?: (event: any) => void
  associatedComponent?: string
  materialNumber: string
  minOrderQuantity: number
  setShowMultipleMinimumQuantityError?: (showMinQtyError: boolean) => any
  setMultipleMinimumQuantityError: (
    materialNumber: string,
    error: boolean
  ) => any
  isIndonesiaUser?: boolean
}

const LiquidEmeraldQuantityAdapter: React.FC<
  LiquidEmeraldQuantityAdapterProps
> = ({
  rootClass,
  rootStyle,
  field,
  form,
  help,
  delay,
  min,
  type,
  materialNumber,
  minOrderQuantity,
  setShowMultipleMinimumQuantityError,
  setMultipleMinimumQuantityError,
  isIndonesiaUser,
  ...otherProps
}) => {
  const intl = useIntl()
  const multipleMinimumOrderError = hasMultipleMiniMumOrderQuantityError(
    field.value,
    minOrderQuantity,
    isIndonesiaUser
  )
  const error = multipleMinimumOrderError
    ? intl.formatMessage(messages.MIN_N_QUANTITY, {
        min: minOrderQuantity,
      })
    : getIn(form.errors, field.name)
  const touched = multipleMinimumOrderError || getIn(form.touched, field.name)
  const hasError = error && touched

  const handleChange = (v: any) => {
    form.setFieldValue(field.name, v)
    form.setFieldTouched(field.name)
    const hasMultipleMinimumOrderError = hasMultipleMiniMumOrderQuantityError(
      v,
      minOrderQuantity,
      isIndonesiaUser
    )
    setShowMultipleMinimumQuantityError &&
      setShowMultipleMinimumQuantityError(hasMultipleMinimumOrderError)
    setMultipleMinimumQuantityError(
      materialNumber,
      hasMultipleMinimumOrderError
    )
  }

  return (
    <div
      className={clsx({
        [String(rootClass)]: rootClass,
      })}
      style={rootStyle}
    >
      <LiquidQuantityInput
        {...otherProps}
        id={`${type}-${form.values.materialNumber}-${field.name}`}
        value={field.value}
        onChange={handleChange}
        delay={delay}
        error={Boolean(hasError)}
        aria-describedby={(hasError || help) && `${field.name}-help-text`}
        onBlur={() => {
          if (min && field.value < min) {
            form.setFieldValue(field.name, 1)
          }
        }}
        min={min}
      />
    </div>
  )
}

export default LiquidEmeraldQuantityAdapter
