import React from 'react'
import { documentsSearch } from '@src/routes'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography, Link } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { CertificateType } from '@src/types/graphql-types'
import { DocumentTypeAbbreviations } from '@src/routes/DocumentSearch/DocumentSearch'

const useStyles = makeStyles((theme: Theme) => ({
  docBanner: {
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(4),
    background: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
    height: '53px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3),
    },
    '& p': {
      fontSize: theme.typography.pxToRem(16),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  lowercase: {
    textTransform: 'lowercase',
  },
}))

export enum SafetyDataSheet {
  SDS = 'sds',
}

interface DocumentSearchBannerProps {
  searchQuery: string
}

const queryToMessageMap = {
  [SafetyDataSheet.SDS]: {
    id: 'LOOKING_FOR_SDS',
    defaultMessage: 'Looking for a <b>Safety Data Sheet</b>?',
    values: {
      b: (...chunks) => <strong>{chunks}</strong>,
    },
  },
  [CertificateType.Coa.toLowerCase()]: {
    id: 'LOOKING_FOR_COA',
    defaultMessage: 'Looking for a <b>Certificate of Analysis</b>?',
    values: {
      b: (...chunks) => <strong>{chunks}</strong>,
    },
  },
  [CertificateType.Coo.toLowerCase()]: {
    id: 'LOOKING_FOR_COO',
    defaultMessage: 'Looking for a <b>Certificate of Origin</b>?',
    values: {
      b: (...chunks) => <strong>{chunks}</strong>,
    },
  },
  [CertificateType.Coq.toLowerCase()]: {
    id: 'LOOKING_FOR_COQ',
    defaultMessage: 'Looking for a <b>Certificate of Quality</b>?',
    values: {
      b: (...chunks) => <strong>{chunks}</strong>,
    },
  },
}

const DocumentSearchBanner: React.FC<DocumentSearchBannerProps> = ({
  searchQuery,
}) => {
  const classes = useStyles()
  const messageInfo = queryToMessageMap[searchQuery]
  const documentSearchPath = documentsSearch.index(searchQuery)
  const documentType: DocumentTypeAbbreviations = DocumentTypeAbbreviations[
    searchQuery
  ]?.replaceAll(' ', '_')
  const icidParam = `icid=search_term_specific_tip-banner-looking_for_a_${documentType}-documents`

  return (
    <div className={classes.docBanner}>
      <Typography variant="body1">
        {messageInfo && (
          <FormattedMessage
            id={messageInfo.id}
            defaultMessage={messageInfo.defaultMessage}
            values={messageInfo.values}
          />
        )}{' '}
        <FormattedMessage
          id="THESE_AND_OTHER_DOCS"
          defaultMessage="These and other documents can be found on our"
        />{' '}
        <Link href={`${documentSearchPath}&${icidParam}`}>
          <FormattedMessage id="DOCUMENTS" defaultMessage="Documents" />
        </Link>{' '}
        <span className={classes.lowercase}>
          <FormattedMessage id="PAGE" defaultMessage="Page" />
        </span>
        {'.'}
      </Typography>
    </div>
  )
}

export default DocumentSearchBanner
