import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Theme,
  Link as MuiLink,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { productDetailRoute, substanceRoute, useRouter } from '@src/routes'
import { Link } from '@src/components/Link'
import { CatalogType } from '@src/types/graphql-types'
import { SubstanceFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import {
  ProductSearchType,
  AliasType,
  determineQueryFocus,
  determineCatalogType,
  getValidSearchQuery,
} from '@utils/searchUtils'
import messages from '@utils/messages'
import CatalogImage from '@src/components/CatalogImage'
import SubstanceDetailSegment from '../SubstanceDetailSegment'
import ProductTable from '@src/routes/SearchResults/ProductSearchResults/ProductTable'
import ResponsiveModal, { ModalSizes } from '../ResponsiveModal'
import CaretRightIcon from '@src/icons/CaretRightIcon'
import MobileProductTable from '@src/routes/SearchResults/ProductSearchResults/MobileProductTable'
import { TrackedAvailabilityMessageProvider } from '../TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'

const useStyles = makeStyles((theme: Theme) => {
  return {
    mainContainer: {
      padding: theme.spacing(10, 4, 4, 4),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8),
      },
    },
    imgWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(4),
      marginTop: theme.spacing(4),
      height: theme.typography.pxToRem(300),
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: '4px',
      '& img': {
        width: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        minWidth: '36%',
        '& img': {
          width: '100%',
        },
      },
    },
    link: {
      fontWeight: theme.typography.fontWeightBold,
    },
    substance: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        marginBottom: theme.spacing(8),
      },
    },
    substanceName: {
      fontSize: theme.typography.pxToRem(18),
      marginBottom: theme.spacing(4),
    },
    substanceDetailsContainer: {
      padding: theme.spacing(5, 5, 5, 0),
      maxWidth: '100%',
      '& .MuiGrid-container': {
        marginBottom: theme.spacing(1.5),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 5),
        minWidth: '64%',
      },
    },
    dialogHeader: {
      backgroundColor: theme.palette.background.grey,
      fontSize: theme.typography.pxToRem(12),
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    caretRight: {
      width: '5px',
      marginLeft: theme.spacing(1),
    },
    detailsLink: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(1),
      },
    },
    breakWord: { wordBreak: 'break-word' },
    breakAll: { wordBreak: 'break-all' },
    mobileProductTable: {
      borderTop: `1px solid ${theme.palette.grey[400]}`,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      '& li': {
        padding: theme.spacing(4, 0),
      },
    },
  }
})
// optional override prop if focus is set by a no results product search first
interface QuickViewModalProps {
  catalogOverride?: CatalogType
  catalogType?: CatalogType
  open: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  substance: SubstanceFieldsFragment
  substanceName: React.ReactNode
}

const QuickViewModal: React.FC<QuickViewModalProps> = ({
  catalogOverride = null,
  catalogType,
  open,
  setModalOpen,
  substance,
  substanceName,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const router = useRouter()
  const validSearchQuery = getValidSearchQuery({
    focus: router.query.focus,
  })
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const {
    products,
    images,
    name,
    synonyms,
    casNumber,
    molecularWeight,
    linearFormula,
    aliases,
    id,
  } = substance
  const ecNumber = aliases?.find((alias) => alias.key === AliasType.EcNumber)
  const beilsteinRegistryNumber = aliases?.find((alias) =>
    alias.key.includes(AliasType.BeilsteinRegistryNumber)
  )
  const catalog =
    catalogOverride ??
    determineCatalogType(catalogType ?? validSearchQuery.focus)
  const renderProductsLink = (text) => {
    return products.length === 1 ? (
      <Link
        {...productDetailRoute.index(
          products[0].brand.key,
          products[0].productKey,
          { catalog: catalog }
        )}
        passHref
      >
        <MuiLink>{text}</MuiLink>
      </Link>
    ) : (
      <Link {...substanceRoute.index(id, catalog)} passHref>
        <MuiLink>{text}</MuiLink>
      </Link>
    )
  }

  return (
    <ResponsiveModal
      open={open}
      size={ModalSizes.Large}
      onClose={() => setModalOpen(false)}
    >
      <div className={classes.mainContainer}>
        <div className={classes.substance}>
          <div className={classes.imgWrapper}>
            <CatalogImage image={images[0]} altTextFallback={name} />
          </div>
          <div className={classes.substanceDetailsContainer}>
            <Typography
              className={classes.substanceName}
              component="h2"
              variant="h3"
            >
              {substanceName}
            </Typography>
            {synonyms?.length ? (
              <SubstanceDetailSegment
                message={messages.SYNONYMS}
                searchValue={synonyms.join(', ')}
                classes={classes.breakWord}
              />
            ) : null}
            {molecularWeight ? (
              <SubstanceDetailSegment
                message={messages.MOLECULAR_WEIGHT}
                searchValue={molecularWeight}
                classes={classes.breakAll}
              />
            ) : null}
            {casNumber ? (
              <SubstanceDetailSegment
                message={messages.CAS_NO}
                searchValue={casNumber}
                classes={classes.link}
                searchFocusType={determineQueryFocus(validSearchQuery.focus)}
                productSearchType={ProductSearchType.CasNumber}
              />
            ) : null}
            {ecNumber ? (
              <SubstanceDetailSegment
                message={messages.EC_NO}
                searchValue={ecNumber.value}
                searchFocusType={
                  catalogOverride ?? determineQueryFocus(validSearchQuery.focus)
                }
                productSearchType={ProductSearchType.EgecNumber}
                classes={classes.link}
              />
            ) : null}
            {beilsteinRegistryNumber ? (
              <SubstanceDetailSegment
                message={messages.BEILSTEIN_NO}
                searchValue={beilsteinRegistryNumber.value}
                classes={classes.breakAll}
              />
            ) : null}
            {linearFormula ? (
              <SubstanceDetailSegment
                message={messages.MOLECULAR_FORMULA}
                searchValue={linearFormula}
                classes={classes.breakWord}
              />
            ) : null}
          </div>
        </div>
        <div>
          <TrackedAvailabilityMessageProvider
            source={`srp - ${validSearchQuery.focus}`}
          >
            {isDesktop ? (
              <ProductTable
                products={products}
                displayBadgesOnProduct
                focus={
                  catalogOverride ?? determineQueryFocus(validSearchQuery.focus)
                }
                displayViewLink={false}
              />
            ) : (
              <div className={classes.mobileProductTable}>
                <MobileProductTable
                  products={products}
                  displayBadgesOnProduct
                  focus={
                    catalogOverride ??
                    determineQueryFocus(validSearchQuery.focus)
                  }
                />
              </div>
            )}
          </TrackedAvailabilityMessageProvider>
        </div>
        <div>
          <div className={classes.detailsLink}>
            <FormattedMessage {...messages.VIEW_FULL_DETAILS}>
              {(text) => (
                <>
                  <span>{renderProductsLink(text)}</span>
                  <CaretRightIcon className={classes.caretRight} />
                </>
              )}
            </FormattedMessage>
          </div>
        </div>
      </div>
    </ResponsiveModal>
  )
}

export default QuickViewModal
