// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SpecificationSheetUrlQueryVariables = Types.Exact<{
  input: Types.SpecSheetInput;
}>;


export type SpecificationSheetUrlQuery = { __typename?: 'Query', getSpecificationSheet?: { __typename?: 'SpecificationSheet', url?: string | null, text?: string | null, documentType?: string | null, materialId?: string | null } | null };


export const SpecificationSheetUrlDocument = gql`
    query SpecificationSheetUrl($input: SpecSheetInput!) {
  getSpecificationSheet(input: $input) {
    url
    text
    documentType
    materialId
  }
}
    `;
export type SpecificationSheetUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SpecificationSheetUrlQuery, SpecificationSheetUrlQueryVariables>, 'query'> & ({ variables: SpecificationSheetUrlQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SpecificationSheetUrlComponent = (props: SpecificationSheetUrlComponentProps) => (
      <ApolloReactComponents.Query<SpecificationSheetUrlQuery, SpecificationSheetUrlQueryVariables> query={SpecificationSheetUrlDocument} {...props} />
    );
    

/**
 * __useSpecificationSheetUrlQuery__
 *
 * To run a query within a React component, call `useSpecificationSheetUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificationSheetUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificationSheetUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpecificationSheetUrlQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SpecificationSheetUrlQuery, SpecificationSheetUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SpecificationSheetUrlQuery, SpecificationSheetUrlQueryVariables>(SpecificationSheetUrlDocument, options);
      }
export function useSpecificationSheetUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpecificationSheetUrlQuery, SpecificationSheetUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SpecificationSheetUrlQuery, SpecificationSheetUrlQueryVariables>(SpecificationSheetUrlDocument, options);
        }
export type SpecificationSheetUrlQueryHookResult = ReturnType<typeof useSpecificationSheetUrlQuery>;
export type SpecificationSheetUrlLazyQueryHookResult = ReturnType<typeof useSpecificationSheetUrlLazyQuery>;
export type SpecificationSheetUrlQueryResult = ApolloReactCommon.QueryResult<SpecificationSheetUrlQuery, SpecificationSheetUrlQueryVariables>;