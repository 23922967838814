import React from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import LoadingBox from '../LoadingBox'
import messages from '../../utils/messages'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    tRow: {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        width: '25%',
      },
      textAlign: 'center',
    },
    tHeadRow: {
      fontWeight: theme.typography.fontWeightBold,
      paddingBottom: theme.spacing(2),
    },
    tBodyRow: {
      padding: theme.spacing(3, 0),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    loadingBox: {
      backgroundImage:
        'linear-gradient(to right, #cfcfcf, #e0e0e0 25%, #f9f9f9, #e0e0e0 75%, #cfcfcf)',
      animationDuration: '2s',
      animationDelay: '0s',
      animationTimeFunction: 'ease-in',
      backgroundColor: theme.palette.grey[50],
    },
  }
})

const ProductPriceAvailabilitySkeleton: React.FC<{ displaySDS?: boolean }> = ({
  displaySDS,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <>
      <Box display={['block', 'none']}>
        <LoadingBox height={20} width={0.4} marginBottom={3} />
        <LoadingBox height={40} width={0.75} marginBottom={4} />
        <Box display="flex" marginBottom={4}>
          <LoadingBox
            height={24}
            width={24}
            marginRight={3}
            style={{ borderRadius: '50%' }}
          />
          <Box flex={1}>
            <LoadingBox height={14} width={0.9} marginBottom={2} />
            <LoadingBox height={14} width={0.7} />
          </Box>
        </Box>
        <LoadingBox height={38} width={1} marginBottom={4} />
        <LoadingBox height={38} width={1} marginBottom={4} />
        <LoadingBox height={40} width={1} />
      </Box>
      <Box display={['none', 'block']}>
        <div className={clsx(classes.tRow, classes.tHeadRow)}>
          <div>{formatMessage(messages.SKU)}</div>
          <div>{formatMessage(messages.PACK_SIZE)}</div>
          <div>{formatMessage(messages.AVAILABILITY)}</div>
          {displaySDS && <div>{formatMessage(messages.SDS)}</div>}
          <div>{formatMessage(messages.PRICE)}</div>
          <div>{formatMessage(messages.QUANTITY)}</div>
        </div>
        <div>
          {Array(3)
            .fill({})
            .map((_, index) => (
              <div key={index} className={clsx(classes.tRow, classes.tBodyRow)}>
                {Array(displaySDS ? 6 : 5)
                  .fill({})
                  .map((_, index) => (
                    <div key={index}>
                      <LoadingBox
                        height={28}
                        width={0.9}
                        borderRadius="borderRadius"
                        className={classes.loadingBox}
                      />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </Box>
    </>
  )
}

export default ProductPriceAvailabilitySkeleton
