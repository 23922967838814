import React from 'react'
import { ButtonBase } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import XIcon from '@src/icons/XIcon'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'table',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.primary,
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: '3px',
    border: `1px solid ${theme.palette.grey[500]}`,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 1.5),
    },
  },
  closeBtn: {
    marginLeft: theme.spacing(2),
    marginRight: '0 !important',
    fontSize: theme.typography.pxToRem(10),
  },
  alignmentWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface SelectionChipProps {
  label: string | React.ReactNode
  onDelete: () => void
  dataTestId?: string
  ariaLabel: string
}

const SelectionChip: React.FC<SelectionChipProps> = ({
  label,
  onDelete,
  dataTestId,
  ariaLabel,
}) => {
  const classes = useStyles()
  const inspectedLabel =
    typeof label === 'string' ? <span>{label}</span> : label
  return (
    <div className={classes.root} data-testid={dataTestId}>
      <div className={classes.alignmentWrapper}>
        <span>{inspectedLabel}</span>
        <ButtonBase
          aria-label={ariaLabel}
          className={classes.closeBtn}
          onClick={onDelete}
        >
          <XIcon fontSize="inherit" color="primary" />
        </ButtonBase>
      </div>
    </div>
  )
}

export default SelectionChip
