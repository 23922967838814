import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  ButtonBase,
  Collapse,
  Theme,
  Typography,
  TypographyProps,
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { DataColumn } from '../DataTable/DataTable'
import { uniqueId } from 'lodash'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(5, 4),
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerWrapper: {
    textAlign: 'left',
    width: '90%',
  },
  buttonWrapper: {
    display: 'flex',
    width: 20,
    flexGrow: 0,
    '& > svg': {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  content: {
    padding: theme.spacing(3, 0, 0, 0),
  },
  toggleButton: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  descriptionList: {
    marginBottom: theme.spacing(1),
    '& > * + *': {
      marginTop: theme.spacing(6),
    },
  },
}))

export interface ResponsiveDataAccordionItemProps<DataType> {
  data: DataType
  columns: DataColumn[]
  accordionTitleColumn: DataColumn
  accordionSubtitleColumn?: DataColumn
  headerProps?: Partial<TypographyProps<React.ElementType<any>>>
  subheaderProps?: Partial<TypographyProps<React.ElementType<any>>>
  renderFooter?: (data: DataType, isExpanded: boolean) => JSX.Element | null
  onToggle?: (willExpand: boolean) => void
  titleIsLink?: boolean
}

const ResponsiveDataAccordionItem = <DataType extends {}>({
  data,
  columns,
  accordionTitleColumn,
  accordionSubtitleColumn,
  headerProps,
  subheaderProps,
  renderFooter,
  onToggle,
  titleIsLink,
}: ResponsiveDataAccordionItemProps<DataType>) => {
  const titleId = useMemo(() => `accordion-title-${uniqueId()}`, [])
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)

  const ToggleComponent = titleIsLink ? 'div' : ButtonBase
  const renderCaret = () => {
    const caret = isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />
    if (!titleIsLink) {
      return <div className={classes.buttonWrapper}>{caret}</div>
    }
    return (
      <ButtonBase
        className={classes.buttonWrapper}
        aria-expanded={isExpanded}
        aria-labelledby={titleId}
      >
        {caret}
      </ButtonBase>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.itemHeader}>
        <ToggleComponent
          aria-expanded={!titleIsLink ? isExpanded : undefined}
          className={classes.toggleButton}
          onClick={(e: React.MouseEvent<Element>) => {
            const clickedEl = (e.target as Element).closest(
              `a, .${classes.toggleButton}`
            )
            if (clickedEl?.tagName === 'A') return
            setIsExpanded(!isExpanded)
            onToggle && onToggle(!isExpanded)
          }}
        >
          <div className={classes.headerWrapper}>
            <Typography
              component="div"
              variant="h2"
              id={titleId}
              {...headerProps}
            >
              {accordionTitleColumn.bodyElement(data, !titleIsLink)}
            </Typography>
            {accordionSubtitleColumn && (
              <Typography component="div" variant="body2" {...subheaderProps}>
                {accordionSubtitleColumn.bodyElement(data, true)}
              </Typography>
            )}
          </div>
          {renderCaret()}
        </ToggleComponent>
      </div>
      <Collapse in={isExpanded}>
        <div className={classes.content}>
          <dl className={classes.descriptionList}>
            {columns.map((column, index) => {
              const columnName = column.headElement
              const noLink = !!titleIsLink && column === accordionTitleColumn
              const columnBody = column.bodyElement(data, noLink)

              return (
                <div key={index}>
                  <Typography component="dt" variant="caption">
                    {columnName ? columnName : column.headElement}
                    {column.headElementEndIcon}
                  </Typography>
                  <Typography component="dd" variant="body1">
                    {columnBody}
                  </Typography>
                </div>
              )
            })}
          </dl>
          {renderFooter && renderFooter(data, isExpanded)}
        </div>
      </Collapse>
    </div>
  )
}

export default ResponsiveDataAccordionItem
