// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PromotionalBundlesQueryVariables = Types.Exact<{
  brand: Types.Scalars['String'];
  productNumber: Types.Scalars['String'];
  catalogType?: Types.InputMaybe<Types.CatalogType>;
  orgId?: Types.InputMaybe<Types.Scalars['String']>;
  materialIds?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  erp_type?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type PromotionalBundlesQuery = { __typename?: 'Query', getPromotionalBundlesForProduct?: Array<{ __typename?: 'PromotionalBundle', promotionalBundleId: string, bundleId: string, bundleName?: string | null, bundleDescription?: string | null, createdBy: string, createdDate: string, lastUpdatedBy?: string | null, lastUpdatedDate?: string | null, bundleStartDate: string, bundleEndDate: string, noOfItems: number, countries: Array<string>, status: boolean, bundleItems: Array<{ __typename?: 'PromotionalBundleItem', materialNumber: string, materialIds?: Array<string> | null, quantity?: number | null, discount?: number | null, showOnPdp: boolean }> }> | null };

export type PromotionalBundleDetailFragment = { __typename?: 'PromotionalBundle', promotionalBundleId: string, bundleId: string, bundleName?: string | null, bundleDescription?: string | null, createdBy: string, createdDate: string, lastUpdatedBy?: string | null, lastUpdatedDate?: string | null, bundleStartDate: string, bundleEndDate: string, noOfItems: number, countries: Array<string>, status: boolean, bundleItems: Array<{ __typename?: 'PromotionalBundleItem', materialNumber: string, materialIds?: Array<string> | null, quantity?: number | null, discount?: number | null, showOnPdp: boolean }> };

export type BundleItemsFragment = { __typename?: 'PromotionalBundleItem', materialNumber: string, materialIds?: Array<string> | null, quantity?: number | null, discount?: number | null, showOnPdp: boolean };

export const BundleItemsFragmentDoc = gql`
    fragment BundleItems on PromotionalBundleItem {
  materialNumber
  materialIds
  quantity
  discount
  showOnPdp
}
    `;
export const PromotionalBundleDetailFragmentDoc = gql`
    fragment PromotionalBundleDetail on PromotionalBundle {
  promotionalBundleId
  bundleId
  bundleName
  bundleDescription
  createdBy
  createdDate
  lastUpdatedBy
  lastUpdatedDate
  bundleStartDate
  bundleEndDate
  noOfItems
  countries
  bundleItems {
    ...BundleItems
  }
  status
}
    ${BundleItemsFragmentDoc}`;
export const PromotionalBundlesDocument = gql`
    query PromotionalBundles($brand: String!, $productNumber: String!, $catalogType: CatalogType, $orgId: String, $materialIds: [String!], $erp_type: [String!]) {
  getPromotionalBundlesForProduct(input: {brand: $brand, productNumber: $productNumber, catalogType: $catalogType, orgId: $orgId, materialIds: $materialIds, erp_type: $erp_type}) {
    ...PromotionalBundleDetail
  }
}
    ${PromotionalBundleDetailFragmentDoc}`;
export type PromotionalBundlesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PromotionalBundlesQuery, PromotionalBundlesQueryVariables>, 'query'> & ({ variables: PromotionalBundlesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PromotionalBundlesComponent = (props: PromotionalBundlesComponentProps) => (
      <ApolloReactComponents.Query<PromotionalBundlesQuery, PromotionalBundlesQueryVariables> query={PromotionalBundlesDocument} {...props} />
    );
    

/**
 * __usePromotionalBundlesQuery__
 *
 * To run a query within a React component, call `usePromotionalBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionalBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionalBundlesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      productNumber: // value for 'productNumber'
 *      catalogType: // value for 'catalogType'
 *      orgId: // value for 'orgId'
 *      materialIds: // value for 'materialIds'
 *      erp_type: // value for 'erp_type'
 *   },
 * });
 */
export function usePromotionalBundlesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PromotionalBundlesQuery, PromotionalBundlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PromotionalBundlesQuery, PromotionalBundlesQueryVariables>(PromotionalBundlesDocument, options);
      }
export function usePromotionalBundlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PromotionalBundlesQuery, PromotionalBundlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PromotionalBundlesQuery, PromotionalBundlesQueryVariables>(PromotionalBundlesDocument, options);
        }
export type PromotionalBundlesQueryHookResult = ReturnType<typeof usePromotionalBundlesQuery>;
export type PromotionalBundlesLazyQueryHookResult = ReturnType<typeof usePromotionalBundlesLazyQuery>;
export type PromotionalBundlesQueryResult = ApolloReactCommon.QueryResult<PromotionalBundlesQuery, PromotionalBundlesQueryVariables>;