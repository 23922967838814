import React from 'react'
import Head from 'next/head'
import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { productDetailRoute, useRouter } from '@src/routes'
import { Link } from '@src/components/Link'
import { sendRecommendedProductsClickEvent } from '@src/utils/analytics'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { CarouselProduct } from './DynamicProductCardTypes'
import { ProductLockupMini } from '@src/components/ProductLockupMini/ProductLockupMini'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    border: '2px solid transparent',
    display: 'block',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
    // match CTA button focus styles
    '&:focus-visible': {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0 0 6px 0 rgba(15, 105, 175, 0.5)',
      outline: 'none',
    },
  },
  footer: {
    marginTop: theme.spacing(6),
  },
  footerRaisedCard: {
    marginTop: theme.spacing(4),
  },
  btn: {
    whiteSpace: 'nowrap',
  },
}))

interface DynamicProductCardProps {
  product: CarouselProduct
  testId: string
  onViewPrice: () => void
  onLinkClick?: () => void
  preloadCardImages?: boolean
  className?: string
  isRaisedCardLayout?: boolean
  index: number
  type: string
  hideProductImg?: boolean
  displayDiscount?: (discountPercent?: string | null) => void
}

const DynamicProductCard: React.FC<DynamicProductCardProps> = ({
  product,
  testId,
  onViewPrice,
  onLinkClick,
  preloadCardImages = false,
  className,
  isRaisedCardLayout,
  index,
  type,
  hideProductImg = false,
  displayDiscount,
}) => {
  const router = useRouter()
  const catalog = router?.query?.catalog
  const classes = useStyles()
  const linkId = `product-card-${product.productNumber}${product.brand.key}-${testId}`

  return (
    <div className={clsx(className, 'promotions-slider-div')}>
      {preloadCardImages && (
        <Head>
          <link rel="preload" href={product.images[0].mediumUrl} as="image" />
        </Head>
      )}
      <Link
        {...productDetailRoute.index(product.brand.key, product.productKey, {
          catalog,
        })}
      >
        {/* jsx-a11y rules disabled because href is implicitly passed to anchor via Link component */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a
          className={classes.link}
          data-testid={linkId}
          id={linkId}
          onClick={() => {
            if (onLinkClick) onLinkClick()
            sendRecommendedProductsClickEvent(type, product, index)
          }}
        >
          <ProductLockupMini
            productNumber={product.productNumber}
            brand={product.brand}
            name={product.name}
            description={product.description}
            image={!hideProductImg ? product.images[0] : undefined}
            isRaisedCardLayout={isRaisedCardLayout}
            displayDiscount={displayDiscount}
            discount={product.discount}
          />
        </a>
      </Link>
      <div
        className={clsx({
          [classes.footer]: !isRaisedCardLayout,
          [classes.footerRaisedCard]: isRaisedCardLayout,
        })}
      >
        <Button
          size="large"
          color="primary"
          variant="outlined"
          fullWidth
          onClick={() => {
            onViewPrice()
            sendRecommendedProductsClickEvent(type, product, index)
          }}
          className={classes.btn}
        >
          <FormattedMessage id="QUICK_VIEW" defaultMessage="Quick View" />
        </Button>
      </div>
    </div>
  )
}

export default DynamicProductCard
