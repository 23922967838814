import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import vrStyles from '@src/styles/utils/vrStyles'
import { makeStyles } from '@material-ui/core/styles'

const { vr4 } = vrStyles
const useStyles = makeStyles(() => ({
  vr4,
}))

interface Props {
  contactInfo: string
}

const InquireMessages: FC<Props> = ({ contactInfo }) => {
  const classes = useStyles()

  return (
    <>
      {!!contactInfo ? (
        <div className={classes.vr4}>
          <i>
            <FormattedMessage
              id="INQUIRE_FOR_PRICE_AND_AVAILABILITY_MODAL"
              defaultMessage="This item is not able to be purchased as a guest user. For further information, please contact us at {inquirePriceContactInfo}."
              values={{
                inquirePriceContactInfo: contactInfo,
              }}
            />
          </i>
        </div>
      ) : (
        <div className={classes.vr4}>
          <i>
            <FormattedMessage
              id="INQUIRE_FOR_PRICE_AND_AVAILABILITY"
              defaultMessage="Please inquire for price and availability"
            />
          </i>
        </div>
      )}
    </>
  )
}

export default InquireMessages
