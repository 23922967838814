import React from 'react'
import HandleMarkup from '@src/components/HandleMarkup'
import { Link } from '@src/components/Link'

interface MapAttributeValuesProps {
  values: string[]
  className?: string | ''
  url?: string
}

const AttrComponent = (props) =>
  props.href ? (
    <Link {...props}>
      <a>{props.value}</a>
    </Link>
  ) : (
    <HandleMarkup {...props} />
  )

const MapAttributeValues: React.FC<MapAttributeValuesProps> = ({
  values,
  className,
  url,
}) => (
  <>
    {Array.isArray(values) && values.length > 1
      ? values.map((val: string, i: number) => (
          <span key={`${val}${i}`}>
            <AttrComponent value={val} className={className} href={url} />
            <br />
          </span>
        ))
      : values[0] && (
          <AttrComponent value={values[0]} className={className} href={url} />
        )}
  </>
)

export default MapAttributeValues
