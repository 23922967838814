import React, { useState, useEffect } from 'react'
import { useRouter } from '@src/routes'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  Button,
  Typography,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, Form, Formik, FormikProps } from 'formik'
import { useMaterialPricingAndAvailabilityLazyQuery } from '@src/queries/MaterialPricingAndAvailabilityQuery.generated'
import {
  ValidMaterialPricingDetailFragment,
  AvailabilitiesFragment,
} from '@src/fragments/ProductPricing.generated'
import { useMaterialAvailabilitiesLazyQuery } from '@src/queries/MaterialAvailabilitiesQuery.generated'
import { CatalogType } from '@src/types/graphql-types'
import ResponsiveModal from '../ResponsiveModal'
import AvailabilityMessages from '../ProductPriceAvailability/MobileProductPriceAvailabilityForm/AvailabilityMessages'
import vrStyles from '@src/styles/utils/vrStyles'
import { useAddToCartWithGlobalSnackbar } from '@src/utils/useCart'
import LiquidQuantityInputAdapter from '../LiquidQuantityInputAdapter'
import { determineCatalogType } from '@src/utils/searchUtils'
import { GTMEventCategory } from '@sial/common-utils'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'
import { setAddToCartData } from '@utils/analytics/cartAndCheckoutEvents'
import { AddToCartPagesEnum } from '@utils/analytics/enums'
import { TrackedAvailabilityMessageProvider } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import { availableWithinFiveDays } from '@utils/availableWithinFiveDays'
import { SitePreference, useCurrentUser } from '@utils/useCurrentUser'

const { vr1, vr4, vr6, vr8 } = vrStyles

const useStyles = makeStyles((theme: Theme) => ({
  constrainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  availabilityForm: {
    ...vr8,
    paddingTop: theme.spacing(2),
  },
  formLabel: {
    ...vr6,
    display: 'inline-block',
  },
  availabilityFormBody: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  quantityInput: {
    ...vr4,
    height: theme.typography.pxToRem(48),
    minWidth: theme.typography.pxToRem(95),
    fontSize: theme.typography.pxToRem(12),
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      marginBottom: 0,
      maxWidth: theme.typography.pxToRem(100),
      marginRight: theme.spacing(6),
    },
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
  },
  resultsLabel: {
    ...vr1,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
  },
  resultsContainer: {
    flex: '1 1 auto',
    overflow: 'scroll',
    [theme.breakpoints.up('md')]: {
      ...vr4,
    },
  },
  footer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.grey[50],
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
    },
  },
  warningText: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.typography.pxToRem(6),
  },
}))

interface MaterialAvailabilityModalProps {
  isModalOpen: boolean
  onModalClose(): void
  material: ValidMaterialPricingDetailFragment
  canAddToCart: boolean
  countryCode?: string
  availableQty?: number | null
  source?: string
}

interface FormValues {
  quantity: number
}

const MaterialAvailabilityModal: React.FC<MaterialAvailabilityModalProps> = ({
  isModalOpen,
  onModalClose,
  material,
  canAddToCart,
  countryCode,
  availableQty,
  source,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const intl = useIntl()
  const router = useRouter()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { focus = '', catalog = '' } = router.query || {}
  const { getSitePreference } = useCurrentUser()
  const orgId = getSitePreference(SitePreference.CatalogFilter)
  const { materialNumber, availabilities: initialAvailabilities } = material
  const [addToCartLoading, setAddToCartLoading] = useState(false)
  const addToCart = useAddToCartWithGlobalSnackbar()
  // WIBF-994 materials with availability key AP_NO_STOCK will run simulate to
  // get a more accurate availability message in the check availability modal
  const runSimulateCheck =
    material.availabilities?.[0].key === 'APO_NO_STOCK' ? true : false

  // Store previous availability data for rendering previous data in the loading state
  // Initialize availability data with availabilities passed in from props
  const [previousAvailabilityData, setPreviousAvailabilityData] = useState<
    AvailabilitiesFragment[]
  >(initialAvailabilities ? initialAvailabilities : [])

  const [fetchAvailabilityData, { loading, error, data }] =
    useMaterialPricingAndAvailabilityLazyQuery()

  const [
    fetchAvailabilityDataForSimulate,
    { loading: simulateLoading, error: simulateError, data: simulateData },
  ] = useMaterialAvailabilitiesLazyQuery()

  const availabilityData =
    data?.getPricingForMaterial.materialPricing?.availabilities

  const availabilityDataForSimulate =
    simulateData?.getAvailabilityForMaterial.materialAvailabilities
      ?.availabilities

  // WIBF-994 to use simulate the call requires checkavailability=true and BE
  // decided not to return prcing, brand, etc - just avail message for this.
  // These things are required in the current call so created a new query
  // that doesn't require them to be returned - only availabilities
  const updateSimulateAvailability = async (quantity) => {
    // Set previous availability data to render while loading new data
    if (availabilityDataForSimulate) {
      setPreviousAvailabilityData(availabilityDataForSimulate)
    }
    await fetchAvailabilityDataForSimulate({
      variables: {
        materialNumber,
        quantity,
        /* we use focus on the srp product table and catalog on the PDP table
        so we'll use whichever is available per location */
        catalogType: determineCatalogType(focus || catalog),
        orgId,
        countryCode,
        useSimulate: runSimulateCheck,
      },
    })
  }

  useEffect(() => {
    if (runSimulateCheck && isModalOpen) {
      updateSimulateAvailability(1)
    }
  }, [isModalOpen])

  const handleCheckAvailability = async (values: FormValues) => {
    //STRAT-20942-track check availability event
    sendPricingAvailabilityInteractionEvent(
      {
        action: 'check product availability',
        section: source || 'product availability',
        component: 'modal',
        elementType: 'button',
        elementText: 'check availability',
        material,
      },
      {
        eventCategory: GTMEventCategory.PricingAndAvailability,
        eventAction: 'check availability',
        eventLabel: material.materialNumber.toLowerCase(),
        eventInteractionType: 0,
      }
    )
    if (!runSimulateCheck) {
      // Set previous availability data to render while loading new data
      if (availabilityData) {
        setPreviousAvailabilityData(availabilityData)
      }
      const marketplace =
        material.catalogType === CatalogType.Marketplace
          ? CatalogType.Marketplace
          : null
      await fetchAvailabilityData({
        variables: {
          materialNumber,
          quantity: values.quantity,
          /* we use focus on the srp product table and catalog on the PDP table
        so we'll use whichever is available per location */
          catalogType: determineCatalogType(marketplace || focus || catalog),
          orgId,
          countryCode,
        },
      })
    } else {
      updateSimulateAvailability(values.quantity)
    }
  }

  const handleAddMaterialToCart = async (quantity: number) => {
    try {
      const cartData = [
        {
          materialNumber: material.materialNumber,
          quantity: quantity,
          marketplaceOfferId: material.marketplaceOfferId,
        },
      ]
      setAddToCartLoading(true)
      setAddToCartData(
        cartData,
        [material],
        AddToCartPagesEnum.AvailabilityModal
      )
      await addToCart(
        cartData,
        setAddToCartLoading,
        undefined,
        material.marketplaceOfferId !== null
      )
    } catch (_) {}
  }

  return (
    <Formik
      initialValues={{
        quantity: 1,
      }}
      onSubmit={handleCheckAvailability}
    >
      {(formikProps: FormikProps<FormValues>) => {
        const { values } = formikProps
        return (
          <ResponsiveModal
            open={isModalOpen}
            onClose={onModalClose}
            renderTitle={() => (
              <Typography variant="h2" component="h2">
                {`${intl.formatMessage({
                  id: 'PRODUCT_AVAILABILITY_HEADING',
                  defaultMessage: 'Availability for',
                })} ${materialNumber}`}
              </Typography>
            )}
            closeButtonId="mat-avl-modal-close"
          >
            <>
              <div
                className={clsx(classes.availabilityForm, classes.constrainer)}
              >
                <label htmlFor="quantity" className={classes.formLabel}>
                  {availableQty ? (
                    <Typography className={classes.warningText}>
                      <FormattedMessage
                        id="DEPLETED_PRODUCTS_AVAILABILITY_WARNING"
                        defaultMessage="This item is discontinued, but limited quantities are available."
                      />
                    </Typography>
                  ) : null}
                  {`${intl.formatMessage({
                    id: 'PRODUCT_AVAILABILITY_LABEL',
                    defaultMessage:
                      'Enter quantity to check availability and estimated ship date.',
                  })}`}
                </label>
                <Form className={classes.availabilityFormBody}>
                  <Field
                    name="quantity"
                    component={LiquidQuantityInputAdapter}
                    className={classes.quantityInput}
                    min={1}
                    max={material?.availableQtyInStock ?? 9999}
                  />
                  <Button
                    id="mat-avl-modal-check-availability"
                    data-testid="mat-avl-modal-check-availability"
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth={!isDesktop}
                    disabled={loading || simulateLoading}
                  >
                    <FormattedMessage
                      id="CHECK_AVAILABILITY_BUTTON"
                      defaultMessage="Check Availability"
                    />
                  </Button>
                </Form>
                {error || simulateError ? (
                  <div className={classes.error}>
                    <FormattedMessage
                      id="PRICING_AND_AVAILABILITY_UNAVAILABLE"
                      defaultMessage="Pricing and availability is not currently available."
                    />
                  </div>
                ) : null}
              </div>
              {!availableWithinFiveDays && (
                <div
                  className={clsx(classes.resultsLabel, classes.constrainer)}
                >
                  <FormattedMessage
                    id="PRODUCT_AVAILABILITY_SHIP_DATES"
                    defaultMessage="Ship Date(s)"
                  />
                </div>
              )}
              <div
                className={clsx(classes.resultsContainer, classes.constrainer)}
              >
                {simulateLoading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  <TrackedAvailabilityMessageProvider
                    source="availability modal"
                    brand={material.brand}
                    item={materialNumber}
                    pricing={material}
                  >
                    <AvailabilityMessages
                      availabilities={
                        availabilityDataForSimulate ||
                        availabilityData ||
                        previousAvailabilityData
                      }
                      updateAvailable={loading || !!error || simulateLoading}
                    />
                  </TrackedAvailabilityMessageProvider>
                )}
              </div>
              {canAddToCart && (
                <div className={clsx(classes.footer, classes.constrainer)}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={addToCartLoading}
                    size="large"
                    fullWidth={!isDesktop}
                    onClick={() => handleAddMaterialToCart(values.quantity)}
                    id="mat-avl-modal-add-to-cart"
                  >
                    <FormattedMessage
                      id="ADD_TO_CART"
                      defaultMessage="Add to Cart"
                    />
                  </Button>
                </div>
              )}
            </>
          </ResponsiveModal>
        )
      }}
    </Formik>
  )
}

export default MaterialAvailabilityModal
