// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
export type DynamicProductFragment = { __typename?: 'Product', id?: string | null, productKey: string, productNumber: string, productSku?: string | null, lastBoughtUnix?: string | null, name: string, description?: string | null, gaProductCode?: string | null, isMarketplace: boolean, erp_type?: Array<string> | null, tags?: string | null, discount?: string | null, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', altText?: string | null, mediumUrl: string }> };

export const DynamicProductFragmentDoc = gql`
    fragment DynamicProduct on Product {
  id
  productKey
  productNumber
  productSku
  lastBoughtUnix
  brand {
    key
    erpKey
    name
    color
  }
  name
  description
  images {
    altText
    mediumUrl
  }
  gaProductCode
  isMarketplace
  erp_type
  tags
  discount
}
    `;