import {
  Box,
  Button,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import { EventValues } from '@sial/common-utils'
import { Link } from '@src/components/Link'
import SelectDtaqDealerModal from '@src/components/SelectDtaqDealerModal'
import CheckMarkCircleIcon from '@src/icons/CheckMarkCircleIcon'
import { staticContent } from '@src/routes'
import {
  Dealer,
  MaterialAvailability,
  MaterialAvailabilityKey,
  ProductAttribute,
} from '@src/types/graphql-types'
import { useDealersQuery } from '@src/queries/DealersQuery.generated'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import messages from '@utils/MessageSets/p&aDynamicMessages'
import { SitePreference, useCurrentUser } from '@utils/useCurrentUser'
import { useUserSession } from '@utils/useUserSession'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'

import AddToCartButton from '../AddToCartButton'
import ProductMaterialRow from './ProductMaterialRow'
import ProductPartnerInfo from './ProductPartnerInfo'
import useStyles from './styles'
import { ApolloQueryResult } from 'apollo-client/core/types'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { sendPricingAndAvailabilityEvent } from '@src/utils/analytics/pricingAndAvailability'
import { TrackedAvailabilityMessage } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessage'
import {
  TrackedAvailabilityMessageContext,
  TrackedAvailabilityMessageProvider,
} from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import { useProductErpType } from '@src/utils/useProductErpType'
import { useChinaUser } from '@src/utils/useChinaUser'
import SignInButton from '../SignInButton'
import { useDecision } from '@optimizely/react-sdk'
import { availableWithinFiveDays } from '@src/utils/availableWithinFiveDays'

export interface AddToCartFormValues {
  [key: string]: {
    materialNumber: string
    quantity: number | string
    dealerId: string | null
  }
}

export interface MultipleMinQtyErrorsType {
  error: boolean
  name: string
}

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

export interface ProductPriceAvailabilityFormBodyProps {
  country?: string
  productName?: string
  displaySellerName?: string
  productDescription?: string
  productId?: string
  materialPricing: ValidMaterialPricingDetailFragment[]
  isSubmitting: boolean
  dirty: boolean
  submitForm: () => void
  resetForm: (nextState?: { values?: AddToCartFormValues }) => void
  canAddToCart: boolean
  hideAddToCartForPrepackItems: boolean
  displayPromotionalBundlesAnchor?: boolean
  displaySDS?: boolean
  ctas?: PdpFieldsFragment['forms']
  isAddToList: boolean
  condensed: boolean
  setFieldValue: (field: string, value: any) => void
  values: AddToCartFormValues
  isMarketplace?: boolean
  isMarketplaceCartEnabled?: boolean
  marketplaceSellerId?: string
  marketplaceOfferId?: string
  productAttributes?: ProductAttribute[]
  handlePriceUpdateForDealer?: (
    dealerId: string | null,
    values: AddToCartFormValues,
    materialPricing: ValidMaterialPricingDetailFragment[],
    setFieldValue: (field: string, value: any) => void,
    setIsModalOpen: (value: React.SetStateAction<boolean>) => void,
    dealerName: Maybe<string> | undefined
  ) => Promise<void>
  updateQuantity?: (quantity: number) => Promise<ApolloQueryResult<unknown>>
  erpType?: string[]
}

export const InitialShipEstimate: FC<{
  availability: MaterialAvailability
  quantity?: number
}> = ({ availability }) => {
  const classes = useStyles()
  const [flagData] = useDecision('wabt-210')

  if (!availability) {
    return (
      <TrackedAvailabilityMessage
        id="PRICING_AND_AVAILABILITY_UNAVAILABLE"
        defaultMessage="Pricing and availability is not currently available."
        availability={availability}
      />
    )
  }

  if (availability.key === MaterialAvailabilityKey.UnknownAvailability) {
    return (
      <>
        <CheckMarkCircleIcon className={classes.availabilityUnknownIcon} />
        <TrackedAvailabilityMessage
          id="AVAILABILITY_UNKNOWN"
          defaultMessage="Availability Unknown"
          availability={availability}
        />
      </>
    )
  }

  if (availability.key === MaterialAvailabilityKey.ContactForAvailability) {
    return (
      <>
        <CheckMarkCircleIcon className={classes.availabilityUnknownIcon} />
        <TrackedAvailabilityMessage
          id="CONTACT_FOR_AVAILABILITY"
          defaultMessage="Please contact Customer Service for Availability"
          availability={availability}
        />
      </>
    )
  }

  if (availability.key === MaterialAvailabilityKey.FulfilmentDeliveryDelayed) {
    return (
      <>
        <CheckMarkCircleIcon className={classes.availabilityUnknownIcon} />
        <TrackedAvailabilityMessage
          id="FULFILMENT_DELIVERY_DELAYED"
          defaultMessage="Fulfilment and delivery delayed"
          availability={availability}
        />
      </>
    )
  }

  if (availability.key === MaterialAvailabilityKey.LimitedAvailability) {
    return <TrackedAvailabilityMessage {...messages[availability.key]} />
  }

  if (availability.key === MaterialAvailabilityKey.OutOfStockKey) {
    return (
      <TrackedAvailabilityMessage
        id="OUT_OF_STOCK_KEY"
        defaultMessage="We apologize but fulfillment and delivery of this product is delayed. We are working to minimize these delays as quickly as possible."
        availability={availability}
      />
    )
  }

  if (availability.key === MaterialAvailabilityKey.OnlyFewLeftInStock) {
    const qty = availability.quantity
    return (
      <>
        <CheckMarkCircleIcon className={classes.availableIcon} />
        <TrackedAvailabilityMessage
          id="FEW_LEFT_IN_STOCK"
          defaultMessage="{qty} left in stock (more on the way)"
          values={{ qty }}
          availability={availability}
        />
      </>
    )
  }

  if (availability.key === MaterialAvailabilityKey.ApoNoStock) {
    return (
      <>
        <CheckMarkCircleIcon className={classes.availabilityUnknownIcon} />
        <TrackedAvailabilityMessage
          id="APO_NO_STOCK"
          defaultMessage="No Local Stock"
          availability={availability}
        />
      </>
    )
  }

  // MaterialAvailability has nullable date and quantity fields that must be checked before use
  if (!availability.date || !availability.quantity) {
    return (
      <TrackedAvailabilityMessage
        id="PRICING_AND_AVAILABILITY_UNAVAILABLE"
        defaultMessage="Pricing and availability is not currently available."
        availability={availability}
      />
    )
  }

  return (
    <>
      <CheckMarkCircleIcon className={classes.availableIcon} />
      {availableWithinFiveDays(availability) && flagData.enabled ? (
        <span>
          <TrackedAvailabilityMessage
            id="IN_STOCK"
            defaultMessage="IN_STOCK"
            availability={availability}
          />
        </span>
      ) : (
        <span>
          <TrackedAvailabilityMessage
            availability={availability}
            {...messages[
              availability.key === MaterialAvailabilityKey.EstimatedDeliveryOn
                ? 'AVAILABILITY_ESTIMATED_DELIVERY_ON'
                : availability.key
            ]}
          />{' '}
          <FormattedDate
            value={new Date(availability.date)}
            year="numeric"
            month="long"
            day="2-digit"
            timeZone="UTC"
          />
        </span>
      )}
    </>
  )
}

const PreferredDealerShippingText: FC<{
  preferredDealerName: string
  noPriceForDealer: boolean
}> = ({ preferredDealerName, noPriceForDealer }) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  if (noPriceForDealer) {
    return (
      <span className={classes.seeDealerOptionsText}>
        <ErrorIcon color="error" className={classes.errorIcon} />
        <TrackedAvailabilityMessage
          id="PRICING_AND_AVAILABILITY_TEMPORARILY_UNAVAILABLE_FROM"
          defaultMessage="Pricing and availability is temporarily unavailable from {preferredDealerName}"
          values={{ preferredDealerName }}
        />
      </span>
    )
  }

  const preferredDealerText = preferredDealerName
    ? `${formatMessage({
        id: 'SHIPPED_SOLD_BY',
        defaultMessage: 'Shipped & Sold By',
      })} ${preferredDealerName}`
    : ''

  if (!preferredDealerText) return <></>
  // Space after text needed for formatting
  return (
    <span
      className={classes.seeDealerOptionsText}
    >{`${preferredDealerText} `}</span>
  )
}

const SeeBuyingOptionsDealerText: FC<{
  dealerCount: number | undefined
}> = ({ dealerCount }) => {
  const { formatMessage } = useIntl()

  const linkText = formatMessage({
    id: 'SEE_ALL_BUYING_OPTIONS',
    defaultMessage: 'See All Buying Options',
  })

  if (Boolean(dealerCount)) {
    return <>{`${linkText} (${dealerCount})`}</>
  }

  return <>{`${linkText}`}</>
}

const ProductPriceAvailabilityFormBody: FC<
  ProductPriceAvailabilityFormBodyProps
> = ({
  country,
  productName,
  displaySellerName,
  productDescription,
  productId,
  materialPricing,
  canAddToCart,
  isSubmitting,
  dirty,
  submitForm,
  hideAddToCartForPrepackItems,
  displayPromotionalBundlesAnchor,
  displaySDS,
  ctas,
  isAddToList,
  setFieldValue,
  values,
  isMarketplace,
  isMarketplaceCartEnabled = false,
  marketplaceSellerId,
  marketplaceOfferId,
  productAttributes,
  handlePriceUpdateForDealer,
  erpType,
}) => {
  const router = useRouter()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const {
    currentUser,
    currentUserState,
    getSitePreference,
    isPublicOrderingCustomer,
    isQuoteRequester,
    isDTAQZuCustomer,
    userIsLoggedIn,
  } = useCurrentUser()
  const { userSession } = useUserSession()
  const preferredDealerId = getSitePreference(SitePreference.PreferredDealerId)
  const [dealerCount, setDealerCount] = useState(0)
  const isMissingDealer = Boolean(!preferredDealerId)
  const [decision] = useDecision('find-572')
  const preferredDealerInfo = getSitePreference(
    SitePreference.PreferredDealerInfo
  )
  const preferredDealerName = preferredDealerInfo?.orgName
  const [dealerSoldBy, setDealerSoldBy] = useState<string | null>(
    preferredDealerName
  )

  const { isPurpleProduct } = useProductErpType(erpType)
  const isChinaUser = useChinaUser()

  const requiresSignInForBestPrice =
    isPurpleProduct && isChinaUser && !userIsLoggedIn

  const initMultipleMiniMumQuantityError = materialPricing.map((m) => ({
    name: m.materialNumber,
    error: false,
  }))
  const [multipleMinimumQuantityError, setMultipleMinimumQuantityError] =
    useState<MultipleMinQtyErrorsType[]>(initMultipleMiniMumQuantityError)

  const onMinimumOrderQtyChange = (name, hasError) => {
    const newErrorList = multipleMinimumQuantityError.map((item) => ({
      ...item,
      error: item.name === name ? hasError : item.error,
    }))
    setMultipleMinimumQuantityError(newErrorList)
  }

  const {
    data: dealersData,
    refetch,
    error: dealersError,
  } = useDealersQuery({
    context: { userSession },
    errorPolicy: 'all',
    variables: {
      countryCode: userSession?.country,
      stateCode: String(currentUserState),
      searchTerm: '',
    },
    skip: !userIsLoggedIn || !isDTAQZuCustomer,
  })

  useEffect(() => {
    if (materialPricing[0].dealerId !== null) {
      const updatedDealerId = materialPricing[0].dealerId
      const { organizationName = null } = dealersData?.getDealers.find(
        (dealer) => dealer.id === updatedDealerId
      ) as Dealer
      setDealerSoldBy(organizationName)
    }
  }, [materialPricing[0].dealerId])

  useEffect(() => {
    if (dealersData && dealerCount === 0) {
      setDealerCount(dealersData?.getDealers?.length)
    }
  }, [dealersData])

  useEffect(() => {
    if (decision.enabled) {
      setFieldValue('0', {
        ...values['0'],
        quantity: '1',
      })
    }
  }, [])

  const onSearch = async (term: string) => {
    await refetch({
      countryCode: userSession.country,
      stateCode: String(currentUserState),
      searchTerm: term.toLowerCase(),
    })
  }
  const type =
    materialPricing && materialPricing.length && materialPricing[0].type

  // Check user to show/hide contact link for users in non-guest shopping countries
  const guestShowLocalDealer =
    currentUser?.__typename === 'GuestUser' && !canAddToCart
  const loggedInShowLocalDealer =
    currentUser?.__typename === 'LoggedInUser' &&
    !currentUser?.metadata?.guestShoppingType &&
    !isPublicOrderingCustomer &&
    !isQuoteRequester
  const isBuildingBlocks =
    router.query?.focus === 'buildingblocks' || router.query?.context === 'bbe'
  const showPreferredDealerSection =
    featureFlags.dealerCartEnabled &&
    isDTAQZuCustomer &&
    !isBuildingBlocks &&
    !isMissingDealer
  const isPreferredDealerRequired = isDTAQZuCustomer && isMissingDealer

  const noPriceForDealer = !materialPricing[0].price

  // if any item is set true, preserve the table cell for all rows
  const renderBuyNow = materialPricing.some(({ isBuyNow }) => isBuyNow)

  const bulkOrderCta = ctas?.find((form) => form.type === 'bulk order')
  const secondaryCta = ctas?.find((form) => form.type !== 'bulk order')

  const shouldDisabledAddToCartBtn = useCallback(() => {
    return multipleMinimumQuantityError.some((m) => m.error)
  }, [multipleMinimumQuantityError])

  const ctx = useContext(TrackedAvailabilityMessageContext)

  return (
    <>
      <div className={!!country ? classes.multiCountryTable : classes.table}>
        <Table>
          {((!noPriceForDealer && showPreferredDealerSection) ||
            !showPreferredDealerSection) && (
            <>
              <TableHead>
                <TableRow classes={{ root: classes.tHeadRow }}>
                  <TableCell>
                    {formatMessage({ id: 'SKU', defaultMessage: 'SKU' })}
                  </TableCell>
                  {type === 'ThirdPartyProvider' ? (
                    <TableCell>
                      {formatMessage({
                        id: 'VENDOR_SKU',
                        defaultMessage: 'Vendor SKU',
                      })}
                    </TableCell>
                  ) : (
                    ''
                  )}
                  <TableCell>
                    {formatMessage({
                      id: 'PACK_SIZE',
                      defaultMessage: 'Pack Size',
                    })}
                  </TableCell>
                  <TableCell>
                    {formatMessage({
                      id: 'AVAILABILITY',
                      defaultMessage: 'Availability',
                    })}
                  </TableCell>
                  {displaySDS && (
                    <TableCell>
                      {formatMessage({ id: 'SDS', defaultMessage: 'SDS' })}
                    </TableCell>
                  )}
                  <TableCell>
                    {formatMessage({ id: 'PRICE', defaultMessage: 'Price' })}
                  </TableCell>
                  <TableCell>
                    {canAddToCart && !hideAddToCartForPrepackItems
                      ? formatMessage({
                          id: 'QUANTITY',
                          defaultMessage: 'Quantity',
                        })
                      : null}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {materialPricing.map((material, index) => {
                  // if there is a primary availability message make it is shown
                  // if not the first message in the array will be shown
                  const availability =
                    material.availabilities?.filter((item) => {
                      return item.messageType === 'primary'
                    })[0] || material.availabilities?.[0]

                  return (
                    <TableRow
                      key={material.materialNumber}
                      data-testid={`P&A-row-${material.brand}-${material.materialNumber}`}
                      id={`P&A-row-${material.brand}-${material.materialNumber}`}
                    >
                      <TrackedAvailabilityMessageProvider
                        {...ctx}
                        brand={material.brand}
                        item={material.materialNumber}
                        pricing={material}
                      >
                        <ProductMaterialRow
                          key={index}
                          isMarketplace={isMarketplace}
                          canAddToCart={canAddToCart}
                          hideAddToCartForPrepackItems={
                            hideAddToCartForPrepackItems
                          }
                          initialShipEstimate={
                            // Need help understanding this portion
                            <InitialShipEstimate availability={availability!} />
                          }
                          productName={productName}
                          productDescription={productDescription}
                          productId={productId}
                          material={material}
                          index={index}
                          displayPromotionalBundlesAnchor={
                            displayPromotionalBundlesAnchor
                          }
                          displaySDS={displaySDS}
                          countryCode={country}
                          rowValues={values[index]}
                          renderBuyNow={renderBuyNow}
                          setMultipleMinimumQuantityError={
                            onMinimumOrderQtyChange
                          }
                          erpType={erpType}
                        />
                      </TrackedAvailabilityMessageProvider>
                    </TableRow>
                  )
                })}
              </TableBody>
            </>
          )}
          {showPreferredDealerSection && (
            <TableFooter className={classes.tfoot}>
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  {preferredDealerId && (
                    <PreferredDealerShippingText
                      preferredDealerName={dealerSoldBy ?? preferredDealerName}
                      noPriceForDealer={noPriceForDealer}
                    />
                  )}
                  <Button
                    variant="text"
                    className={classes.seeDealerOptionsLink}
                    onClick={() => setIsModalOpen(true)}
                  >
                    <SeeBuyingOptionsDealerText dealerCount={dealerCount} />
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {/* This version is the saving of a dealer when a preferred one is not set */}
        {isModalOpen && isPreferredDealerRequired && (
          <SelectDtaqDealerModal
            isSavingDealerPreference={isPreferredDealerRequired}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onDealerSelectCallback={submitForm}
            disabled={false}
            dealers={dealersData?.getDealers || []}
            onSearch={onSearch}
            error={dealersError}
            values={values}
            materialPricing={materialPricing}
            setFieldValue={setFieldValue}
            setIsModalOpen={setIsModalOpen}
          />
        )}
        {/* This modal will set the dealer for the item, but not save it */}
        {isModalOpen && !isPreferredDealerRequired && (
          <SelectDtaqDealerModal
            isSavingDealerPreference={isPreferredDealerRequired}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onDealerSelect={handlePriceUpdateForDealer}
            disabled={false}
            dealers={dealersData?.getDealers || []}
            onSearch={onSearch}
            error={dealersError}
            values={values}
            materialPricing={materialPricing}
            setFieldValue={setFieldValue}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
      {!country &&
        ((!noPriceForDealer && showPreferredDealerSection) ||
          !showPreferredDealerSection) && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop={!isMarketplaceCartEnabled && 4}
          >
            <Box display="flex" justifyContent="space-between">
              <ProductPartnerInfo
                isMarketplace={isMarketplace}
                marketplaceSellerId={marketplaceSellerId}
                marketplaceOfferId={marketplaceOfferId}
                productAttributes={productAttributes}
                displaySellerName={displaySellerName}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              my={4}
              px={2}
            >
              {guestShowLocalDealer || loggedInShowLocalDealer ? (
                <p>
                  <FormattedMessage
                    id="CONTACT_LOCAL_DEALER"
                    defaultMessage="To order products, please {contactLink} your local dealer."
                    values={{
                      contactLink: (
                        <Link
                          {...staticContent.index({
                            path: '/collections/offices',
                            language: userSession.language,
                            country: userSession.country,
                          })}
                          passHref
                        >
                          <a rel="noopener" target="_blank">
                            <FormattedMessage
                              id="CONTACT"
                              defaultMessage="contact"
                            />
                          </a>
                        </Link>
                      ),
                    }}
                  />
                </p>
              ) : (
                <>
                  {bulkOrderCta && !isMarketplace && (
                    <Link href={bulkOrderCta.url} passHref>
                      <MuiLink
                        data-testid={'p&a-form-request-bulk-link'}
                        id={'p&a-form-request-bulk-link'}
                        className={classes.bulkOrderLink}
                        color="primary"
                        target="_blank"
                        onClick={() =>
                          sendPricingAndAvailabilityEvent(
                            EventValues.RequestBulkOrder,
                            materialPricing[0].product
                          )
                        }
                      >
                        {bulkOrderCta.label}
                      </MuiLink>
                    </Link>
                  )}
                  {secondaryCta && (
                    <Button
                      component="a"
                      target="_blank"
                      href={secondaryCta.url}
                      variant="outlined"
                      size="large"
                      color="primary"
                      className={classes.buttonFilled}
                      onClick={() =>
                        sendPricingAndAvailabilityEvent(
                          `request ${secondaryCta.type}`,
                          materialPricing[0].product
                        )
                      }
                    >
                      {secondaryCta.label}
                    </Button>
                  )}
                  {requiresSignInForBestPrice ? (
                    <SignInButton size="medium" />
                  ) : (
                    (!isMarketplace ||
                      (isMarketplace && isMarketplaceCartEnabled)) && (
                      <AddToCartButton
                        handleAddToCart={(e) => {
                          e.preventDefault()
                          if (isPreferredDealerRequired)
                            return setIsModalOpen(true)
                          submitForm()
                        }}
                        isSubmitting={isSubmitting}
                        dirty={dirty}
                        disabled={shouldDisabledAddToCartBtn()}
                        canAddToCart={
                          canAddToCart &&
                          (!isMarketplace ||
                            (isMarketplace && isMarketplaceCartEnabled))
                        }
                        hideAddToCartForPrepackItems={
                          hideAddToCartForPrepackItems
                        }
                        isAddToList={isAddToList}
                        size="large"
                      />
                    )
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
    </>
  )
}

export default ProductPriceAvailabilityFormBody
