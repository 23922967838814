import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import { sellerRoutes, substanceRoute, useRouter } from '@src/routes'
import React, { useMemo, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ButtonBase, Typography } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { ProductSearchGroup } from '@src/types/graphql-types'
import { Link } from '@src/components/Link'
import { determineCatalogType, getValidSearchQuery } from '@utils/searchUtils'
import HandleMarkup from '@src/components/HandleMarkup'
import ResponsiveCatalogImage from '@src/components/ResponsiveCatalogImage'
import { FormattedMessage } from 'react-intl'
import messages from '../messages'
import clsx from 'clsx'
import vrStyles from '@src/styles/utils/vrStyles'
import ResponsiveSubstanceMetadata from './ResponsiveSubstanceMetadata'
import MobileProductTable from './MobileProductTable'
import { Breakpoints } from '@src/utils/useResponsiveSizes'
import ProductTable from './ProductTable'
import { getSellerName } from '@src/utils/searchUtils'
import MarketplaceSellerModal from '@src/components/MarketplaceSellerModal'
import ProductImagesModal from '@src/components/ProductImagesModal'
import { SubstanceFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import { TrackedAvailabilityMessageProvider } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import { sendProductInfoInteractionEvent } from '@src/utils/analytics'

const { vr1 } = vrStyles

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(6),
      },
    },
    overview: {
      borderBottom: `solid 1px ${theme.palette.grey[400]}`,
      padding: theme.spacing(4),
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        borderBottom: 'none',
        padding: theme.spacing(6, 0),
      },
    },
    flexGrow: {
      flexGrow: 1,
    },
    thumbnailWrapper: {
      height: 58,
      width: 58,
      borderRadius: 2,
      border: `solid 1px ${theme.palette.grey[500]}`,
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        width: 130,
        height: 130,
        borderRadius: 4,
        marginRight: theme.spacing(6),
      },
    },
    thumbnail: {
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto',
      height: 'auto',
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      // Prevents image distortion in IE
      thumbnail: {
        maxWidth: 124,
      },
    },
    name: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
      ...vr1,
      [theme.breakpoints.up('md')]: {
        lineHeight: 1, // aligns top of text closer to top of thumbnail
        fontSize: theme.typography.pxToRem(24),
        marginBottom: theme.spacing(3),
      },
    },
    synonyms: {
      lineHeight: 'normal',
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
    definition: {
      fontSize: theme.typography.pxToRem(12),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(1),
      },
    },
    product: {
      display: 'block',
    },
    breakWord: {
      wordBreak: 'break-word',
    },
    hideBelowMd: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    verticalLine: {
      [theme.breakpoints.up('lg')]: {
        borderRight: `1px solid ${theme.palette.grey[400]}`,
        marginRight: theme.spacing(4),
        paddingRight: theme.spacing(4),
        lineHeight: theme.typography.pxToRem(16),
      },
    },
    infoIcon: {
      transform: 'scale(1.2)',
      color: theme.palette.primary.main,
      height: theme.spacing(4),
      verticalAlign: 'bottom',
      cursor: 'pointer',
    },
    trustedInfo: {
      fontSize: theme.typography.pxToRem(12),
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    sellerInfo: {
      fontSize: theme.typography.pxToRem(12),
      marginTop: theme.spacing(2),
    },
    trigger: {
      height: '100%',
      width: '100%',
      display: 'block',
    },
    triggerText: {
      marginTop: theme.spacing(2),
    },
    triggerLink: {
      color: theme.palette.primary.main,
      fontWeight: 900,
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    imageText: {
      marginLeft: theme.spacing(1),
    },
  }
})

interface SubstanceProps {
  substance: SubstanceFieldsFragment
  substancePosition?: number
  onSubstanceClick?: () => void
}

const ResponsiveSubstance: React.FC<SubstanceProps> = ({
  onSubstanceClick,
  substance,
  substancePosition,
}) => {
  const {
    products,
    id,
    synonyms,
    images,
    linearFormula,
    empiricalFormula,
    legalName,
    __typename,
  } = substance

  const classes = useStyles()
  const router = useRouter()
  const validSearchQuery = getValidSearchQuery({
    focus: router.query.focus,
  })
  const img = substance.images[0] || {}
  const sizes = '58px'
  const productsWithImages = products.map((product) => ({
    ...product,
    images: images,
  }))

  const firstProduct = products[0]
  const isMarketplace = firstProduct?.isMarketplace
  const sellerName =
    firstProduct?.displaySellerName || getSellerName(firstProduct?.attributes)
  const marketplaceSellerId = firstProduct?.marketplaceSellerId || ''
  const marketplaceData = isMarketplace && !!sellerName && !!marketplaceSellerId
  const routerCatalogType = router.query.catalogType
  const catalogType = determineCatalogType(
    routerCatalogType ?? validSearchQuery.focus
  )

  const [formula, formulaLabel] = useMemo(() => {
    if (linearFormula) {
      return [linearFormula, messages.LINEAR_FORMULA]
    } else if (empiricalFormula) {
      return [empiricalFormula, messages.EMPIRICAL_FORMULA_HILL_NOTATION]
    }
    return [null, null]
  }, [empiricalFormula, linearFormula])

  const theme = useTheme()
  const isMdOrUp = useMediaQuery(theme.breakpoints.up(Breakpoints.MD), {
    defaultMatches: true,
  })
  const ResponsiveProductTable = isMdOrUp ? ProductTable : MobileProductTable

  const displayBadgesOnProduct = products.some((p) => p.features.length > 0)
  const totalProducts = products.length

  const [marketplaceModalIsOpen, setMarketplaceModalIsOpen] = useState(false)
  const toggleMarketplaceSellerModalOpen = (): void => {
    setMarketplaceModalIsOpen((open) => !open)
  }
  const shouldDisplayField =
    __typename?.toLowerCase() === ProductSearchGroup.Substance

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = (imageClick: boolean): void => {
    setIsModalOpen(!isModalOpen)

    if (!isModalOpen) {
      sendProductInfoInteractionEvent({
        action: 'view image',
        detail: substance.name,
        component: 'body',
        elementType: imageClick ? 'image' : 'link',
        elementText: imageClick ? undefined : 'all photos',
      })
    }
  }

  return (
    <div className={classes.root} data-testid="srp-substance-group">
      <div id="product-info" className={classes.overview}>
        <div>
          {shouldDisplayField ? (
            <>
              <div
                id="srp-image-container"
                className={classes.thumbnailWrapper}
              >
                <ButtonBase
                  className={classes.trigger}
                  onClick={() => toggleModal(true)}
                >
                  <ResponsiveCatalogImage
                    className={classes.thumbnail}
                    alt={img?.altText || substance.name}
                    src={img?.mediumUrl}
                    sizes={sizes}
                    urls={[img?.smallUrl, img?.mediumUrl, img?.largeUrl]}
                    width="130"
                    height="50"
                  />
                </ButtonBase>
              </div>
              <div className={classes.triggerText}>
                <span
                  role="button"
                  className={classes.triggerLink}
                  onClick={() => toggleModal(false)}
                  onKeyPress={() => toggleModal(false)}
                  tabIndex={0}
                >
                  <FormattedMessage {...messages.ALL_PHOTOS} />
                </span>
                <span className={classes.imageText}>
                  ({substance.images.length})
                </span>
              </div>
            </>
          ) : null}
        </div>
        <div className={classes.flexGrow}>
          <Typography
            id="substance-name"
            component="h2"
            className={clsx(classes.name, classes.breakWord)}
          >
            {totalProducts > 1 ? (
              <Link {...substanceRoute.index(id, catalogType)}>
                <a
                  aria-hidden
                  data-testid={`substance-name-link-${substance.name}`}
                  id="substance-link"
                  onClick={onSubstanceClick}
                  role="button"
                >
                  <HandleMarkup value={legalName || substance.name} />
                </a>
              </Link>
            ) : (
              <HandleMarkup value={legalName || substance.name} />
            )}
          </Typography>
          {shouldDisplayField && synonyms?.length > 0 && (
            <div className={classes.synonyms}>
              <Typography component="span" variant="caption">
                <FormattedMessage id="SYNONYM(S)" defaultMessage="Synonym(s)" />
                {': '}
              </Typography>
              <Typography component="span" className={classes.definition}>
                <HandleMarkup
                  className={classes.breakWord}
                  value={synonyms.join(', ')}
                />
              </Typography>
            </div>
          )}
          <div className={classes.hideBelowMd}>
            {shouldDisplayField && formula && (
              <>
                <Typography component="span" variant="caption">
                  <FormattedMessage {...formulaLabel} />
                  {': '}
                </Typography>
                <Typography component="span" className={classes.definition}>
                  <HandleMarkup className={classes.breakWord} value={formula} />
                </Typography>
              </>
            )}
            <ResponsiveSubstanceMetadata substance={substance} />
          </div>
          {marketplaceData && (
            <div className={classes.sellerInfo}>
              <FormattedMessage {...messages.CONTACT_SELLER_SHIPPED_SOLD} />{' '}
              <Link {...sellerRoutes.index(sellerName, marketplaceSellerId)}>
                <a>{sellerName}</a>
              </Link>
              <span className={classes.verticalLine} />
              <span className={classes.trustedInfo}>
                <FormattedMessage {...messages.CONTACT_SELLER_TRUSTED} />
                <InfoIcon
                  className={classes.infoIcon}
                  onClick={toggleMarketplaceSellerModalOpen}
                />
                {marketplaceModalIsOpen && (
                  <MarketplaceSellerModal
                    open={marketplaceModalIsOpen}
                    onCancel={toggleMarketplaceSellerModalOpen}
                  />
                )}
              </span>
            </div>
          )}
        </div>
      </div>
      <TrackedAvailabilityMessageProvider
        source={`srp - ${validSearchQuery.focus}`}
      >
        <ResponsiveProductTable
          products={productsWithImages}
          displayBadgesOnProduct={displayBadgesOnProduct}
          focus={validSearchQuery.focus}
          substanceId={substance.id}
          substanceName={substance.name}
          substancePosition={substancePosition}
          substanceKeyAttributes={substance.keyAttributes}
        />
      </TrackedAvailabilityMessageProvider>
      <ProductImagesModal
        isOpen={isModalOpen}
        onClose={setIsModalOpen}
        images={substance.images}
        name={substance.name}
        productId={firstProduct.productNumber}
      />
    </div>
  )
}

export default ResponsiveSubstance
