import React from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import {
  MaterialAvailability,
  MaterialAvailabilityKey,
} from '@src/types/graphql-types'
import AvailabilityOverwriteMessage from '@src/components/AvailabilityOverwriteMessage'
import messages from '@utils/messages'
import CheckMarkCircleIcon from '@src/icons/CheckMarkCircleIcon'
import ErrorIcon from '@material-ui/icons/Error'
import { TrackedAvailabilityMessage } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessage'
import { availableWithinFiveDays } from '@src/utils/availableWithinFiveDays'
import { useDecision } from '@optimizely/react-sdk'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    paddingInlineStart: 0,
    transition: 'color 250ms',
  },
  availabilityItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
    borderTop: 'none',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  availableIcon: {
    fontSize: 16,
    marginRight: theme.spacing(2),
    display: 'flex',
  },
  disabled: {
    color: theme.palette.grey[400],
    '& svg': {
      fill: theme.palette.grey[400],
    },
  },
}))

interface Props {
  availabilities: MaterialAvailability[]
  updateAvailable: boolean
  className?: string
  hideQuantity?: boolean
  hidePlantLoc?: boolean
}

export const AvailabilityErrorMessage: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const classes = useStyles()

  return (
    <ol className={clsx(classes.root, className)}>
      <li className={classes.availabilityItem}>
        <div className={classes.availableIcon}>
          <ErrorIcon color="error" fontSize="inherit" />
        </div>
        <p>{children}</p>
      </li>
    </ol>
  )
}

const AvailabilityMessages: React.FC<Props> = ({
  availabilities,
  updateAvailable,
  className,
  hideQuantity,
  hidePlantLoc,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const [flagData] = useDecision('wabt-210')

  return (
    <ol
      className={clsx(
        classes.root,
        className,
        updateAvailable && classes.disabled
      )}
    >
      {availabilities.map((availability, index) => {
        if (availability.key === MaterialAvailabilityKey.OutOfStockKey) {
          return (
            <li key={index} className={classes.availabilityItem}>
              <p>
                <TrackedAvailabilityMessage
                  id="OUT_OF_STOCK_KEY"
                  defaultMessage="We apologize but fulfillment and delivery of this product is delayed. We are working to minimize these delays as quickly as possible."
                  availability={availability}
                />
              </p>
            </li>
          )
        }
        if (availability.key === MaterialAvailabilityKey.UnknownAvailability) {
          return (
            <li key={index} className={classes.availabilityItem}>
              <div className={classes.availableIcon}>
                <CheckMarkCircleIcon color="warning" fontSize="inherit" />
              </div>
              <p>
                <TrackedAvailabilityMessage
                  id="AVAILABILITY_UNKNOWN"
                  defaultMessage="Availability Unknown"
                  availability={availability}
                />
              </p>
            </li>
          )
        }
        if (
          availability.key === MaterialAvailabilityKey.ContactForAvailability
        ) {
          return (
            <li key={index} className={classes.availabilityItem}>
              <div className={classes.availableIcon}>
                <CheckMarkCircleIcon color="warning" fontSize="inherit" />
              </div>
              <p>
                <TrackedAvailabilityMessage
                  id="CONTACT_FOR_AVAILABILITY"
                  defaultMessage="Please contact Customer Service for Availability"
                  availability={availability}
                />
              </p>
            </li>
          )
        }
        if (
          availability.key === MaterialAvailabilityKey.FulfilmentDeliveryDelayed
        ) {
          return (
            <li key={index} className={classes.availabilityItem}>
              <div className={classes.availableIcon}>
                <CheckMarkCircleIcon color="warning" fontSize="inherit" />
              </div>
              <p>
                <TrackedAvailabilityMessage
                  id="FULFILMENT_DELIVERY_DELAYED"
                  defaultMessage="Fulfilment and delivery delayed"
                  availability={availability}
                />
              </p>
            </li>
          )
        }
        if (
          availability.availabilityOverwriteMessage?.messageValue &&
          availability.messageType === 'secondary'
        ) {
          return (
            <li key={index} className={classes.availabilityItem}>
              <AvailabilityOverwriteMessage
                availabilityOverwrite={
                  availability.availabilityOverwriteMessage
                }
              />
            </li>
          )
        }

        // MaterialAvailability has nullable date and quantity fields that must be checked before use
        if (!availability.date || !availability.quantity) return null

        // the availability message in the 'from' modal should only show secondaryMessage not primary
        if (
          availability.messageType &&
          availability.messageType === 'secondary'
        ) {
          return (
            <li key={index} className={classes.availabilityItem}>
              <div className={classes.availableIcon}>
                <CheckMarkCircleIcon color="success" fontSize="inherit" />
              </div>
              {availableWithinFiveDays(availability) && flagData.enabled ? (
                <span>
                  <TrackedAvailabilityMessage
                    id="IN_STOCK"
                    defaultMessage="IN_STOCK"
                    availability={availability}
                  />
                </span>
              ) : (
                <p>
                  {!hideQuantity &&
                  (availability.quantity > 0 || availabilities.length > 0)
                    ? `${availability.quantity} `
                    : null}
                  <TrackedAvailabilityMessage
                    availability={availability}
                    {...messages[
                      availability.key ===
                      MaterialAvailabilityKey.EstimatedDeliveryOn
                        ? 'AVAILABILITY_ESTIMATED_DELIVERY_ON'
                        : availability.key
                    ]}
                  />{' '}
                  <FormattedDate
                    value={new Date(availability.date)}
                    year="numeric"
                    month="long"
                    day="2-digit"
                    timeZone="UTC"
                  />
                  {!hidePlantLoc && availability.plantLoc && (
                    <>
                      {` ${intl.formatMessage(
                        messages.PRODUCT_AVAILABILITY_FROM
                      )} `}
                      <span>{availability.plantLoc}</span>
                    </>
                  )}
                </p>
              )}
            </li>
          )
        } else {
          return null
        }
      })}
    </ol>
  )
}

export default AvailabilityMessages
