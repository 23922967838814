// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CreateCustomPromotionalBundleMutationVariables = Types.Exact<{
  input: Types.CustomPromotionalBundleInput;
}>;


export type CreateCustomPromotionalBundleMutation = { __typename?: 'Mutation', createCustomPromotionalBundle?: boolean | null };


export const CreateCustomPromotionalBundleDocument = gql`
    mutation CreateCustomPromotionalBundle($input: CustomPromotionalBundleInput!) {
  createCustomPromotionalBundle(input: $input)
}
    `;
export type CreateCustomPromotionalBundleMutationFn = ApolloReactCommon.MutationFunction<CreateCustomPromotionalBundleMutation, CreateCustomPromotionalBundleMutationVariables>;
export type CreateCustomPromotionalBundleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomPromotionalBundleMutation, CreateCustomPromotionalBundleMutationVariables>, 'mutation'>;

    export const CreateCustomPromotionalBundleComponent = (props: CreateCustomPromotionalBundleComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomPromotionalBundleMutation, CreateCustomPromotionalBundleMutationVariables> mutation={CreateCustomPromotionalBundleDocument} {...props} />
    );
    

/**
 * __useCreateCustomPromotionalBundleMutation__
 *
 * To run a mutation, you first call `useCreateCustomPromotionalBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomPromotionalBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomPromotionalBundleMutation, { data, loading, error }] = useCreateCustomPromotionalBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomPromotionalBundleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomPromotionalBundleMutation, CreateCustomPromotionalBundleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCustomPromotionalBundleMutation, CreateCustomPromotionalBundleMutationVariables>(CreateCustomPromotionalBundleDocument, options);
      }
export type CreateCustomPromotionalBundleMutationHookResult = ReturnType<typeof useCreateCustomPromotionalBundleMutation>;
export type CreateCustomPromotionalBundleMutationResult = ApolloReactCommon.MutationResult<CreateCustomPromotionalBundleMutation>;
export type CreateCustomPromotionalBundleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomPromotionalBundleMutation, CreateCustomPromotionalBundleMutationVariables>;