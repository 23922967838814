import React from 'react'
import { Link, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import ReplacementProductsCarousel from '@src/components/ReplacementProductsCarousel'
import ResponsiveModal, {
  ResponsiveModalBody,
  ModalSizes,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import { ProductCardType, getSellerName } from '@src/utils/searchUtils'
import { DiscontinuedMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { ContactSellerReasonType, sellerRoutes, useRouter } from '@src/routes'
import { Linq } from '@src/components/Linq'
import messages from '@utils/MessageSets/defineReplacementProductsCarouselModalMessages'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
  },
  message: {
    marginLeft: theme.spacing(1),
  },
}))

interface ReplacementProductsCarouselModalProps {
  errorKey: string
  product?: Partial<PdpFieldsFragment>
  replacementProducts?: DiscontinuedMaterialPricingDetailFragment['replacementProducts']
  onClose: () => void
}

const ReplacementProductsCarouselModal: React.FC<
  ReplacementProductsCarouselModalProps
> = ({ errorKey, product, replacementProducts, onClose }) => {
  const classes = useStyles()
  const router = useRouter()
  const messageId = `ReplacementProductsCarouselModal.${errorKey}`
  const sellerName =
    product?.displaySellerName || getSellerName(product?.attributes)
  const mainProductToCompare = {
    productKey: product?.productKey,
    brandKey: product?.brand?.key,
    images: product?.images,
    showViewDetailsLink: true,
  }

  const getAdditionalMessage = () => {
    if (product?.isMarketplace) {
      return (
        <Linq
          {...sellerRoutes.contact(
            sellerName,
            product?.marketplaceSellerId ?? ``,
            router.asPath,
            ContactSellerReasonType.OFFER_MESSAGING,
            product?.marketplaceOfferId ?? ``
          )}
        >
          <FormattedMessage
            {...messages['CONTACT_SELLER_WITH_NAME']}
            values={{ sellerName }}
          />
        </Linq>
      )
    }

    if (replacementProducts?.length) {
      return (
        <Typography className={classes.message} variant="inherit">
          <FormattedMessage {...messages['REPLACEMENT_PRODUCT_MESSAGE']} />
        </Typography>
      )
    }

    return (
      <>
        <Link className={classes.link} href={'/support/customer-support'}>
          <FormattedMessage {...messages['CONTACT_TECHNICAL_SERVICE']} />
        </Link>
        <FormattedMessage {...messages['FOR_ASSISTANCE']} />
      </>
    )
  }

  return (
    <ResponsiveModal
      size={ModalSizes.Small}
      open
      onClose={onClose}
      renderTitle={() =>
        messages[errorKey]?.title ? (
          <Typography variant="h2">
            <FormattedMessage
              id={messageId}
              defaultMessage="{title}"
              values={{
                title: messages[errorKey]?.title,
              }}
            />
          </Typography>
        ) : null
      }
    >
      <ResponsiveModalBody>
        <FormattedMessage
          {...messages[errorKey]}
          values={{
            productNumber: product?.productNumber,
          }}
        />
        <FormattedMessage
          id={messageId}
          defaultMessage="{additionalMessage}"
          values={{
            additionalMessage: getAdditionalMessage(),
          }}
        />
        {replacementProducts?.length ? (
          <ReplacementProductsCarousel
            type={ProductCardType.Recommended}
            preloadCardImages
            mainProduct={mainProductToCompare}
            products={replacementProducts ?? []}
            slidesToShow={1}
            withoutControls={
              replacementProducts?.length === 1 ||
              replacementProducts?.length <= 4
            }
          />
        ) : null}
      </ResponsiveModalBody>
    </ResponsiveModal>
  )
}

export default ReplacementProductsCarouselModal
