import { useCallback, useMemo } from 'react'
import qs from 'qs'
import { NextRouter } from 'next/router'
import { useSessionStorage, sessionStorage } from 'react-storage'
import { productDetailRoute } from '@src/routes'
import { ProductDetailQueryVariables } from '@src/queries/ProductDetailQuery.generated'
import { CatalogType, CatalogImage } from '@src/types/graphql-types'
import { SearchQuery, SearchFocusType } from '@src/utils/searchUtils'
import { sendProductInfoInteractionEvent } from '@src/utils/analytics'
import { GTMEventCategory, EventValues } from '@sial/common-utils'
import { parseRegionalUrl } from '@src/utils/regional'

export interface CompareProductItem extends ProductDetailQueryVariables {
  images?: Partial<CatalogImage>[]
  productNumber?: string
  productName?: string
}

export type SelectedProductState = ProductDetailQueryVariables[] | []
export const PROUCT_COMPARE_DELIMITERS = {
  PRODUCTS: ',',
  OPTION_SETS: ':',
}

export const parseProductCompareString = (
  productsUrlString?: string
): SelectedProductState => {
  if (!productsUrlString || productsUrlString.length === 0) {
    return []
  }

  const productSet = productsUrlString.split(PROUCT_COMPARE_DELIMITERS.PRODUCTS)
  if (!productSet || productSet.length === 0) {
    return []
  }

  const productsList: ProductDetailQueryVariables[] = []
  productSet.map((product) => {
    const options = product.split(PROUCT_COMPARE_DELIMITERS.OPTION_SETS)
    if (options && options.length >= 1) {
      const productKey = options[0]
      const brandKey = options.length > 1 ? options[1] : ''
      const catalogType = options.length > 2 ? options[2] : ''
      const productDetais: ProductDetailQueryVariables = {
        productKey,
        brandKey,
      }
      if (catalogType) {
        if (catalogType === CatalogType.B2b) {
          productDetais.catalogType = CatalogType.B2b
        } else if (catalogType === CatalogType.Buildingblocks) {
          productDetais.catalogType = CatalogType.Buildingblocks
        } else if (catalogType === CatalogType.Sial) {
          productDetais.catalogType = CatalogType.Sial
        }
      }

      productsList.push(productDetais)
    }
    return product
  })

  return productsList
}

export const buildProductCompareString = (
  compareProducts: ProductDetailQueryVariables[]
) => {
  return compareProducts
    .map((product) => {
      const productKey = product.productKey
      const brandKey = product.brandKey
      const catalogType = product.catalogType
      return `${productKey}${PROUCT_COMPARE_DELIMITERS.OPTION_SETS}${brandKey}${PROUCT_COMPARE_DELIMITERS.OPTION_SETS}${catalogType}`
    })
    .join(PROUCT_COMPARE_DELIMITERS.PRODUCTS)
}

const COMPARE_PRODUCTS_STORAGE_KEY = 'compareProducts'
export const MAX_COMPARE_SIZE = 4

export function useCompareProducts() {
  const compareProducts = useSessionStorage<CompareProductItem[]>(
    COMPARE_PRODUCTS_STORAGE_KEY,
    []
  )

  const addCompareProduct = useCallback(
    (product: CompareProductItem) => {
      if (!Array.isArray(compareProducts)) {
        return sessionStorage.setItem(COMPARE_PRODUCTS_STORAGE_KEY, [product])
      }

      if (compareProducts.length >= MAX_COMPARE_SIZE) {
        return
      }

      const productAlreadyAdded =
        compareProducts.findIndex(
          ({ brandKey, productKey }) =>
            product.brandKey === brandKey && product.productKey === productKey
        ) >= 0

      if (!productAlreadyAdded) {
        return sessionStorage.setItem(
          COMPARE_PRODUCTS_STORAGE_KEY,
          compareProducts.concat([product])
        )
      }
    },
    [compareProducts]
  )

  const removeCompareProduct = useCallback(
    (product: CompareProductItem) => {
      if (!Array.isArray(compareProducts)) {
        return
      }

      const productIndex = compareProducts.findIndex(
        ({ brandKey, productKey }) =>
          product.brandKey === brandKey && product.productKey === productKey
      )

      if (productIndex >= 0) {
        return sessionStorage.setItem(
          COMPARE_PRODUCTS_STORAGE_KEY,
          compareProducts
            .slice(0, productIndex)
            .concat(compareProducts.slice(productIndex + 1))
        )
      }
    },
    [compareProducts]
  )

  const clearAllCompareProducts = useCallback(
    (newValue: CompareProductItem[] = []) =>
      sessionStorage.setItem(COMPARE_PRODUCTS_STORAGE_KEY, newValue),
    []
  )

  const compareProductsMemo = useMemo(
    () => (Array.isArray(compareProducts) ? compareProducts : []),
    [compareProducts]
  )

  const sendToProductCompareDetail = useCallback(
    (query: SearchQuery, router: NextRouter) => {
      const isStructureSearch = query.focus === SearchFocusType.StructureSearch
      const { pathAfterBasename } = parseRegionalUrl(router.asPath)

      sendProductInfoInteractionEvent(
        {
          action: EventValues.CompareProducts,
          detail: compareProducts
            ?.map((product) => product.productKey)
            .join('|'),
          section: 'choose up to 4 products to compare',
          component: 'slider',
          elementType: 'button',
          elementText: 'compare',
        },
        {
          eventCategory: isStructureSearch
            ? GTMEventCategory.StructureSearchResultPage
            : GTMEventCategory.SearchResultPage,
          eventAction: EventValues.CompareProducts,
          eventLabel: compareProducts
            ?.map((product) => product.productKey)
            .join(' - '),
          eventInteractionType: 0,
        }
      )
      const productCompareString = buildProductCompareString(
        compareProducts || []
      )
      const compareQuery = {
        ...query,
        products: productCompareString,
        returnUrl: pathAfterBasename,
      }
      return router.push(
        `${productDetailRoute.compare()}?${qs.stringify(compareQuery)}`
      )
    },
    [compareProducts]
  )

  return {
    compareProducts: compareProductsMemo,
    addCompareProduct,
    removeCompareProduct,
    clearAllCompareProducts,
    sendToProductCompareDetail,
  }
}
