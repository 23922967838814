import Image from 'next/image'
import React from 'react'

const GreenBadge = () => {
  return (
    <Image
      style={{ margin: '4px' }}
      src="/static/globe_greener_alt_icon.svg"
      alt="greener alternative"
      title="Greener Alternative Product"
      height={28}
      width={28}
    />
  )
}

export default GreenBadge
