import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Button,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { makeStyles } from '@material-ui/core/styles'
import messages from '@utils/messages'
import LiquidInputAdapter from '@src/components/LiquidInputAdapter'
import LiquidMaterialAutoSuggestAdapter from '@src/components/LiquidMaterialAutoSuggestAdapter'
import LiquidQuantityInputAdapter from '@src/components/LiquidQuantityInputAdapter'
import AlertDialog from '@src/components/AlertDialog'
import {
  useGlobalSnackbar,
  SnackbarType,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { useCreateCustomPromotionalBundleMutation } from '@src/mutations/CreateCustomBundleMutation.generated'
import { sendBuyInKitClickEvent } from '@src/utils/analytics'
import { BuyInKItEventActionEnum } from '@utils/analytics/enums'
import { PromotionalBundle } from '@src/types/graphql-types'

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.background.grey,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.secondary.light,
  },
  dialogActionsRoot: {
    padding: '8px 24px',
  },
  submitButton: {
    minWidth: '182px',
    marginLeft: theme.spacing(4),
  },
  cancelButton: {
    color: theme.palette.grey[600],
    borderWidth: '2px',
    minWidth: '120px',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(0, 3),
  },
  dialogWidth: {
    maxWidth: theme.typography.pxToRem(660),
  },
}))

const defaultBundleItem = {
  materialNumber: '',
  quantity: 1,
}
const initialValues = {
  bundleDescription: '',
  bundleItems: [
    defaultBundleItem,
    defaultBundleItem,
    defaultBundleItem,
    defaultBundleItem,
    defaultBundleItem,
  ],
}

const filterBundleItems = (bundleItems) =>
  bundleItems.filter(({ materialNumber }) => materialNumber)

interface CreateCustomBundleProps {
  setCreateCustomBundleModal: React.Dispatch<React.SetStateAction<boolean>>
  materialNumber: string
  bundleData: PromotionalBundle[]
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateCustomBundleModal: React.FC<CreateCustomBundleProps> = ({
  setCreateCustomBundleModal,
  materialNumber,
  bundleData,
  setShowModal,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { setGlobalSnackbarState } = useGlobalSnackbar()
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [createCustomBundle, { loading }] =
    useCreateCustomPromotionalBundleMutation()

  const validateForm = (values) => {
    const filteredBundleItems = filterBundleItems(values.bundleItems)

    if (filteredBundleItems.length < 2) {
      return { bundleItems: formatMessage(messages.CUSTOM_BUNDLE_ITEMS_ERROR) }
    }
    return {}
  }

  const handleSubmit = async ({ bundleDescription, bundleItems }) => {
    const bundleRequest = {
      bundleDescription,
      bundleItems: filterBundleItems(bundleItems),
    }
    sendBuyInKitClickEvent(
      materialNumber,
      bundleData,
      BuyInKItEventActionEnum.SubmitCustomBundleRequest
    )
    await createCustomBundle({
      variables: { input: bundleRequest },
    })

    setGlobalSnackbarState({
      open: true,
      message: formatMessage(messages.CUSTOM_BUNDLE_REQUEST_SUCCESS),
      variant: SnackbarType.Success,
    })
    setCreateCustomBundleModal(false)
    setShowModal(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ values, submitForm, errors }) => (
        <Dialog classes={{ paperWidthSm: classes.dialogWidth }} fullWidth open>
          <DialogTitle className={classes.dialogTitle}>
            {formatMessage(messages.CREATE_CUSTOM_BUNDLE)}
            <IconButton
              className={classes.closeButton}
              disabled={loading}
              onClick={() => setOpenCancelModal(true)}
            >
              <CloseIcon fontSize="large" onClick={() => setShowModal(false)} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Form>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <DialogContentText>
                    {formatMessage(messages.CUSTOM_BUNDLE_EXPLANATION)}
                  </DialogContentText>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={
                    !errors.bundleItems ? { marginBottom: '17px' } : undefined
                  }
                >
                  <Field
                    label={formatMessage(messages.CUSTOM_BUNDLE_DESCRIPTION)}
                    name="bundleDescription"
                    filled
                    multiline
                    component={LiquidInputAdapter}
                    disabled={loading}
                  />
                </Grid>
                <ErrorMessage name="bundleItems">
                  {(message) => (
                    <div className={classes.errorMessage}>{message}</div>
                  )}
                </ErrorMessage>
                <FieldArray name="bundleItems">
                  {({ push }) => (
                    <>
                      {values.bundleItems.map((_item, index) => (
                        <Grid item container spacing={2} key={index}>
                          <Grid item xs={10}>
                            <Field
                              name={`bundleItems[${index}].materialNumber`}
                              label={
                                index === 0 &&
                                formatMessage(messages.SKU_PLUS_PACK_SIZE)
                              }
                              component={LiquidMaterialAutoSuggestAdapter}
                              placeholder={formatMessage(
                                messages.ENTER_SKU_PLACEHOLDER
                              )}
                              disabled={loading}
                              filled
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Field
                              name={`bundleItems[${index}].quantity`}
                              label={
                                index === 0 && formatMessage(messages.QUANTITY)
                              }
                              component={LiquidQuantityInputAdapter}
                              min={1}
                              max={999}
                              disabled={loading}
                              filled
                            />
                          </Grid>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Button
                          color="secondary"
                          startIcon={<AddCircleIcon fontSize="large" />}
                          onClick={() => push(defaultBundleItem)}
                        >
                          {formatMessage(messages.ADD_ROW)}
                        </Button>
                      </Grid>
                    </>
                  )}
                </FieldArray>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions
            disableSpacing
            classes={{
              root: classes.dialogActionsRoot,
            }}
          >
            <Button
              className={classes.cancelButton}
              variant="outlined"
              onClick={() => setOpenCancelModal(true)}
            >
              {formatMessage(messages.CANCEL)}
            </Button>
            <AlertDialog
              open={openCancelModal}
              description={formatMessage(messages.CANCEL_CART_CONFIRM_TEXT)}
              cancelText={formatMessage(messages.CANCEL_CART_NO)}
              confirmText={formatMessage(messages.CANCEL_CART_YES)}
              onCancel={() => setOpenCancelModal(false)}
              onConfirm={() => setCreateCustomBundleModal(false)}
            />
            <Button
              className={classes.submitButton}
              color="primary"
              variant="contained"
              onClick={() => submitForm()}
              disabled={loading}
            >
              {formatMessage(messages.SUBMIT)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default CreateCustomBundleModal
