import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    banner: {
      alignItems: 'center',
      background: theme.palette.primary.light,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2, 0, 2),
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        padding: theme.spacing(4, 45, 4, 6),
      },
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.up('sm')]: {
          fontSize: theme.typography.pxToRem(15),
        },
      },
    },
  }
})

interface DocumentLibraryBannerProps {
  currentTab?: string
  children?: React.ReactNode
}

const DocumentLibraryBanner: React.FC<DocumentLibraryBannerProps> = ({
  children,
}) => {
  const classes = useStyles()
  return <div className={classes.banner}>{children}</div>
}

export default DocumentLibraryBanner
