import React, { FC, ReactNode, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ButtonBase, Collapse } from '@material-ui/core'
import clsx from 'clsx'
import CaretDownIcon from '@src/icons/CaretDownIcon'
import vrStyles from '@src/styles/utils/vrStyles'

const { vr8 } = vrStyles
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...vr8,
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1),
  },
  body: {
    paddingTop: theme.spacing(4),
  },
  icon: {
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.primary.main,
    transition: 'transform 0.25s',
  },
  iconIsOpen: {
    transform: 'rotate(180deg)',
  },
}))

interface Props {
  renderHeading(): ReactNode
  children: ReactNode
}

const MobileFacetGroup: FC<Props> = ({ renderHeading, children }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const iconClasses = clsx(classes.icon, {
    [classes.iconIsOpen]: isOpen,
  })

  const toggleAccordion = (): void => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={classes.root}>
      <ButtonBase onClick={toggleAccordion} className={classes.heading}>
        {renderHeading()}
        <CaretDownIcon className={iconClasses} />
      </ButtonBase>
      <Collapse in={isOpen}>
        <div className={classes.body}>{children}</div>
      </Collapse>
    </div>
  )
}

export default MobileFacetGroup
