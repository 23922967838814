import React from 'react'
import { Box } from '@material-ui/core'
import GridSkeleton from '../GridSkeleton'
import ProductSearchResultsSkeleton from '../../routes/SearchResults/ProductSearchResults/ProductSearchResultsSkeleton'
import { ApolloError } from 'apollo-boost'
import { ViewPreference } from '../../routes/SearchResults/SearchResults'
import SearchResultsError from '../SearchResultsError'

interface GridOrListSRPErrorAndLoadingProps {
  view: ViewPreference
  error?: ApolloError
}
const GridOrListSRPErrorAndLoading: React.FC<
  GridOrListSRPErrorAndLoadingProps
> = ({ view, error }) => {
  const LoadingSkeleton =
    view === ViewPreference.list ? ProductSearchResultsSkeleton : GridSkeleton
  return error ? (
    //TODO add error handler pages when designed
    <Box display="flex" flexDirection="column" alignItems="center" mt={20}>
      <SearchResultsError error={error} />
    </Box>
  ) : (
    <LoadingSkeleton />
  )
}

export default GridOrListSRPErrorAndLoading
