import React, { useMemo, useState } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { Formik, Form, Field, FormikProps } from 'formik'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import LiquidSelectAdapater from '@src/components/LiquidSelectAdapter'
import LiquidQuantityInput from '@src/components/LiquidQuantityInput'
import SubmitButton from './SubmitButton'
import { useAddToCartWithGlobalSnackbar } from '@utils/useCart'
import { useCurrentUser, SitePreference } from '@utils/useCurrentUser'
import AddToListButton from '@src/components/AddToListButton'
import messages from '@utils/messages'
import { useRouter } from 'next/router'
import {
  setStoreAddToCartDataForAnalytics,
  ProductListPagesEnum,
} from '@utils/tealiumAnalytics'
import MaterialAvailabilityPreview from '@src/components/MaterialAvailabilityPreview/MaterialAvailabilityPreview'
import SpacingBox from '@src/components/SpacingBox'

const useStyles = makeStyles((theme: Theme) => ({
  materialSelect: {
    marginBottom: theme.spacing(3),
    '& .MuiSelect-selectMenu': {
      fontSize: theme.typography.pxToRem(16),
    },
    '& $optionPrice': {
      display: 'none',
    },
  },
  option: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  optionPrice: {},
  quantityInput: { fontWeight: theme.typography.fontWeightMedium },
  listPrice: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  },
  price: {
    fontSize: theme.typography.pxToRem(36),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  multiCountryTable: {
    paddingBottom: theme.spacing(7),
  },
}))

interface MobilePAFormValues {
  materialNumber: string
  quantity: number
  material: ValidMaterialPricingDetailFragment
  marketplaceOfferId?: string
}

interface Props {
  materialPricing: ValidMaterialPricingDetailFragment[]
  country?: string
  productNumber: string
  canAddToCart: boolean
  anonymousUser: boolean
  marketplaceOfferId?: string
}

const MobileProductPriceAvailability: React.FC<Props> = ({
  materialPricing,
  country,
  productNumber,
  canAddToCart,
  anonymousUser,
  marketplaceOfferId,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const initialFormValues = {
    materialNumber: materialPricing[0].materialNumber,
    quantity: 1,
    material: materialPricing[0],
  }
  const [formikInitialValues, setFormikInitialValues] =
    useState(initialFormValues)
  const addToCart = useAddToCartWithGlobalSnackbar()
  const { getSitePreference } = useCurrentUser()
  const hideAddToCartForPrepackItems = getSitePreference(
    SitePreference.HideAddToCartForPrepackItems
  )

  /**
   * have to useMemo here bc we need this hook to run before render
   * useEffect works in the UI but causes matrial ui warning as result of formik initial props
   * not having the correct data until second render
   * Ex: Material-UI: you have provided an out-of-range value `G8898-500G` for the select (name="materialNumber") component.
   * Consider providing a value that matches one of the available options or ''.
   * The available values are `T1503-25G`, `T1503-100G`.
   */
  useMemo(() => {
    return setFormikInitialValues(initialFormValues)
    // NOTE: the dependency array requires productNumber, not initialFormValues to operate properly.
    // Also, see the above comment about using useMemo vs. useEffect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productNumber])

  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        // material pricing info of selected add to cart item(s)
        const selectedAddToCartMaterialItems = materialPricing.filter(
          (material) =>
            [values].filter(
              (item) => item.materialNumber === material?.materialNumber
            )[0]
        )
        setStoreAddToCartDataForAnalytics({
          items: selectedAddToCartMaterialItems,
          productListPageName: router.asPath.includes('/search')
            ? ProductListPagesEnum.SearchResultPage
            : ProductListPagesEnum.ProductDetailPage,
        })
        addToCart(
          [
            {
              materialNumber: values.materialNumber,
              quantity: values.quantity,
              marketplaceOfferId: marketplaceOfferId,
            },
          ],
          setSubmitting,
          resetForm,
          materialPricing[0].catalogType === 'marketplace'
        )
      }}
      enableReinitialize
    >
      {({
        values,
        isSubmitting,
        initialValues,
      }: FormikProps<MobilePAFormValues>) => {
        const selectedMaterial: ValidMaterialPricingDetailFragment =
          values.material

        const propsNotReinitialzied =
          formikInitialValues.materialNumber !== initialValues.materialNumber
        return propsNotReinitialzied ? null : (
          <Form>
            <div className={clsx(country && classes.multiCountryTable)}>
              {/* Material Number Select */}
              <Box mb={1}>
                <label htmlFor="TEMP">
                  <FormattedMessage {...messages.SKU} />
                </label>
              </Box>
              <Field name="materialNumber">
                {({ form, field, meta }) => (
                  <LiquidSelectAdapater
                    filled
                    field={field}
                    meta={meta}
                    form={form}
                    size="large"
                    className={classes.materialSelect}
                    onChange={(e) => {
                      field.onChange(e)
                      form.setFieldValue('quantity', 1)
                    }}
                    disabled={!!selectedMaterial?.availabilities?.length}
                    options={materialPricing.map((material) => ({
                      label: (
                        <div className={classes.option}>
                          <div>{material.materialNumber}</div>
                          {!!material.price ? (
                            <div className={classes.optionPrice}>
                              <FormattedNumber
                                style="currency"
                                currencyDisplay="symbol"
                                currency={material.currency}
                                value={material.price}
                              />
                            </div>
                          ) : (
                            <FormattedMessage {...messages.UNAVAILABLE} />
                          )}
                        </div>
                      ),
                      value: material.materialNumber,
                    }))}
                  />
                )}
              </Field>
              {/* List Price */}
              {!!selectedMaterial.price &&
              !!selectedMaterial.listPrice &&
              selectedMaterial.listPrice > selectedMaterial.price ? (
                <div className={classes.listPrice}>
                  <FormattedNumber
                    style="currency"
                    currencyDisplay="symbol"
                    currency={selectedMaterial.currency}
                    value={selectedMaterial.listPrice}
                  />
                </div>
              ) : null}
              {/* Price */}
              <Box display="flex" alignItems="center">
                {!!selectedMaterial.price && (
                  <div className={classes.price}>
                    <FormattedNumber
                      style="currency"
                      currencyDisplay="symbol"
                      currency={selectedMaterial.currency}
                      value={selectedMaterial.price}
                    />
                  </div>
                )}
              </Box>
              {/* availability */}
              <SpacingBox mb={6} mt={4}>
                <MaterialAvailabilityPreview
                  material={selectedMaterial}
                  canAddToCart={canAddToCart}
                />
              </SpacingBox>
              <AddToListButton
                quantity={selectedMaterial.quantity}
                materialNumber={selectedMaterial.materialNumber}
                brand={selectedMaterial?.brand}
                productName={selectedMaterial?.materialDescription}
                productId={selectedMaterial?.product}
              />
              {/* Show Quantity Field if User Can AddToCart */}
              {canAddToCart && !hideAddToCartForPrepackItems ? (
                <Field name="quantity">
                  {({ form, field }) => (
                    <LiquidQuantityInput
                      {...field}
                      size="large"
                      className={classes.quantityInput}
                      filled
                      onChange={(e) => {
                        form.setFieldValue(field.name, e)
                      }}
                      min={1}
                      onBlur={() => {
                        if (field.value < 1) {
                          form.setFieldValue(field.name, 1)
                        }
                      }}
                      disabled={!!selectedMaterial?.availabilities?.length}
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                    />
                  )}
                </Field>
              ) : null}
              {/* SubmitButton */}
              {!country && (
                <SubmitButton
                  isSubmitting={isSubmitting}
                  availabilityLoading={
                    !!selectedMaterial?.availabilities?.length
                  }
                  canAddToCart={canAddToCart}
                  hideAddToCartForPrepackItems={hideAddToCartForPrepackItems}
                  anonymousUser={anonymousUser}
                />
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default MobileProductPriceAvailability
