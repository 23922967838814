import React from 'react'
import getConfig from 'next/config'
import { useIntl } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Tab, Tabs } from '@material-ui/core'
import messages from '@utils/messages'
import { getValidSearchQuery, SearchFocusType } from '@utils/searchUtils'
import { useBuildingBlocksCountry } from '@utils/regional'
import { getSearchResultsLink } from './SearchResultsNavigationLink'
import { useRouter } from 'next/router'

const useStyles = makeStyles((theme: Theme) => ({
  tabsRoot: {
    minHeight: 30,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  indicator: {
    backgroundColor: theme.palette.text.primary,
  },
}))

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

const SearchResultsNavigation: React.FC<{ orgId?: string }> = ({ orgId }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const router = useRouter()
  const showBuildingBlocks =
    useBuildingBlocksCountry() && !featureFlags.disableBBE
  const validSearchQuery = getValidSearchQuery({
    focus: router.query.focus,
    term: router.query.term,
  })
  const showSearchNavItem = (focus: string): boolean =>
    validSearchQuery.term && !orgId ? true : validSearchQuery.focus === focus

  const trimmedOrgId = orgId?.trim()
  const formattedOrgId =
    trimmedOrgId === 'UCB' ? trimmedOrgId : trimmedOrgId?.toLowerCase()

  return (
    <nav aria-label="Main Navigation">
      <Tabs
        variant="scrollable"
        scrollButtons="off"
        value={validSearchQuery.focus}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.indicator,
        }}
      >
        {showSearchNavItem(SearchFocusType.Products) && !orgId ? (
          <Tab
            value={SearchFocusType.Products}
            label={formatMessage(messages.PRODUCTS)}
            component={getSearchResultsLink(
              SearchFocusType.Products,
              'srp-nav-products'
            )}
          />
        ) : (
          showSearchNavItem(SearchFocusType.B2B) && (
            <Tab
              component="span"
              label={`${formattedOrgId} ${formatMessage(messages.PRODUCTS)}`}
            />
          )
        )}
        {showBuildingBlocks &&
          showSearchNavItem(SearchFocusType.BuildingBlocks) && (
            <Tab
              value={SearchFocusType.BuildingBlocks}
              label={formatMessage(messages.BUILDING_BLOCKS_EXPLORER)}
              component={getSearchResultsLink(
                SearchFocusType.BuildingBlocks,
                'srp-nav-building-blocks'
              )}
            />
          )}
        {showSearchNavItem(SearchFocusType.Genes) && (
          <Tab
            value={SearchFocusType.Genes}
            label={formatMessage({ id: 'GENES', defaultMessage: 'Genes' })}
            component={getSearchResultsLink(
              SearchFocusType.Genes,
              'srp-nav-genes'
            )}
          />
        )}
        {showSearchNavItem(SearchFocusType.Papers) && (
          <Tab
            value={SearchFocusType.Papers}
            label={formatMessage({ id: 'PAPERS', defaultMessage: 'Papers' })}
            component={getSearchResultsLink(
              SearchFocusType.Papers,
              'srp-nav-papers'
            )}
          />
        )}
        {showSearchNavItem(SearchFocusType.TechnicalDocuments) && (
          <Tab
            value={SearchFocusType.TechnicalDocuments}
            label={formatMessage(messages.TECHNICAL_DOCUMENTS)}
            component={getSearchResultsLink(
              SearchFocusType.TechnicalDocuments,
              'srp-nav-techdocs'
            )}
          />
        )}
        {showSearchNavItem(SearchFocusType.SiteContent) && (
          <Tab
            value={SearchFocusType.SiteContent}
            label={formatMessage(messages.SITE_CONTENT)}
            component={getSearchResultsLink(
              SearchFocusType.SiteContent,
              'srp-nav-sitecontent'
            )}
          />
        )}
        {featureFlags.chromatogramSearch &&
          showSearchNavItem(SearchFocusType.Chromatograms) && (
            <Tab
              value={SearchFocusType.Chromatograms}
              label={formatMessage(messages.CHROMATOGRAMS)}
              component={getSearchResultsLink(
                SearchFocusType.Chromatograms,
                'srp-nav-chromatograms'
              )}
            />
          )}
      </Tabs>
    </nav>
  )
}

export default SearchResultsNavigation
