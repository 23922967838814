// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import { DynamicProductFragmentDoc } from '../fragments/DynamicProduct.generated';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type RecentlyViewedProductsQueryVariables = Types.Exact<{
  brand: Types.Scalars['String'];
  productKey: Types.Scalars['String'];
  dispType: Types.AnalyticsDispType;
  erpType?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RecentlyViewedProductsQuery = { __typename?: 'Query', getRecentlyViewedProducts?: { __typename?: 'AssociatedProducts', products: Array<{ __typename?: 'Product', id?: string | null, productKey: string, productNumber: string, productSku?: string | null, lastBoughtUnix?: string | null, name: string, description?: string | null, gaProductCode?: string | null, isMarketplace: boolean, erp_type?: Array<string> | null, tags?: string | null, discount?: string | null, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', altText?: string | null, mediumUrl: string }> }> } | null };


export const RecentlyViewedProductsDocument = gql`
    query RecentlyViewedProducts($brand: String!, $productKey: String!, $dispType: AnalyticsDispType!, $erpType: String) {
  getRecentlyViewedProducts(input: {brand: $brand, productKey: $productKey, dispType: $dispType, erpType: $erpType}) {
    products {
      ...DynamicProduct
    }
  }
}
    ${DynamicProductFragmentDoc}`;
export type RecentlyViewedProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecentlyViewedProductsQuery, RecentlyViewedProductsQueryVariables>, 'query'> & ({ variables: RecentlyViewedProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecentlyViewedProductsComponent = (props: RecentlyViewedProductsComponentProps) => (
      <ApolloReactComponents.Query<RecentlyViewedProductsQuery, RecentlyViewedProductsQueryVariables> query={RecentlyViewedProductsDocument} {...props} />
    );
    

/**
 * __useRecentlyViewedProductsQuery__
 *
 * To run a query within a React component, call `useRecentlyViewedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentlyViewedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentlyViewedProductsQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      productKey: // value for 'productKey'
 *      dispType: // value for 'dispType'
 *      erpType: // value for 'erpType'
 *   },
 * });
 */
export function useRecentlyViewedProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RecentlyViewedProductsQuery, RecentlyViewedProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RecentlyViewedProductsQuery, RecentlyViewedProductsQueryVariables>(RecentlyViewedProductsDocument, options);
      }
export function useRecentlyViewedProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecentlyViewedProductsQuery, RecentlyViewedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RecentlyViewedProductsQuery, RecentlyViewedProductsQueryVariables>(RecentlyViewedProductsDocument, options);
        }
export type RecentlyViewedProductsQueryHookResult = ReturnType<typeof useRecentlyViewedProductsQuery>;
export type RecentlyViewedProductsLazyQueryHookResult = ReturnType<typeof useRecentlyViewedProductsLazyQuery>;
export type RecentlyViewedProductsQueryResult = ApolloReactCommon.QueryResult<RecentlyViewedProductsQuery, RecentlyViewedProductsQueryVariables>;