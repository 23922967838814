import { useCurrentUser } from '@utils/useCurrentUser'
import { CatalogType, UserErpType } from '@src/types/graphql-types'
// This only handles calling getMaterialsDetail from the client side.
// There are many places calling fetchMaterialDetail in graphql side,
// it's handled in catalog-data.ts#fetchMaterialDetail
export const useMaterialCatalogFilter = () => {
  const {
    isBlueErpIntegrationEnabled,
    userErpType,
    hasOnlyBlueERP,
    isDarmstadtUser,
  } = useCurrentUser()

  let catalogType
  let filter

  if (!isBlueErpIntegrationEnabled) {
    return {}
  }
  if (isDarmstadtUser) {
    catalogType = CatalogType.B2b
  } else if (hasOnlyBlueERP) {
    // hasOnlyBlueERP will be true for blue b2b user, ID and TW. BWP-624
    catalogType = CatalogType.Sialblue
  } else {
    // for red and blue countries, change CatalogType from sial to Sialpurple
    catalogType = CatalogType.Sialpurple
    if (
      userErpType === UserErpType.Anonymous ||
      userErpType === UserErpType.NewUser ||
      userErpType === UserErpType.Red
    ) {
      filter = 'erp_type:borred'
    } else if (
      userErpType === UserErpType.Blue ||
      userErpType === UserErpType.Purple
    ) {
      filter = 'erp_type:borblue'
    }
  }
  return { catalogType, filter }
}
