import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { messages } from '@src/utils/messages'

const useStyles = makeStyles((theme: Theme) => {
  return {
    badge: {
      margin: theme.spacing(1),
      // TODO: convert badges to SVG icons
      width: 28,
      height: 28,
    },
  }
})

export enum ProductFeatures {
  New = 'NEW',
  EnhancedValidation = 'enhancedValidation',
}

const ProductFeaturesBadges: React.FC<{
  features: string[]
  customClass?: string
}> = ({ features, customClass }) => {
  const classes = useStyles()
  return (
    <>
      {features.includes(ProductFeatures.New) && (
        <img
          className={customClass ?? classes.badge}
          src="/static/new_product_badge.png"
          alt="new"
          title={messages.NEW_PRODUCT.defaultMessage}
        />
      )}
      {features.includes(ProductFeatures.EnhancedValidation) && (
        <img
          className={customClass ?? classes.badge}
          src="/static/enhanced_validation_badge.png"
          alt="enhanced validation"
          title={messages.ENHANCED_VALIDATION.defaultMessage}
        />
      )}
    </>
  )
}

export default ProductFeaturesBadges
