import React from 'react'
import { FormattedMessage } from 'react-intl'
import { homeRoute, registerRoute, loginRoute, useRouter } from '@src/routes'
import { Link } from '@src/components/Link'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import messages from '@utils/messages'

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    marginTop: theme.spacing(4),
  },
  linkButton: {
    width: '100%',
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  cannotAddToCart: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}))

interface Props {
  isSubmitting: boolean
  availabilityLoading: boolean
  canAddToCart: boolean
  hideAddToCartForPrepackItems: boolean
  anonymousUser: boolean
}

const SubmitButton: React.FC<Props> = ({
  availabilityLoading,
  isSubmitting,
  canAddToCart,
  hideAddToCartForPrepackItems,
  anonymousUser,
}) => {
  const classes = useStyles()
  const router = useRouter()

  if (hideAddToCartForPrepackItems) {
    return (
      <>
        <div className={classes.cannotAddToCart}>
          <FormattedMessage
            id="CANNOT_ADD_TO_CART"
            defaultMessage="Cannot Add To Cart"
          />
        </div>
        <FormattedMessage
          id="CANNOT_ADD_TO_CART_DETAILS"
          defaultMessage="Your profile is currently configured to allow ordering for configurable items such as oligos and peptides but not regular inventory items. Although you can access pricing and availability for these products, they cannot be added to your shopping cart."
        />
      </>
    )
  } else if (canAddToCart) {
    return (
      <Button
        type="submit"
        className={classes.submitButton}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        disabled={availabilityLoading || isSubmitting}
        id="submit-button-add-to-cart"
      >
        <FormattedMessage id="ADD_TO_CART" defaultMessage="Add to Cart" />
      </Button>
    )
  } else if (anonymousUser) {
    return (
      <Link
        passHref
        href={`${loginRoute.index()}?redirect=${encodeURIComponent(
          router.asPath || homeRoute.index()
        )}`}
      >
        <ButtonBase
          className={classes.linkButton}
          id="submit-button-login-to-add-to-cart"
        >
          <FormattedMessage {...messages.LOGIN_TO_ADD_TO_CART} />
        </ButtonBase>
      </Link>
    )
  } else {
    return (
      <Link
        passHref
        href={`${registerRoute.linkProfile()}?returnUrl=${encodeURIComponent(
          router.asPath || homeRoute.index()
        )}`}
      >
        <ButtonBase className={classes.linkButton}>
          <FormattedMessage {...messages.LINK_PROFILE} />
        </ButtonBase>
      </Link>
    )
  }
}

export default SubmitButton
