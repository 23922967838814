import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AvailabilityOverwriteMessage from '@src/components/AvailabilityOverwriteMessage'
import SupplementaryMessage from '@src/components/SupplementaryMessage'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import {
  MaterialAvailability,
  MaterialAvailabilityKey,
} from '@src/types/graphql-types'
import messages from '@utils/messages'
import { ShippingTypes } from '@utils/searchUtils'
import ShippingDetails from './ShippingDetails'
import MaterialAvailabilityModal from '../MaterialAvailabilityModal/MaterialAvailabilityModal'
import Modal from '@src/components/Modal'
import WarningCircleIcon from '@src/icons/WarningCircleIcon'
import clsx from 'clsx'
import AvailabilityMessages from '../ProductPriceAvailability/MobileProductPriceAvailabilityForm/AvailabilityMessages'
import { TrackedAvailabilityMessage } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessage'
import PromotionalMessage from '@src/components/PromotionalMessage'
import { availableWithinFiveDays } from '@utils/availableWithinFiveDays'

const useStyles = makeStyles((theme: Theme) => ({
  shipFromLink: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
  availableIcon: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.success.main,
    marginRight: theme.spacing(2),
  },
  listPrice: {
    fontSize: theme.typography.pxToRem(11),
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  },
  qtyInput: {
    height: 36,
    maxWidth: theme.typography.pxToRem(100),
    minWidth: theme.typography.pxToRem(95),
  },
  shipEstimateColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  shipEstimateWithLink: {
    display: 'flex',
    flexDirection: 'column',
  },
  availableInKitAnchor: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: theme.spacing(1),
  },
  promoWrapper: {
    fontSize: theme.typography.pxToRem(12),
  },
  initialShipText: {
    display: 'flex',
    alignItems: 'flex-start',
    '& svg': {
      paddingTop: theme.spacing(0.5),
    },
  },
  fromWarehouse: {
    textTransform: 'capitalize',
    marginLeft: theme.spacing(1),
  },
  availabilityIcon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
  },
  warningText: {
    color: theme.palette.error.main,
  },
  baseClass: {
    minWidth: 0,
    paddingLeft: 0,
  },
  topAligned: {
    display: 'flex',
    padding: 0,
    margin: 0,
  },
}))

interface MaterialAvailabilityDisplayProps {
  material: ValidMaterialPricingDetailFragment
  initialShipEstimate: React.ReactFragment
  canAddToCart: boolean
  displayPromotionalBundlesAnchor?: boolean
  countryCode?: string
  isDynamicProductCarousel?: boolean
  isLoading?: boolean
  hideDetailsLink?: boolean
  gaDetailsClick?: () => void
  source?: string
  gaSection?: string
  gaComponent?: string
}

const MaterialAvailabilityDisplay: React.FC<
  MaterialAvailabilityDisplayProps
> = ({
  material,
  initialShipEstimate,
  canAddToCart,
  countryCode,
  isDynamicProductCarousel,
  isLoading = false,
  hideDetailsLink = false,
  gaDetailsClick,
  source,
  gaSection,
  gaComponent,
}) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOnModalClose = () => {
    setIsModalOpen(false)
  }

  const { formatMessage } = useIntl()

  const availability = material.availabilities?.[0] as
    | MaterialAvailability
    | undefined

  // availableQtyInStock can be 0 which is a valid scenario. So just checking for null

  const { availableQtyInStock } = material
  const hasAvailableQtyInStock = availableQtyInStock !== null
  const isInquirePA =
    availability &&
    availability?.key === MaterialAvailabilityKey.InquireForPriceAndAvailability
  /**
   * If key is INQUIRE_FOR_PRICE_AND_AVAILABILITY do an early return
   * this logic was written after code that follows
   * future changes should branch of this if statement
   */
  const [openInquireModal, setOpenInquireModal] = useState(false)

  if (isInquirePA) {
    const contactInfo = availability?.contactInfo

    const inquirePriceContactInfo = [
      contactInfo?.contactPhone,
      contactInfo?.contactEmail,
    ]
      .filter((contactValue) => !!contactValue)
      .join(` ${formatMessage(messages.OR)} `)

    return (
      <>
        <Button
          className={clsx(classes.baseClass, {
            [classes.topAligned]: isInquirePA,
          })}
          onClick={() => setOpenInquireModal(true)}
        >
          <TrackedAvailabilityMessage
            {...messages.INQUIRE_FOR_PRICE_AND_AVAILABILITY}
          />
        </Button>

        {openInquireModal && (
          <Modal
            open={openInquireModal}
            setModalOpen={setOpenInquireModal}
            headerContent={material.materialNumber}
            bodyContent={
              <Box mr={12} mb={8}>
                <TrackedAvailabilityMessage
                  {...messages.INQUIRE_FOR_PRICE_AND_AVAILABILITY_MODAL}
                  values={{
                    inquirePriceContactInfo,
                  }}
                />
              </Box>
            }
          />
        )}
      </>
    )
  }

  const showAvailability = (
    e:
      | React.MouseEvent<EventTarget & HTMLSpanElement>
      | React.KeyboardEvent<EventTarget & HTMLSpanElement>
  ) => {
    e.preventDefault()
    gaDetailsClick && gaDetailsClick()
    setIsModalOpen(true)
  }

  const isApoNoStock =
    availability && availability?.key === MaterialAvailabilityKey.ApoNoStock

  return (
    <Box display="flex" alignItems="flex-start">
      <div className={classes.shipEstimateWithLink}>
        <div className={classes.shipEstimateColumn}>
          {availability?.availabilityOverwriteMessage?.messageKey ? (
            <AvailabilityOverwriteMessage
              availabilityOverwrite={availability.availabilityOverwriteMessage}
            />
          ) : isDynamicProductCarousel ? (
            // this handles Availability just for Dynamic Product carousel/ Product Quick Look section -
            // mainly to handle check availability functionality and avoid modal over modal scenarios
            <AvailabilityMessages
              availabilities={material?.availabilities || []}
              updateAvailable={isLoading}
            />
          ) : (
            <>
              {hasAvailableQtyInStock ? (
                <div
                  className={clsx(classes.initialShipText, {
                    [classes.warningText]: hasAvailableQtyInStock,
                  })}
                >
                  <WarningCircleIcon
                    className={classes.availabilityIcon}
                    color="warning"
                  />
                  <TrackedAvailabilityMessage
                    id="DEPLETED_PRODUCTS_QTY_FEW_LEFT_IN_STOCK"
                    defaultMessage="{availableQtyInStock} left in stock"
                    values={{
                      availableQtyInStock,
                    }}
                  />
                </div>
              ) : (
                <div className={classes.initialShipText}>
                  {initialShipEstimate}
                </div>
              )}
              <span>
                {isModalOpen && (
                  <MaterialAvailabilityModal
                    isModalOpen={isModalOpen}
                    onModalClose={() => handleOnModalClose()}
                    material={material}
                    canAddToCart={canAddToCart}
                    countryCode={countryCode}
                    availableQty={availableQtyInStock}
                    source={source}
                  />
                )}
                {!hideDetailsLink &&
                (availability?.displayFromLink || isApoNoStock) ? (
                  <>
                    <span
                      className={classes.shipFromLink}
                      onClick={showAvailability}
                      onKeyPress={showAvailability}
                      tabIndex={0}
                      role="button"
                      id={`mat-num-${material.materialNumber}-from`}
                    >
                      {isApoNoStock ? (
                        <>
                          {' - '}
                          <FormattedMessage {...messages.CHECK_AVAILABILITY} />
                        </>
                      ) : (
                        <>
                          <FormattedMessage {...messages.DETAILS} />
                          {'...'}
                        </>
                      )}
                    </span>
                  </>
                ) : availability?.plantLoc &&
                  !hasAvailableQtyInStock &&
                  !availableWithinFiveDays(availability) ? (
                  <span className={classes.fromWarehouse}>
                    <FormattedMessage {...messages.PRODUCT_AVAILABILITY_FROM} />{' '}
                    {availability.plantLoc}
                  </span>
                ) : null}
              </span>
            </>
          )}
          <Box pt={0.5}>
            {material.shipsToday && (
              <ShippingDetails
                type={ShippingTypes.SameDay}
                gaSection={gaSection}
                gaComponent={gaComponent}
                material={material}
              />
            )}
            {material.freeFreight && (
              <ShippingDetails type={ShippingTypes.FreeFreight} />
            )}
          </Box>
        </div>
        {availability?.supplementaryMessage?.messageKey ? (
          <Box mt={1}>
            <SupplementaryMessage
              supplementary={availability.supplementaryMessage}
            />
          </Box>
        ) : null}
        {material.promotionalMessage?.messageKey && (
          <Box mt={1} className={classes.promoWrapper}>
            <PromotionalMessage promotional={material.promotionalMessage} />
          </Box>
        )}
      </div>
    </Box>
  )
}

export default MaterialAvailabilityDisplay
