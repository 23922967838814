import React from 'react'
import { Theme, ButtonBase } from '@material-ui/core'
import {
  useCompareProducts,
  CompareProductItem,
} from '@src/utils/useCompareProducts'
import { SearchFocusType, determineCatalogType } from '@src/utils/searchUtils'
import { Link } from '@src/components/Link'
import { makeStyles } from '@material-ui/core/styles'
import { productDetailRoute } from '@src/routes'
import XIcon from '@src/icons/XIcon'

const useStyles = makeStyles((theme: Theme) => ({
  productContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  product: {
    position: 'relative',
    width: 'auto',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '58px',
    width: '58px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '2px',
  },
  image: {
    maxHeight: '54px',
    maxWidth: '54px',
  },
  x: {
    width: '12px',
    height: '12px',
  },
}))

export const CompareProduct: React.FC<CompareProductItem> = ({
  images,
  productKey,
  productNumber,
  brandKey,
}) => {
  const classes = useStyles()
  const { removeCompareProduct } = useCompareProducts()
  const image = images?.find((img) => {
    if (!img.productKey || !img.brandKey) return true
    return img.productKey === productKey && img.brandKey === brandKey
  })
  return (
    <div className={classes.productContainer}>
      <div className={classes.product}>
        <div>
          {/* Images are optional due to an edgecase where user utilizes the 
              ReplacementProductsModal an Image might not be saved to sessionStorage */}
          {image ? (
            <div className={classes.imageContainer}>
              <img
                className={classes.image}
                src={image.smallUrl || image.mediumUrl}
                alt={image?.altText || ''}
              />
            </div>
          ) : (
            <img
              className={classes.image}
              src="/assets/images/supelco-no-image/supelco-no-image_w300.png"
              alt="Supelco"
            />
          )}
        </div>
        <div>
          <Link
            {...productDetailRoute.index(brandKey, productKey, {
              catalog: determineCatalogType(SearchFocusType.Products),
            })}
            passHref
          >
            {productNumber ? productNumber : productKey}
          </Link>
        </div>
      </div>
      <ButtonBase
        onClick={() => removeCompareProduct({ brandKey, productKey })}
      >
        <XIcon className={classes.x} />
      </ButtonBase>
    </div>
  )
}
