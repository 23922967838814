import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import messages from '@utils/messages'
import { FormattedMessage } from 'react-intl'
import { useCompanyName } from '@src/utils/useCompanyName'
import { useCountryName } from '@utils/regional'

const useStyles = makeStyles((theme: Theme) => ({
  productInfoMessage: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
    borderTop: `2px solid ${theme.palette.grey[300]}`,
  },
}))

interface ProductInfoMessageProps {
  country: string
  brandKey: string
  productNumber: string
  productMessage: string
}

const ProductInfoMessage: React.FC<ProductInfoMessageProps> = ({
  country,
  brandKey,
  productNumber,
  productMessage,
}) => {
  const classes = useStyles()
  const companyName = useCompanyName()
  const countryName = useCountryName(country)
  const productInfoMessageWebsite =
    productMessage === 'PROCUT_ORDER_ON_AVANTI_SITE'
      ? 'https://avantilipids.com/ '
      : productMessage === 'PROCUT_ORDER_ON_ROCHE_SITE'
        ? 'https://sequencing.roche.com'
        : ''
  const productInfoMessageWebsiteText =
    productMessage === 'PROCUT_ORDER_ON_AVANTI_SITE'
      ? 'avantilipids.com'
      : productMessage === 'PROCUT_ORDER_ON_ROCHE_SITE'
        ? 'sequencing.roche.com'
        : ''

  const renderProductInfoMessage = (message) => {
    switch (message) {
      case 'PROCUT_ORDER_ON_AVANTI_SITE':
      case 'PROCUT_ORDER_ON_ROCHE_SITE':
        return (
          <FormattedMessage
            {...messages[productMessage]}
            values={{
              companyName,
              countryName,
              link: (
                <a
                  key={`product-info-link-${brandKey}-${productNumber}`}
                  href={productInfoMessageWebsite}
                  target="_blank"
                  rel="noreferrer"
                >
                  {productInfoMessageWebsiteText}
                </a>
              ),
            }}
          >
            {(text) => <span>{text}</span>}
          </FormattedMessage>
        )
      default:
        return (
          <FormattedMessage
            {...messages.PRICING_AND_AVAILABILITY_UNAVAILABLE}
          />
        )
    }
  }
  return (
    <Box
      id={`product-info-message-${brandKey}-${productNumber}`}
      className={classes.productInfoMessage}
    >
      {renderProductInfoMessage(productMessage)}
    </Box>
  )
}

export default ProductInfoMessage
