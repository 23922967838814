import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { NextComponentType, NextPageContext } from 'next'
import { pickBy } from 'lodash'
import { Router, searchResultsRoute, useRouter } from '@src/routes'
import { userSession } from '@utils/userSession'
import {
  getValidSearch,
  getValidSearchQuery,
  parseFacetsQuery,
  SearchQuery,
  stringifySearchQuery,
} from '@utils/searchUtils'
import {
  CatalogType,
  Facet,
  FacetInput,
  ProductSearchType,
  Sort,
  StructureSearchFormat,
} from '@src/types/graphql-types'
import SearchResultsRoute from '../../../../../routes/SearchResults'
import B2BSearchResultRoute from '../../../../../routes/SearchResults/B2BProductSearchResults'
import { SitePreference, useCurrentUser } from '@utils/useCurrentUser'
import { getLocalizedUrl } from '@utils/regional'
import getCookiesFromContext from '@src/utils/getCookiesFromContext'
import qs from 'qs'
import { EventValues } from '@sial/common-utils'
import {
  getSearchFocusEventValue,
  sendRemoveSearchTermEvent,
  sendSRPActionEvent,
} from '@src/utils/analytics'
import { FACET_SEARCH } from '@src/utils/termlessSearch'

interface SearchProps {
  searchQuery: Maybe<SearchQuery>
}

export const SearchQueryContext = createContext<SearchQuery>(
  getValidSearchQuery({ term: '' })
)

export enum FacetChangeAction {
  Add,
  Remove,
}

type FacetChange = {
  action: FacetChangeAction.Add | FacetChangeAction.Remove
  key: string
  options: string[]
  multiSelect?: boolean
  prefix?: string | null
}

export interface SearchQueryActions {
  handleFocusChange: (focus: string) => void
  handlePageChange: (page: number) => void
  handleTermRemoval: (selectedFacets: FacetInput[], facets?: Facet[]) => void
  handleSelectedFacetChange: (props: FacetChange) => void
  handleAvailableForSaleChange: (region: string) => void
  handleSortChange: (sort: Sort) => void
}

const removeNonMultiSelectFacets = (
  facets: string[],
  facetToAdd: string[]
): string[] => {
  facets.map((facet, i) => {
    const facetToAddKey = facetToAdd[0].split(':')[0]
    const facetKey = facet.split(':')[0]
    if (facetKey === facetToAddKey) {
      facets.splice(i, 1)
    }
    return null
  })
  return facets.concat(facetToAdd)
}

export const useSearchQuery = (): [
  SearchQuery,
  FacetInput[],
  SearchQueryActions,
] => {
  const query = useContext(SearchQueryContext)
  const { getSitePreference } = useCurrentUser()
  const currentOrgId = getSitePreference(SitePreference.CatalogFilter)
  const router = useRouter()
  /*
  If performing a structure search the path param term should be structure-search, the mol file
  should use the query term param or in future versions be included in the HTTP request.
  STRAT-11440
   */
  const isStructureSearch = router.asPath.includes('structure-search')
  const term = isStructureSearch ? 'structure-search' : query.term || ''
  const {
    facet,
    focus,
    type,
    sort,
    perpage,
    format,
    filters,
    image,
    catalogType,
    region,
  } = query
  const constantSearchTerms = {
    term: query.term,
    selectedFacets: facet,
    focus,
    type,
    sort,
    perpage,
    format,
    filters,
    image,
    catalogType,
    region,
  }

  // need the double bang to force null and undefined to the same type
  const noUserOrgIdAndQueryOrgId =
    !currentOrgId && !!currentOrgId !== !!query.orgId
  //Fix for STRAT-16791 : Automatic redirect to Advanced Search page issue for UCB users
  const userOrgIdAndNoQueryOrgId =
    currentOrgId && currentOrgId !== query.orgId && currentOrgId !== 'UCB'

  useEffect(() => {
    if (noUserOrgIdAndQueryOrgId) {
      // redirects darmstadt user to regular srp if token expires but orgId still exists in old query params
      router.push(
        `/search/[searchTerm]${getValidSearch(constantSearchTerms)}`,
        `${searchResultsRoute.searchResults(term)}${getValidSearch(
          constantSearchTerms
        )}`
      )
    } else if (userOrgIdAndNoQueryOrgId) {
      // also keeps darmstadt user on b2b srp if it's removed from the url params and makes it not required to provide in all the getValidSearch instances
      router.push(
        `/search/[searchTerm]${getValidSearch({
          ...constantSearchTerms,
          orgId: currentOrgId,
        })}`,
        `${searchResultsRoute.searchResults(term)}${getValidSearch({
          ...constantSearchTerms,
          orgId: currentOrgId,
        })}`
      )
    }
    // NOTE: router isn't needed in the deps array and can possibly cause memory to not be reclaimed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    constantSearchTerms,
    currentOrgId,
    noUserOrgIdAndQueryOrgId,
    query.orgId,
    userOrgIdAndNoQueryOrgId,
  ])

  const handleFocusChange = useCallback(
    (focus: string): void => {
      sendSRPActionEvent(
        EventValues.SearchWithin,
        getSearchFocusEventValue(focus)
      )
      router.push(
        `/search/[searchTerm]${getValidSearch({
          term: query.term,
          focus,
        })}`,
        `${searchResultsRoute.searchResults(query.term)}${getValidSearch({
          term: query.term,
          focus,
        })}`
      )
    },
    [constantSearchTerms, currentOrgId, router, term]
  )

  const handlePageChange = useCallback(
    (page: number): void => {
      router.push(
        `/search/[searchTerm]${getValidSearch({
          ...constantSearchTerms,
          orgId: currentOrgId,
          page,
        })}`,
        `${searchResultsRoute.searchResults(term)}${getValidSearch({
          ...constantSearchTerms,
          orgId: currentOrgId,
          page,
        })}`
      )
      window.scrollTo(0, 0)
    },
    [constantSearchTerms, currentOrgId, router, term]
  )

  const handleTermRemoval = useCallback(
    (selectedFacets: FacetInput[], facets?: Facet[]) => {
      sendRemoveSearchTermEvent(query.term || '', selectedFacets, facets)
      if (!router.query.facet) {
        return router.push(searchResultsRoute.index())
      }

      if (router.query.facet.length > 0) {
        const searchHref = `${searchResultsRoute.searchResults(
          FACET_SEARCH
        )}${getValidSearch({
          ...query,
          page: 1,
          selectedFacets: [router.query.facet],
          term: FACET_SEARCH,
        })}`

        return router.push(searchHref)
      }

      return router.push(searchResultsRoute.index())
    },
    [router, query]
  )

  const handleSelectedFacetChange = useCallback(
    (props: FacetChange): void => {
      let newFacets: Maybe<string[]> = null

      const { options, prefix, key } = props
      const facetStrs = options.map((option) =>
        prefix ? `${key}:${prefix}${option}` : `${key}:${option}`
      )

      const setNewFacets = {
        [FacetChangeAction.Add]: (props: FacetChange) => {
          if (facet.indexOf(facetStrs[0]) < 0) {
            newFacets = props.multiSelect
              ? facet.concat(facetStrs[0])
              : removeNonMultiSelectFacets(facet, facetStrs)
          }
        },
        [FacetChangeAction.Remove]: () => {
          if (facet.length === 1 && term === FACET_SEARCH) {
            newFacets = null
            return
          }
          newFacets = facet.filter((facet) => facetStrs.indexOf(facet) < 0)
        },
      }
      setNewFacets[props.action](props)

      if (newFacets !== null) {
        router.push(
          `/search/[searchTerm]${getValidSearch({
            ...constantSearchTerms,
            selectedFacets: newFacets,
            page: 1,
            orgId: currentOrgId,
          })}`,
          `${searchResultsRoute.searchResults(term)}${getValidSearch({
            ...constantSearchTerms,
            selectedFacets: newFacets,
            page: 1,
            orgId: currentOrgId,
          })}`
        )
        window.scrollTo(0, 0)
      } else {
        router.push(searchResultsRoute.index())
      }
    },
    [constantSearchTerms, currentOrgId, facet, router, term]
  )

  const handleAvailableForSaleChange = useCallback(
    (region: string) => {
      router.push(
        `/search/[searchTerm]${getValidSearch({
          ...constantSearchTerms,
          page: 1,
          orgId: currentOrgId,
          region,
        })}`,
        `${searchResultsRoute.searchResults(term)}${getValidSearch({
          ...constantSearchTerms,
          page: 1,
          orgId: currentOrgId,
          region,
        })}`
      )
      window.scrollTo(0, 0)
    },
    [constantSearchTerms, currentOrgId, router, term]
  )

  const handleSortChange = useCallback(
    (newSort: Sort): void => {
      router.push(
        `/search/[searchTerm]${getValidSearch({
          ...constantSearchTerms,
          page: 1,
          sort: newSort,
          orgId: currentOrgId,
        })}`,
        `${searchResultsRoute.searchResults(term)}${getValidSearch({
          ...constantSearchTerms,
          page: 1,
          sort: newSort,
          orgId: currentOrgId,
        })}`
      )
    },
    [constantSearchTerms, currentOrgId, router, term]
  )

  const parsedFacets = useMemo(() => parseFacetsQuery(facet), [facet])

  return useMemo(
    () => [
      query,
      parsedFacets,
      {
        handleFocusChange,
        handlePageChange,
        handleTermRemoval,
        handleSelectedFacetChange,
        handleAvailableForSaleChange,
        handleSortChange,
      },
    ],
    [
      query,
      handleFocusChange,
      handlePageChange,
      handleTermRemoval,
      handleSelectedFacetChange,
      handleAvailableForSaleChange,
      handleSortChange,
      parsedFacets,
    ]
  )
}

const Search: NextComponentType<NextPageContext, {}, SearchProps> = ({
  searchQuery,
}) => {
  if (!searchQuery) {
    return null
  }

  const ParsedSearchResultsRoute = searchQuery.orgId
    ? B2BSearchResultRoute
    : SearchResultsRoute

  return (
    <SearchQueryContext.Provider value={searchQuery}>
      <ParsedSearchResultsRoute />
    </SearchQueryContext.Provider>
  )
}

const returnInNonArrayFormat = (input: string | string[]) =>
  Array.isArray(input) ? input[0] : input
const returnInArrayFormat = (input: string | string[]) =>
  input ? (Array.isArray(input) ? input : [input]) : []
const returnPaginationInNonArrayFormat = (pageInput: string | string[]) =>
  typeof pageInput !== 'undefined'
    ? Array.isArray(pageInput)
      ? parseInt(pageInput[0], 10)
      : parseInt(pageInput, 10)
    : pageInput

Search.getInitialProps = async (
  ctx: ExtendedNextPageContext
): Promise<SearchProps> => {
  const { query, res, asPath } = ctx
  if (!query) {
    return { searchQuery: null }
  }
  const term = returnInNonArrayFormat(query.term)
  const cookies = getCookiesFromContext(ctx)

  const redirectRoute = getLocalizedUrl(
    userSession(cookies.getAll()),
    searchResultsRoute.index()
  )

  if (!term && !query.facet) {
    if (res) {
      res.writeHead(301, {
        Location: redirectRoute.as,
      })
      res.end()
    } else {
      Router.replace(redirectRoute.href, redirectRoute.as)
    }
    return {
      searchQuery: null,
    }
  }

  const focus = returnInNonArrayFormat(query.focus)
  const type = returnInNonArrayFormat(query.type) as ProductSearchType
  const selectedFacets = returnInArrayFormat(query.facet)
  const page = returnPaginationInNonArrayFormat(query.page) as number
  const sort = returnInNonArrayFormat(query.sort) as Sort
  const perPage = returnPaginationInNonArrayFormat(query.perpage)
  const filters = returnInArrayFormat(query.filters)
  const format = Array.isArray(query.format)
    ? StructureSearchFormat[query.format[0]]
    : StructureSearchFormat[query.format]
  const image = returnInNonArrayFormat(query.image)
  const orgId = returnInNonArrayFormat(query.orgId)
  const catalogType = returnInNonArrayFormat(query.catalogType) as CatalogType
  const region = returnInNonArrayFormat(query.region)
  const dym = returnInNonArrayFormat(query.dym)
  const debug = returnInNonArrayFormat(query.debug)

  // We need to ensure these params are kept to track them with GA. See STRAT-16480
  const marketingParams = pickBy(
    query,
    (_: any, key: string) => !!key.match(/^(utm_|gclid|dclid|msclkid|fbclid)/i)
  )

  const validSearchQuery = getValidSearchQuery({
    term,
    selectedFacets,
    focus,
    type,
    page,
    sort,
    perPage,
    format,
    filters,
    image,
    orgId,
    catalogType,
    region,
    dym,
    debug,
  })

  let searchTerm = term
  if (asPath?.includes('structure-search')) {
    /*
    Dont do a redirect if performing a structure-search, previously
    the mole file was the term and included as a query param, now we
    want structure search results under the ../structured-search path and
    to use the term query param
    STRAT-11440
    */

    searchTerm = 'structure-search'
  }

  const validPath = getLocalizedUrl(
    userSession(cookies.getAll()),
    `${searchResultsRoute.searchResults(searchTerm)}${stringifySearchQuery({
      ...validSearchQuery,
      ...marketingParams,
    })}`
  )

  // Discovered in STRAT-18099, sometimes asPath can have unencoded characters; this change parses the query string and then stringify it again to ensure all characters are properly encoded
  const urlArray = asPath?.split('?') || []
  const parsedQueryString = qs.parse(urlArray[1])
  const newAsPath = `${urlArray[0]}?${qs.stringify(parsedQueryString, {
    indices: false,
  })}`

  if (validPath.as !== newAsPath) {
    if (res) {
      const redirectStatus = /[A-Z]/.test(validPath.as) ? 301 : 302 //If the actual url has some uppercase letters http redirect status should 301
      res.writeHead(redirectStatus, {
        Location: validPath.as,
      })
      res.end()
    } else {
      Router.replace(validPath.href, validPath.as)
    }
    return { searchQuery: null }
  }
  return { searchQuery: validSearchQuery }
}

export default Search
