import React, { useEffect } from 'react'
import { Box, Link, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ErrorCircleIcon from '@src/icons/ErrorCircleIcon'
import ErrorIcon from '@material-ui/icons/Error'
import messages from '@src/utils/messages'
import { FormattedMessage } from 'react-intl'
import GlobalAlert from '../GlobalAlert'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { DiscontinuedMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { TrackedAvailabilityMessage } from '../TrackedAvailabilityMessage/TrackedAvailabilityMessage'
import { sendPricingAndAvailabilityErrorEvent } from '@utils/analytics/pricingAndAvailability'

const useStyles = makeStyles((theme: Theme) => ({
  simpleAlert: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'inline',
    },
  },
  errorIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      position: 'relative',
      top: theme.spacing(1),
    },
  },
  alignLeft: {
    justifyContent: 'flex-start',
  },
  errorAlert: {
    display: 'flex',
    alignItems: 'flex-start',
    lineHeight: 1.5,
  },
  link: {
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
  },
}))

interface DiscontinuedProductAlertProps {
  discontinuedPricingInfo?: DiscontinuedMaterialPricingDetailFragment | null
  leftAligned?: boolean
  discontinuedProductNumber?: string
  discontinuedProductBrand?: string
  discontinuedProductName?: string
}

const DiscontinuedProductAlert: React.FC<DiscontinuedProductAlertProps> = ({
  discontinuedPricingInfo,
  leftAligned,
  discontinuedProductNumber,
  discontinuedProductBrand,
  discontinuedProductName,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const srpPage = router.asPath.includes('/search')
  const comparePage = router.asPath.includes('/compare')

  const showReplacementProducts =
    !!discontinuedPricingInfo?.replacementProducts?.length ||
    !!discontinuedPricingInfo?.displaySimilarProductLabel ||
    !discontinuedPricingInfo?.hideReplacementProductLink

  useEffect(() => {
    sendPricingAndAvailabilityErrorEvent(
      discontinuedProductNumber ?? '',
      undefined,
      'THIS_PRODUCT_DISCONTINUED',
      discontinuedProductBrand ?? '',
      discontinuedProductName ?? ''
    )
  }, [])

  const recommendedMsg = discontinuedPricingInfo?.displaySimilarProductLabel ? (
    <>
      <FormattedMessage
        id="RECOMMENDED_MESSAGE_DISCRIPTION"
        defaultMessage="View Similar Product(s) Below or{contactTechnicalService}for assistance. "
        values={{
          contactTechnicalService: (
            <Link className={classes.link} href={'/support/customer-support'}>
              <FormattedMessage {...messages.CONTACT_TECHNICAL_SERVICE} />
            </Link>
          ),
        }}
      />
    </>
  ) : (
    <FormattedMessage {...messages.RECOMMENDED_REPLACEMENT_PRODUCTS} />
  )

  const alertMessage = showReplacementProducts ? (
    <TrackedAvailabilityMessage
      {...messages.THIS_PRODUCT_DISCONTINUED}
      values={{
        recommendedProductMessage: !comparePage ? recommendedMsg : null,
      }}
    />
  ) : (
    <TrackedAvailabilityMessage
      {...messages.THIS_PRODUCT_DISCONTINUED}
      values={{
        recommendedProductMessage: (
          <>
            <Link className={classes.link} href={'/support/customer-support'}>
              <FormattedMessage {...messages.CONTACT_TECHNICAL_SERVICE} />
            </Link>
            <FormattedMessage {...messages.FOR_ASSISTANCE} />
          </>
        ),
      }}
    />
  )

  return srpPage || comparePage ? (
    <Box
      className={clsx(classes.simpleAlert, {
        [classes.alignLeft]: comparePage || leftAligned,
      })}
    >
      <Box className={classes.errorIcon}>
        <ErrorIcon color="error" />
      </Box>
      {alertMessage}
    </Box>
  ) : (
    <GlobalAlert
      severity="error"
      className={classes.errorAlert}
      icon={<ErrorCircleIcon style={{ fontSize: 16 }} />}
    >
      {alertMessage}
    </GlobalAlert>
  )
}

export default DiscontinuedProductAlert
