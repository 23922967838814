import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { ButtonBase, Grid, Tooltip, Typography } from '@material-ui/core'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import XIcon from '@src/icons/XIcon'
import InfoIcon from '@material-ui/icons/Info'
import HandleMarkup from '../HandleMarkup'

const useStyles = makeStyles((theme: Theme) => ({
  infoIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    position: 'relative',
    top: theme.spacing(0.5),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4),
    color: theme.palette.secondary.main,
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.black,
  },
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0 6px 13px 0 rgba(0, 0, 0, 0.16)',
    padding: theme.spacing(4, 10, 4, 5),
    minWidth: 250,
    maxWidth: 500,
  },
  arrow: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(20),
  },
  tooltipPlacementTop: {
    top: theme.spacing(2),
  },
}))(Tooltip)

interface AdditionalInfoTooltipProps {
  material: ValidMaterialPricingDetailFragment | null
}

const AdditionalInfoColumn = ({ id, defaultMessage, columnValue }) => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item>
        <Typography variant="body2" className={classes.label}>
          <FormattedMessage id={id} defaultMessage={defaultMessage} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          <HandleMarkup value={columnValue} />
        </Typography>
      </Grid>
    </Grid>
  )
}

const AdditionalInfoTooltip = ({ material }: AdditionalInfoTooltipProps) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const additionalInfoFields = [
    {
      id: 'ADDITIONAL_INFO_CARRIER_RESTRICTION',
      defaultMessage: 'Additional Fees/Restrictions:',
      key: 'carrierRestriction',
    },
    {
      id: 'ADDITIONAL_INFO_FTCC',
      defaultMessage: 'Foreign Trade Commodity Code:',
      key: 'tariff',
    },
    {
      id: 'ADDITIONAL_INFO_UNTN',
      defaultMessage: 'UN Transportation Number(IATA):',
      key: 'unNumber',
    },
    {
      id: 'ADDITIONAL_INFO_CAS',
      defaultMessage: 'CAS Number:',
      key: 'casNumber',
    },
    {
      id: 'ADDITIONAL_INFO_JFC',
      defaultMessage: 'Japan Fire Code:',
      key: 'jfcCode',
    },
    {
      id: 'ADDITIONAL_INFO_PDSC',
      defaultMessage: 'Poison/Deleterious Substance Code:',
      key: 'pdcCode',
    },
  ]

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <HtmlTooltip
      arrow
      interactive
      placement="top"
      open={open}
      onClick={handleOpen}
      title={
        <>
          <Typography variant="h3" className={classes.title}>
            <FormattedMessage
              id="PRODUCT_INFORMATION"
              defaultMessage="Product Information"
            />
          </Typography>
          <ButtonBase className={classes.closeButton} onClick={handleClose}>
            <XIcon className={classes.closeIcon} />
          </ButtonBase>
          {additionalInfoFields.map(
            (field) =>
              material?.additionalInfo?.[field.key] && (
                <AdditionalInfoColumn
                  id={field.id}
                  key={field.key}
                  defaultMessage={field.defaultMessage}
                  columnValue={material.additionalInfo[field.key]}
                />
              )
          )}
          {material?.packageType && material?.packageSize && (
            <AdditionalInfoColumn
              id="ADDITIONAL_INFO_PACKAGING_INFO"
              defaultMessage="Packaging Information:"
              columnValue={`${material.packageSize} in ${material.packageType}`}
            />
          )}
        </>
      }
    >
      <InfoIcon className={classes.infoIcon} />
    </HtmlTooltip>
  )
}

export default AdditionalInfoTooltip
