import React from 'react'
import { Container } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import DefaultLayout from '@src/components/DefaultLayout'
import B2BProductSearchResults from './B2BProductSearchResults'

const useStyles = makeStyles((theme: Theme) => {
  return {
    searchContainer: {
      [theme.breakpoints.down('xs')]: {
        overflowX: 'hidden',
      },
      [theme.breakpoints.up('xs')]: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderTop: 0,
      },
    },
    resultsContainer: {
      marginBottom: theme.spacing(10),
      paddingTop: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(8),
      },
      [theme.breakpoints.only('xs')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },
  }
})

const B2BSearchResults = () => {
  const classes = useStyles()
  return (
    <DefaultLayout className={classes.searchContainer}>
      <Container maxWidth="lg" classes={{ root: classes.resultsContainer }}>
        <div>
          <B2BProductSearchResults />
        </div>
      </Container>
    </DefaultLayout>
  )
}

export default B2BSearchResults
