// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PromotionalBundlesPricingQueryVariables = Types.Exact<{
  promotionalBundleId: Types.Scalars['String'];
}>;


export type PromotionalBundlesPricingQuery = { __typename?: 'Query', getPricingForPromotionalBundle: { __typename?: 'PromotionalBundlePricing', promotionalBundleId?: string | null, listPrice?: number | null, yourPrice?: number | null, currency: string, bundleItems: Array<{ __typename?: 'PromotionalBundleItemPricing', materialNumber: string, materialName?: string | null, materialDescription?: string | null, materialId?: string | null, brand?: string | null, brandKey?: string | null, quantity?: number | null, discount?: number | null, listPrice?: number | null, yourPrice?: number | null, currency?: string | null, availabilityMessages?: Array<{ __typename?: 'MaterialAvailability', key: Types.MaterialAvailabilityKey, quantity?: number | null, date?: number | null, plantLoc?: string | null } | null> | null }> } };

export type PromotionalBundlePricingDetailFragment = { __typename?: 'PromotionalBundlePricing', promotionalBundleId?: string | null, listPrice?: number | null, yourPrice?: number | null, currency: string, bundleItems: Array<{ __typename?: 'PromotionalBundleItemPricing', materialNumber: string, materialName?: string | null, materialDescription?: string | null, materialId?: string | null, brand?: string | null, brandKey?: string | null, quantity?: number | null, discount?: number | null, listPrice?: number | null, yourPrice?: number | null, currency?: string | null, availabilityMessages?: Array<{ __typename?: 'MaterialAvailability', key: Types.MaterialAvailabilityKey, quantity?: number | null, date?: number | null, plantLoc?: string | null } | null> | null }> };

export type PromotionalBundleItemPricingDetailFragment = { __typename?: 'PromotionalBundleItemPricing', materialNumber: string, materialName?: string | null, materialDescription?: string | null, materialId?: string | null, brand?: string | null, brandKey?: string | null, quantity?: number | null, discount?: number | null, listPrice?: number | null, yourPrice?: number | null, currency?: string | null, availabilityMessages?: Array<{ __typename?: 'MaterialAvailability', key: Types.MaterialAvailabilityKey, quantity?: number | null, date?: number | null, plantLoc?: string | null } | null> | null };

export type MaterialAvailabilityDetailFragment = { __typename?: 'MaterialAvailability', key: Types.MaterialAvailabilityKey, quantity?: number | null, date?: number | null, plantLoc?: string | null };

export const MaterialAvailabilityDetailFragmentDoc = gql`
    fragment MaterialAvailabilityDetail on MaterialAvailability {
  key
  quantity
  date
  plantLoc
}
    `;
export const PromotionalBundleItemPricingDetailFragmentDoc = gql`
    fragment PromotionalBundleItemPricingDetail on PromotionalBundleItemPricing {
  materialNumber
  materialName
  materialDescription
  materialId
  brand
  brandKey
  quantity
  discount
  listPrice
  yourPrice
  currency
  availabilityMessages {
    ...MaterialAvailabilityDetail
  }
}
    ${MaterialAvailabilityDetailFragmentDoc}`;
export const PromotionalBundlePricingDetailFragmentDoc = gql`
    fragment PromotionalBundlePricingDetail on PromotionalBundlePricing {
  promotionalBundleId
  listPrice
  yourPrice
  currency
  bundleItems {
    ...PromotionalBundleItemPricingDetail
  }
}
    ${PromotionalBundleItemPricingDetailFragmentDoc}`;
export const PromotionalBundlesPricingDocument = gql`
    query PromotionalBundlesPricing($promotionalBundleId: String!) {
  getPricingForPromotionalBundle(input: {promotionalBundleId: $promotionalBundleId}) {
    ...PromotionalBundlePricingDetail
  }
}
    ${PromotionalBundlePricingDetailFragmentDoc}`;
export type PromotionalBundlesPricingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PromotionalBundlesPricingQuery, PromotionalBundlesPricingQueryVariables>, 'query'> & ({ variables: PromotionalBundlesPricingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PromotionalBundlesPricingComponent = (props: PromotionalBundlesPricingComponentProps) => (
      <ApolloReactComponents.Query<PromotionalBundlesPricingQuery, PromotionalBundlesPricingQueryVariables> query={PromotionalBundlesPricingDocument} {...props} />
    );
    

/**
 * __usePromotionalBundlesPricingQuery__
 *
 * To run a query within a React component, call `usePromotionalBundlesPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionalBundlesPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionalBundlesPricingQuery({
 *   variables: {
 *      promotionalBundleId: // value for 'promotionalBundleId'
 *   },
 * });
 */
export function usePromotionalBundlesPricingQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PromotionalBundlesPricingQuery, PromotionalBundlesPricingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PromotionalBundlesPricingQuery, PromotionalBundlesPricingQueryVariables>(PromotionalBundlesPricingDocument, options);
      }
export function usePromotionalBundlesPricingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PromotionalBundlesPricingQuery, PromotionalBundlesPricingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PromotionalBundlesPricingQuery, PromotionalBundlesPricingQueryVariables>(PromotionalBundlesPricingDocument, options);
        }
export type PromotionalBundlesPricingQueryHookResult = ReturnType<typeof usePromotionalBundlesPricingQuery>;
export type PromotionalBundlesPricingLazyQueryHookResult = ReturnType<typeof usePromotionalBundlesPricingLazyQuery>;
export type PromotionalBundlesPricingQueryResult = ApolloReactCommon.QueryResult<PromotionalBundlesPricingQuery, PromotionalBundlesPricingQueryVariables>;