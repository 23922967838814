import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  chipDisplay: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('md')]: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  chip: {
    margin: theme.spacing(0, 1, 4),
    '&:first-child': {
      marginLeft: theme.spacing(0),
    },
  },
  chip_selected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
  chip_unavailable: {
    color: theme.palette.common.black,
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[200],
  },
  chip_selected_unavailable: {
    borderColor: theme.palette.primary.main,
  },
}))

interface IChip {
  label: string
  value: string
}

interface SelectableChipsProps {
  items: IChip[]
  selectedChip: string
  unavailableChips?: string[]
  onSelect: (item: string) => void
  id?: string
}

const SelectableChips: React.FC<SelectableChipsProps> = ({
  items,
  selectedChip,
  unavailableChips,
  onSelect,
  id,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.chipDisplay} id={id}>
      {items.map(({ label, value }, i) => {
        const isSelected = value === selectedChip
        const isUnavailable = unavailableChips?.includes(value)
        return (
          <Chip
            key={`${i}-${label}-${value}`}
            label={label}
            variant="outlined"
            color={isSelected ? 'primary' : 'default'}
            clickable
            onClick={() => onSelect(value)}
            className={clsx(classes.chip, {
              [classes.chip_selected]: isSelected,
              [classes.chip_unavailable]: isUnavailable,
              [classes.chip_selected_unavailable]: isSelected && isUnavailable,
            })}
          />
        )
      })}
    </div>
  )
}

export default SelectableChips
