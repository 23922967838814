import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useProductSearchQuery } from '@src/queries/ProductSearchQuery.generated'
import { CatalogType, ProductSearchType } from '@src/types/graphql-types'
import { useSearchQuery } from '../../../pages/[country]/[language]/search/[searchTerm]'
import messages from '../messages'
import Box from '@src/components/Box'
import Pagination from '@src/components/Pagination'
import GridOrListSRPErrorAndLoading from '@src/components/GridOrListSRPErrorAndLoading'
import { ViewPreference } from '../SearchResults'
import SearchResultsGrid from '../SearchResultsGrid'
import ResponsiveSubstance from '../ProductSearchResults/ResponsiveSubstance'

const B2BProductSearchResults = () => {
  const [query, parsedFacets, queryActions] = useSearchQuery()
  const { data, loading, error } = useProductSearchQuery({
    fetchPolicy: 'network-only',
    variables: {
      orgId: query.orgId,
      searchTerm: query.term,
      page: query.page,
      selectedFacets: parsedFacets,
      sort: query.sort,
      type: query.type
        ? (query.type.toUpperCase() as ProductSearchType)
        : undefined,
      catalogType: CatalogType.B2b,
    },
  })

  let metadata
  let items

  return (
    <SearchResultsGrid
      showFacets
      showSortBar
      orgId={query.orgId}
      loading={loading}
      metadata={data?.getProductSearchResults?.metadata}
      facets={data?.getProductSearchResults?.facets}
    >
      {!data?.getProductSearchResults ? (
        <GridOrListSRPErrorAndLoading
          view={ViewPreference.list}
          error={error}
        />
      ) : (
        (({ metadata, items } = data.getProductSearchResults),
        (
          <>
            {items.length ? (
              items.map((substance, index) => (
                <ResponsiveSubstance key={index} substance={substance} />
              ))
            ) : (
              <FormattedMessage {...messages.SORRY_NO_MATCHES_FOUND_FOR}>
                {(text) => (
                  <Box fontSize={20} mt={6}>
                    {text} {`'${query.term}'`}
                  </Box>
                )}
              </FormattedMessage>
            )}
            {metadata.numPages > 1 && (
              <Pagination
                curPage={query.page}
                numPages={metadata.numPages}
                clickHandler={queryActions.handlePageChange}
              />
            )}
          </>
        ))
      )}
    </SearchResultsGrid>
  )
}

export default B2BProductSearchResults
