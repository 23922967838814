import { Button, CircularProgress, Theme, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import ResponsiveModal from '@src/components/ResponsiveModal'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  ReadyToShipNotificationRequestStatus,
  ReadyToShipNotificationModalType,
} from '@src/components/ProductInfo/ReadyToShipNotification'

interface ReadyToShipNotificationModalProps {
  requestStatus: ReadyToShipNotificationRequestStatus
  modalType: ReadyToShipNotificationModalType
  handleClick(): void
  handleError(): void
  handleClose(): void
  userEmail: string
  productSKU: string
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(6),
  },
  text: {
    marginBottom: theme.spacing(8),
  },
  callOut: {
    wordWrap: 'break-word',
    fontWeight: 700,
  },
  modalBody: {
    margin: theme.spacing(0, 4, 4, 4),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 8, 8, 8),
    },
  },
  modalActions: {
    display: 'flex',
    flexDirection: 'column',
    '& button:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'unset',
      justifyContent: 'flex-end',
      '& button:not(:last-child)': {
        marginRight: theme.spacing(4),
        marginBottom: 'unset',
      },
    },
  },
}))

export const ReadyToShipNotificationModal: React.FC<
  ReadyToShipNotificationModalProps
> = ({
  requestStatus,
  modalType,
  handleClick,
  handleError,
  handleClose,
  userEmail,
  productSKU,
}) => {
  const classes = useStyles()
  return (
    <>
      {modalType === 'request' && (
        <ResponsiveModal
          open={requestStatus === 'ready'}
          onClose={() => handleClose()}
          renderTitle={() => (
            <Typography variant="h2" component="h2" className={classes.title}>
              <FormattedMessage id="NOTIFY_ME" defaultMessage="Notify Me" />
            </Typography>
          )}
          closeButtonId="mat-avl-modal-close"
        >
          <div className={classes.modalBody}>
            <Typography component="p" className={classes.text}>
              <FormattedMessage
                id="NOTIFY_ME_DESCRIPTION_MODAL"
                defaultMessage="We will send an email to {email} when the available to ship date for {productSKU} is within 30 days."
                values={{
                  email: (
                    <Typography component="span" className={classes.callOut}>
                      {userEmail}
                    </Typography>
                  ),
                  productSKU: (
                    <Typography component="span" className={classes.callOut}>
                      {productSKU}
                    </Typography>
                  ),
                }}
              />
            </Typography>
            <div className={classes.modalActions}>
              <Button
                data-testid="modal-notify-me-cancel"
                color="primary"
                variant="outlined"
                size="large"
                onClick={() => handleClose()}
              >
                <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
              </Button>
              <Button
                id="modal-notify-me"
                color="primary"
                variant="contained"
                size="large"
                onClick={handleClick}
                endIcon={
                  requestStatus === 'communicating' && (
                    <CircularProgress color="inherit" size={20} thickness={5} />
                  )
                }
                disabled={requestStatus === 'communicating'}
              >
                <FormattedMessage id="NOTIFY_ME" defaultMessage="Notify Me" />
              </Button>
            </div>
          </div>
        </ResponsiveModal>
      )}
      {modalType === 'error' && (
        <ResponsiveModal
          role="alertdialog"
          aria-labelledby="alertHeading"
          aria-describedby="alertText"
          aria-relevant="all"
          open={modalType === 'error'}
          onClose={() => handleClose()}
          renderTitle={() => (
            <Typography
              variant="h2"
              component="h2"
              className={classes.title}
              id="alertHeading"
            >
              {requestStatus === 'communicating' ? (
                <FormattedMessage id="PROCESSING" defaultMessage="Processing" />
              ) : (
                <FormattedMessage id="ERROR" defaultMessage="Error" />
              )}
            </Typography>
          )}
          closeButtonId="mat-avl-modal-close"
        >
          <div className={classes.modalBody}>
            <Typography component="p" className={classes.text} id="alertText">
              <FormattedMessage
                id="NOTIFY_ME_ERROR"
                defaultMessage="We are unable to complete the request at this time. Please try again."
              />
            </Typography>
            <div className={classes.modalActions}>
              <Button
                data-testid="modal-notify-me-cancel"
                color="primary"
                variant="outlined"
                size="large"
                onClick={() => handleClose()}
              >
                <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
              </Button>

              <Button
                id="modal-notify-me-try-again"
                color="primary"
                variant="contained"
                size="large"
                onClick={handleError}
                endIcon={
                  requestStatus === 'communicating' && (
                    <CircularProgress color="inherit" size={20} thickness={5} />
                  )
                }
                disabled={requestStatus === 'communicating'}
              >
                <FormattedMessage id="TRY_AGAIN" defaultMessage="Try Again" />
              </Button>
            </div>
          </div>
        </ResponsiveModal>
      )}
    </>
  )
}
